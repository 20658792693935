
const getProductPriceFromRatePlan = (productRatePlan) => Number?.parseFloat(productRatePlan?.productRatePlanCharges?.reduce((acc, currentCharge) => acc + currentCharge?.pricing[0]?.price, 0));

const getRecurringRatePlanFromProduct = (product) => product?.productRatePlans?.find(plan => plan?.productRatePlanCharges[0]?.billingPeriod === 'Month');

const getAnnualRatePlanFromProduct = (product) => product?.productRatePlans?.find(plan => plan?.productRatePlanCharges[0]?.billingPeriod === 'Annual');

const convertPriceToUseComa = (priceWithDot) => {
  if (!priceWithDot) {
    return undefined
  }

  return priceWithDot.toString().replace(/\./g, ',');
}

export default { getProductPriceFromRatePlan, getRecurringRatePlanFromProduct, getAnnualRatePlanFromProduct, convertPriceToUseComa };
