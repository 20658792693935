import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';

@inject('authStore', 'mypageStore')
@observer
class Logout extends Component {
  constructor(props) {
    super(props);
    this.props.authStore.logout();
    this.props.mypageStore.setSelectedManageableVehicle(undefined);
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default Logout;
