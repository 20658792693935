import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import AppBrand from '../AppBrand';
import { IphoneIcon, MenuVsIcon } from '../../icons';

import './SmallHeader.css';

@inject('translationsStore', 'mypageStore')
@observer
export default class SmallHeader extends Component {
  onLeftClick = () => this.props.onLeftClick instanceof Function && this.props.onLeftClick();

  onRightClick = () => this.props.onRightClick instanceof Function && this.props.onRightClick();

  render() {
    const { translationsStore, mypageStore } = this.props;
    const cls = classNames({
      SmallHeader: true,
      [this.props.className]: !!this.props.className
    });
    return (
      <div className={cls} style={{ display: 'flex' }}>
        <div onClick={this.onLeftClick} style={{ flex: 1, color: 'var(--black-color)', cursor: 'pointer' }}>
          <MenuVsIcon />
        </div>
        <div style={{ flex: 1, alignItems: 'center' , color: 'var(--action-primary-color)', fontSize: 64 }}>
          <AppBrand style={{ color: 'var(--action-brand-color)' }} />
        </div>
        <div onClick={this.onRightClick} style={{ flex: 1, fontSize: 16, fontWeight: 'bold', cursor: 'pointer', alignItems: 'flex-end' }}>
          {isMobile && (!mypageStore.remoteUserIsFromMoVeInProgram || mypageStore.remoteUserhasSubscribedMyApp)  && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IphoneIcon style={{ marginRight: 4 }} />
              {translationsStore.translate('common.actionSwitchToApp')}
            </div>
          )}
        </div>
      </div>
    );
  }
}
