import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import AppBrand from '../AppBrand';

import './AuthMobileContainer.css';

@inject('translationsStore', 'loginStore', 'authStore')
@observer
export default class AuthMobileContainer extends Component {
  render() {
    const { welcomeNode } = this.props;
    const cls = classNames({
      AuthMobileContainer: true,
      [this.props.className]: !!this.props.className
    });
    const brandStyle = { marginTop: 40, width: 127, height: 56, color: 'var(--action-brand-color)' };
    return (
      <div className={cls}>
        <div>
          <AppBrand style={brandStyle} />
        </div>
        <div>
          <div
            style={{
              borderBottom: '2px solid var(--action-primary-color)',
              paddingBottom: 8,
              color: 'var(--action-primary-color)',
              fontSize: 24,
              fontWeight: 600,
              letterSpacing: -0.2,
              lineHeight: '36px'
            }}
          >
            {welcomeNode}
          </div>
        </div>
        <div>{this.props.children}</div>
        <div />
      </div>
    );
  }
}
