import React, {Component, Fragment} from "react";
import AirModal from "../../../../common/components/AirModal";
import MyPageButton from "../../../../common/components/MyPageButton";
import {DatePicker} from "antd";
import {inject, observer} from "mobx-react";
import moment from "moment";

@inject('translationsStore', 'mypageStore')
@observer
class AgendaRenewalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedDate: undefined
    }
  }

  onSubmit = async () => {
    this.setState({modalVisible: false});
    await this.props.onSubmit(moment(this.state.selectedDate).format("YYYY-MM-DD"));
  };

  render() {
    return (
      <Fragment>
        <MyPageButton
          theme={this.props.disabled ? "grey" : "warning"}
          solid
          disabled={this.props.disabled}
          title="Aggiorna"
          onClick={() => this.setState({modalVisible: true})}
        />
        <AirModal
          width="90vw"
          className={"AgendaModal"}
          visible={this.state.modalVisible}
          onCancel={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <h2>Inserisci la data dell'intervento/rinnovo</h2>
          <div className="Content">
            <DatePicker value={this.state.selectedDate} onChange={(date) => this.setState({selectedDate: date})} />
            <MyPageButton
              theme="primary"
              solid
              title="Salva"
              onClick={this.onSubmit}
            />
          </div>
        </AirModal>
      </Fragment>
    )
  }
}

export default AgendaRenewalButton
