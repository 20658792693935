import React from 'react';
import ProductCardBase from "./ProductCardBase";

const AirProductCard = ({...otherProps}) => {
  return (
    <ProductCardBase
      logo="air_product"
      {...otherProps}
    />

  )
};

export default AirProductCard;
