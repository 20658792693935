import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import MyPageContainer from '../../../../common/components/MyPageContainer';

import './SuccessView.css';

@inject('translationsStore', 'mypageStore')
@observer
export default class SuccessView extends Component {
  componentDidMount() {
    this.props.mypageStore.updateUserManageableVehicles();
  }

  render() {
    const { translationsStore, ...props } = this.props;
    const successMessage = (
      <div className="SuccessView">
        <h2>Grazie, il pagamento è andato a buon fine.</h2>
        <p>Ti ricordiamo che entro 30 giorni dovrai recarti dall'installatore per completare la procedura.</p>
        <p>Benvenuto in Air!</p>
      </div>
    );
    return <MyPageContainer {...props}>{successMessage}</MyPageContainer>;
  }
}
