import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withRouter} from "react-router-dom";
import AirModal from "../../../../../common/components/AirModal";
import PaymentPage from "./PaymentPage";
import Success from "./Success";
import "./PaymentMethodPopup.css"

@withRouter
@inject('mypageStore')
@observer
export default class PaymentMethodPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'payment'
    }
  }

  onClose = () => {
    const {mypageStore} = this.props;

    mypageStore.getZuoraPaymentMethods();
    this.props.onCancel();
    this.setState({page: 'payment'});
  }

  renderContent = () => {
    const {mypageStore} = this.props;

    if(this.state.page === 'payment') {
      return <PaymentPage customerDetails={mypageStore.remoteUserData} onNext={() => this.setState({page: 'success'})} />
    }
    if(this.state.page === 'success') {
      return <Success onNext={this.onClose} />
    }
  }

  render() {
    return (
      <AirModal
        className='PaymentMethodPopup'
        visible={this.props.hasOwnProperty('visible') ? this.props.visible : true}
        onCancel={this.onClose}
        buttonsStyle={{ display: 'none' }}
        width={600}
      >
        {this.renderContent()}
      </AirModal>
    );
  }
}
