import MyPageButton from "../../../../common/components/MyPageButton";
import { Media as BreakpointsMedia } from 'react-breakpoints';
import React, {Component} from "react";
import {Radio, Modal, message, Checkbox} from "antd";
import {inject, observer} from "mobx-react";

@inject('translationsStore', 'mypageStore')
@observer
class PrivacyPolicyAirClub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managePolicy: false,
      air_club_profiling: props.mypageStore.selectedManageableVehicle.air_club_profiling,
      air_club_marketing: props.mypageStore.selectedManageableVehicle.air_club_marketing,
      air_club_third_parties: props.mypageStore.selectedManageableVehicle.air_club_third_parties
    }
  }

  showConfirm = () => {
    Modal.confirm({
      title: 'Attenzione',
      content: 'State modificando il consenso del marketing, siete sicuri di procedere?',
      okText: 'Si',
      cancelText: 'No',
      onOk: async() => {
        try {
          await this.props.mypageStore.updateAirClubMarketingConsent(
            true,
            this.state.air_club_profiling,
            this.state.air_club_marketing,
            this.state.air_club_third_parties
          )
        } catch (e) {
          console.error(e);
          message.error(this.props.translationsStore.translate('common.genericError'));
        }
        this.props.onCancel()
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  onPolicyChange = (e) => {
    this.setState({policyAccepted: e.target.value})
  };

  render() {
    const { src = K.POLICY_BASE_URL.concat('/privacy-policy-2022-09-23.pdf') } = this.props;

    if(this.state.managePolicy) {
      return (
        <BreakpointsMedia>
          {({ breakpoints, currentBreakpoint }) => {
            const classes = breakpoints[currentBreakpoint] >= breakpoints.desktop ? "PrivacyUpdate AirclubPrivacyForm desktop" : "PrivacyUpdate AirclubPrivacyForm";

            return (
              <div className={classes}>
                <h1 className="PrivacyTitle">Informativa sulla privacy</h1>
                <p className="Subtitle">Per usufruire dei nostri servizi e necessario fornire il consenso al trattamento dei dati Personali secondo il nuovo Reglamenta EU 2016/679, noto come GDPR.</p>

                <p className="AgreementItem">Il sottoscritto, informato in merito al trattamento dei propri dati personali da parte di AIR S.r.l. (la “Società”), come da informativa da quest’ultima predisposta ai sensi dell’art. 13 del Regolamento UE 2016/679 che conferma di aver letto e ben compreso, conferma di aver preso atto delle conseguenze del rifiuto a conferire i Dati Personali per determinati trattamenti e di essere consapevole che il consenso è revocabile in qualsiasi momento. Inoltre il sottoscritto dichiara quanto segue:</p>

                <p className="AgreementItem">Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per l’individuazione e lo studio dei dati sulle concrete modalità di utilizzo del veicolo, quali anche acquisiti tramite Dispositivo di cui all’articolo 2 lett. e) dell’informativa (profilazione):</p>
                <div className="PrivacyUpdateForm" style={{display: 'flex', fontWeight: 'bold', marginBottom: 16}}>
                  <div style={{marginRight: 40}}><Checkbox onChange={() => this.setState({air_club_profiling: true})} checked={this.state.air_club_profiling === true} /> Acconsento</div>
                  <div><Checkbox onChange={() => this.setState({air_club_profiling: false})} checked={this.state.air_club_profiling === false} /> Non acconsento</div>
                </div>

                <p className="AgreementItem">Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per attività promozionali e di vendita di cui all’articolo 2 lett. f) dell’informativa (marketing): comunicazione ed iniziative promozionali e di vendita di prodotti e/o servizi della società o di soggetti terzi appartenenti alle categorie sopra menzionate via email, SMS o altri sistemi automatizzati e per posta, nonché per ricerche di mercato e rilevazioni statistiche. Il consenso per marketing deve intendersi esteso anche alle modalità tradizionali di contatto:</p>
                <div className="PrivacyUpdateForm" style={{display: 'flex', fontWeight: 'bold', marginBottom: 16}}>
                  <div style={{marginRight: 40}}><Checkbox onChange={() => this.setState({air_club_marketing: true})} checked={this.state.air_club_marketing === true} /> Acconsento</div>
                  <div><Checkbox onChange={() => this.setState({air_club_marketing: false})} checked={this.state.air_club_marketing === false} /> Non acconsento</div>
                </div>

                <p className="AgreementItem">Con riferimento al trasferimento dei Dati Personali, compresi quelli raccolti tramite Dispositivo, a soggetti terzi (imprese di assicurazione, broker, agenti assicurativi, banche e intermediari finanziari, operatori del settore automobilistico e della componentistica, carrozzerie e autofficine, fornitori di prodotti e servizi relativi al settore automotive, compresi fornitori di servizi di navigazione e viabilità), per lo svolgimento da parte di questi ultimi di finalità loro proprie, ove del caso anche di marketing, come indicato all’articolo 2 lett. g) dell’informativa:</p>
                <div className="PrivacyUpdateForm" style={{display: 'flex', fontWeight: 'bold', marginBottom: 16}}>
                  <div style={{marginRight: 40}}><Checkbox onChange={() => this.setState({air_club_third_parties: true})} checked={this.state.air_club_third_parties === true} /> Acconsento</div>
                  <div><Checkbox onChange={() => this.setState({air_club_third_parties: false})} checked={this.state.air_club_third_parties === false} /> Non acconsento</div>
                </div>

                <div style={{display: "flex", justifyContent: "flex-end", flexWrap: "wrap", marginTop: 8}}>
                  <MyPageButton
                    theme="primary"
                    solid
                    title={"Annula le Modifiche"}
                    onClick={() => {this.props.onCancel()}}
                  />
                  <MyPageButton
                    theme="primary"
                    solid
                    title={"Salva le Modifiche"}
                    onClick={this.showConfirm}
                  />
                </div>
              </div>
            )
          }}
        </BreakpointsMedia>
      )
    }
    return (
      <React.Fragment>
        <iframe src={src} width="100%" height="540" />
        <div style={{display: "flex", justifyContent: "center", marginTop: 8}}>
          <MyPageButton
            theme="primary"
            solid
            style={{width: "initial"}}
            title={"Gestisci Consensi"}
            onClick={() => {this.setState({managePolicy: true})}}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default PrivacyPolicyAirClub;
