import React, {Component} from "react";
import Button from "antd/es/button";
import {inject, observer} from "mobx-react";


@inject('translationsStore')
@observer
export default class Success extends Component {

  render() {
    const {translationsStore} = this.props;

    return (
      <div>
        <h3>{translationsStore.translate('paymentManagement.paymentMethodPopup.successTitle')}</h3>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
          <Button
            style={{width: 'auto'}}
            onClick={this.props.onNext}
            type="primary"
          >{translationsStore.translate('paymentManagement.paymentMethodPopup.ok')}</Button>
        </div>
      </div>
    )
  }
}

