import React from 'react';
import {Input, Select} from 'antd';
import classNames from "classnames";
import provinces from "./provinces";
import './ProvinceSelector.css'

const { Option } = Select;

const ProvinceSelector = React.forwardRef(({label, style, help, validateStatus, useInputText, showCodeAsLabel, ...props}, ref) => {
  const cls = classNames({
    ProvinceSelector: true,
    ['has-error']: validateStatus === 'error'
  });

  return (
    <div className={cls} style={style} ref={ref}>
      {label ? <div>{label}</div> : undefined}
      {
        !useInputText ? (
          <Select
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            {...props}
          >
            {provinces.map(province =>  (
              <Option key={province.prov_code} value={province.prov_code}>{showCodeAsLabel ? province.prov_code : province.prov_name}</Option>
            ))}
          </Select>
        ) : (
          <Input {...props} />
        )
      }

      {!!help && <div className={'LabelInputExplain'}>{help}</div>}
    </div>
  );
});

export default ProvinceSelector;
