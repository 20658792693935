import React from 'react';
import { Select } from 'antd';
import classNames from "classnames";

const { Option } = Select;

import rawCountries from "./rawCountries";
const countries = Object.keys(rawCountries).map(c => ({code: c, name: rawCountries[c]}));

import './StateSelector.css'

const StateSelector = React.forwardRef(({label, style, help, validateStatus, ...props}, ref) => {
  const cls = classNames({
    StateSelector: true,
    ['has-error']: validateStatus === 'error'
  });

  return (
    <div className={cls} style={style} ref={ref}>
      {label ? <div>{label}</div> : undefined}
      <Select
        showSearch
        defaultValue="IT"
        filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        {...props}
      >
        {countries.map(country =>  (
          <Option key={country.code} value={country.code}>{country.name}</Option>
        ))}
      </Select>
      {!!help && <div className={'LabelInputExplain'}>{help}</div>}
    </div>
  );
});

export default StateSelector;
