import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Checkbox } from 'antd';

import './MyPageCheckbox.css';

@inject('translationsStore')
@observer
export default class MyPageCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked || false
    };
  }

  componentDidMount = () => {
    if (this.props.hasOwnProperty('checked') && this.props.checked !== this.state.checked) {
      this.setState({ checked: this.props.checked });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.hasOwnProperty('checked') && this.props.checked !== this.state.checked) {
      this.setState({ checked: this.props.checked });
    }
  };

  setChecked = checked => {
    this.setState({ checked }, () => {
      this.props.onChange instanceof Function && this.props.onChange(checked);
    });
  };

  toggleChecked = () => {
    const checked = !this.state.checked;
    this.setChecked(checked);
  };

  onChange = e => {
    const checked = e.target.checked;
    this.setChecked(checked);
  };

  render() {
    const cls = classNames({
      MyPageCheckbox: true,
      [this.props.className]: !!this.props.className
    });
    const { label } = this.props;
    return (
      <div className={cls}>
        <Checkbox checked={this.state.checked} onChange={this.onChange} />
        <div onClick={this.toggleChecked}>{label}</div>
      </div>
    );
  }
}
