import React from 'react';
import K from '../constants';
import { CreditCardOutlinedIcon, WalletOutlinedIcon, SavingsIcon } from '../common/icons';
import { isAndroid } from 'react-device-detect';
import { message } from 'antd';

const MESSAGE_CONFIG = {
  top: 100,
  duration: 3,
  maxCount: 3
};

const manageableVehicleLabel = ({ translationsStore, vehicle }) => {
  let label = `${translationsStore.translate('common.plate')}: ${vehicle.license_plate}`;
  if (vehicle.hasOwnProperty('brand') && vehicle.hasOwnProperty('model')) {
    label = `${vehicle.brand.name} ${vehicle.model.name} - ${label}`;
  }
  return label;
};

const paymentIcon = (payment) => {
  const key = payment.hasOwnProperty('key') ? payment.key : payment;
  switch (key) {
    case 'creditcard':
      return <CreditCardOutlinedIcon />;
    case 'sepa':
      return <SavingsIcon />
    default:
      return <WalletOutlinedIcon />;
  }
};

const handleDeepLink = uri => {
  const scheme = uri.substr(0, uri.indexOf(':'));
  const intentPath = uri.substr(uri.indexOf(':') + 1);
  let destination = undefined;
  if (isAndroid) {
    destination = `intent:${intentPath}#Intent;${scheme ? `scheme=${scheme};` : ''}package=${K.ANDROID_INTENT_PACKAGE};end;`;
  } else {
    if (uri.startsWith(K.IOS_DEEPLINK_DESTINATION)) {
      destination = uri;
    } else {
      destination = `${K.IOS_DEEPLINK_DESTINATION}${intentPath}`;
    }
  }
  window.location = destination;
};

const appMessageError = (str, cb = () => {}) => {
  message.error(str, function() {
    cb();
  });
};

const getOperationalAddress = (installer) => {
  let address = (installer.addresses.find((address) => address.type === 'operational_office') || {}).node;
  if (!address) {
    address = (installer.addresses.find((address) => address.type === 'registered_office') || {}).node;
  }
  return address || {};
};

export default { manageableVehicleLabel, paymentIcon, handleDeepLink, getOperationalAddress, appMessageError, MESSAGE_CONFIG };
