import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import { reaction } from 'mobx';

import './AirRenewalSuggestAssistance.css';
import VehicleSelector from "../../../../common/components/VehicleSelector";

import MyPageContainer from "../../../../common/components/MyPageContainer";
import Section from "../../../../common/components/Section";
import {Checkbox, Radio} from "antd";
import {AxaLogo, DoneIcon, MultipleDocuments, PositionMarker, TowingTruck} from "../../../../common/icons";
import MyPageButton from "../../../../common/components/MyPageButton";
import AirModal from "../../../../common/components/AirModal";
import BaseAssistanceImage from "../../../../common/components/BaseAssistanceImage/BaseAssistanceImage";
import BaseAssistanceFullImage from "../../../../common/components/BaseAssistanceFullImage";
import {Media as BreakpointsMedia} from "react-breakpoints";
import {productsHelper} from "../../../../helpers";
import K from "../../../../constants";

const InfoItem = ({children}) => (
    <div className="ListItemContainer">
      <div className="ListIcon"><DoneIcon width={16} height={16}/></div>
      <p>{children}</p>
    </div>
)

const ServiceInfoIcon = ({ title, children }) => {
  return (
      <div className="ServiceInfoIcon">
        <img src="DriverOnly.png" alt={title} />
        <div style={{fontSize: '1.2em', textTransform: 'uppercase', fontWeight: 600, color: '#F6CF02', textAlign: 'center'}}>{children}</div>
      </div>
  );
};

const FeatureItem = ({ children }) => (
    <div className="ListItemContainer">
      <div className="ListIcon">
        <DoneIcon width={24} height={24} />
      </div>
      <p className="Description">{children}</p>
    </div>
);

@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class AirRenewalSuggestAssistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generalConditionsVisible: false,
      monthlyDiscountedPrice: undefined,
      annualDiscountedPrice: undefined,
      AddonMonthlyPrice: 0.00,
      AddonYearlyPrice: 0.00,
      airclub_general: false,
      checkedAgreedRestrictiveClauses: false
    };
  }

  componentDidMount() {
    disposeOnUnmount(
        this,
        reaction(
            () => this.props.productsStore.selectedProduct && this.props.productsStore.selectedProduct.zuora_product_id,
            zuora_product_id => {
              if (zuora_product_id) {
                const axaProduct = this.getAxaProduct();
                const monthlyRatePlan = this.getAxaRecurring(axaProduct);
                const AddonMonthlyPrice = productsHelper.getProductPriceFromRatePlan(monthlyRatePlan);
                const yearlyRatePlan = this.getAxaOneTime(axaProduct);
                const AddonYearlyPrice = productsHelper.getProductPriceFromRatePlan(yearlyRatePlan);

                const monthlyDiscountedPrice = this.getProductPriceWithDiscount(AddonMonthlyPrice);
                const annualDiscountedPrice = this.getProductPriceWithDiscount(AddonYearlyPrice);

                if (monthlyDiscountedPrice !== undefined && annualDiscountedPrice !== undefined) {

                  this.setState({
                    AddonMonthlyPrice,
                    AddonYearlyPrice,
                    monthlyDiscountedPrice: monthlyDiscountedPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                    annualDiscountedPrice: annualDiscountedPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                  })
                } else {
                  this.setState({
                    AddonMonthlyPrice,
                    AddonYearlyPrice,
                  })
                }
              }
            },
            { fireImmediately: true }
        )
    );
  }

  getAxaRecurring = (product) => {
    // MEMO new addon rate plan to pick has charge billing period `Month`
    return product.productRatePlans.find(plan => plan.productRatePlanCharges[0].billingPeriod === 'Month');
  };

  getAxaOneTime = (product) => {
    // MEMO new addon rate plan to pick has charge billing period `Annual`
    return product.productRatePlans.find(plan => plan.productRatePlanCharges[0].billingPeriod === 'Annual');
  };

  isAxa = (product) => {
    return product.features.some(feature => feature.node.name === 'axa-assistance') && product.features.some(feature => feature.node.name === 'reminder');
  };

  getAxaProduct = () => {
    return this.props.productsStore.availableProducts.find(p => p.sku === 'AssBase-MYG')
  };

  getProductPrice = (productRatePlan) => Number.parseFloat(productRatePlan.productRatePlanCharges.reduce((acc, currentCharge) => acc + currentCharge.pricing[0].price, 0));

  onSubmit = async () => {
    if(this.state.selectedOption === 'none') {
      this.props.history.push('/payments');
      this.props.productsStore.setOptionalServiceProduct(undefined);
      this.props.productsStore.setOptionalService(undefined);
      return;
    }

    const {
      airclub_general,
      air_club_profiling,
      air_club_marketing,
      air_club_third_parties
    } = this.state;

    await this.props.mypageStore.updateAirClubMarketingConsent(
        airclub_general,
        air_club_profiling,
        air_club_marketing,
        air_club_third_parties,
    );
    this.props.productsStore.setOptionalServiceProduct(this.getAxaProduct());
    this.props.productsStore.setOptionalService(this.state.selectedOption);
    if(this.props.optional) {
      this.props.history.push('/review-services')
    } else {
      this.props.history.push('/payments');
    }
  };

  isSubmitDisabled = () => {
    if(this.state.selectedOption === undefined) return true;
    if(this.state.selectedOption === 'none') return false;

    return this.state.airclub_general !== true ||
        this.state.checkedAgreedRestrictiveClauses !== true
  };

  isAirCustomer = () => {
    return !this.props.mypageStore.remoteUserIsFromMoVeInProgram;
  }

  getProductPriceWithDiscount = (originalPrice) => {
    const {productsStore} = this.props;
    const parsedOriginalPrice = Number.parseFloat(originalPrice);
    const axaProduct = this.getAxaProduct();

    let discount = productsStore.getDiscountFromProductUuid(axaProduct && axaProduct.uuid);

    if (discount) {

      return productsStore.getDiscountedPrice(discount && discount.discountRatePlanId, parsedOriginalPrice);
    } else {
      return undefined;
    }
  }

  render() {
    const { productsStore, translationsStore, optional } = this.props;

    if(!productsStore.selectedProduct) {
      return <Redirect to="/" />;
    }

    const axaProduct = this.getAxaProduct();
    const {monthlyDiscountedPrice, annualDiscountedPrice} = this.state;
    const { AddonMonthlyPrice, AddonYearlyPrice } = this.state;

    const theme = 'assistancebase',
        titles = {
          airclub: 'Servizio Air Club',
          assistancebase: 'Assistenza Base',
          default: 'Servizio Air Club',
        },
        banners = {
          airclub: <>Servizio<br />Air Club</>,
          assistancebase: <>Assistenza<br />Base</>,
          default: <>Servizio<br />Air Club</>,
        },
        title = ((theme) => titles[theme] || titles.default)(theme),
        banner = ((theme) => banners[theme] || banners.default)(theme);

    return (
        <MyPageContainer className='AirRenewalSuggestAssistance' dataCollection={this.props.dataCollection}>

          <VehicleSelector disabled={true} />

          <Section>
            {(annualDiscountedPrice !== undefined || monthlyDiscountedPrice !== undefined) && <div className='DiscountedBadgeContainer'><div className={'DiscountedBadge'}>{translationsStore.translate('servicesView.serviceCard.discount.sale')}</div></div>}

            {theme === 'airclub' ? (
                <>
                  <header>
                    <h2>{translationsStore.translate(`addonsView.${theme}.header`)}</h2>
                  </header>
                  <div className="OptionalServiceInfo">
                    <div className="InfoColumn">
                      <div>
                        <h4>Supporto in caso di furto</h4>
                        <InfoItem>Servizio Car Finder</InfoItem>
                        <InfoItem>Servizio Protezione Banca Dati</InfoItem>
                      </div>

                      <div style={{marginTop: 16}}>
                        <h4>Le tue principali scadenze</h4>
                        <InfoItem>Reminder scadenza Bollo</InfoItem>
                        <InfoItem>Reminder scadenza Revisione</InfoItem>
                        <InfoItem>Reminder scadenza Assicurazione</InfoItem>
                        <InfoItem>Reminder scadenza Manutenzione</InfoItem>
                      </div>
                    </div>
                    <ServiceInfoIcon title={title}>{banner}</ServiceInfoIcon>
                    <div className="InfoColumn">
                      <div>
                        <h4>Servizi di assistenza veicolo</h4>
                        <InfoItem>Soccorso stradale H24</InfoItem>
                        <InfoItem>Auto Sostitutiva</InfoItem>
                        <InfoItem>Servizio di Accompagnamento</InfoItem>
                        <InfoItem>Numero di Assistenza attivo H24</InfoItem>
                      </div>

                      <div className="AxaLogoContainer">
                        in collaborazione con <img src="axa_logo_open_white.png" alt="AXA"/>
                      </div>

                      <div className="ProtectionLevel">
                        <div>LIVELLO DI PROTEZIONE</div>
                        <div className="ProtectionBar ProtectionRow">
                          <div /><div /><div /><div />
                        </div>
                        <div className="ProtectionRow">
                          <img src="air_club_car_finder.png" alt="Car Finder" />
                          <img src="air_club_calendar.png" alt="Calendar" />
                          <img src="air_club_truck.png" alt="Truck" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="OptionalServiceSelectionContainer">
                    <div className="OptionalServiceSelection">
                      <div>Aggiungi a soli <span className={annualDiscountedPrice !== undefined ? 'StrikeThroughPrice' : 'AirClubPrice'}>
              {AddonYearlyPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})} € </span>
                        {annualDiscountedPrice !== undefined && <span className='DiscountPrice'> {annualDiscountedPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})} € </span>}
                        per un anno
                      </div>
                      <Checkbox
                          onChange={() => this.setState({selectedOption: this.getAxaOneTime(axaProduct)})}
                          checked={this.state.selectedOption === this.getAxaOneTime(axaProduct)}
                      />
                    </div>
                    <div className="OptionalServiceSelection">
                      <div>Aggiungi a soli <span className={monthlyDiscountedPrice !== undefined ? 'StrikeThroughPrice' : 'AirClubPrice'}>
              {AddonMonthlyPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})} € </span>
                        {monthlyDiscountedPrice !== undefined && <span className='DiscountPrice'> {monthlyDiscountedPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})} € </span>}
                        al mese per un anno</div>
                      <Checkbox
                          onChange={() => this.setState({selectedOption: this.getAxaRecurring(axaProduct)})}
                          checked={this.state.selectedOption === this.getAxaRecurring(axaProduct)}
                      />
                    </div>
                  </div>
                </>
            ) : (
                <>
                  <div>
                    <h2 className={'AssistanceTitle'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.title')}</h2>
                  </div>
                  <div className={'BaseAssistanceSubTitleContainer'}>
                    <h3>{translationsStore.translate('purchaseAssistanceView.subtitle')}</h3>
                  </div>
                  <div className={'SvgImageContainer'}>
                    <BreakpointsMedia>
                      {({ breakpoints, currentBreakpoint }) =>
                          breakpoints[currentBreakpoint] > breakpoints.extrasmall ? (
                              <BaseAssistanceFullImage width={441} height={155}/>
                          ) : (
                              <BaseAssistanceImage width={441} height={155}/>
                          )
                      }
                    </BreakpointsMedia>
                  </div>
                  <div className={'ThreeColumnView'}>
                    <div className={'OneColumnComponent'}>
                      <div className={'AssistanceFeatureCardContainer'}>
                        <div className={'AssistanceFeatureOneColumnComponent'} />
                        <div className={'AssistanceFeatureOneColumnComponent'}>
                          <div className={'AssistanceColumnTitle'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.certificatedReport')}</div>
                          <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.localizationReport')}</div>
                        </div>
                        <div className={'AssistanceFeatureOneColumnComponent'}>
                          <div className={'AssistanceIconContainer'}> <MultipleDocuments className={'AssistanceIconColor'}/> </div>
                        </div>
                      </div>
                    </div>
                    <div className={'OneColumnComponent'}>
                      <div className={'AssistanceFeatureCardContainer'}>
                        <div className={'AssistanceFeatureOneColumnComponent'}>
                          <div className={'AxaIconContainer'}>
                            <AxaLogo/>
                          </div>
                        </div>
                        <div className={'AssistanceFeatureOneColumnComponent'}>
                          <div className={'AssistanceColumnTitle'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.vehicleAssistance')}</div>
                          <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.h24Support')}</div>
                          <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.courtesyCar')}</div>
                          <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.taxiService')}</div>
                          <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.h24Number')}</div>
                        </div>
                        <div className={'AssistanceFeatureOneColumnComponent'}>
                          <div className={'AssistanceIconContainer'}> <TowingTruck className={'AssistanceIconColor'}/>  </div>
                        </div>
                      </div>
                    </div>
                    <div className={'OneColumnComponent'}>
                      <div className={'AssistanceFeatureCardContainer'}>
                        <div className={'AssistanceFeatureOneColumnComponent'} />
                        <div className={'AssistanceFeatureOneColumnComponent'}>
                          <div className={'AssistanceColumnTitle'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.theftSupport')}</div>
                          <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.carFinder')}</div>
                          <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.dataBankProtection')}</div>
                        </div>
                        <div className={'AssistanceFeatureOneColumnComponent'}>
                          <div className={'AssistanceIconContainer'}> <PositionMarker className={'AssistanceIconColor'}/> </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="BaseAssistanceProductPlanSelection">
                    <h3>{translationsStore.translate('purchaseAssistanceView.chooseTheBestPlanForYou')}</h3>
                    <div className={'BaseAssistanceCheckboxContainer'}>
                      <div className="BaseAssistanceProductPlan">
                        <Checkbox
                            onChange={() => this.setState({selectedOption: this.getAxaOneTime(axaProduct)})}
                            checked={this.state.selectedOption === this.getAxaOneTime(axaProduct)}
                        />
                        <div>&nbsp;</div>
                        <div className="BaseAssistancePlanPrice">
                          {annualDiscountedPrice !== undefined && <div className='DiscountPrice'>{' '}{annualDiscountedPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>}
                          <span className={`ColoredPriceBaseAssistance ${monthlyDiscountedPrice ? 'StrikeThroughPrice' : ''}`}>{AddonYearlyPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}€</span>{' '}per un anno
                        </div>
                      </div>
                      <div className="BaseAssistanceProductPlan">
                        <Checkbox
                            onChange={() => this.setState({selectedOption: this.getAxaRecurring(axaProduct)})}
                            checked={this.state.selectedOption === this.getAxaRecurring(axaProduct)}
                        />
                        <div>&nbsp;</div>
                        <div className="BaseAssistancePlanPrice">
                          {monthlyDiscountedPrice !== undefined &&
                              <div className='DiscountPrice'>{' '}{monthlyDiscountedPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})} €</div>}
                          <span className={`ColoredPriceBaseAssistance ${monthlyDiscountedPrice ? 'StrikeThroughPrice' : ''}`}>{AddonMonthlyPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2})}€/mese</span>{' '}per un anno
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            )}

            {this.state.selectedOption && this.state.selectedOption !== 'none' && <div>
              <div className="AirclubPrivacyForm" style={{display: 'flex'}}>
                <div className="OptionalServicePrivacy">
                  <div className={'generalConditionsCheckboxContainer'}>
                    <Checkbox className="OptionalServicePrivacyCheck" onChange={() => this.setState(prevState => ({airclub_general: !prevState.airclub_general}))}
                              checked={this.state.airclub_general === true}/>
                    <h4 className="PrivacyHeadTitle">Confermo di aver preso visione e di accettare integralmente le <span
                        onClick={() => this.setState({generalConditionsVisible: true})}
                        style={{fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer'}}>Condizioni Generali per l'Adesione ai Servizi Aggiuntivi AIR</span>.
                    </h4>
                  </div>
                  <div  className={'generalConditionsCheckboxContainer'}>
                    <Checkbox className="OptionalServicePrivacyCheck" onChange={() => this.setState(prevState => ({checkedAgreedRestrictiveClauses: !prevState.checkedAgreedRestrictiveClauses}))}
                              checked={this.state.checkedAgreedRestrictiveClauses === true}/>
                    <p>Ai sensi e per gli effetti di cui agli articoli 1341 e 1342 Codice Civile, dichiaro di aver letto attentamente e di approvare specificatamente le seguenti clausole di cui alle Condizioni Generali: 1.4; 1.5; 2.6; 2.11; 2.12; 3.2, 3.3; 5.3, 5.4; 6.2; 8; 10; 15.2.</p>
                  </div>
                </div>
                <AirModal
                    className="EmbeddedDocumentModal"
                    width="90vw"
                    visible={this.state.generalConditionsVisible}
                    onCancel={() => {
                      this.setState({generalConditionsVisible: false});
                    }}
                >
                  <iframe src={K.POLICY_BASE_URL.concat('condizioni-generali-servizi-aggiuntivi-2022-09-23.pdf')} width="100%" height="540"/>
                </AirModal>
              </div>
            </div>}

            {theme === 'airclub' ? (<>
              {optional && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div style={{marginRight: 16}}>{
                  !this.isAirCustomer()
                      ? "Desidero procedere con Move-In senza aderire al servizio assistenza"
                      : "Desidero procedere con il Rinnovo senza aderire al servizio assistenza"
                }</div>
                <Radio
                    onChange={() => this.setState({selectedOption: 'none'})}
                    checked={this.state.selectedOption === 'none'}
                />
              </div>
              }
              <MyPageButton
                  className="NextButton"
                  title={ "Prosegui"}
                  disabled={this.props.mypageStore.loadingUpdatingAirClubMarketing || this.isSubmitDisabled()}
                  solid
                  theme="primary"
                  onClick={this.onSubmit}
              />
            </>) : (
                <BreakpointsMedia>
                  {({ breakpoints, currentBreakpoint }) =>
                      breakpoints[currentBreakpoint] > breakpoints.extrasmall ? (
                          <>
                            <div className={'BaseAssistanceProceedButtonContainer'}>
                              <MyPageButton
                                  theme="primary"
                                  className={'BaseAssistanceBuyButton'}
                                  solid
                                  disabled={this.props.mypageStore.loadingUpdatingAirClubMarketing || this.isSubmitDisabled()}
                                  title={translationsStore.translate('purchaseAssistanceView.baseAssistance.proceedToBuy')}
                                  onClick={this.onSubmit}
                              />
                            </div>
                            {optional && <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 16}}>
                              <div style={{marginRight: 16}}>{
                                this.isAirCustomer() ? "Desidero procedere con il Rinnovo senza aderire al servizio assistenza" : "Aderisci a Move-in senza Assistenza Base"
                              }</div>
                              <MyPageButton
                                  theme="gray"
                                  className={'BaseAssistanceDontBuyButton'}
                                  solid
                                  title={translationsStore.translate('purchaseAssistanceView.baseAssistance.proceedWithout')}
                                  onClick={() => this.setState({selectedOption: 'none'}, this.onSubmit)}
                              />
                            </div>
                            }
                          </>
                      ) : (
                          <>
                            <div className={'BaseAssistanceProceedButtonsMobileContainer'}>
                              {optional && <div>
                                <MyPageButton
                                    theme="gray"
                                    className={'BaseAssistanceDontBuyMobileButton'}
                                    solid
                                    title={translationsStore.translate('purchaseAssistanceView.baseAssistance.proceedWithoutNotInterested')}
                                    onClick={() => this.setState({selectedOption: 'none'}, this.onSubmit)}
                                />
                              </div>
                              }
                              <MyPageButton
                                  theme="primary"
                                  className={'BaseAssistanceBuyButton'}
                                  innerStyle={{maxWidth: 100}}
                                  solid
                                  disabled={this.props.mypageStore.loadingUpdatingAirClubMarketing || this.isSubmitDisabled()}
                                  title={translationsStore.translate('purchaseAssistanceView.baseAssistance.proceedToBuy')}
                                  onClick={this.onSubmit}
                              />
                            </div>
                          </>
                      )
                  }
                </BreakpointsMedia>
            )}
          </Section>
          <div className="AirClubInfoContract"><a href={K.POLICY_BASE_URL.concat('AIR - Nota informativa precontrattuale.pdf')} target="_blank" alt="Nota informativa">Nota informativa precontrattuale</a></div>

        </MyPageContainer>
    );
  }
}

export default AirRenewalSuggestAssistance;
