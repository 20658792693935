import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { mypageHelper } from '../../../helpers';
import { MobileOnlyView, AndroidView, IOSView, isMobileOnly, isIOS } from 'react-device-detect';
import classNames from 'classnames';

import './MobileMarketplaceLink.css';

const onClickGooglePlay = () => window.location = 'https://play.google.com/store/apps/details?id=io.myair.air&hl=it';
const onClickAppleStore = () => window.location = 'https://apps.apple.com/it/app/myapp-air/id1048912316?itsct=apps_box_link&itscg=30200';
const badgeAssetGooglePlayDefault = 'assets/marketplaces/badges/google-play-store-badge.svg';
const badgeAssetAppleStoreDefault = 'assets/marketplaces/badges/black.svg';
const badgeAssetGooglePlayItalian = 'assets/marketplaces/badges/google-play-store-badge.it.svg';
const badgeAssetAppleStoreItalian = 'assets/marketplaces/badges/black.it.svg';

const GooglePlayBadge = inject('translationsStore')(({ ...props }) => {
  const { translationsStore } = props;
  let badgeAsset = badgeAssetGooglePlayDefault;
  if (translationsStore.locale === 'it') {
    badgeAsset = badgeAssetGooglePlayItalian;
  }
  return (
    <div onClick={onClickGooglePlay}>
      <img src={badgeAsset} style={{ height: 50 }} alt="Google Play" />
    </div>
  );
});

const AppleStoreBadge = inject('translationsStore')(({ ...props }) => {
  const { translationsStore } = props;
  let badgeAsset = badgeAssetAppleStoreDefault;
  if (translationsStore.locale === 'it') {
    badgeAsset = badgeAssetAppleStoreItalian;
  }
  return(<>
    <div onClick={onClickAppleStore}>
      <img src={badgeAsset} style={{ height: 50 }} alt="Apple App Store" />
    </div>
  </>)
});

const MarketplaceBadge = () => {
  if (isIOS) {
    return <AppleStoreBadge/>;
  } else {
    return <GooglePlayBadge/>;
  }
};

const MarketplaceAllBadges = () => {
  return (
    <>
      <GooglePlayBadge />
      <AppleStoreBadge />
    </>
  );
};

export default class MobileMarketplaceLink extends Component {
  render() {
    const cls = classNames({
      MobileMarketplaceLink: true
    });
    const { visible } = this.props;
    if (visible) {
      return (
        <div className={cls}>
          {(() => {
            if (isMobileOnly) {
              return <MarketplaceBadge />;
            } else {
              return <MarketplaceAllBadges />;
            }
          })()}
        </div>
      );
    } else {
      return <></>;
    }
  }
}
