
import React from 'react';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MyPageDesktopContainer from '../MyPageDesktopContainer';
import MyPageMobileContainer from '../MyPageMobileContainer';

const MyPageContainer = ({ children, ...props }) => (
  <BreakpointsMedia>
    {({ breakpoints, currentBreakpoint }) =>
      breakpoints[currentBreakpoint] >= breakpoints.desktop ? (
        <MyPageDesktopContainer {...props}>{children}</MyPageDesktopContainer>
      ) : (
        <MyPageMobileContainer {...props}>{children}</MyPageMobileContainer>
      )
    }
  </BreakpointsMedia>
);

export default MyPageContainer;
