import React from 'react';
import classNames from 'classnames';

import SmallDivision from '../SmallDivision';

import './Section.css';

const Section = ({ title, icon, children, className }) => (
  <div className={classNames('Section', className)}>
    {title && <SmallDivision title={title} icon={icon} />}
    {children ? <div className="SectionContent">{children}</div> : null}
  </div>
);

export default Section;
