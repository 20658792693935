import React, {Component, Fragment} from "react";
import AirModal from "../../../../common/components/AirModal";
import MyPageButton from "../../../../common/components/MyPageButton";
import {inject, observer} from "mobx-react";
import moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

@inject('translationsStore', 'mypageStore')
@observer
class AgendaAddDateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedDate: undefined
    }
  }

  onSubmit = async () => {
    this.setState({modalVisible: false});
    await this.props.onSubmit(this.props.vehicle, moment(this.state.selectedDate).format("YYYY-MM-DD"));
  };

  render() {
    return (
      <Fragment>
        <MyPageButton
          theme="custom"
          className="ScadenzaButton"
          solid
          title="Aggiungi"
          onClick={() => this.setState({modalVisible: true})}
        />
        <AirModal
          className={"AgendaModal"}
          width="90vw"
          visible={this.state.modalVisible}
          onCancel={() => {
            this.setState({ modalVisible: false });
          }}
        >
          <h2>{this.props.title}</h2>
          <div className="CalendarContent">
            <Calendar
              value={this.state.selectedDate}
              minDetail="month"
              locale="it-IT"
              showNeighboringMonth={false}
              onChange={date => this.setState({selectedDate: date})}
              tileDisabled={({activeStartDate, date, view }) => date <= new Date()}
            />
            <MyPageButton
              theme="primary"
              solid
              style={{maxWidth: 200}}
              disabled={!this.state.selectedDate}
              title="Salva"
              onClick={this.onSubmit}
            />
          </div>
        </AirModal>
      </Fragment>
    )
  }
}

export default AgendaAddDateButton
