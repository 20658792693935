import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PermanentLeft from '../PermanentLeft';

import './MyPageDesktopContainer.css';

export default class MyPageDesktopContainer extends Component {
  render() {
    let { dataCollection } = this.props;
    const cls = classNames({
      MyPageDesktopContainer: true,
      [this.props.className]: !!this.props.className
    });
    const containerClassName = classNames({
      AppContent: true
    });
    return (
      <Fragment>
        <PermanentLeft className="LeftMenu" dataCollection={dataCollection} />
        <div className={containerClassName}>
          <div className={cls}>{this.props.children}</div>
        </div>
      </Fragment>
    );
  }
}
