import React, { Component, Fragment } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

@withRouter
export default class MyAppRoute extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    return <Redirect to="/" />;
  }
}
