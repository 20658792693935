import React, { Component, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import MyPageContainer from '../../../../common/components/MyPageContainer';
import ProvinceSelector from '../../../../common/components/ProvinceSelector';
import { PulseLoader } from 'react-spinners';
import { Button, Input } from 'antd';
import InstallerRow from './InstallerRow';
import { InfoIcon } from '../../../../common/icons';
import { mypageHelper } from '../../../../helpers';

import './InstallerView.css';

const InstallerList = inject(
  'translationsStore',
  'moveinStore'
)(
  observer(({ ...props }) => {
    const {
        translationsStore,
        moveinStore,
        moveinStore: { installerList },
        onInstallerSubmit,
        fnInstallersCompare,
      } = props,
      { getOperationalAddress } = mypageHelper;
    const [query, setQuery] = useState('');

    const filteredInstallersList = installerList.slice().filter((installer) => {
      const lowerCaseQuery = query.toLowerCase(),
        name = installer.name.toLowerCase(),
        address = getOperationalAddress(installer),
        street = (address?.street || '').toLowerCase(),
        city = (address?.city || '').toLowerCase(),
        province = (address?.province || '').toLowerCase();
      return (
        installer.uin.includes(lowerCaseQuery) ||
        name.includes(lowerCaseQuery) ||
        street.includes(lowerCaseQuery) ||
        city.includes(lowerCaseQuery) ||
        province.includes(lowerCaseQuery)
      );
    });
    return (
      <div className="InstallerList">
        <h2 style={{ marginTop: 32 }}>{translationsStore.translate('movein.stepInstaller.labelSelectInstaller')}</h2>
        {(() => {
          if (installerList.length > 0) {
            const SuggestionToCustomer = () =>
              moment().month() === 7 ? (
                <div style={{ marginBottom: 20, padding: 20, backgroundColor: 'white', border: '1px solid var(--action-primary-color)', borderRadius: '10px', textAlign: 'left', display: 'flex' }}>
                  <div style={{ height: 22, display: 'flex', alignItems: 'center' }}><InfoIcon width={16} height={16} style={{ color: 'var(--action-primary-color)', marginRight: 10 }} /></div>
                  <div>{translationsStore.translate('movein.stepInstaller.suggestionToCustomer')}</div>
                </div>
              ) : null;
            return (
              <>
                <SuggestionToCustomer />
                <div style={{ marginBottom: 20 }}>
                  <Input placeholder={translationsStore.translate('movein.stepInstaller.placeholderFilter')} value={query} onChange={(e) => setQuery(e.target.value)} allowClear />
                </div>
              </>
            );
          }
        })()}
        <div>
          {filteredInstallersList.sort(fnInstallersCompare).map((installer, index) => (
            <InstallerRow key={index} installer={installer} />
          ))}
        </div>
        <Button className="SumbmitButton" type="primary" disabled={!moveinStore.selectedInstaller} onClick={onInstallerSubmit}>
          {translationsStore.translate('movein.stepInstaller.next')}
        </Button>
      </div>
    );
  })
);

@withRouter
@inject('translationsStore', 'moveinStore', 'mypageStore', 'authStore', 'productsStore')
@observer
export default class InstallerView extends Component {
  constructor(props) {
    super(props);
    this.state = { province: null };

    this.props.productsStore.setOptionalService(undefined);
    this.props.productsStore.setOptionalServiceProduct(undefined);
  }

  onInstallerSubmit = () => {
    const { moveinStore, mypageStore, authStore, history } = this.props;

    const {
      attributes: { 'custom:external_uuid': userUuid },
    } = authStore.user;

    const vehicleUuid = mypageStore.selectedManageableVehicle.uuid;
    moveinStore.chooseInstaller(userUuid, vehicleUuid).then(() => {
      history.push('/optional-services');
    });
  };

  onProvinceSelection = (province) => {
    const { moveinStore } = this.props;
    this.setState({ province });
    moveinStore.loadInstallers(province);
  };

  compareInstallers(insA, insB) {
    const availableDevicesA = insA.assets.length - insA.vehicles.length;
    const availableDevicesB = insB.assets.length - insB.vehicles.length;
    if (availableDevicesA > availableDevicesB) return -1;
    if (availableDevicesA < availableDevicesB) return 1;
    if (insA.hasOwnProperty('code') && insB.hasOwnProperty('code') && insA.code.toUpperCase().startsWith('IE') && !insB.code.toUpperCase().startsWith('IE')) return -1;
    if (insA.hasOwnProperty('code') && insB.hasOwnProperty('code') && !insA.code.toUpperCase().startsWith('IE') && insB.code.toUpperCase().startsWith('IE')) return 1;
    if (insA.hasOwnProperty('code') && insB.hasOwnProperty('code') && insA.code.toUpperCase() < insB.code.toUpperCase()) return -1;
    return 1;
  }

  render() {
    const { mypageStore, moveinStore, authStore } = this.props;
    if (!authStore.user || !mypageStore.selectedManageableVehicle) {
      return <Redirect to="/" />;
    }
    const { dataCollection } = this.props;

    if (!!mypageStore.remoteUserData && mypageStore.remoteUserData.addresses.length === 0) {
      mypageStore.setHasToCompleteProfile(true);
      return <Redirect to="/profile" />;
    }

    return (
      <MyPageContainer dataCollection={dataCollection}>
        <div style={{ textAlign: 'center' }}>
          <ProvinceSelector onChange={this.onProvinceSelection} province={this.state.province} />
          {!this.state.province ? null : moveinStore.loadingInstallers || moveinStore.loadingSubmitInstaller || this.state.loadingToken ? (
            <PulseLoader sizeUnit={'px'} size={20} color={'var(--action-primary-color)'} loading={true} />
          ) : (
            <InstallerList fnInstallersCompare={this.compareInstallers} onInstallerSubmit={this.onInstallerSubmit} />
          )}
        </div>
      </MyPageContainer>
    );
  }
}
