import React, { Component } from 'react';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MyPageSelector from '../../../../common/components/MyPageSelector';
import { mypageHelper } from '../../../../helpers';
import {inject, observer} from 'mobx-react';
import classNames from 'classnames';
import {when} from "mobx";

// import './VehicleSelector.css';

@inject('translationsStore', 'mypageStore')
@observer
class AirclubVehicleSelector extends Component {
  vehicleFilterFn = (...params) => {
    // return this.props.mypageStore.hasVehicleReminder(...params);
    return true;
  }

  componentDidMount() {
    const {translationsStore, mypageStore} = this.props;
    when(
      () => mypageStore.manageableVehicles.filter(this.vehicleFilterFn).length > 0,
      () => {
        const selectedVehicle = mypageStore.manageableVehicles.filter(this.vehicleFilterFn)[0];
        this.props.onVehicleChange({
          key: selectedVehicle.uuid,
          label: mypageHelper.manageableVehicleLabel({ translationsStore, vehicle: selectedVehicle })
        });
    });
  }

  render() {
    const {
      translationsStore,
      mypageStore: { manageableVehicles, selectedManageableVehicle }
    } = this.props;

    const vehicleItems = manageableVehicles.filter(this.vehicleFilterFn).map(vehicle => {
      return ({
      key: vehicle.uuid,
      label: mypageHelper.manageableVehicleLabel({ translationsStore, vehicle })
    })});

    return (
      <BreakpointsMedia>
        {({ breakpoints, currentBreakpoint }) => {
          const inputProps = breakpoints[currentBreakpoint] >= breakpoints.desktop ? { size: 'large' } : {};
          const cls = classNames({
            VehicleSelector: true,
            [this.props.className]: !!this.props.className
          });
          return <MyPageSelector {...this.props} {...inputProps} className={cls} items={vehicleItems} selectedItem={this.props.selectedVehicle} placeholder={'dati del veicolo'} onChange={this.props.onVehicleChange} />;
        }}
      </BreakpointsMedia>
    );
  }
}

export default AirclubVehicleSelector;
