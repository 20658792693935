import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Select } from 'antd';
import { ArrowDownwardIcon, ArrowUpwardIcon } from '../../icons';

import './MyPageSelector.css';

const { Option } = Select;

@inject('translationsStore')
@observer
export default class MyPageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.refSelect = React.createRef();
  }

  onFocus = () => {
    this.setState({ open: true });
  };

  onBlur = () => {
    this.setState({ open: false });
  };

  onArrowClick = () => {
    this.setState({ open: !this.state.open }, () => {
      this.refSelect.current.focus();
    });
  };

  handleChange = value => {
    this.setState({ value, open: false }, () => {
      this.props.onChange(value);
    });
  };

  render() {
    const options = this.props.items.map(d => <Option key={d.key}>{d.label}</Option>);
    const isSizeLarge = this.props.size === 'large';
    const disabled = this.props.disabled || options.length < 2;
    const arrowWrapperStyle = { height: 48, display: 'flex', flexDirection: 'column', justifyContent: 'center', color: disabled ? 'var(--grey-grey-color)' : undefined };
    const arrowStyle = {};
    const arrowWidth = isSizeLarge ? '48px' : '36px';
    const arrowHeight = isSizeLarge ? '20px' : '16px';
    const cls = classNames({
      MyPageSelector: true,
      ['MyPageSelector-lg']: isSizeLarge,
      [this.props.className]: !!this.props.className
    });
    const clsDropdown = classNames({
      MyPageSelectorDropdown: true,
      [this.props.dropdownClassName]: !!this.props.dropdownClassName
    });
    return (
      <div className={cls} style={{ ...this.props.style, cursor: disabled ? 'not-allowed' : undefined }}>
        <Select
          ref={this.refSelect}
          labelInValue={true}
          value={this.props.selectedItem}
          disabled={disabled}
          placeholder={this.props.placeholder}
          style={{ width: '100%' }}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onChange={this.handleChange}
          notFoundContent={null}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          open={this.state.open}
          onClick={this.onSelectClick}
          dropdownClassName={clsDropdown}
        >
          {options}
        </Select>
        <div
          style={arrowWrapperStyle}
          onClick={() => {
            return disabled ? false : this.onArrowClick();
          }}
        >
          {this.state.open ? (
            <ArrowUpwardIcon width={arrowWidth} height={arrowHeight} style={arrowStyle} />
          ) : (
            <ArrowDownwardIcon width={arrowWidth} height={arrowHeight} style={arrowStyle} />
          )}
        </div>
      </div>
    );
  }
}
