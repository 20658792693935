import React, { Component, Fragment } from 'react';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CheckingAuthentication } from 'air-checking-authentication';
import K from '../../../constants';
import { api } from '../../../services';
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import classNames from 'classnames';
import { message, Radio } from 'antd';
import { reaction } from 'mobx';
import { mypageHelper } from '../../../helpers';
import ReactGA from 'react-ga';
import AirModal from "../../../common/components/AirModal";
import MyPageButton from "../../../common/components/MyPageButton";

import ProfileView from './ProfileView';
import DocumentsView from './DocumentsView';
import PersonalDocumentsView from './PersonalDocumentsView';
import VehicleView from './VehicleView';
import ServicesView from './ServicesView';
import SupportRegioneLombardiaView from './SupportRegioneLombardiaView';
import SupportHelpDeskView from "./SupportHelpDeskView";
import SuccessView from './SuccessView';
import InstallerView from './InstallerView';
import ProcessZuora from './ProcessZuora';
import PaymentView from "./PaymentView";
import ThankYouPageView from './ThankYouPageView';
import MyAppRoute from './MyAppRoute';

import './App.css';

import OptionalServicesView from "./OptionalServicesView";
import ReviewPaymentView from "./ReviewPaymentView";
import AgendaView from "./AgendaView";
import AirRenewalView from "./AirRenewalView/AirRenewalView";
import AirRenewalSuggestAssistanceView from './AirRenewalSuggestAssistanceView';

import PaymentManagementView from "./PaymentManagementView";
import InstallerInformationView from "./InstallerInformationView";
import PurchaseAssistanceView from "./PurchaseAssistanceView";
import MoveinSuggestAdditionalServicesView from "./MoveinSuggestAdditionalServicesView";
import BuyAdditionalService from "./BuyAdditionalService";
import MyAppAirAddonServicesView from "./MyAppAirAddonServicesView/MyAppAirAddonServicesView";

ReactGA.initialize(K.GOOGLE_ANALYTICS_TRACK_ID);

@withRouter
@inject('authStore', 'mypageStore', 'translationsStore')
@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerLoading: false,
      activeKey: '',
      blockingContent: undefined
    };

    this.keyRoutes = {
      profile: { route: '/profile' },
      personalDocuments: { route: '/personal-documents' },
      paymentManagement: { route: '/payment-management' },
      installerInformation: { route: '/installer-information' },
      payments: { route: '/payments' },
      vehicles: { route: '/vehicle' },
      documents: { route: '/documents' },
      assets: { route: '/assets' },
      services: { route: '/services' },
      agenda: { route: '/agenda' },
      promoAir: { route: '/promo/air' },
      promoDealer: { route: '/promo/dealer' },
      promoOther: { route: '/promo/other' },
      supportAir: { route: '/support/air' },
      supportLombardia: { route: '/support/regionelombardia' },
      helpDesk: { route: '/support/helpdesk' },
      supportAxa: { route: '/support/axa' },
      supportDealer: { route: '/support/dealer' },
      supportFaq: { route: '/support/faq' },
      airRenew: { route: '/air-renew' },
      logout: { route: '/logout' }
    };

    this.configMessage();
  }

  configMessage = () => {
    message.config(mypageHelper.MESSAGE_CONFIG);
  };

  loadCurrentUser = () => {
    const { authStore } = this.props;
    api('currentUser').then(user => {
      authStore.setUser(user);
    });
  };

  afterTokenLoadings = () => {
    const { authStore } = this.props;

    api('currentUser').then(user => {
      authStore.setUser(user);
    });

    const { mypageStore, authStore: { user: { username } } } = this.props;
    this.setState({ containerLoading: true });
    mypageStore.loadUserManageableVehicles().then(() => {
      let vehicle;

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('plate')) {

        vehicle = mypageStore.manageableVehicles.find(vehicle => vehicle.license_plate === urlParams.get('plate'));
      } else {
        vehicle = mypageStore.manageableVehicles.find(vehicle => vehicle.license_plate.toLowerCase() === username.toLowerCase());
      }
      vehicle && vehicle.uuid && mypageStore.setSelectedManageableVehicleByUuid(vehicle.uuid);

      this.setState({ containerLoading: false });
    });

    mypageStore.loadRemoteUserData().then(() => {
      // if (mypageStore.remoteUserIsFromMoVeInProgram) {
      //   this.goToKeyRoute('services');
      // } // TODO write redirect for classic customers
    });
  };

  componentDidMount = () => {
    const { mypageStore } = this.props;
    this.setState({ loading: true });
    this.loadCurrentUser();

    api('currentUser').then(user => {
      this.props.authStore.setToken(user.signInUserSession.idToken.jwtToken);
      this.setState({ loading: false });
      if (this.props.idToken) {
        mypageStore.userAccessToken = this.props.accessToken;
        this.afterTokenLoadings();
      }
    });

    disposeOnUnmount(
      this,
      reaction(
        () => this.props.mypageStore.selectedManageableVehicle,
        vehicle => {
          if (mypageStore.remoteUserIsFromMoVeInProgram && !vehicle.hasOwnProperty('marketing_third_parties_consent_accepted')) {
            const { marketing_consent_accepted, marketing_third_parties_consent_accepted } = vehicle;
            this.setState({ blockingContent: true, marketing_consent_accepted, marketing_third_parties_consent_accepted });
          }
        },
        { fireImmediately: true }
      )
    );
  };

  componentDidUpdate(prevProps) {
    const { mypageStore, translationsStore } = this.props;
    if (this.props.idToken !== prevProps.idToken) {
      this.props.authStore.setToken(this.props.idToken);
      mypageStore.userAccessToken = this.props.accessToken;
      if (!prevProps.idToken) {
        this.afterTokenLoadings();
      }
    }

    if (this.props.isauthenticated !== prevProps.isauthenticated) {
      this.props.authStore.setIsLoggedIn(this.props.isauthenticated);
    }

    if (mypageStore.exceptionFound) {
      message.error(translationsStore.translate('common.genericError'), function() {
        mypageStore.setExceptionFound(false);
      });
    }
  }

  goToKeyRoute = key => {
    this.setState({ activeKey: key }, () => {
      const { mypageStore } = this.props;
      mypageStore.setVisibleLeftRegion(false);
      const { history } = this.props;
      history.push(this.keyRoutes[key].route);
    });
  };

  trackingpPageview = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return null;
  };

  onMarketingAgreementChange = (e) => {
    this.setState({ marketing_consent_accepted: e.target.value });
  };

  onMarketingThirdPartiesAgreementChange = (e) => {
    this.setState({ marketing_third_parties_consent_accepted: e.target.value });
  };

  onMarketingAgreementsReviewSubmit = async () => {
    const { mypageStore } = this.props,
      { marketing_consent_accepted, marketing_third_parties_consent_accepted } = this.state;
    try {
      await mypageStore.updateMarketingConsent(marketing_consent_accepted, marketing_third_parties_consent_accepted);
      this.setState({ blockingContent: false });
    } catch (e) {
      message.error(this.props.translationsStore.translate('common.genericError'));
    }
  };

  render() {
    const { mypageStore } = this.props;
    const { activeKey, blockingContent } = this.state;
    const dataCollection = {
      handlers: { goToKeyRoute: this.goToKeyRoute },
      activeKey,
      containerLoading: this.state.containerLoading
    };

    // Avoid rendering the pages when authStore.user is not initialized
    if (!this.props.authStore.user || this.props.authStore.token === undefined) {
      return <PulseLoader />;
    }

    return (
      <Fragment>
        <BreakpointsMedia>
          {({ breakpoints, currentBreakpoint }) => {
            const cls = classNames({
              App: true,
              [currentBreakpoint]: !!currentBreakpoint
            });
            return (
              <LoadingOverlay
                className={cls}
                active={this.state.containerLoading}
                spinner={<PulseLoader sizeUnit={'px'} size={20} color={'var(--action-primary-color)'} loading={true} />}
                styles={{
                  overlay: base => ({
                    ...base,
                    background: 'var(--grey-light-color-50)'
                  })
                }}
              >
                <Route path="/" component={this.trackingpPageview} />
                {(() => {
                  const cls = classNames({
                    BlockingContentModal: true,
                    [currentBreakpoint]: !!currentBreakpoint
                  });
                  return !!blockingContent && (
                    <AirModal className={cls} width="90vw" visible={true} onCancel={() => {}}>
                      <h1 className="PrivacyTitle">Informativa sulla privacy</h1>
                      <p className="Subtitle">
                        Per usufruire dei nostri servizi e necessario fornire il consenso al trattamento dei dati Personali secondo il nuovo Reglamenta EU 2016/679, noto come
                        GDPR.
                      </p>
                      <p className="AgreementItem">
                        Con riferimento al trattamento dei dati personali da parte di AIR S.r.l. per le finalità di marketing di cui al punto 4 (ii) del paragrafo 2 dell'informativa, vale a dire ricerche di mercato, invio di materiale informativo, promozionale o pubblicitario, comunicazione di nuove iniziative commerciali, offerta di prodotti o servizi (anche tramite il sito <a href="https://www.myair.io/" target="_blank">www.myair.io</a> ), come servizi e prodotti automotive tra cui anche prodotti assicurativi ed iniziative promozionali e di vendita di prodotti e/o servizi di AIR S.r.l. o di soggetti terzi via email, SMS o altri sistemi automatizzati, telefonicamente e per posta, o mediante offerta tramite il sito <a href="https://www.myair.io/" target="_blank">www.myair.io</a>
                      </p>
                      <Radio.Group className="PrivacyUpdateForm" onChange={this.onMarketingAgreementChange} value={this.state.marketing_consent_accepted}>
                        <Radio value={true}>Acconsento</Radio>
                        <Radio value={false}>Non acconsento</Radio>
                      </Radio.Group>
                      <p />
                      <p className="AgreementItem">
                        Con riferimento alla comunicazione dei miei dati personali da parte di AIR S.r.l. alle categorie di soggetti terzi indicati al punto 4 (i) del paragrafo 2 dell'informativa e per le finalità ivi indicate, tra cui marketing e ricerche di mercato, via email, SMS o altri sistemi automatizzati o per posta cartacea o telefonicamente
                      </p>
                      <Radio.Group className="PrivacyUpdateForm" onChange={this.onMarketingThirdPartiesAgreementChange} value={this.state.marketing_third_parties_consent_accepted}>
                        <Radio value={true}>Acconsento</Radio>
                        <Radio value={false}>Non acconsento</Radio>
                      </Radio.Group>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', marginTop: 8 }}>
                        <MyPageButton theme="primary" solid title={'Salva le Modifiche'} onClick={this.onMarketingAgreementsReviewSubmit} />
                      </div>
                    </AirModal>
                  );
                })()}
                <Switch>
                  <Redirect from="/" exact to="/services" />
                  <Route path="/profile" render={() => <ProfileView dataCollection={dataCollection} />} />
                  <Route path="/agenda" render={() => <AgendaView dataCollection={dataCollection} />} />
                  <Route path="/personal-documents" render={() => <PersonalDocumentsView dataCollection={dataCollection} />} />
                  <Route path="/payment-management" render={() => <PaymentManagementView dataCollection={dataCollection} />} />
                  <Route path="/installer-information" render={() => <InstallerInformationView dataCollection={dataCollection} />} />
                  <Route path="/documents" render={() => <DocumentsView dataCollection={dataCollection} />} />
                  <Route path="/payments" render={() => <PaymentView dataCollection={dataCollection} />} />
                  <Route path="/thankyoupage" render={() => <ThankYouPageView dataCollection={dataCollection} />} />
                  <Route path="/success" render={() => <SuccessView dataCollection={dataCollection} />} />
                  <Route path="/vehicle" render={() => <VehicleView dataCollection={dataCollection} />} />
                  <Route path="/services" render={() => <ServicesView dataCollection={dataCollection} />} />
                  <Route path="/air-renew" render={() => <AirRenewalView dataCollection={dataCollection} />} />
                  <Route path="/air-renew-suggest-assistance" render={() => <AirRenewalSuggestAssistanceView optional={true} dataCollection={dataCollection} />} />
                  <Route path="/movein-suggest-additional-services" render={() => <MoveinSuggestAdditionalServicesView optional={true} dataCollection={dataCollection} />} />
                  <Route path="/buy-additional-service" render={() => <BuyAdditionalService optional={false} dataCollection={dataCollection} />} />
                  <Route path="/optional-services" render={() => <OptionalServicesView optional={true} dataCollection={dataCollection} />} />
                  <Route path="/addon-services" render={() => <MyAppAirAddonServicesView optional={true} dataCollection={dataCollection} />} />
                  <Route path="/buy-airclub" render={() => <OptionalServicesView dataCollection={dataCollection} />} />
                  <Route path="/buy-assistance" render={() => <PurchaseAssistanceView dataCollection={dataCollection} />} />
                  <Route path="/review-services" render={() => <ReviewPaymentView dataCollection={dataCollection} />} />
                  {mypageStore.remoteUserIsFromMoVeInProgram ? (
                    <Route path="/support/regionelombardia" render={() => <SupportRegioneLombardiaView dataCollection={dataCollection} />} />
                  ) : (
                    <Route path="/support/helpdesk" render={() => <SupportHelpDeskView dataCollection={dataCollection} />} />
                  )}
                  <Route path="/movein/installer" render={() => <InstallerView dataCollection={dataCollection} />} />
                  <Route path="/process-zuora" render={() => <ProcessZuora dataCollection={dataCollection} />} />
                  <Route path="/myapp" render={() => <MyAppRoute />} />
                </Switch>
              </LoadingOverlay>
            );
          }}
        </BreakpointsMedia>
      </Fragment>
    );
  }
}
export default CheckingAuthentication({ region: K.AWS_AUTH_REGION, userPoolId: K.AWS_AUTH_USER_POOL_ID, userPoolWebClientId: K.AWS_AUTH_USER_POOL_WEBCLIENT_ID })(App);
