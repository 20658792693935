import React, { Fragment, useState } from 'react';
import { FilesLabeledOutlinedIcon } from '../../../../common/icons';
import AirModal from '../../../../common/components/AirModal';
import PrivacyPolicy from './PrivacyPolicy';
import PrivacyConsentModal from '../PrivacyConsentModal';
import PrivacyPolicyAirClub from './PrivacyPolicyAirClub';
import { api } from '../../../../services';
import { message } from 'antd';
import download from 'downloadjs';


export const StaticDocument = ({ src, translationsStore }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <a onClick={() => setVisible(true)}>{translationsStore.translate('documentsView.view')}</a>
      <AirModal className="EmbeddedDocumentModal" width="90vw" visible={visible} onCancel={() => setVisible(false)} destroyOnClose={true}>
        <iframe title="document" src={src} width="100%" height="540" />
      </AirModal>
    </Fragment>
  );
};

export const MoveInPrivacyPolicy = ({ src, translationsStore }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <a onClick={() => setVisible(true)}>{translationsStore.translate('documentsView.view')}</a>
      <AirModal className="EmbeddedDocumentModal" width="90vw" visible={visible} onCancel={() => setVisible(false)} destroyOnClose={true}>
        <PrivacyPolicy onCancel={() => setVisible(false)} />
      </AirModal>
    </Fragment>
  );
};

export const AirPrivacyPolicy = ({ src, translationsStore }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <a onClick={() => setVisible(true)}>{translationsStore.translate('documentsView.view')}</a>
      <PrivacyConsentModal isDocumentSide={true} isVisible={visible} onCancelAction={() => setVisible(false)} />
    </Fragment>
  );
};

export const AirClubPrivacyPolicy = ({ src, translationsStore }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <a onClick={() => setVisible(true)}>{translationsStore.translate('documentsView.view')}</a>
      <AirModal className="EmbeddedDocumentModal" width="90vw" visible={visible} onCancel={() => setVisible(false)} destroyOnClose={true}>
        <PrivacyPolicyAirClub {...((!!src) ? { src } : {})} onCancel={() => setVisible(false)} />
      </AirModal>
    </Fragment>
  );
};

export const InvoiceDocument = ({ link, slugCode, translationsStore }) => {
  const onDownloadInvoice = () => {
    api('downloadInvoice', link).then(response => {
      const fileName = `${slugCode}.pdf`;

      try {
        const blob = new Blob([response], { type: 'application/pdf' }),
          linkElement = document.createElement('a'),
          url = URL.createObjectURL(blob);

        linkElement.setAttribute('href', url);
        linkElement.setAttribute('download', fileName);
        linkElement.style.visibility = 'hidden';
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
      } catch (error) {
        message.error(translationsStore.translate('common.genericError'));
      }
    });
  };

  return <a onClick={onDownloadInvoice}>{translationsStore.translate('documentsView.view')}</a>;
};

export const ContractDocument = ({ link, title, translationsStore }) => {
  const onDownloadDocument = () => {
    api('downloadDocument', link).then(response => {
      const fileName = `${title}.pdf`;
      download(response, fileName, 'application/pdf');
    });
  };

  return <a onClick={onDownloadDocument}>{translationsStore.translate('documentsView.view')}</a>;
};
