import React, { Component, Fragment } from 'react';

import './PaymentManagementView.css';
import { inject, observer } from 'mobx-react';
import MyPageContainer from "../../../../common/components/MyPageContainer";
import SmallDivision from "../../../../common/components/SmallDivision";
import {CreditCardIcon} from "../../../../common/icons";
import LoadingOverlay from "react-loading-overlay";
import {PulseLoader} from "react-spinners";
import MyPageButton from "../../../../common/components/MyPageButton";
import PaymentMethodPopup from "./PaymentMethodPopup";

const CreditCardPaymentMethod = inject('translationsStore')(({translationsStore, defaultPaymentMethod}) => {

  return (
    <Fragment>
      <span style={{fontSize: '18px'}}>{translationsStore.translate('paymentManagement.creditCard')}</span>
      <div className={'PaymentMethodDetailsContainer'}>
        <div>
          <div className={'PaymentMethodDetailsItem'}>
            {translationsStore.translate('paymentManagement.paymentType')}
            <div className={'paymentType'}>{defaultPaymentMethod.CreditCardType}</div>
          </div>
          <div className={'PaymentMethodDetailsItem'}>
            {translationsStore.translate('paymentManagement.cardNumber')}
            <input className={'InputPaymentDetails'} value={defaultPaymentMethod.CreditCardMaskNumber} disabled />
          </div>
          <div className={'PaymentMethodDetailsItem'}>
            {translationsStore.translate('paymentManagement.expirationDate')}
            <div className={'PaymentMethodExpirationDateRow'}>
              <input className={'InputPaymentDetails'} value={defaultPaymentMethod.CreditCardExpirationMonth} disabled />
              <input className={'InputPaymentDetails'} style={{marginLeft: '20px'}} value={defaultPaymentMethod.CreditCardExpirationYear} disabled />
            </div>
          </div>
          <div className={'PaymentMethodDetailsItem'}>
            {translationsStore.translate('paymentManagement.cardHolder')}
            <input className={'InputPaymentDetails'} value={defaultPaymentMethod.CreditCardHolderName} disabled />
          </div>
          <div className={'PaymentMethodDetailsItem'}>
            {translationsStore.translate('paymentManagement.country')}
            <input className={'InputPaymentDetails'} value={defaultPaymentMethod.CreditCardCountry} disabled />
          </div>
        </div>
      </div>
    </Fragment>
  );
});

const BankTransferPaymentMethod = inject('translationsStore')(({translationsStore, defaultPaymentMethod}) => {

  return (
    <Fragment>
      <span style={{fontSize: '18px'}}>{translationsStore.translate('paymentManagement.bankTransferSepa')}</span>
      <div className={'PaymentMethodDetailsContainer'}>
        <div>
          <div className={'PaymentMethodDetailsItem'}>
            {translationsStore.translate('paymentManagement.paymentType')}
            <div className={'paymentType'}>{defaultPaymentMethod.BankTransferType}</div>
          </div>
          <div className={'PaymentMethodDetailsItem'}>
            {translationsStore.translate('paymentManagement.bankName')}
            <input className={'InputPaymentDetails'} value={defaultPaymentMethod.BankName} disabled />
          </div>
          <div className={'PaymentMethodDetailsItem'}>
            {translationsStore.translate('paymentManagement.accountNumber')}
            <input className={'InputPaymentDetails'} value={defaultPaymentMethod.BankTransferAccountNumberMask} disabled />
          </div>
        </div>
      </div>
    </Fragment>
  );
});

@inject('translationsStore', 'mypageStore')
@observer
export default class PaymentManagementView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePaymentMethodPopUp: false
    }
  }

  toggleModalAddPaymentMethod = () => {
    this.setState(prevState => {return {visiblePaymentMethodPopUp: !prevState.visiblePaymentMethodPopUp}});
  }

  render() {
    const { dataCollection, mypageStore, translationsStore } = this.props
      , defaultPaymentMethod = mypageStore.defaultPaymentMethod;

    return (
      <Fragment>
        <MyPageContainer  dataCollection={dataCollection}>
          <LoadingOverlay
            active={mypageStore.loadingZuoraPaymentMethods}
            spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true} />}
            styles={{
              overlay: base => ({
                ...base,
                background: 'var(--grey-light-color-50)'
              })
            }}
          >
            <SmallDivision title={translationsStore.translate('paymentManagement.title')} icon={<CreditCardIcon />} />
            <div className={'PaymentManagementView'}>
              {!defaultPaymentMethod && <div className={'AddFirstPaymentMethodContainer'}>
                  <MyPageButton
                    theme="primary"
                    className={'ButtonAddPaymentMethod'}
                    solid
                    title={translationsStore.translate('paymentManagement.addPaymentMethod')}
                    onClick={this.toggleModalAddPaymentMethod}
                  />
              </div>}
              {defaultPaymentMethod && <div className={'AlreadyPresentPaymentMethodContainer'}>
                <div className={'PreviousPaymentMethodContainer'}>
                  {defaultPaymentMethod.Type === 'CreditCard' ?
                    <CreditCardPaymentMethod defaultPaymentMethod={defaultPaymentMethod}/>
                    : <BankTransferPaymentMethod defaultPaymentMethod={defaultPaymentMethod}/>
                  }
                </div>
                <MyPageButton
                  theme="custom"
                  className={'ChangePaymentMethodButton'}
                  solid
                  title={translationsStore.translate('paymentManagement.change')}
                  onClick={this.toggleModalAddPaymentMethod}
                />
              </div>}
            </div>
            <PaymentMethodPopup visible={this.state.visiblePaymentMethodPopUp} onCancel={() => this.toggleModalAddPaymentMethod()} />
          </LoadingOverlay>
        </MyPageContainer>
        </Fragment>
    );
  }
}
