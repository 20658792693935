import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import { QsHelper } from '../../../../helpers';
import MobileLogin from './MobileLogin';
import DesktopLogin from './DesktopLogin';

@withRouter
@inject('authStore', 'loginStore')
@observer
class Login extends Component {
  componentDidMount = () => {
    const { loginStore } = this.props;
    const autocompleteFields = { username: new QsHelper(this.props.location.search).get('username') };
    if (autocompleteFields.username !== undefined) {
      loginStore.setUsername(autocompleteFields.username);
    }
  };

  goToForgottenPassword = () => {
    this.props.history.push('/forgotten-password');
  };

  render() {
    let { dataCollection } = this.props;
    const { authStore } = this.props;
    if (!authStore.isLoggedIn) {
      dataCollection = {
        ...dataCollection,
        onForgottenPasswordClick: this.goToForgottenPassword
      };
      return (
        <BreakpointsMedia>
          {({ breakpoints, currentBreakpoint }) => {
            return currentBreakpoint === 'extrasmall' || currentBreakpoint === 'small' ? (
              <MobileLogin dataCollection={dataCollection} />
            ) : (
              <DesktopLogin dataCollection={dataCollection} />
            );
          }}
        </BreakpointsMedia>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Login;
