import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { AddCircleIcon } from '../../../../common/icons';
import AirModal from '../../../../common/components/AirModal';
import LabelInput from '../../../../common/components/LabelInput';
import MyPageButton from '../../../../common/components/MyPageButton';
import { Select } from 'antd';
import { MobileView } from 'react-device-detect';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import classNames from 'classnames';
import FileBlobPreview from '../../../../common/components/FileBlobPreview';

const { Option } = Select;

@inject('translationsStore', 'mypageStore')
@observer
class AddDocumentButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      name: '',
      category: undefined,
      file: undefined,
      vehicle: undefined
    };
    this.fileInput = React.createRef();
  }

  handleSubmit = async () => {
    const formData = new FormData();

    formData.append('name', this.state.name);
    formData.append('category', this.state.category);
    formData.append('file', this.state.file);
    formData.append('fileExtension', this.state.file.name.split('.').pop());
    formData.append('vehicleUuid', this.state.vehicle);

    await this.props.mypageStore.uploadDocument(formData);
    this.setState({ modalVisible: false }, () => this.props.mypageStore.loadDocuments());
  };

  onFileChange = event => {
    this.setState({
      file: event.target.files[0]
    });
  };

  render() {
    const isSubmitDisabled = this.props.mypageStore.loadingUploadDocument || !this.state.file || !this.state.name || !this.state.category || !this.state.vehicle;

    return (
      <Fragment>
        <AddCircleIcon
          className="AddDocumentButton"
          onClick={() =>
            this.setState({
              modalVisible: true,
              vehicle: this.props.mypageStore.selectedManageableVehicle.uuid
            })
          }
          width={40}
          height={40}
        />
        <AirModal
          className="EmbeddedDocumentModal"
          width="90vw"
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          destroyOnClose={true}
          title={'Aggiungi nuovo documento'}
        >
          <BreakpointsMedia>
            {({ _, currentBreakpoint }) => {
              return (
                <Fragment>
                  {this.state.file && <FileBlobPreview fileBlob={this.state.file} style={{ marginBottom: 20 }} width="100%" height="540" />}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <MobileView>
                      <label className="document-file-upload" style={{ marginRight: 20 }}>
                        SCATTA
                        <input type="file" capture="environment" accept="image/*" onChange={this.onFileChange} />
                      </label>
                    </MobileView>
                    <label className="document-file-upload">
                      SCEGLI FILE
                      <input type="file" accept="image/*,.pdf" onChange={this.onFileChange} />
                    </label>
                  </div>
                  <div
                    className={classNames({
                      CustomDocumentFields: true,
                      [currentBreakpoint]: !!currentBreakpoint
                    })}
                  >
                    <LabelInput label={'Nome'} value={this.state.name} maxlength={20} onChange={e => this.setState({ name: e.target.value })} />
                    <div className="LabelInput">
                      <div>Tipologia</div>
                      <Select style={{ width: '100%' }} value={this.state.category} onChange={value => this.setState({ category: value })}>
                        <Option key="contract" value="contract">
                          Contratto
                        </Option>
                        <Option key="invoice" value="invoice">
                          Fattura
                        </Option>
                        <Option key="document" value="document">
                          Generico
                        </Option>
                      </Select>
                    </div>
                    <div className="LabelInput">
                      <div>Veicolo</div>
                      <Select style={{ width: '100%' }} defaultActiveFirstOption={true} value={this.state.vehicle} onChange={value => this.setState({ vehicle: value })}>
                        {this.props.mypageStore.manageableVehicles.map((vehicle, index) => (
                          <Option key={index} value={vehicle.uuid}>
                            {vehicle.license_plate}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div
                    className={classNames({
                      CustomDocumentButtons: true,
                      [currentBreakpoint]: !!currentBreakpoint
                    })}
                  >
                    <MyPageButton theme="primary" solid title={'SALVA'} onClick={this.handleSubmit} disabled={isSubmitDisabled} />
                  </div>
                </Fragment>
              );
            }}
          </BreakpointsMedia>
        </AirModal>
      </Fragment>
    );
  }
}

export default AddDocumentButton;
