import React, {Component, Fragment} from 'react';
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { mypageHelper } from '../../../helpers';
import classNames from 'classnames';
import AppBrand from '../AppBrand';
import PermanentGroup from '../PermanentGroup';
import PermanentResource from '../PermanentGroup/PermanentResource';
import {
  UserBigIcon,
  CalendarIcon,
  FoldersOutlinedIcon,
  FilesLabeledOutlinedIcon,
  CrownOutlinedIcon,
  SupportIcon,
  DoorIcon,
  CarIcon, IphoneIcon, WrenchIcon
} from '../../icons';

import './PermanentLeft.css';

@withRouter
@inject('translationsStore', 'mypageStore')
@observer
export default class PermanentLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: undefined
    }
  }

  onMenuSelect = (selected) => {
    this.setState({selectedGroup: selected})
  }

  checkGroupMatch = (groupRoutes, groupKey) =>  {
    if(groupRoutes.includes(this.props.match.path)) {
      this.setState({selectedGroup: groupKey})
    }
  }

  onSupport = () =>
    this.props.mypageStore.remoteUserIsFromMoVeInProgram
      ? this.props.dataCollection.handlers.goToKeyRoute('supportLombardia')
      : this.props.dataCollection.handlers.goToKeyRoute('helpDesk');

  onInstaller = () =>
    this.props.dataCollection.handlers.goToKeyRoute('installerInformation');

  componentDidMount() {
    this.checkGroupMatch(['/profile', '/personal-documents', '/payment-management'], 'myaccount');
    this.checkGroupMatch([
      '/vehicle',
      '/documents',
      '/services',
      '/payments',
      '/air-renew',
      '/optional-services',
      '/buy-additional-service',
      '/movein/installer',
      '/buy-airclub',
      '/review-services'
    ], 'vehicles');
    this.checkGroupMatch(['/agenda'], 'agenda');
    this.checkGroupMatch(['/support/regionelombardia', '/support/helpdesk'], 'support');
    this.checkGroupMatch(['/installer-information'], 'installerInformation');
  }

  manageSwitchToMobileApp = () => {
    isMobile && mypageHelper.handleDeepLink('myair://app.myair.io/');
  };

  render() {
    const { translationsStore, dataCollection, mypageStore, match } = this.props;
    const cls = classNames({
      PermanentLeft: true,
      [this.props.className]: !!this.props.className
    });
    const brandStyle = { width: 64, height: 32, margin: '24px 0 40px 24px', color: 'var(--action-brand-color)' };

    return (
      <div className={cls}>
        <AppBrand style={brandStyle} />
        <PermanentGroup
          title={translationsStore.translate('header.resources.account.title')}
          onMenuSelect={this.onMenuSelect}
          active={this.state.selectedGroup}
          value={'myaccount'}
          icon={<UserBigIcon />}
        >
          <PermanentResource
            active={match.path === '/profile'}
            title={translationsStore.translate('header.resources.account.resources.profile.title')}
            onClick={() => {
              dataCollection.handlers.goToKeyRoute('profile');
            }}
          />
          { ! mypageStore.remoteUserIsFromMoVeInProgram && (
            <PermanentResource
              active={match.path === '/personal-documents'}
              title={translationsStore.translate('header.resources.account.resources.personalDocuments.title')}
              onClick={() => {
                dataCollection.handlers.goToKeyRoute('personalDocuments');
              }}
            />
          )}
          { mypageStore.hasAtLeastOneSubscription && (
            <PermanentResource
            active={match.path === '/payment-management'}
            title={translationsStore.translate('header.resources.account.resources.paymentManagement.title')}
            onClick={() => {
              dataCollection.handlers.goToKeyRoute('paymentManagement');
            }}
          />
          )}
        </PermanentGroup>
        <PermanentGroup
          title={translationsStore.translate('header.resources.vehicles.title')}
          onMenuSelect={this.onMenuSelect}
          active={this.state.selectedGroup}
          value={'vehicles'}
          icon={<CarIcon />}
        >
          <PermanentResource
            active={match.path === '/vehicle'}
            title={translationsStore.translate('header.resources.vehicles.resources.vehiclesData.title')}
            onClick={() => {
              dataCollection.handlers.goToKeyRoute('vehicles');
            }}
          />
          <PermanentResource
            active={match.path === '/documents'}
            title={translationsStore.translate('header.resources.vehicles.resources.documents.title')}
            onClick={() => {
              dataCollection.handlers.goToKeyRoute('documents');
            }}
          />
          <PermanentResource
            active={[
              '/services',
              '/payments',
              '/air-renew',
              '/optional-services',
              '/buy-additional-service',
              '/movein-suggest-additional-services',
              '/buy-airclub',
              '/movein/installer',
              '/review-services'
            ].includes(match.path)}
            title={translationsStore.translate('header.resources.vehicles.resources.services.title')}
            onClick={() => {
              dataCollection.handlers.goToKeyRoute('services');
            }}
          />
        </PermanentGroup>
        {mypageStore.remoteUserIsFromMoVeInProgram && mypageStore.hasAtLeastOneVehicleWithInstaller && <PermanentGroup
          title={translationsStore.translate('header.resources.installer.title')}
          onMenuSelect={this.onMenuSelect}
          active={this.state.selectedGroup}
          icon={<WrenchIcon/>}
          value={'installerInformation'}
          isSingleItem
          routeHandler={this.onInstaller}
        />}
        <PermanentGroup
          title={translationsStore.translate('header.resources.agenda.title')}
          onMenuSelect={this.onMenuSelect}
          active={this.state.selectedGroup}
          value={'agenda'}
          icon={<CalendarIcon />}
        >
          <PermanentResource
            active={match.path === '/agenda'}
            title={translationsStore.translate('header.resources.agenda.resources.scadenze.title')}
            onClick={() => {
              if(!mypageStore.remoteUserIsFromMoVeInProgram || mypageStore.hasReminder || mypageStore.hasAdditionalReminderService) {
                dataCollection.handlers.goToKeyRoute('agenda');
              }
            }}
          />
        </PermanentGroup>
        <PermanentGroup
          title={translationsStore.translate('header.resources.support.title')}
          onMenuSelect={this.onMenuSelect}
          active={this.state.selectedGroup}
          icon={<SupportIcon />}
          value={'support'}
          isSingleItem
          routeHandler={this.onSupport}
        />
        {isMobile && !mypageStore.remoteUserIsFromMoVeInProgram && (
          <div
            style={{
              marginTop: 72,
            }}
          >
            <PermanentGroup
              title={translationsStore.translate('header.resources.mobileapp.title')}
              active={false}
              icon={<IphoneIcon />}
              value={'mobileapp'}
              onMenuSelect={this.onMenuSelect}
              isSingleItem
              routeHandler={this.manageSwitchToMobileApp}
            />
          </div>
        )}
        <div
          style={{
            marginTop: 72
          }}
        >
          <PermanentResource
            active={false}
            title={translationsStore.translate('header.resources.logout.title')}
            icon={<DoorIcon />}
            onClick={() => {
              dataCollection.handlers.goToKeyRoute('logout');
            }}
          />
        </div>
      </div>
    );
  }
}
