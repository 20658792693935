import React, { Component } from 'react';

import './DrivingLicenseForm.css';
import SmallDivision from '../../../../../common/components/SmallDivision';
import { FilesIcon } from '../../../../../common/icons';
import LabelInput from '../../../../../common/components/LabelInput';
import { Select, Form, Upload, Button, Icon, message } from 'antd';
import MyPageButton from '../../../../../common/components/MyPageButton';
import moment from 'moment';
import PersonalDocumentItem from "../PersonalDocumentItem";
const { Option } = Select;

class DrivingLicenseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {hasDrivingLicense: false, loadingDrivingLicense: true};
  }

  componentDidMount() {
    this.props.mypageStore.loadDrivingLicense().then(()=>{
      if(this.props.mypageStore.hasDrivingLicense){
        this.setState({...this.props.mypageStore.drivingLicense, hasDrivingLicense: true, loadingDrivingLicense: false});
      }
      if(this.state.mypageStore.hasDrivingLicense && !this.props.form.getFieldValue('license_type')){
        this.props.form.setFieldsValue({
          license_type: this.props.mypageStore.drivingLicense.license_type
        });
      }
    }).catch(()=>{
        this.setState({hasDrivingLicense: false, loadingDrivingLicense: false})
      });
  }

  drivingLicenseType = ['A', 'B', 'C', 'D', 'D1'];

  handleSubmit = e => {
    e.preventDefault();

    const hideLoading = message.loading('loading', 0);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        message.error(this.props.translationsStore.translate('licenseView.errorMessage'));
        hideLoading();
        return;
      }
      this.validateState()
        .then(() => {
          const fileFront = (this.state.fileListFront || [])[0]
            , fileBack = (this.state.fileListBack || [])[0]
            , fileFaceAndDocument = (this.state.fileListFaceAndDocument || [])[0];


          let formData = new FormData();
          Object.keys(values)
            .map(key => ({ key, value: key === 'issue_date' || key === 'expire_date' ? moment(values[key]).format('YYYY-MM-DD') : values[key] }))
            .forEach(({ key, value }) => {
              formData.append(key, value);
            });

          if(this.state.fileListFront) {
            formData.append('fileFrontExtension', fileFront.name.substring(fileFront.name.lastIndexOf('.') + 1));
            formData.append('fileFront', fileFront);
          }
          if(this.state.fileListBack) {
            formData.append('fileBackExtension', fileBack.name.substring(fileBack.name.lastIndexOf('.') + 1));
            formData.append('fileBack', fileBack);
          }
          if(this.state.fileListFaceAndDocument) {
            formData.append('fileFaceAndDocumentExtension', fileFaceAndDocument.name.substring(fileFaceAndDocument.name.lastIndexOf('.') + 1));
            formData.append('fileFaceAndDocument', fileFaceAndDocument);
          }

          let allowedFileExtensions = ['jpg', 'jpeg', 'bmp', 'gif', 'png', 'pdf'];
          ['fileFrontExtension', 'fileBackExtension', 'fileFaceAndDocumentExtension'].forEach(key => {
            if(formData.get(key)) {
              if (!allowedFileExtensions.includes(formData.get(key))) {
                throw this.props.translationsStore.translate('licenseView.sectionLicense.errorFileExtension');
              }
            }
          });

          new Promise( resolve => {
            if(this.props.mypageStore.hasDrivingLicense){
              return this.props.mypageStore
                .updateDrivingLicense(formData).then(res =>{
                  resolve (res);
                });
            }else{
              return this.props.mypageStore
                .createDrivingLicense(formData).then(res =>{
                  resolve(res);
              });
            }})
            .then( res => {
              hideLoading();
              if(res.error){
                throw res.error;
              }
              message.success(this.props.translationsStore.translate('licenseView.successMessage'));
              this.setState(res);
            })
            .catch(() => {
              hideLoading();
              message.error(this.props.translationsStore.translate('licenseView.errorMessage'));
            });
        })
        .catch(err => {
          hideLoading();
          message.error(err);
        });
    });
  };

  validateState = () =>
    new Promise((resolve, reject) => {
      let isValid = true;
      if(this.props.mypageStore.hasDrivingLicense){
        resolve();
      }
      ['fileListFront', 'fileListBack', 'fileListFaceAndDocument'].forEach(key => {
        isValid = isValid && !!this.state[key] && this.state[key].length > 0;
      });

      if (!isValid) {
        reject(this.props.translationsStore.translate('licenseView.sectionLicense.errorFileEmpty'));
      } else {
        resolve();
      }
    });

  beforeUpload = (file, fileList, parameterName) => {
    fileList = fileList.slice(-1);
    this.setState({ [parameterName]: fileList });
    return false;
  };

  handleSelect = value => {
    this.props.form.setFieldsValue({
      license_type: value
    });
  };

  disabledDateAfterToday = current => {
    const userAge = moment().diff(this.props.mypageStore.remoteUserData.birth_date, 'years');
    return current && (current > moment().endOf('day') || current < moment().subtract((userAge >= 28? 10 : userAge- 17),'year').endOf('day'));
  };

  disabledDateBeforeToday = current => {
    return current && (current < moment().endOf('day') || current > moment().add(10,'year').endOf('day'));
  };

  render() {
    const { fileListFront, fileListBack, fileListFaceAndDocument } = this.state;
    let { translationsStore, mypageStore } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
        <Form className={'PersonalDocumentsView'} onSubmit={this.handleSubmit}>
          <SmallDivision title={translationsStore.translate('licenseView.sectionLicense.title')} icon={<FilesIcon />} style={{ marginBottom: 40 }} />
          <div className="FieldsRow">
            <Form.Item>
              {getFieldDecorator('license_type', {
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  }
                ]
              })(
                <div className={'LicenseTypeSelector'}>
                  <div style={{ marginBottom: 8 }}>{translationsStore.translate('licenseView.sectionLicense.labelLicenseType')}</div>
                  {(this.state.license_type || (!this.state.loadingDrivingLicense && !this.state.hasDrivingLicense)) && (
                    <Select onChange={this.handleSelect} defaultValue={this.state.license_type}>
                      {this.drivingLicenseType.map(element => (
                        <Option key={element} value={element}>
                          {element}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('document_number', {
                initialValue: this.state.document_number,
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  },
                  {
                    len: 10,
                    message: translationsStore.translate('licenseView.sectionLicense.errorDocumentNumberLength')
                  }
                ]
              })(<LabelInput dataTestId={'testId_document_number'} label={translationsStore.translate('licenseView.sectionLicense.labelDocumentNumber')} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('issue_date', {
                initialValue: this.state.issue_date ? moment(this.state.issue_date) : null,
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  }
                ]
              })(<LabelInput type="date" disabledDate={this.disabledDateAfterToday} label={translationsStore.translate('licenseView.sectionLicense.labelDocumentIssueDate')} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('expire_date', {
                initialValue: this.state.expire_date ? moment(this.state.expire_date) : null,
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  }
                ]
              })(<LabelInput type="date" disabledDate={this.disabledDateBeforeToday} label={translationsStore.translate('licenseView.sectionLicense.labelDocumentExpiryDate')} />)}
            </Form.Item>
          </div>

          <div className={"ButtonContainer"}>

            <div>
              <Form.Item required={!mypageStore.hasDrivingLicense}>
                <Upload listType="text" beforeUpload={(file, fileList) => this.beforeUpload(file, fileList, 'fileListFront')} fileList={fileListFront}>
                  <Button className={'UploadButton'}>
                    <Icon type="upload" /> {translationsStore.translate('licenseView.sectionLicense.labelFrontImage')}
                  </Button>
                </Upload>
              </Form.Item>
              {!mypageStore.loadingDrivingLicense && mypageStore.drivingLicense && (
                <PersonalDocumentItem title={translationsStore.translate('licenseView.sectionLicense.labelFrontImage')} link={this.state.file_front_url} />
              )}
            </div>

            <div>
              <Form.Item required={!mypageStore.hasDrivingLicense}>
                <Upload listType="text" beforeUpload={(file, fileList) => this.beforeUpload(file, fileList, 'fileListBack')} fileList={fileListBack}>
                  <Button className={'UploadButton'}>
                    <Icon type="upload" /> {translationsStore.translate('licenseView.sectionLicense.labelBackImage')}
                  </Button>
                </Upload>
              </Form.Item>
              {!mypageStore.loadingDrivingLicense && mypageStore.drivingLicense && (
                <PersonalDocumentItem title={translationsStore.translate('licenseView.sectionLicense.labelBackImage')} link={this.state.file_back_url} />
              )}
            </div>

            <div>
              <Form.Item required={!mypageStore.hasDrivingLicense}>
                <Upload listType="text"  beforeUpload={(file, fileList) => this.beforeUpload(file, fileList, 'fileListFaceAndDocument')} fileList={fileListFaceAndDocument}>
                  <Button className={'UploadButton'}>
                    <Icon type="upload" /> {translationsStore.translate('licenseView.sectionLicense.labelFaceAndDocumentImage')}
                  </Button>
                </Upload>
              </Form.Item>
              {!mypageStore.loadingDrivingLicense && mypageStore.drivingLicense && (
                <PersonalDocumentItem
                  title={translationsStore.translate('licenseView.sectionLicense.labelFaceAndDocumentImage')}
                  link={this.state.file_face_and_document_url}
                />
              )}
            </div>

          </div>

          <div className={'buttonCenter'}>
            <Form.Item>
              <MyPageButton htmlType="submit" theme="primary" title={translationsStore.translate('profileView.submitButton')} solid />
            </Form.Item>
          </div>
        </Form>
    );
  }
}

export default Form.create({ name: 'drivingLicense' })(DrivingLicenseForm);
