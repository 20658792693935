import {observable, action, computed, toJS} from 'mobx';

import { mypageStore } from '.';
import { api } from '../services';
import rollbar from "../services/rollbar";
import {addDays} from "date-fns";

class ProductsStore {
  constructor({ mypageStore }) {
    this.mypageStore = mypageStore;
  }

  @observable
  loadingSubscriptionToProduct = false;

  @observable
  loadingAvailableProducts = false;

  @observable
  availableProducts = [];

  @observable
  discountRatePlans = [];

  @observable
  selectedProduct = undefined;
  @observable
  selectedProductRateplan = undefined;

  @observable
  discountFromMyApp = undefined;

  @observable
  optionalServiceProduct = undefined;

  @observable
  optionalService = undefined;

  @observable
  selectedAddOn = undefined;

  @observable
  selectedAddOnProduct = undefined;

  @observable
  comeFromService = false


  @observable
  selectedOptionalServicesWithRatePlans = [];

  @action
  setAvailableProducts = availableProducts => {
    this.availableProducts = availableProducts;
  };

  @action
  setDiscountFromMyApp = (discountRatePlanId, discountId) => {
    if (!discountRatePlanId || !discountId) {

      this.discountFromMyApp = undefined;
    } else {

      this.discountFromMyApp = {
        discountRatePlanId,
        discountId,
      };
    }
  };

  @action
  setSelectedProduct = selectedProduct => {
    this.selectedProduct = selectedProduct;
  };
  @action
  setComeFromServices = comeFromService => {
    this.comeFromService = comeFromService;
  }
  @action
  setSelectedProductRateplan = selectedProductRateplan => {
    this.selectedProductRateplan = selectedProductRateplan;
  };

  @action
  setSelectedProductRateplanById = selectedProductRateplanId => {
    const ratePlan = this.selectedProduct && this.selectedProduct.productRatePlans.find(ratePlan => ratePlan.id === selectedProductRateplanId);

    if (ratePlan) {
      this.setSelectedProductRateplan(ratePlan);
    }
  };

  @action
  setSelectedProductById = selectedProductId => {
    const product = this.availableProducts.find(product => product.id === selectedProductId);

    if (product) {
      this.setSelectedProduct(product);
    }
  };

  @action
  setDiscountRatePlans = discountRatePlans => {
    if(Array.isArray(discountRatePlans)) {
      this.discountRatePlans = discountRatePlans;
    }
  };

  @action
  selectAirRenewProduct = () => {
    this.selectedProduct = this.availableProducts.find(product => product.category === 'renew' && product.features.some(feature => feature.node.name === 'air'));
  };

  @computed
  get isAirRenewSelected() {
    return this.selectedProduct.category === 'renew';
  }

  @computed
  get isRenewSubscribed() {
    return !!this.mypageStore.selectedManageableVehicle && this.mypageStore.selectedManageableVehicle.subscriptions.some(subs => subs.product.category === "renew");
  }

  @computed
  get isTheMostRecentRenewExpiring() {
    const allRenewSubscriptions = this.mypageStore.selectedManageableVehicle.subscriptions.filter(subs => subs.product.category === "renew");
    const mostRecentRenewSubscription = allRenewSubscriptions.reduce((prev, current) => (prev.node.end_date > current.node.end_date) ? prev : current);
    return this.isSubscriptionExpiring(mostRecentRenewSubscription);
  }

  @action
  isSubscriptionExpiring = (subscription) => {
    return subscription.node && subscription.node.end_date && new Date(subscription.node.end_date) < addDays(new Date(), 30);
  };

  setOptionalServiceProduct = optionalServiceProduct => {
    this.optionalServiceProduct = optionalServiceProduct;
  };
  setOptionalService = optionalService => {
    this.optionalService = optionalService;
  };

  setSelectedAddOn = selectedAddOn => {
    this.selectedAddOn = selectedAddOn;
  };
  setSelectedAddOnProduct = selectedAddOnProduct => {
    this.selectedAddOnProduct = selectedAddOnProduct;
  };


  @action
  addSelectedOptionalServiceWithRatePlans = optionalServiceProductWithRatePlan => {
    if (optionalServiceProductWithRatePlan && optionalServiceProductWithRatePlan.product && optionalServiceProductWithRatePlan.ratePlan) {

      const discount = this.getDiscountFromProductUuid(optionalServiceProductWithRatePlan.product.uuid);

      if (discount) {

        optionalServiceProductWithRatePlan.discountRatePlanId = discount.discountRatePlanId;
        optionalServiceProductWithRatePlan.discountId = discount.discountId;
      }

      this.removeSelectedOptionalServiceWithRatePlansByProductUuid(optionalServiceProductWithRatePlan.product.uuid);
      this.selectedOptionalServicesWithRatePlans.push(optionalServiceProductWithRatePlan);
    }
  };

  @action
  removeSelectedOptionalServiceWithRatePlansByProductUuid = (productUuid) => {
    const indexOfElementToRemove = this.selectedOptionalServicesWithRatePlans.findIndex(selectedProductWithRatePlan => selectedProductWithRatePlan.product.uuid === productUuid);

    if (indexOfElementToRemove > -1) {

      this.selectedOptionalServicesWithRatePlans.splice(indexOfElementToRemove, 1);
    }
  };

  @action
  removeSelectedOptionalServiceWithRatePlansByProductUuidAndRatePlanId = (productUuid, ratePlanId) => {
    const indexOfElementToRemove = this.selectedOptionalServicesWithRatePlans.findIndex(selectedProductWithRatePlan => selectedProductWithRatePlan.product.uuid === productUuid && selectedProductWithRatePlan.ratePlan.id === ratePlanId);

    if (indexOfElementToRemove > -1) {

      this.selectedOptionalServicesWithRatePlans.splice(indexOfElementToRemove, 1);
    }
  };

  @action
  getSelectedRatePlanForProductInSelectedOptionalServiceWithRatePlansByProductUuid = (productUuid) => {
    const selectedProductWithRatePlanFound = this.selectedOptionalServicesWithRatePlans.find(selectedProductWithRatePlan => selectedProductWithRatePlan.product.uuid === productUuid);

    if (!selectedProductWithRatePlanFound) {
      return undefined;
    }

    return selectedProductWithRatePlanFound.ratePlan && selectedProductWithRatePlanFound.ratePlan.id;
  };

  @action
  resetSelectedOptionalServiceWithRatePlans = () => {
    this.selectedOptionalServicesWithRatePlans = [];
  };

  @action
  isRatePlanSelectedInSelectedOptionalServiceWithRatePlans = (ratePlanId) => !!this.selectedOptionalServicesWithRatePlans.find(selectedProductWithRatePlan => selectedProductWithRatePlan.ratePlan.id === ratePlanId);

  @computed
  get isAtLeastAnAdditionalProductRatePlanSelected () {
    return !!(this.selectedOptionalServicesWithRatePlans && this.selectedOptionalServicesWithRatePlans.length);
  }

  @action
  async subscribeToProduct(paymentMethod, refId) {
    this.loadingSubscriptionToProduct = true;
    let products = [];

    try {

      products = this.getProductsWithRatePlansToSubscribe();

      if(refId) {
        await api('setPaymentMethodDefault', this.mypageStore.remoteUserData.zuora_account_id, refId);
      }

      const response = await api('subscribeToProduct', {
        zuora_account_id: this.mypageStore.remoteUserData.zuora_account_id,
        products,
        uuid: this.mypageStore.remoteUserData.uuid,
        licensePlate: this.mypageStore.selectedManageableVehicle.license_plate,
        paymentMethod: paymentMethod
      });

      if (!response.subscription || response.subscription.some(sub => !sub.success)) {
        throw 'Error on subscription';
      }

      return response;
    } catch (error) {

      rollbar.error(`Error while subscribing user with uuid ${this.mypageStore.remoteUserData.uuid} to products ${JSON.stringify(products)} with payment method ${paymentMethod} ${refId ? `refId ${refId}` : ''} error: ${error}`);

      throw error;
    } finally {
      this.selectedProduct = undefined;
      this.optionalServiceProduct = undefined;
      this.optionalService = undefined;
      this.loadingSubscriptionToProduct = false;
    }
  }

  @action
  getProductsWithRatePlansToSubscribe = () => {
    const products = [];

    const discount = this.discountFromMyApp ? this.discountFromMyApp : this.getDiscountFromProductUuid(this.selectedProduct.uuid);

    if(this.selectedProduct && this.selectedProduct.uuid && this.selectedProductRateplan && this.selectedProductRateplan.id) {
      products.push({
        productUuid: this.selectedProduct.uuid,
        productRatePlanId: this.selectedProductRateplan.id,
        ...discount && discount.discountRatePlanId && {discountRatePlanId: discount.discountRatePlanId},
        ...discount && discount.discountId && {discountId: discount.discountId}
      });
    }

    if (this.optionalServiceProduct && this.optionalServiceProduct.uuid &&  this.optionalService && this.optionalService.id) {
      const discount = this.getDiscountFromProductUuid(this.optionalServiceProduct.uuid);
      products.push({
        productUuid: this.optionalServiceProduct.uuid,
        productRatePlanId: this.optionalService.id,
        ...discount && discount.discountRatePlanId && {discountRatePlanId: discount.discountRatePlanId},
        ...discount && discount.discountId && {discountId: discount.discountId}
      });
    }

    if(this.selectedAddOnProduct && this.selectedAddOnProduct[0] && this.selectedAddOnProduct[0].uuid && this.selectedAddOn && this.selectedAddOn.id)
    {
      const discount = this.getDiscountFromProductUuid(this.selectedAddOnProduct.uuid);
      products.push({
        productUuid: this.selectedAddOnProduct[0].uuid,
        productRatePlanId: this.selectedAddOn.id,
        ...discount && discount.discountRatePlanId && {discountRatePlanId: discount.discountRatePlanId},
        ...discount && discount.discountId && {discountId: discount.discountId}
      });
    }

    if (this.selectedOptionalServicesWithRatePlans.length) {

      for (let selectedOptionalServiceWithRatePlans of this.selectedOptionalServicesWithRatePlans) {

        products.push({
          productUuid: selectedOptionalServiceWithRatePlans.product.uuid,
          productRatePlanId: selectedOptionalServiceWithRatePlans.ratePlan.id,
          ...selectedOptionalServiceWithRatePlans.discountRatePlanId && {discountRatePlanId: selectedOptionalServiceWithRatePlans.discountRatePlanId},
          ...selectedOptionalServiceWithRatePlans.discountId && {discountId: selectedOptionalServiceWithRatePlans.discountId}
        })
      }
    }

    return products;
  }

  @action
  getDiscountFromProductUuid = productUuid => {
    if (this.mypageStore.selectedManageableVehicle && this.mypageStore.selectedManageableVehicle.discounts && Array.isArray(this.mypageStore.selectedManageableVehicle.discounts)) {

      let discount = this.mypageStore.selectedManageableVehicle.discounts
          .find(discount => !discount.bundleProductUuids && discount.discountedProductUuids && discount.discountedProductUuids.indexOf(productUuid) > -1);

      const checkBundle = (this.selectedProduct && this.selectedProduct.uuid !== productUuid) || (this.optionalService);

      if (!discount && checkBundle) {
        discount = this.getDiscountOnBundleWithProductUuids([this.selectedProduct && this.selectedProduct.uuid !== productUuid? this.selectedProduct.uuid : this.optionalServiceProduct.uuid, productUuid], productUuid);
      }

      if (discount) {
        return discount;
      }
    }

    return undefined;
  }

  @action
  getDiscountOnBundleWithProductUuids = (productUuids, productUuidToCheck = undefined) => {
    const checkOnlyTheArray = productUuidToCheck === undefined;

    if (this.mypageStore.selectedManageableVehicle
      && this.mypageStore.selectedManageableVehicle.discounts
      && Array.isArray(this.mypageStore.selectedManageableVehicle.discounts)
      && Array.isArray(productUuids)
    ) {

      const discount = this.mypageStore.selectedManageableVehicle.discounts.find(availableDiscount =>
        availableDiscount.bundleProductUuids
        && availableDiscount.bundleProductUuids
          .every(bundleProductUuid => productUuids.includes(bundleProductUuid))
      );

      if (discount && discount.discountedProductUuids && (checkOnlyTheArray || discount.discountedProductUuids.find(discountedProductUuid => discountedProductUuid === productUuidToCheck))) {
        return discount;
      }
    }

    return undefined;
  }

  @action
  loadAvailableProducts() {
    this.loadingAvailableProducts = true;
    return api('getProducts')
      .then(response => {
        if (response && response.length > 0) {
          const availableProductsForMyPage = response.filter(product => this.isProductAvailableForMyPage(product));

          this.setAvailableProducts(availableProductsForMyPage);
        }
      })
      .catch(error => {
        this.mypageStore.setExceptionFound(true);
      })
      .then(() => {
        this.loadingAvailableProducts = false;
      });
  }

  @action
  loadDiscountsRatePlans() {
    return api('getDiscountRatePlans')
      .then(response => {
        this.setDiscountRatePlans(response);
      })
      .catch(() => {
        this.mypageStore.setExceptionFound(true);
      });
  }

  @action
  getDiscountPercentage(zuoraDiscountRatePlanId) {
    const discountRatePlan = this.discountRatePlans.find(discountRatePlan => discountRatePlan.id === zuoraDiscountRatePlanId);

    return discountRatePlan && discountRatePlan.productRatePlanCharges && discountRatePlan.productRatePlanCharges[0]
      && discountRatePlan.productRatePlanCharges[0].pricing && discountRatePlan.productRatePlanCharges[0].pricing[0].discountPercentage;
  }

  @action
  getDiscountFixedAmount(zuoraDiscountRatePlanId) {
    const discountRatePlan = this.discountRatePlans.find(discountRatePlan => discountRatePlan.id === zuoraDiscountRatePlanId);

    return discountRatePlan && discountRatePlan.productRatePlanCharges && discountRatePlan.productRatePlanCharges[0]
      && discountRatePlan.productRatePlanCharges[0].pricing && discountRatePlan.productRatePlanCharges[0].pricing[0].discountAmount;
  }

  @action
  getDiscountedPrice(zuoraDiscountRatePlanId, originalPrice) {
    const discountPercentage =  this.getDiscountPercentage(zuoraDiscountRatePlanId);

    if (discountPercentage) {

      return originalPrice - (originalPrice / 100 * discountPercentage);
    }

    const discountFixedAmount = this.getDiscountFixedAmount(zuoraDiscountRatePlanId);

    if (discountFixedAmount) {

      return originalPrice - discountFixedAmount;
    }

    return undefined;
  }

  @action
  getFakeOriginalPriceByProductUuid(productUuid) {

    const product = this.availableProducts.find(product => product.uuid === productUuid);

    if (product) {
      if (this.isAssistanceBase(product)) {
        return '4,99 €';
      }

      if (this.isCarlLocator(product)) {
        return '9,99 €';
      }

      if (this.isFindMechanic(product)) {
        return '4,99 €';
      }
    }
  }

  @action
  getImageFilePathByProductUuid(productUuid) {

    const product = this.availableProducts.find(product => product.uuid === productUuid);

    if (product) {
      if (this.isAssistanceBase(product)) {
        return 'products/AIR_phonehand.png';
      }

      if (this.isCarlLocator(product)) {
        return 'products/AIR_shield.png';
      }

      if (this.isFindMechanic(product)) {
        return 'products/valerio_product_logo.png';
      }
    }
  }


  @action
  getProductNameByUuid(productUuid) {

    const product = this.availableProducts.find(product => product.uuid === productUuid);

    if (product) {
      if (this.isAssistanceBase(product)) {
        return 'Assistenza Base';
      }

      if (this.isAssistanceAdvanced(product)) {
        return 'Assistenza Avanzata';
      }

      if (this.isCarlLocator(product)) {
        return 'Car Locator';
      }

      if (this.isFindMechanic(product)) {
        return 'Valerio il meccanico amico';
      }

      if (product.category === 'base' && product.features.some(feature => feature.node.name === 'movein')) {
        return 'Servizio Move-In';
      }

      if (product.category === 'renew' && product.features.some(feature => feature.node.name === 'movein')) {
        return 'Rinnovo Servizio Move-In';
      }

      if (product.category === 'add-on' && product.features.some(feature => feature.node.name === 'axa-assistance')) {
        return 'Air Club';
      }

      if (product.category === 'renew' && product.features.some(feature => feature.node.name === 'air')) {
        return 'Rinnovo Servizi Air';
      }
    }

    return 'Servizio Air';
  }

  @action
  isBuyableProduct = (product) => {
    const vehicleRegions = ((this.mypageStore.remoteUserVehiclesRegions.find((vr) => vr.license_plate === this.mypageStore.selectedManageableVehicle.license_plate) || {}).regions)||[];
    let productAvailableInRegionsOnly = product.regions.map(r => r.node.code);
    productAvailableInRegionsOnly = productAvailableInRegionsOnly.length > 0 ? productAvailableInRegionsOnly : undefined;
    const canBuyAccordingVehicleRegions = Array.isArray(productAvailableInRegionsOnly) ? (vehicleRegions.every(vr => productAvailableInRegionsOnly.includes(vr.code)) || (vehicleRegions.some(vr => vr.code === '01') && product.zuora_product_id === '8a1285e290e2f9510191043cf86037a6')) : true;
    return (
      (product.hasOwnProperty('buyable_till') ? new Date(`${product.buyable_till}T23:59:59`) >= new Date() : true) &&
      (product.hasOwnProperty('buyable_from') ? new Date(product.buyable_from) <= new Date() : true) &&
      canBuyAccordingVehicleRegions
    );
  };

  @action
  isMoveIn = (product) => {
    return product.features.some(feature => feature.node.name === 'movein')
  };

  @action
  isProductAvailableForMyPage = (product) => {
    return !this.isFit2youQuoteProduct(product);
  };


  @action
  isFit2youQuoteProduct = (product) => {
    return product.features && product.features.some(feature => feature.node.name === 'fit2you');
  };

  @action
  isAirClub = (product) => {
    return product.category === 'add-on' && product.features.some(feature => feature.node.name === 'axa-assistance') && product.features.some(feature => feature.node.name === 'reminder')
  };

  @action
  isMyAppAddon = (product) => {
    return product.category === 'add-on' && product.features.some(feature => feature.node.name === 'myapp')
  };

  @action
  isPrivateCustomerPortability = (product) => {
    // INFO this is the portability that my-page users should buy
    return product && product.category === 'portability' && product.availability === 'private-customer';
  };

  @action
  isPortability = (product) => {
    return product && product.category === 'portability';
  };

  @action
  isAirClubBuyable = () => {
    const airClubProduct = this.availableProducts.find(product => this.isAirClub(product));

    return this.isBuyableProduct(airClubProduct);
  };

  @action
  isAssistance = (product) => {
    return product.category === 'add-on' && product.features.some(feature => feature.node.name === 'axa-assistance') && !product.features.some(feature => feature.node.name === 'reminder')
  };

  @action
  isAssistanceBase = (product) => {
    return this.isAssistance(product) && !product.features.some((feature) => feature.node.name === 'crash-assistance');
  };

  @action
  isAssistanceAdvanced = (product) => {
    return this.isAssistance(product) && product.features.some((feature) => feature.node.name === 'crash-assistance');
  };

  @action
  isFindMechanic = (product) => {
    return product.category === 'add-on' && product.features.some(feature => feature.node.name === 'find-mechanic');
  };

  @action
  isCarlLocator = (product) => {
    return product.category === 'add-on' && product.features.some(feature => feature.node.name === 'find-vehicle');
  };

  @action
  areAirClubOrAssistanceFeaturesAlreadySubscribed = (product) => {
    const { subscriptions } = this.mypageStore.selectedManageableVehicle;
    const subscribedFeatures = subscriptions.reduce((acc, sub) => ([...acc, ...sub.product.features.map(f => f.node.name)]), []);
    const alreadyFeaturedAssistance = subscribedFeatures.includes('axa-assistance');
    const alreadyFeaturedAssistanceAdvanced = subscribedFeatures.includes('axa-assistance') && subscribedFeatures.includes('crash-assistance');
    const allFeaturesMatching = product.features.every(feature => subscribedFeatures.includes(feature.node.name));
    const hasAirBaseSubscription = subscriptions.some((sub) => sub.product.features.some((feat) => feat.node.name === 'air') && !this.isSubscriptionExpired(sub));
    return (
        allFeaturesMatching ||
        ((this.isAirClub(product) || this.isAssistance(product)) &&
            hasAirBaseSubscription &&
            this.isBuyableProduct(product) &&
            (!alreadyFeaturedAssistance || (this.isAssistanceAdvanced(product) && !alreadyFeaturedAssistanceAdvanced)))
    );
  };

  @action
  isSubscriptionExpired = (subscription) => {
    return subscription && subscription.node && new Date(subscription.node.end_date) < new Date();
  };

  @action
  isPurchasableAdditionalProduct = (product) => {
    if (!this.isBuyableProduct(product)) {
      return false;
    }

    if (!(this.isAssistance(product)
      || this.isCarlLocator(product)
      || this.isFindMechanic(product)
      || (this.isMyAppAddon(product) && this.mypageStore.remoteUserIsFromMoVeInProgram))) { //XXX myapp add-on is only for movein customers
      return false;
    }

    const { subscriptions } = this.mypageStore.selectedManageableVehicle;
    const activeSubscriptions = subscriptions.filter(subscription => subscription && subscription.node && new Date(subscription.node.end_date) > new Date());
    const activeSubscriptionsFeatures = activeSubscriptions.reduce((acc, sub) => ([...acc, ...sub.product.features.map(f => f.node.name)]), []);

    if (this.mypageStore.remoteUserIsFromMoVeInProgram && this.isAssistanceAdvanced(product)) {
      return false;
    }

    if (!this.mypageStore.remoteUserIsFromMoVeInProgram && (this.isCarlLocator(product) || this.isFindMechanic(product))) {
      return false;
    }

    const allFeaturesMatching = product.features.every(feature => activeSubscriptionsFeatures.includes(feature.node.name));
    return !allFeaturesMatching;
  };
}

export default new ProductsStore({ mypageStore });
