import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import { withRouter, Redirect } from 'react-router-dom';

import Section from "../../../../common/components/Section";
import MyPageContainer from "../../../../common/components/MyPageContainer";
import VehicleSelector from "../../../../common/components/VehicleSelector";
import MyPageButton from "../../../../common/components/MyPageButton";
import {DoneIcon} from "../../../../common/icons";
import { productsHelper } from "../../../../helpers";
import {  toJS } from 'mobx';

import './ReviewPaymentView.css'

const ServiceListItem = ({title, subtitle, showCheckmark, price, discountedPrice}) => (
  <div className="ServiceListItem">
    <div className="ListItemContainer">
      {showCheckmark && <div className="ListIcon"><DoneIcon width={16} height={16}/></div>}
      <div>
        <div>{title}</div>
        {subtitle && <div className="ListItemSubtitle">{subtitle}</div>}
      </div>
    </div>
    <div className={'PriceContainer'}>
      <div className={discountedPrice !== undefined? 'OriginalPriceDiscounted' : ''}>{price} €</div>
      {discountedPrice !== undefined && <div className={'DiscountedPrice'}>{discountedPrice} €</div>}
    </div>
  </div>
);

@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class ReviewPaymentView extends Component {

  getProductPriceWithDiscount = (productUuid, originalPrice) => {
    const {productsStore} = this?.props;
    const parsedOriginalPrice = Number.parseFloat(originalPrice);

    let discount = productsStore?.getDiscountFromProductUuid(productUuid);

    if (discount) {

      return productsStore.getDiscountedPrice(discount && discount?.discountRatePlanId, parsedOriginalPrice);
    } else {
      return undefined;
    }
  }

  getBillingPeriodSubTitle = (ratePlan) => {
    // MEMO new addon rate plan can have charge billing period `Month` or `Annual`
    return ratePlan ?? ratePlan?.productRatePlanCharges[0]?.billingPeriod === 'Month' ? "abbonamento mensile" : "12 mesi";
  }

  getTotal = (mainPrice, mainPriceDiscounted, optionalPrice, optionalPriceDiscounted,addonProductRatePlan) => {
    const { productsStore } = this?.props;
    let total = 0;

    total += mainPriceDiscounted !== undefined ? mainPriceDiscounted : mainPrice;

    if (optionalPrice) {
      total += optionalPriceDiscounted !== undefined ? optionalPriceDiscounted : optionalPrice;
    }
    if(addonProductRatePlan){
      total += addonProductRatePlan?.productRatePlanCharges[0]?.pricing[0]?.price
    }

    if (productsStore?.isAtLeastAnAdditionalProductRatePlanSelected) {
      for (let selectedOptionServiceWithRatePlan of productsStore?.selectedOptionalServicesWithRatePlans) {
        const standardPrice = productsHelper?.getProductPriceFromRatePlan(selectedOptionServiceWithRatePlan?.ratePlan);
        const discountPrice = this?.getProductPriceWithDiscount(selectedOptionServiceWithRatePlan?.product?.uuid, productsHelper?.getProductPriceFromRatePlan(selectedOptionServiceWithRatePlan?.ratePlan));
        total += discountPrice !== undefined ? discountPrice : standardPrice;
      }
    }

    return total;
  }

  render() {
    const { productsStore } = this?.props;
    const mainProduct = productsStore?.selectedProduct;
    const optionalProductRatePlan = productsStore?.optionalService;
    const addonProductRatePlan = productsStore?.selectedAddOn;

    if(!mainProduct || (!optionalProductRatePlan && !productsStore?.isAtLeastAnAdditionalProductRatePlanSelected) && !addonProductRatePlan) {
      return <Redirect to="/" />;
    }

    const mainPrice = productsHelper?.getProductPriceFromRatePlan(productsStore?.selectedProductRateplan);

    let optionalPrice, optionalPriceDiscounted;
    if (optionalProductRatePlan) {
      optionalPrice = productsHelper?.getProductPriceFromRatePlan(optionalProductRatePlan);
      optionalPriceDiscounted = this?.getProductPriceWithDiscount(productsStore?.optionalServiceProduct && productsStore?.optionalServiceProduct?.uuid, optionalPrice);
    }

    const mainPriceDiscounted = this?.getProductPriceWithDiscount(productsStore?.selectedProduct?.uuid, mainPrice);

    return (
      <MyPageContainer className='ReviewPaymentView' dataCollection={this?.props?.dataCollection}>
        <VehicleSelector disabled={true} />
        <h3>Dettaglio prodotti scelti</h3>
        <Section>
          {
            productsStore.isAirRenewSelected
              ? <ServiceListItem
                title={`Servizio Air - 12 mesi`}
                price={mainPrice?.toFixed(2)}
                showCheckmark={true}
                discountedPrice={mainPriceDiscounted && mainPriceDiscounted?.toFixed(2)}
              />
              : <ServiceListItem
                title={`${mainProduct.name} - ${mainProduct?.duration_in_months} mesi`}
                price={mainPrice?.toFixed(2)}
                showCheckmark={true}
                discountedPrice={mainPriceDiscounted && mainPriceDiscounted?.toFixed(2)}
              />
          }
          {Object.keys(optionalProductRatePlan ?? {}).length > 0 && optionalPrice ?  <ServiceListItem
            title={optionalProductRatePlan?.name}
            subtitle={this?.getBillingPeriodSubTitle(optionalProductRatePlan)}
            price={optionalPrice}
            showCheckmark={true}
            discountedPrice={optionalPriceDiscounted && optionalPriceDiscounted?.toFixed(2)}
          />:<></>}
          {Object.keys(addonProductRatePlan ?? {}).length >0  && <ServiceListItem
            title={addonProductRatePlan?.name}
            subtitle={addonProductRatePlan?.description}
            price={addonProductRatePlan?.productRatePlanCharges[0]?.pricing[0]?.price}
            showCheckmark={true}
            discountedPrice={optionalPriceDiscounted && optionalPriceDiscounted?.toFixed(2)}
          />}
          {productsStore?.isAtLeastAnAdditionalProductRatePlanSelected &&
            productsStore?.selectedOptionalServicesWithRatePlans?.map((selectedOptionServiceWithRatePlan, index) => {
              const discountedPrice = this.getProductPriceWithDiscount(selectedOptionServiceWithRatePlan?.product?.uuid, productsHelper?.getProductPriceFromRatePlan(selectedOptionServiceWithRatePlan?.ratePlan));

              return (<ServiceListItem
                key={index}
                title={selectedOptionServiceWithRatePlan?.ratePlan?.name}
                subtitle={this?.getBillingPeriodSubTitle(selectedOptionServiceWithRatePlan?.ratePlan)}
                price={productsHelper?.getProductPriceFromRatePlan(selectedOptionServiceWithRatePlan?.ratePlan)?.toFixed(2)}
                showCheckmark={true}
                discountedPrice={discountedPrice && discountedPrice?.toFixed(2)}
              />);
            })
          }
          <ServiceListItem
            title={"Prezzo totale"}
            subtitle={"iva compresa"}
            price={this?.getTotal(mainPrice, mainPriceDiscounted, optionalPrice, optionalPriceDiscounted,addonProductRatePlan)?.toFixed(2)}
          />
        </Section>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <MyPageButton
            style={{width: 'auto'}}
            title={ "Torna indietro"}
            onClick={() => this?.props?.history?.goBack()}
            solid
            theme="custom"
          />
          <MyPageButton
            style={{width: 'auto'}}
            title={ "Procedi all'acquisto"}
            onClick={() => this?.props?.history?.push('/payments')}
            solid
            theme="primary"
          />
        </div>
      </MyPageContainer>
    )
  }
}

export default ReviewPaymentView
