import React from 'react';
import ProductCardBase from "./ProductCardBase";

const AirClubCard = ({...otherProps}) => {
  return (
    <ProductCardBase
      logo="airclub_logo"
      description="Acquista a 49€ all'anno oppure a soli 4,99€ al mese"
      {...otherProps}
    />

  )
};

export default AirClubCard;
