import K from '../constants';
import restHelpers from '../helpers/restHelper';

const getUserFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}`, {});
const updateUserProfileFn = (userUuid, userData) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}`, {data: userData});
const getManageableVehiclesFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles`, {});
const getManageableVehicleDataFn = (userUuid, vehicleId) => restHelpers.withBearer.get(`${K.API_URL}/users​/${userUuid}/direct_manageable/vehicles/${vehicleId}`, {});
const getZuoraHostPageTokenFn = (pageId) => restHelpers.withBearer.get(`${K.API_URL}/zuora/token/${pageId}`, {});
const setPaymentMethodDefaultFn = (zuora_account_id, refId) => restHelpers.withBearer.post(`${K.API_URL}/zuora/default-payment-method`, {data: { zuora_account_id: zuora_account_id, refId: refId }});
const subscribeToProductFn = (data) => restHelpers.withBearer.post(`${K.API_URL}/zuora/subscriptions`, {data: data});
const getProductsFn = () => restHelpers.withBearer.get(`${K.API_URL}/products`, {});
const getDocumentsFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/documents`, {});
const downloadDocumentFn = (documentUrl) =>  restHelpers.withBearer.post(`${K.API_URL}/download-document`, {data: {url: documentUrl}});
const downloadInvoiceFn = (invoiceUrl) =>  restHelpers.withBearer.post(`${K.API_URL}/download-invoice`, {data: {url: invoiceUrl}});
const updateMarketingConsentFn = (userUuid, vehicleId, data) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/privacy`, {data});
const updateAirclubMarketingConsentFn = (userUuid, vehicleId, data) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/airclub_privacy`, {data});
const getSlimpayIframeFn = (userUuid) =>  restHelpers.withBearer.post(`${K.API_URL}/slimpay-order`, {data: {uuid: userUuid}});
const getSlimpayOrderFn = (orderId) =>  restHelpers.withBearer.get(`${K.API_URL}/slimpay-order/${orderId}`, {});
const getContractsGeneralConditionsNameFn = (userUuid, vehicleId) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/contracts-general-conditions`, {});
const updateDrivingLicenseFn = (userUuid, licenseData) => restHelpers.withBearer.putFormData(`${K.API_URL}/users/${userUuid}/driving-license-document`, {formData: licenseData});
const createDrivingLicenseFn = (userUuid, licenseData) => restHelpers.withBearer.postFormData(`${K.API_URL}/users/${userUuid}/driving-license-document`, {formData: licenseData});
const getDrivingLicenseFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/driving-license-document`, {});
const getAgendaInsurancesFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/insurances`, {});
const getAgendaMaintenancesFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/maintenances`, {});
const getAgendaTaxesFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/taxes`, {});
const createAgendaMaintenanceFn = (userUuid, vehicleId, data) => restHelpers.withBearer.post(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/maintenances`, {data});
const createAgendaTaxesFn = (userUuid, vehicleId, date) => restHelpers.withBearer.post(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/taxes`, {data: {date}});
const createAgendaInsuranceFn = (userUuid, vehicleId, type, date) => restHelpers.withBearer.post(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/insurances`, {data: {date, type}});
const updateAgendaMaintenanceFn = (userUuid, vehicleId, uuid, performed_date) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/maintenances/${uuid}`, {data: {performed_date}});
const updateAgendaTaxesFn = (userUuid, vehicleId, uuid, performed_date) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/taxes/${uuid}`, {data: {performed_date}});
const updateAgendaInsuranceFn = (userUuid, vehicleId, uuid, performed_date) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/insurances/${uuid}`, {data: {performed_date}});
const updateAirCustomerConsentFn = (userUuid, vehicleId, data) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/air-user-privacy`, {data});
const uploadDocumentFn = (userUuid, data) => restHelpers.withBearer.postFormData(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/documents`, {formData: data});
const updateDocumentFn = (userUuid, documentUuid, data) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/documents/${documentUuid}`, {data});
const deleteDocumentFn = (userUuid, documentUuid) => restHelpers.withBearer.delete(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/documents/${documentUuid}`, {});
const getIdentityCardFn = (userUuid) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/identity-card-document`, {});
const updateIdentityCardFn = (userUuid, identityCardData) => restHelpers.withBearer.putFormData(`${K.API_URL}/users/${userUuid}/identity-card-document`, {formData: identityCardData});
const createIdentityCardFn = (userUuid, identityCardData) => restHelpers.withBearer.postFormData(`${K.API_URL}/users/${userUuid}/identity-card-document`, {formData: identityCardData});
const updateVehicleDataFn = (userUuid, vehicleId, data) => restHelpers.withBearer.put(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}`, {data});
const getNotificationFn = (userUuid, notificationId) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/notifications/${notificationId}`, {});
const getArchivedNotificationsFn = (userUuid, type) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/notifications-archived${type ? `?type=${type}` : ''}`, {});
const archiveNotificationFn = (userUuid, notificationId) => restHelpers.withBearer.post(`${K.API_URL}/users/${userUuid}/notifications/${notificationId}/archive`, {});
const snoozeNotificationFn = (userUuid, notificationId, data) => restHelpers.withBearer.post(`${K.API_URL}/users/${userUuid}/notifications/${notificationId}/disable`, { data });
const confirmSupportRequestNotificationFn = (userUuid, notificationId) => restHelpers.withBearer.post(`${K.API_URL}/users/${userUuid}/notifications/${notificationId}/confirm`, {});
const getZuoraPaymentMethodsFn = uuid => restHelpers.withBearer.get(`${K.API_URL}/users/${uuid}/get-zuora-payment-methods`, {});
const getAvailableDiscountsFn = (userUuid, vehicleId) => restHelpers.withBearer.get(`${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleId}/available-discounts`, {});
const getDiscountRatePlansFn = () => restHelpers.withBearer.get(`${K.API_URL}/discounts/products`, {});
const getZuoraTokenFn = (pageId) => restHelpers.withBearer.get(`${K.API_URL}/zuora/token/${pageId}`, {});

export default {
  getUserData: getUserFn,
  updateUserProfile: updateUserProfileFn,
  getUserManageableVehicles: getManageableVehiclesFn,
  getUserManageableVehicleData: getManageableVehicleDataFn,
  getZuoraHostedPageToken: getZuoraHostPageTokenFn,
  setPaymentMethodDefault: setPaymentMethodDefaultFn,
  subscribeToProduct: subscribeToProductFn,
  getProducts: getProductsFn,
  getDocuments: getDocumentsFn,
  downloadDocument: downloadDocumentFn,
  downloadInvoice: downloadInvoiceFn,
  updateMarketingConsent: updateMarketingConsentFn,
  updateAirclubMarketingConsent: updateAirclubMarketingConsentFn,
  getSlimpayIframe: getSlimpayIframeFn,
  getSlimpayOrder: getSlimpayOrderFn,
  getContractsGeneralConditionsName: getContractsGeneralConditionsNameFn,
  updateDrivingLicense: updateDrivingLicenseFn,
  createDrivingLicense: createDrivingLicenseFn,
  getDrivingLicense: getDrivingLicenseFn,
  getAgendaInsurances: getAgendaInsurancesFn,
  getAgendaMaintenances: getAgendaMaintenancesFn,
  getAgendaTaxes: getAgendaTaxesFn,
  createAgendaMaintenance: createAgendaMaintenanceFn,
  createAgendaTaxes: createAgendaTaxesFn,
  createAgendaInsurance: createAgendaInsuranceFn,
  updateAgendaMaintenance: updateAgendaMaintenanceFn,
  updateAgendaTaxes: updateAgendaTaxesFn,
  updateAgendaInsurance: updateAgendaInsuranceFn,
  updateAirCustomerConsent: updateAirCustomerConsentFn,
  uploadDocument: uploadDocumentFn,
  updateDocument: updateDocumentFn,
  deleteDocument: deleteDocumentFn,
  getIdentityCard: getIdentityCardFn,
  updateIdentityCard: updateIdentityCardFn,
  createIdentityCard: createIdentityCardFn,
  updateVehicleData: updateVehicleDataFn,
  getNotification: getNotificationFn,
  getArchivedNotifications: getArchivedNotificationsFn,
  archiveNotification: archiveNotificationFn,
  snoozeNotification: snoozeNotificationFn,
  confirmSupportRequestNotification: confirmSupportRequestNotificationFn,
  getZuoraPaymentMethods: getZuoraPaymentMethodsFn,
  getZuoraToken: getZuoraTokenFn,
  getAvailableDiscounts: getAvailableDiscountsFn,
  getDiscountRatePlans: getDiscountRatePlansFn
};
