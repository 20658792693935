import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import './BuyAdditionalService.css';
import VehicleSelector from '../../../../common/components/VehicleSelector';

import MyPageContainer from '../../../../common/components/MyPageContainer';
import Section from '../../../../common/components/Section';
import MyPageButton from '../../../../common/components/MyPageButton';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import AdditionServicesConditionCheckboxes from '../AdditionServicesConditionCheckboxes';
import ProductCardWithSelectableRatePlan from '../../../../common/components/ProductCardWithSelectableRatePlan';
import K from "../../../../constants";

@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class BuyAdditionalService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: undefined,
    };
  }

  componentDidMount() {
    const { mypageStore } = this.props;
    const { selectedManageableVehicle } = mypageStore || {};

    if (
      selectedManageableVehicle &&
      (selectedManageableVehicle.airclub_general !== undefined ||
        selectedManageableVehicle.air_club_marketing !== undefined ||
        selectedManageableVehicle.air_club_profiling !== undefined ||
        selectedManageableVehicle.air_club_third_parties !== undefined)
    ) {
      this.setState({
        airclub_general: selectedManageableVehicle.airclub_general,
        checkedAgreedRestrictiveClauses: selectedManageableVehicle.airclub_general,
        air_club_profiling: selectedManageableVehicle.air_club_marketing,
        air_club_marketing: selectedManageableVehicle.air_club_profiling,
        air_club_third_parties: selectedManageableVehicle.air_club_third_parties,
      });
    }
  }

  onSubmit = async () => {
    if (this.state.selectedOption === 'none') {
      this.props.history.push('/payments');
      this.props.productsStore.setOptionalServiceProduct(undefined);
      this.props.productsStore.setOptionalService(undefined);
      return;
    }

    const { airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties } = this.state;

    await this.props.mypageStore.updateAirClubMarketingConsent(airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties);

    if (this.props.optional) {
      this.props.productsStore.setOptionalService(this.state.selectedOption);

      this.props.history.push('/review-services');
    } else {
      this.props.productsStore.setSelectedProductRateplanById(this.state.selectedOption);

      this.props.history.push('/payments');
    }
  };

  isSubmitDisabled = () => {
    const { selectedOption } = this.state;

    if (!selectedOption) return true;
    if (selectedOption === 'none') return false;

    return (
      this.state.airclub_general !== true ||
      this.state.checkedAgreedRestrictiveClauses !== true ||
      this.state.air_club_profiling === undefined ||
      this.state.air_club_marketing === undefined ||
      this.state.air_club_third_parties === undefined
    );
  };

  onCheckedChange = (productWithRatePlans, ratePlan) => () => {
    this.setState({ selectedOption: ratePlan.id });
  };

  render() {
    const { productsStore, translationsStore, optional } = this.props;
    const { selectedOption } = this.state;

    if (!productsStore.selectedProduct) {
      return <Redirect to="/" />;
    }

    const productFeatures = productsStore.selectedProduct.features.map((feature) => translationsStore.translate('servicesView.serviceCard.features.' + feature.node.name)).flat();

    const productMainFeature = productsStore.selectedProduct.features && productsStore.selectedProduct.features[0] && productsStore.selectedProduct.features[0].node.name;

    return (
      <MyPageContainer className="BuyAdditionalServiceView" dataCollection={this.props.dataCollection}>
        <VehicleSelector disabled={true} />

        <Section>
          <div>
            <h2
              className={'BuyAdditionalServiceSelectionTitle'}
              dangerouslySetInnerHTML={{ __html: translationsStore.translate('productCardWithSelectableRatePlan.' + productMainFeature + '.enrichedTitle') }}
            />
          </div>
          <div
            className={'BuyAdditionalServiceSubTitleContainer'}
            dangerouslySetInnerHTML={{ __html: translationsStore.translate('productCardWithSelectableRatePlan.' + productMainFeature + '.pageSubTitle') }}
          />

          <div className={'OneColumnComponent'}>
            <ProductCardWithSelectableRatePlan
              features={productFeatures}
              productWithRatePlans={productsStore.selectedProduct}
              onCheckedChange={this.onCheckedChange}
              checkedRatePlanId={this.state.selectedOption}
            />
          </div>

          {selectedOption && selectedOption !== 'none' && (
            <AdditionServicesConditionCheckboxes
              airClubGeneral={this.state.airclub_general}
              setGeneral={(newValue) => this.setState({ airclub_general: newValue })}
              checkedAgreedRestrictiveClauses={this.state.checkedAgreedRestrictiveClauses}
              setCheckedAgreedRestrictiveClauses={(newValue) => this.setState({ checkedAgreedRestrictiveClauses: newValue })}
              airClubProfiling={this.state.air_club_profiling}
              setProfiling={(newValue) => this.setState({ air_club_profiling: newValue })}
              airClubMarketing={this.state.air_club_marketing}
              setMarketing={(newValue) => this.setState({ air_club_marketing: newValue })}
              airClubThirdParties={this.state.air_club_third_parties}
              setThirdParties={(newValue) => this.setState({ air_club_third_parties: newValue })}
            />
          )}

          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) =>
              breakpoints[currentBreakpoint] > breakpoints.extrasmall ? (
                <>
                  <div className={'BuyAdditionalServiceProceedButtonContainer'}>
                    <MyPageButton
                      theme="primary"
                      className={'BuyAdditionalServiceBuyButton'}
                      solid
                      disabled={this.props.mypageStore.loadingUpdatingAirClubMarketing || this.isSubmitDisabled()}
                      title={translationsStore.translate('additionalServicesView.proceedToBuy')}
                      onClick={this.onSubmit}
                    />
                  </div>
                  {optional && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 16 }}>
                      <div style={{ marginRight: 16 }}>Aderisci a Move-In senza Servizi Aggiuntivi</div>
                      <MyPageButton
                        theme="gray"
                        className={'BuyAdditionalServiceDontBuyButton'}
                        solid
                        title={translationsStore.translate('additionalServicesView.proceedWithout')}
                        onClick={() => this.setState({ selectedOption: 'none' }, this.onSubmit)}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={'BuyAdditionalServiceProceedButtonsMobileContainer'}>
                    {optional && (
                      <div>
                        <MyPageButton
                          theme="gray"
                          className={'BuyAdditionalServiceDontBuyMobileButton'}
                          solid
                          title={translationsStore.translate('additionalServicesView.proceedWithoutNotInterested')}
                          onClick={() => this.setState({ selectedOption: 'none' }, this.onSubmit)}
                        />
                      </div>
                    )}
                    <MyPageButton
                      theme="primary"
                      className={'BuyAdditionalServiceBuyButton'}
                      innerStyle={{ maxWidth: 100 }}
                      solid
                      disabled={this.props.mypageStore.loadingUpdatingAirClubMarketing || this.isSubmitDisabled()}
                      title={translationsStore.translate('additionalServicesView.proceedToBuy')}
                      onClick={this.onSubmit}
                    />
                  </div>
                </>
              )
            }
          </BreakpointsMedia>
        </Section>
        <div className="InformativeNotesContract">
          <a href={K.POLICY_BASE_URL.concat('AIR - Nota informativa precontrattuale.pdf')} target="_blank" alt="Nota informativa">
            Nota informativa precontrattuale
          </a>
        </div>
      </MyPageContainer>
    );
  }
}

export default BuyAdditionalService;
