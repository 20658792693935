import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import reactStringReplace from 'react-string-replace';
import VehicleSelector from '../../../../common/components/VehicleSelector';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MyPageRadioGroup from '../../../../common/components/MyPageRadioGroup';
import MyPageRadioButton from '../../../../common/components/MyPageRadioButton';
import { QsHelper } from '../../../../helpers';
import MyPageContainer from '../../../../common/components/MyPageContainer';
import { DoneIcon } from '../../../../common/icons';

import './ThankYouPageView.css';

@withRouter
@inject('translationsStore', 'mypageStore')
@observer
export default class ThankYouPageView extends Component {
  componentDidMount() {
    this.props.mypageStore.updateUserManageableVehicles();
  }

  onViewGroupChange = e => {
    const {
      dataCollection: {
        handlers: { goToKeyRoute }
      }
    } = this.props;
    goToKeyRoute(e.target.value);
  };

  isLegalPerson = () => {
    const { mypageStore } = this.props,
      user = mypageStore.remoteUserData;
    return user && user.person_type === 'LegalPerson';
  };

  render() {
    const { translationsStore, mypageStore, ...props } = this.props;

    const viewGroupItems = [
      { value: 'vehicles', title: translationsStore.translate('groupViews.myair.vehicles') },
      { value: 'documents', title: translationsStore.translate('groupViews.myair.documents') },
      // { value: 'assets', title: translationsStore.translate('groupViews.myair.assets') },
      { value: 'services', title: translationsStore.translate('groupViews.myair.services') }
    ];

    const defaultGroupItem = 'services';

    const movein = new QsHelper(this.props.location.search).get('movein') === 'true';

    const renewal = new QsHelper(this.props.location.search).get('renewal') === 'true';

    return (
      <MyPageContainer className="ThankYouPageView" {...props}>
        <VehicleSelector />
        <BreakpointsMedia>
          {({ breakpoints, currentBreakpoint }) => {
            const inputProps = breakpoints[currentBreakpoint] >= breakpoints.desktop ? { size: 'large' } : {};
            return (
              <MyPageRadioGroup {...inputProps} className="SubPageNavbar" defaultValue={defaultGroupItem} onChange={this.onViewGroupChange} buttonStyle="solid">
                {viewGroupItems.map((item, index) => (
                  <MyPageRadioButton key={index} value={item.value}>
                    {item.title}
                  </MyPageRadioButton>
                ))}
              </MyPageRadioGroup>
            );
          }}
        </BreakpointsMedia>
        {mypageStore.remoteUserData &&
          (() => {
            return (
              <div className="ThankYouPageViewInner">
                <p>
                  {reactStringReplace(translationsStore.translate('paymentsView.labelPaymentSuccessful'), /(__PERSON__|__PAYMENT_RESULT__)/, (match, index) => {
                    switch (match) {
                      case '__PERSON__':
                        return this.isLegalPerson() ? mypageStore.remoteUserData.company_name : `${mypageStore.remoteUserData.first_name} ${mypageStore.remoteUserData.last_name}`;
                      case '__PAYMENT_RESULT__':
                        return (
                          <span key={index} className="textSuccessfulPayment">
                            {translationsStore.translate('paymentsView.paymentResults.successful')} <DoneIcon width={16} height={16} />
                          </span>
                        );
                    }
                  })}
                </p>
                <p style={{ marginTop: 20 }}>
                  {(movein && !renewal)
                    ? <>
                      <div style={{fontWeight: 'bold'}}>{translationsStore.translate('paymentsView.labelPaymentSuccessfulMoveInNextStep2')}</div>
                      {reactStringReplace(translationsStore.translate('paymentsView.labelPaymentSuccessfulInstaller'), '__ACTION_INSTALLER__', (match, index) => (
                        <span key={index} className="textGoToSupport" onClick={() => this.props.history.push(`/installer-information`)}>
                          {translationsStore.translate('paymentsView.paymentNextStepsActions.installer')}
                        </span>
                      ))}
                    </>
                    : reactStringReplace(translationsStore.translate('paymentsView.labelPaymentSuccessfulNextStep2'), '__ACTION_MYPAGE__', (match, index) => (
                        <span key={index} className="textGoToHome" onClick={() => this.props.history.push(`/`)}>
                          {translationsStore.translate('paymentsView.paymentNextStepsActions.mypage')}
                        </span>
                      ))}
                </p>
                {(movein && renewal) && <p style={{marginTop: 20}}>
                  {translationsStore.translate('paymentsView.labelPaymentSuccessfulMoveInRenewalNextStep2_1')}
                </p>}
                <p style={{ marginTop: 20 }}>
                  {movein
                    ? reactStringReplace(translationsStore.translate('paymentsView.labelPaymentSuccessfulMoveInNextStep3'), '__ACTION_SUPPORT__', (match, index) => (
                        <span key={index} className="textGoToSupport" onClick={() => this.props.history.push(`/support/regionelombardia`)}>
                          {translationsStore.translate('paymentsView.paymentNextStepsActions.support')}
                        </span>
                      ))
                    : reactStringReplace(translationsStore.translate('paymentsView.labelPaymentSuccessfulNextStep3'), '__ACTION_SUPPORT__', (match, index) => (
                        <span key={index} className="textGoToSupport" onClick={() => this.props.history.push(`/support/helpdesk`)}>
                          {translationsStore.translate('paymentsView.paymentNextStepsActions.support')}
                        </span>
                      ))}
                </p>
                {movein &&
                  <p style={{ marginTop: 20 }}>{translationsStore.translate('paymentsView.labelPaymentSuccessfulMoveInNextStep2_1')}</p> &&
                  <p style={{ marginTop: 20, fontWeight: 'bold' }}>{translationsStore.translate('paymentsView.labelPaymentSuccessfulMoveInAppInstruction')}</p>
                }
                <p style={{ marginTop: 20, color: 'var(--action-brand-color)' }}>Air Team</p>
              </div>
            );
          })()}
      </MyPageContainer>
    );
  }
}
