import React from 'react';
import ProductCardBase from "./ProductCardBase";

const MoveInCard = ({product, ...otherProps}) => {
  return (
    <ProductCardBase
      logo={(product.logo||'').slice(0, -4)}
      description={product.description}
      product={product}
      showPrice
      {...otherProps}
    />

  )
};

export default MoveInCard;
