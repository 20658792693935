import React, { Component } from 'react';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MyPageSelector from '../MyPageSelector';
import { mypageHelper } from '../../../helpers';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import './VehicleSelector.css';
import PrivacyConsentModal from "../../../components/AppRouter/App/PrivacyConsentModal";

@inject('translationsStore', 'mypageStore')
@observer
class VehicleSelector extends Component {
  onVehicleChange = vehicle => {
    this.props.mypageStore.setSelectedManageableVehicleByUuid(vehicle.key);
  };

  render() {
    const {
      translationsStore,
      mypageStore: { manageableVehicles, selectedManageableVehicle }
    } = this.props;

    const vehicleItems = manageableVehicles.map(vehicle => ({
      key: vehicle.uuid,
      label: mypageHelper.manageableVehicleLabel({ translationsStore, vehicle })
    }));

    const selectedVehicleItem = selectedManageableVehicle
      ? {
          key: selectedManageableVehicle.uuid,
          label: mypageHelper.manageableVehicleLabel({ translationsStore, vehicle: selectedManageableVehicle })
        }
      : undefined;

    return (
      <BreakpointsMedia>
        {({ breakpoints, currentBreakpoint }) => {
          const inputProps = breakpoints[currentBreakpoint] >= breakpoints.desktop ? { size: 'large' } : {};
          const cls = classNames({
            VehicleSelector: true,
            [this.props.className]: !!this.props.className
          });
          return <div>
            <MyPageSelector {...this.props} {...inputProps} className={cls} items={vehicleItems} selectedItem={selectedVehicleItem} placeholder={'dati del veicolo'} onChange={this.onVehicleChange} />
            {
              !this.props.mypageStore.remoteUserIsFromMoVeInProgram && !!this.props.mypageStore.selectedManageableVehicle && !this.props.mypageStore.selectedManageableVehicle.id_adesione &&
              <PrivacyConsentModal
                isDocumentSide={false}
                isVisible={
                  (
                    this.props.mypageStore.selectedManageableVehicle.agreement_profiling === undefined ||
                    this.props.mypageStore.selectedManageableVehicle.agreement_marketing === undefined ||
                    this.props.mypageStore.selectedManageableVehicle.agreement_third_parties === undefined
                  )
                }
                isMandatory={true}
              />
            }
          </div>;
        }}
      </BreakpointsMedia>
    );
  }
}

export default VehicleSelector;
