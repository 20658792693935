import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { api } from '../../../../../services';
import { mypageHelper } from '../../../../../helpers';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import moment from 'moment';
import classNames from 'classnames';
import { default as _isEqual } from 'lodash/isEqual';
import { Table, message, Radio } from 'antd';
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';
import AirModal from '../../../../../common/components/AirModal';
import MyPageButton from '../../../../../common/components/MyPageButton';
import MyPageSelector from '../../../../../common/components/MyPageSelector';
import { NotificationsAlertIcon } from '../../../../../common/icons';

import './ReportNotificationWithActions.css';

@inject('translationsStore', 'authStore', 'mypageStore')
@observer
class ReportNotificationWithActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleSlides: {
        slidePrompt: true,
        slideDismissSupport: false,
        slideSnooze: false,
        slideSupportConfirmed: false,
      },
      loading: false,
      ignoredNotification: undefined,
      archivedNotifications: undefined,
      snoozeRules: {
        time: undefined,
        reason: undefined,
      },
      submittedRequestNotification: undefined
    };
  }

  componentDidMount = () => {
    const { reportNotification } = this.props;
    if (!!reportNotification) {
      this.initSnoozeOptions(reportNotification);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { reportNotification } = this.props;
    if (!!reportNotification && !_isEqual(reportNotification, prevProps.reportNotification)) {
      this.initSnoozeOptions(reportNotification);
    }
  };

  initSnoozeOptions = (reportNotification) => {
    const { translationsStore } = this.props;
    this.snoozeTimeOptions = ['5m', '1h', '3h', '6h', '12h', '1d', '3d'].map((value) => ({
      key: value,
      label: translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.snoozeTimeOptions.${value}`)
    }));
    this.snoozeReasonOptions = ['vehicleInWorkshop', 'holiday', 'other'].map((value) => ({
      key: value,
      label: translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.snoozeReasonOptions.customer.${value}`),
      supportOperationsCenter: translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.snoozeReasonOptions.supportOperationsCenter.${value}`)
    }));
  };

  goToSlideSnooze = async () => {
    const { mypageStore } = this.props;
    const {
      attributes: { 'custom:external_uuid': userUuid },
    } = this.props.authStore.user;
    try {
      this.setState({ loading: true });
      const ignoredNotification = await mypageStore.ignoreNotification(this.props.reportNotification);
      let archivedNotifications = [];
      if (ignoredNotification) {
        archivedNotifications = await api('getArchivedNotifications', userUuid, ignoredNotification.type);
      }
      archivedNotifications = archivedNotifications.sort((a, b) => (a.timestamp > b.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0));
      let { visibleSlides } = this.state;
      Object.keys(visibleSlides).forEach((key) => (visibleSlides[key] = false));
      const state = { loading: false, ignoredNotification, archivedNotifications, visibleSlides: { ...visibleSlides, slideSnooze: true } };
      this.setState(state);
    } catch (error) {
      this.setState({ loading: false });
      // console.log('error', error);
      message.error(this.props.translationsStore.translate('common.genericError'));
    }
  };

  onSnoozeTimeChange = (e) => {
    this.setState({ snoozeRules: { ...this.state.snoozeRules, time: e.key } });
  };

  onSnoozeReasonChange = (e) => {
    this.setState({ snoozeRules: { ...this.state.snoozeRules, reason: e.target.value } });
  };

  snoozeSave = async () => {
    const { mypageStore } = this.props;
    let { ignoredNotification, snoozeRules } = this.state;
    snoozeRules.reason = (this.snoozeReasonOptions.find((option) => option.key === snoozeRules.reason)||{}).supportOperationsCenter;
    try {
      this.setState({ loading: true });
      await mypageStore.snoozeNotification(ignoredNotification, snoozeRules);
      let { visibleSlides } = this.state;
      Object.keys(visibleSlides).forEach((key) => (visibleSlides[key] = false));
      this.setState({ loading: false, visibleSlides }, () => {
        mypageStore.setReportNotification(undefined);
        mypageHelper.handleDeepLink('myair://app.myair.io/');
      });
    } catch (error) {
      this.setState({ loading: false });
      message.error(this.props.translationsStore.translate('common.genericError'));
    }
  };

  snoozeCancel = () => {
    let { visibleSlides } = this.state;
    Object.keys(visibleSlides).forEach((key) => (visibleSlides[key] = false));
    this.setState({ visibleSlides }, () => {
      this.props.mypageStore.setReportNotification(undefined);
      mypageHelper.handleDeepLink('myair://app.myair.io/');
    });
  };

  goToSlideSupportConfirmed = async () => {
    const { mypageStore } = this.props;
    try {
      this.setState({ loading: true });
      const submittedRequestNotification = await mypageStore.submitRequestNotification(this.props.reportNotification);
      let { visibleSlides } = this.state;
      Object.keys(visibleSlides).forEach((key) => (visibleSlides[key] = false));
      const state = { loading: false, submittedRequestNotification, visibleSlides: { ...visibleSlides, slideSupportConfirmed: true } };
      this.setState(state);
    } catch (error) {
      this.setState({ loading: false });
      // console.log('error', error);
      message.error(this.props.translationsStore.translate('common.genericError'));
    }
  };

  proceedClose = () => {
    let { visibleSlides } = this.state;
    Object.keys(visibleSlides).forEach((key) => (visibleSlides[key] = false));
    this.setState({ visibleSlides }, () => {
      this.props.mypageStore.setReportNotification(undefined);
      mypageHelper.handleDeepLink('myair://app.myair.io/');
    });
  };

  render() {
    const { visible, translationsStore, reportNotification } = this.props;
    const { loading, ignoredNotification, archivedNotifications, visibleSlides, submittedRequestNotification } = this.state;
    const { snoozeRules } = this.state;

    const historyColumns = [
      {
        dataIndex: 'date',
        width: 120,
        render: (text, record) => moment(record.timestamp).format(this.props.translationsStore.translate('profileNotifications.__NOTIFICATION_DATE__')),
      },
      {
        dataIndex: 'time',
        render: (text, record) =>
          `${this.props.translationsStore.translate('common.atTime')} ${moment(record.timestamp).format(
            this.props.translationsStore.translate('profileNotifications.__NOTIFICATION_TIME__')
          )}`,
      },
    ];
    const hasHistory = Array.isArray(archivedNotifications) && archivedNotifications.length > 0;

    return (
      <BreakpointsMedia>
        {({ _, currentBreakpoint }) => {
          const cls = classNames({
            ReportNotificationWithActions: true,
            [currentBreakpoint]: !!currentBreakpoint,
          });
          return (
            <AirModal className={cls} visible={visible}>
              {visibleSlides.slidePrompt && (
                <LoadingOverlay
                  active={loading}
                  spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true} />}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: 'var(--grey-light-color-50)',
                    }),
                  }}
                >
                  <div className="SlidePrompt">
                    <div>
                      <NotificationsAlertIcon width={'20px'} height={'20px'} />
                      <div>{translationsStore.translate(`profileNotifications.pending.warning.${reportNotification.type}`)}</div>
                    </div>
                    <div>{translationsStore.translate('profileNotifications.pending.actionIntro')}</div>
                    <div>
                      <MyPageButton
                        theme="primary"
                        solid
                        style={{ height: 60, maxWidth: '100%', whiteSpace: 'normal' }}
                        title={
                          <Fragment>
                            <div className="Action">{translationsStore.translate(`profileNotifications.pending.actionA`)}</div>
                            <div className="ActionSub">{translationsStore.translate(`profileNotifications.pending.actionASub`)}</div>
                          </Fragment>
                        }
                        onClick={this.goToSlideSnooze}
                      />
                      <MyPageButton
                        theme="danger"
                        solid
                        style={{ height: 60, maxWidth: '100%', whiteSpace: 'normal' }}
                        title={
                          <Fragment>
                            <div className="Action">{translationsStore.translate(`profileNotifications.pending.actionB`)}</div>
                            <div className="ActionSub">{translationsStore.translate(`profileNotifications.pending.actionBSub`)}</div>
                          </Fragment>
                        }
                        onClick={this.goToSlideSupportConfirmed}
                      />
                    </div>
                  </div>
                </LoadingOverlay>
              )}
              {visibleSlides.slideDismissSupport && (
                <div className="SlideDismissSupport">
                  <div style={{ marginBottom: 20 }}>
                    {translationsStore
                      .translate(`profileNotifications.pending.dismiss.${reportNotification.type}.feedback`)
                      .replace(/(__NOTIFICATION_DATE__|__NOTIFICATION_TIME__)/g, (match) =>
                        match === '__NOTIFICATION_DATE__'
                          ? moment(ignoredNotification.timestamp).format(translationsStore.translate('profileNotifications.__NOTIFICATION_DATE__'))
                          : moment(ignoredNotification.timestamp).format(translationsStore.translate('profileNotifications.__NOTIFICATION_TIME__'))
                      )}
                  </div>
                  {hasHistory && (
                    <Fragment>
                      <div style={{ marginBottom: 20, fontWeight: 'bold' }}>{translationsStore.translate('profileNotifications.archived.historyTitle')}</div>
                      <Table
                        className="NotificationsHistory"
                        columns={historyColumns}
                        dataSource={archivedNotifications.map((item, index) => ({ ...item, key: index }))}
                        size="small"
                        showHeader={false}
                        pagination={false}
                      />
                    </Fragment>
                  )}
                  <div style={{ marginTop: hasHistory ? 20 : 100, display: 'flex', justifyContent: 'center' }}>
                    <MyPageButton theme="primary" solid title="OK" onClick={this.goToSlideSnooze} />
                  </div>
                </div>
              )}
              {visibleSlides.slideSnooze && (
                <LoadingOverlay
                  active={loading}
                  spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true} />}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: 'var(--grey-light-color-50)',
                    }),
                  }}
                >
                  <div className="SlideSnooze">
                    {reportNotification.type === 'unplugged' && (
                      <Fragment>
                        <div style={{ marginBottom: 20 }}>
                          {translationsStore
                            .translate(`profileNotifications.pending.dismiss.${reportNotification.type}.feedback`)
                            .replace(/(__NOTIFICATION_DATE__|__NOTIFICATION_TIME__)/g, (match) =>
                              match === '__NOTIFICATION_DATE__'
                                ? moment(ignoredNotification.timestamp).format(translationsStore.translate('profileNotifications.__NOTIFICATION_DATE__'))
                                : moment(ignoredNotification.timestamp).format(translationsStore.translate('profileNotifications.__NOTIFICATION_TIME__'))
                            )}
                        </div>
                        {hasHistory && (
                          <Fragment>
                            <div style={{ marginBottom: 20, fontWeight: 'bold' }}>{translationsStore.translate('profileNotifications.archived.historyTitle')}</div>
                            <Table
                              className="NotificationsHistory"
                              columns={historyColumns}
                              dataSource={archivedNotifications.map((item, index) => ({ ...item, key: index }))}
                              size="small"
                              showHeader={false}
                              pagination={false}
                              style={{ marginBottom: 20 }}
                            />
                          </Fragment>
                        )}
                        <div style={{ marginBottom: 20 }}>{translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.text2`)}</div>
                        <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
                          {translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.snoozeTimeLabel`)}
                          <MyPageSelector
                            style={{ flex: '1 0 0', marginLeft: 4 }}
                            items={this.snoozeTimeOptions}
                            selectedItem={snoozeRules.time ? this.snoozeTimeOptions.find((option) => snoozeRules.time === option.key) : undefined}
                            placeholder={translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.snoozeTimePlaceholder`)}
                            onChange={this.onSnoozeTimeChange}
                          />
                        </div>
                        <div style={{ marginBottom: 20 }}>
                          {translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.snoozeReasonLabel`)}
                          <Radio.Group style={{ display: 'block' }} onChange={this.onSnoozeReasonChange} value={snoozeRules.reason}>
                            {this.snoozeReasonOptions.map((item, index) => (
                              <Radio
                                key={index}
                                value={item.key}
                                style={{
                                  display: 'block',
                                  height: '30px',
                                  lineHeight: '30px',
                                }}
                              >
                                {item.label}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </div>
                        <div style={{ marginBottom: 20, display: ' flex' }}>
                          <MyPageButton
                            theme="primary"
                            solid
                            style={{ height: 60, maxWidth: '100%', whiteSpace: 'normal', flex: '1 0 0', marginRight: 20, fontWeight: 'bold' }}
                            title={translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.snoozeSave`)}
                            onClick={this.snoozeSave}
                            disabled={!snoozeRules.time || !snoozeRules.reason}
                          />
                          <MyPageButton
                            theme="primary"
                            solid
                            style={{ height: 60, maxWidth: '100%', whiteSpace: 'normal', flex: '1 0 0', marginLeft: 20, fontWeight: 'bold' }}
                            title={translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.snoozeCancel`)}
                            onClick={this.snoozeCancel}
                          />
                        </div>
                        <div>{translationsStore.translate('profileNotifications.pending.supportInfo')}</div>
                      </Fragment>
                    )}
                    {reportNotification.type === 'blockbeat' && (
                      <Fragment>
                        <div style={{ marginBottom: 20 }}>
                          {translationsStore
                            .translate(`profileNotifications.pending.dismiss.${reportNotification.type}.feedback`)
                            .replace(/(__NOTIFICATION_DATE__|__NOTIFICATION_TIME__)/g, (match) =>
                              match === '__NOTIFICATION_DATE__'
                                ? moment(ignoredNotification.timestamp).format(translationsStore.translate('profileNotifications.__NOTIFICATION_DATE__'))
                                : moment(ignoredNotification.timestamp).format(translationsStore.translate('profileNotifications.__NOTIFICATION_TIME__'))
                            )}
                        </div>
                        {hasHistory && (
                          <Fragment>
                            <div style={{ marginBottom: 20, fontWeight: 'bold' }}>{translationsStore.translate('profileNotifications.archived.historyTitle')}</div>
                            <Table
                              className="NotificationsHistory"
                              columns={historyColumns}
                              dataSource={archivedNotifications.map((item, index) => ({ ...item, key: index }))}
                              size="small"
                              showHeader={false}
                              pagination={false}
                              style={{ marginBottom: 20 }}
                            />
                          </Fragment>
                        )}
                        <div style={{ marginBottom: 20 }}>{translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.text2`)}</div>
                        <div style={{ marginBottom: 20 }}>{translationsStore.translate(`profileNotifications.pending.dismiss.${reportNotification.type}.text3`)}</div>
                        <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
                          <MyPageButton theme="primary" solid title="OK" onClick={this.snoozeCancel} />
                        </div>
                        <div>{translationsStore.translate('profileNotifications.pending.supportInfo')}</div>
                      </Fragment>
                    )}
                  </div>
                </LoadingOverlay>
              )}
              {visibleSlides.slideSupportConfirmed && (
                <div className="SlideSupportConfirmed">
                  <div style={{ marginBottom: 20 }}>
                    {translationsStore
                      .translate(`profileNotifications.pending.proceed.${reportNotification.type}.feedback1`)
                      .replace(/(__NOTIFICATION_DATE__|__NOTIFICATION_TIME__)/g, (match) =>
                        match === '__NOTIFICATION_DATE__'
                          ? moment(submittedRequestNotification.timestamp).format(translationsStore.translate('profileNotifications.__NOTIFICATION_DATE__'))
                          : moment(submittedRequestNotification.timestamp).format(translationsStore.translate('profileNotifications.__NOTIFICATION_TIME__'))
                      )}
                  </div>
                  <div style={{ marginBottom: 20 }}>{translationsStore.translate(`profileNotifications.pending.proceed.${reportNotification.type}.feedback2`)}</div>
                  <div style={{ marginBottom: 20 }}>{translationsStore.translate(`profileNotifications.pending.proceed.${reportNotification.type}.feedback3`)}</div>
                  <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                    <MyPageButton theme="primary" solid title="OK" onClick={this.proceedClose} />
                  </div>
                </div>
              )}
            </AirModal>
          );
        }}
      </BreakpointsMedia>
    );
  }
}

export default ReportNotificationWithActions;
