import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import AuthMobileContainer from '../../../../../common/components/AuthMobileContainer';
import { PulseLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import LabelInput from '../../../../../common/components/LabelInput';
import MyPageCheckbox from '../../../../../common/components/MyPageCheckbox';
import MyPageButton from '../../../../../common/components/MyPageButton';
import MyPageAlert from '../../../../../common/components/MyPageAlert';
import { ErrorIcon } from '../../../../../common/icons';
import MobileMarketplaceLink from '../../../../../common/components/MobileMarketplaceLink';
import { withRouter } from "react-router-dom";
import { mypageHelper, QsHelper } from "../../../../../helpers";

import './MobileLogin.css';

@withRouter
@inject('translationsStore', 'loginStore', 'authStore')
@observer
class MobileLogin extends Component {
  handleEnter = e => {
    const { loginStore } = this.props;
    if (e.key === 'Enter') {
      loginStore.loginSubmit();
    }
  };

  render() {
    const { translationsStore, loginStore, authStore } = this.props;
    const { newPasswordRequired } = authStore;
    const errorUsername = loginStore.fieldTouched('username')
      ? loginStore.fieldError('username')
        ? translationsStore.translate('login.errors.local.missingUsername')
        : undefined
      : undefined;
    const errorPassword = loginStore.fieldTouched('password')
      ? loginStore.fieldError('password')
        ? translationsStore.translate('login.errors.local.missingPassword')
        : undefined
      : undefined;
    const errorNewPassword = loginStore.fieldTouched('newPassword')
      ? loginStore.fieldError('newPassword')
        ? translationsStore.translate('login.errors.local.missingNewPassword')
        : undefined
      : undefined;
    const errorRemote = loginStore.remoteError ? loginStore.remoteError.code : undefined;
    const cls = classNames({
      MobileLogin: true
    });
    const querystringFields = { mobileappdownload: new QsHelper(this.props.location.search).get('mobileappdownload') };
    const visibleMobileMarketplaceLink = querystringFields.mobileappdownload === 'true';
    return (
      <AuthMobileContainer
        className={cls}
        welcomeNode={translationsStore.translate('login.sectionFirst.labelWelcome')}
      >
        <LoadingOverlay
          className="LoginStepFirst"
          active={loginStore.loginLoading}
          spinner={<PulseLoader sizeUnit={'px'} size={20} color={'var(--action-primary-color)'} loading={true} />}
          styles={{
            overlay: base => ({
              ...base,
              background: 'var(--grey-light-color-50)'
            })
          }}
        >
          <LabelInput
            label={translationsStore.translate('login.sectionFirst.labelUsername')}
            value={loginStore.fields.username}
            validateStatus={errorUsername ? 'error' : undefined}
            help={errorUsername}
            onChange={e => {
              loginStore.setUsername(e.target.value);
              loginStore
                .validateLoginFields()
                .then(() => {})
                .catch(() => {});
            }}
            onKeyUp={this.handleEnter}
            style={{ marginBottom: 20 }}
          />
          <LabelInput
            label={translationsStore.translate('login.sectionFirst.labelPassword')}
            value={loginStore.fields.password}
            validateStatus={errorPassword ? 'error' : undefined}
            help={errorPassword}
            onChange={e => {
              loginStore.setPassword(e.target.value);
              loginStore
                .validateLoginFields()
                .then(() => {})
                .catch(() => {});
            }}
            onKeyUp={this.handleEnter}
            type="password"
          />
          {newPasswordRequired && (
            <LabelInput
              label={translationsStore.translate('login.sectionFirst.labelNewPassword')}
              value={loginStore.fields.newPassword}
              validateStatus={errorNewPassword ? 'error' : undefined}
              help={errorNewPassword}
              onChange={e => {
                loginStore.setNewPassword(e.target.value);
                loginStore
                  .validateLoginFields()
                  .then(() => {})
                  .catch(() => {});
              }}
              onKeyUp={this.handleEnter}
              type="password"
              style={{ marginTop: 20 }}
            />
          )}
          <div className="TwoColumns" style={{ marginTop: 30 }}>
            <div>
              <MyPageCheckbox
                checked={loginStore.loginKeepLogged}
                onChange={checked => {
                  loginStore.setKeepLogged(checked);
                }}
                label={translationsStore.translate('login.sectionFirst.labelKeepLogged')}
              />
            </div>
            <div
              onClick={this.props.dataCollection.onForgottenPasswordClick}
              style={{ cursor: 'pointer', opacity: 0.4, color: 'var(--black-color)', fontSize: 14, letterSpacing: -0.2, lineHeight: '20px' }}
            >
              {translationsStore.translate('login.sectionFirst.labelForgottenPassword')}
            </div>
          </div>
          {errorRemote !== undefined && (
            <MyPageAlert
              style={{ marginTop: 16 }}
              message={translationsStore.translate(`login.errors.remoteTitle`)}
              description={translationsStore.translate(`login.errors.remote.${errorRemote}`)}
              type="error"
              closable
              showIcon
              icon={<ErrorIcon width={20} height={20} />}
            />
          )}
          <MyPageButton
            className=""
            theme="primary"
            style={{ marginTop: 54, height: 40, fontSize: 16 }}
            solid
            title={newPasswordRequired ? translationsStore.translate('login.labelSubmitNewPassword') : translationsStore.translate('login.labelSubmitLogin')}
            disabled={loginStore.fields.username === '' || loginStore.fields.password === '' || (newPasswordRequired && loginStore.fields.newPassword === '')}
            onClick={loginStore.loginSubmit}
          />
          <MobileMarketplaceLink visible={visibleMobileMarketplaceLink} />
        </LoadingOverlay>
      </AuthMobileContainer>
    );
  }
}

export default MobileLogin;
