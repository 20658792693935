import React, { Component } from 'react';
import { isEqual, omit } from 'lodash';

class FileBlobPreview extends Component {
  shouldComponentUpdate = nextProps => {
    const fileIsEqual = isEqual(this.props.fileBlob, nextProps.fileBlob);
    return !fileIsEqual;
  };

  render = () => {
    let { props } = this;
    props = omit(props, ['fileBlob']);
    return <iframe src={window.URL.createObjectURL(this.props.fileBlob)} {...props} />;
  };
}

export default FileBlobPreview;
