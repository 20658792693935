export default class QsHelper {
  constructor(queryString) {
    this.queryString = queryString;
    this.queryStringAsMap = {};
    let qs = this.queryString;
    this.prefix = '';
    if (qs.indexOf('?') === 0) {
      this.prefix = '?';
      qs = qs.substring(1);
    }
    const kvqs = qs.split('&');
    kvqs.forEach(kv => {
      if (!!kv.split('=') && !!kv.split('=')[0]) {
        this.queryStringAsMap[kv.split('=')[0]] = kv.split('=')[1];
      }
    });
  }

  get(key) {
    return this.queryStringAsMap[key];
  }

  hasParam(key) {
    return this.queryStringAsMap.hasOwnProperty(key);
  }

  hasNotNull(key) {
    return this.hasParam(key) && this.get(key) !== undefined && this.get(key) !== null;
  }

  keyHasValue(key, value) {
    return this.hasParam(key) && this.get(key) === value;
  }

  set(key, value, prefix) {
    if (value) {
      this.queryStringAsMap[key] = value;
    } else {
      delete this.queryStringAsMap[key];
    }
    let newqs = '';
    Object.keys(this.queryStringAsMap).forEach((key, index) => {
      let _prefix = '&';
      if (index === 0) {
        _prefix = '';
      }
      newqs = `${newqs}${_prefix}${key}=${this.queryStringAsMap[key]}`;
    });
    if (newqs.trim() === '') {
      this.queryString = '';
    } else {
      this.queryString = (prefix || this.prefix) + newqs;
    }
    return this;
  }

  getQueryString() {
    return this.queryString;
  }
}
