import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import MyPageMobileContainer from '../../../../../common/components/MyPageMobileContainer';
import MyPageSelector from '../../../../../common/components/MyPageSelector';
import MyPageRadioGroup from '../../../../../common/components/MyPageRadioGroup';
import MyPageRadioButton from '../../../../../common/components/MyPageRadioButton';
import { CarIcon, AccountSquareIcon } from '../../../../../common/icons';
import LabelInput from '../../../../../common/components/LabelInput';
import SmallDivision from '../../../../../common/components/SmallDivision';

import './MobileVehicleView.css';
import VehicleSelector from "../../../../../common/components/VehicleSelector";
import ContactDataForm from "../ContactDataForm/ContactDataForm";
import MyPageDesktopContainer from "../../../../../common/components/MyPageDesktopContainer";

@inject('translationsStore', 'mypageStore')
@observer
export default class MobileVehicleView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onViewGroupChange = e => {
    const {
      dataCollection: { onViewGroupChange }
    } = this.props;
    onViewGroupChange(e.target.value);
  };

  render() {
    const { translationsStore, mypageStore, dataCollection } = this.props;
    const cls = classNames({
      [this.props.className]: !!this.props.className
    });

    const { selectedVehicleData, viewGroupItems, defaultGroupItem } = dataCollection;

    return (
      <MyPageMobileContainer className={cls} dataCollection={dataCollection}>
        <VehicleSelector />
        <MyPageRadioGroup defaultValue={defaultGroupItem} onChange={this.onViewGroupChange} buttonStyle="solid" style={{ marginBottom: 56 }}>
          {viewGroupItems.map((item, index) => (
            <MyPageRadioButton key={index} value={item.value}>{item.title}</MyPageRadioButton>
          ))}
        </MyPageRadioGroup>
        <SmallDivision title={translationsStore.translate('vehiclesView.vehicleData.titleVehicleData')} icon={<CarIcon />} style={{ marginBottom: 20 }} />
        <LabelInput label={translationsStore.translate('vehiclesView.vehicleData.labelBrand')} value={selectedVehicleData.brand} disabled={true} style={{ marginBottom: 20 }} />
        <LabelInput label={translationsStore.translate('vehiclesView.vehicleData.labelModel')} value={selectedVehicleData.model} disabled={true} style={{ marginBottom: 20 }} />
        <LabelInput
          label={translationsStore.translate('vehiclesView.vehicleData.labelVariant')}
          value={selectedVehicleData.variant}
          disabled={true}
          style={{ marginBottom: 20 }}
        />
        <LabelInput
          label={translationsStore.translate('vehiclesView.vehicleData.labelLicensePlate')}
          value={selectedVehicleData.license_plate}
          disabled={true}
          style={{ marginBottom: 20 }}
        />
        <LabelInput label={translationsStore.translate('vehiclesView.vehicleData.labelVin')} value={selectedVehicleData.vin} disabled={true} style={{ marginBottom: 20 }} />
        <LabelInput
          label={translationsStore.translate('vehiclesView.vehicleData.labelRegistrationDate')}
          value={selectedVehicleData.registration_date}
          disabled={true}
          style={{ marginBottom: 20 }}
        />
        <LabelInput
          label={translationsStore.translate('vehiclesView.vehicleData.labelFuelType')}
          value={selectedVehicleData.fuel_type}
          disabled={true}
          style={{ marginBottom: 20 }}
        />
        <LabelInput
          label={translationsStore.translate('vehiclesView.vehicleData.labelDisplacement')}
          value={selectedVehicleData.displacement}
          disabled={true}
          style={{ marginBottom: 20 }}
        />
        <SmallDivision
          title={translationsStore.translate('vehiclesView.contactData.titleContactData')}
          icon={<AccountSquareIcon />}
          style={{ marginTop: 40, marginBottom: 20 }}
        />
        {!!mypageStore.remoteUserData && !!mypageStore.selectedManageableVehicle && !mypageStore.remoteUserIsFromMoVeInProgram ? (
          <ContactDataForm/>
        ) : (
          <div>
            <LabelInput
              label={translationsStore.translate('vehiclesView.contactData.labelContactEmail')}
              value={selectedVehicleData.contactEmail}
              disabled={true}
              style={{ marginBottom: 20 }}
              dataTestId={'contactEmailTestId'}
            />
            <LabelInput
              label={translationsStore.translate('vehiclesView.contactData.labelContactPhone')}
              value={selectedVehicleData.contactPhone}
              disabled={true}
              style={{ marginBottom: 20 }}
              dataTestId={'contactPhoneTestId'}
            />
          </div>
        )}
      </MyPageMobileContainer>
    );
  }
}
