import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import ReactBreakpoints from 'react-breakpoints';
import AppRouter from './components/AppRouter';
import * as stores from './stores';
import translationsStore from 'air-translations-store';

import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';

import it_IT from 'antd/lib/locale-provider/it_IT';
import en_US from 'antd/lib/locale-provider/en_US';

import it from './translations/it';
import en from './translations/en';

import 'moment/locale/it';
import moment from 'moment';

translationsStore.addTranslation('it', it);
translationsStore.addTranslation('en', en);

const [language] = (navigator.language || navigator.userLanguage || 'en').split('-');

let locale = en_US;
moment.locale('en');
translationsStore.setLocale('en');

if (language === 'it') {
  locale = it_IT;
  moment.locale('it');
  translationsStore.setLocale('it');
}

const breakpoints = {
  extrasmall: 320,
  small: 600,
  desktop: 840
};

stores.authStore
  .checkSession()
  .then(() => {
    // check local storage preferences
    const storageValue = stores.commonStore.getStoragePreferencesKey('keepLogged');
    const keepLogged = storageValue !== undefined ? storageValue : true;
    if (!keepLogged) {
      stores.authStore.logout();
    }
    //
  })
  .then(() => {
    // let's do http to https redirect check according to environment
    if (process.env.REACT_APP_ENV === 'production' && window.location.protocol !== 'https:') {
      window.location = 'https:' + window.location.href.substring(window.location.protocol.length);
      return;
    }
    ReactDOM.render(
      <ReactBreakpoints breakpoints={breakpoints}>
        <ConfigProvider locale={locale}>
          <Provider {...stores} translationsStore={translationsStore}>
            <AppRouter />
          </Provider>
        </ConfigProvider>
      </ReactBreakpoints>,
      document.getElementById('root')
    );
  });

serviceWorker.register();
