import React, { Component } from 'react';
import { Radio } from 'antd';
import classNames from 'classnames';

import './MyPageRadioButton.css';

export default class MyPageRadioButton extends Component {
  render() {
    const cls = classNames({
      MyPageRadioButton: true,
      [this.props.className]: !!this.props.className
    });
    return (
      <Radio.Button {...this.props} className={cls}>
        {this.props.children}
      </Radio.Button>
    );
  }
}
