import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MobileForgottenPassword from './MobileForgottenPassword';
import DesktopForgottenPassword from './DesktopForgottenPassword';

@withRouter
@inject('authStore', 'loginStore')
@observer
class ForgottenPassword extends Component {
  handleGotoLogin = () => {
    const { loginStore } = this.props;
    loginStore.resetForgottenData();
    this.props.history.push('/login');
  };

  handleGotoStepOne = () => {
    const { loginStore } = this.props;
    loginStore.setForgottenFields({ verificationCode: '', newPassword: '' });
    loginStore.setForgottenData({ stepOneCleared: false, stepTwoCleared: false });
  };

  handleDone = () => {
    const { loginStore } = this.props;
    loginStore.resetForgottenFields();
    loginStore.resetForgottenTouched();
    loginStore.resetForgottenData();
    this.props.history.push('/login');
  };

  render() {
    const { authStore } = this.props;
    if (!authStore.isLoggedIn) {
      const handlers = {
        handleGotoLogin:this.handleGotoLogin,
        handleGotoStepOne:this.handleGotoStepOne,
        handleDone:this.handleDone
      };
      return (
        <Fragment>
          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {
              return currentBreakpoint === 'extrasmall' || currentBreakpoint === 'small' ? (
                <MobileForgottenPassword handlers={handlers} />
              ) : (
                <DesktopForgottenPassword handlers={handlers} />
              );
            }}
          </BreakpointsMedia>
        </Fragment>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default ForgottenPassword;
