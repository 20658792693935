import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import {  inject, observer } from 'mobx-react';

import VehicleSelector from "../../../../common/components/VehicleSelector";

import MyPageContainer from "../../../../common/components/MyPageContainer";
import Section from "../../../../common/components/Section";
import {Checkbox} from "antd";
import { TowingTruck} from "../../../../common/icons";
import MyPageButton from "../../../../common/components/MyPageButton";
import AirModal from "../../../../common/components/AirModal";
import BaseAssistanceImage from "../../../../common/components/BaseAssistanceImage/BaseAssistanceImage";
import BaseAssistanceFullImage from "../../../../common/components/BaseAssistanceFullImage";
import {Media as BreakpointsMedia} from "react-breakpoints";
import K from "../../../../constants";
@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class MyAppAirAddonServicesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generalConditionsVisible: false,
            privacyVisible: false,
            myappAddonProduct: [],
        };
    }

    componentDidMount = async () => {
        const addOnProduct = await this.getProducts();
        this.props.productsStore.setSelectedAddOnProduct(addOnProduct);
        this.setState({selectedAddOnProduct: addOnProduct})
        const addOnProducts = addOnProduct[0];
        if (addOnProducts?.productRatePlans.length > 0) {
            this.setState({
                myappAddonProduct: addOnProducts?.productRatePlans,
            });
        }
        const { mypageStore } = this.props;
        const { selectedManageableVehicle } = mypageStore || {};
        if (
            selectedManageableVehicle && (
                selectedManageableVehicle.airclub_general !== undefined
                || selectedManageableVehicle.air_club_marketing !== undefined
                || selectedManageableVehicle.air_club_profiling !== undefined
                || selectedManageableVehicle.air_club_third_parties !== undefined
            )
        ) {
            this.setState({
                airclub_general: selectedManageableVehicle.airclub_general,
                checkedAgreedRestrictiveClauses: selectedManageableVehicle.airclub_general,
                air_club_profiling: selectedManageableVehicle.air_club_marketing,
                air_club_marketing: selectedManageableVehicle.air_club_profiling,
                air_club_third_parties: selectedManageableVehicle.air_club_third_parties
            });
        }
    }

    getProducts =async () => {
        const { mypageStore, productsStore } = this.props;

        if (mypageStore.remoteUserIsFromMoVeInProgram) {
            const filteredProducts = productsStore.availableProducts?.filter((pro) =>
                pro?.features?.some((fea) => fea?.node?.name === 'myapp')
            );
            if (filteredProducts.length > 0) {
                return  filteredProducts
            } else {
                return { message: 'No products match the condition' };
            }
        } else {
            return { message: 'no product' };
        }
    };
    getMyAppRecurring = (product) => {
        return  product
    };
    getMyAppOneTime = (product) => {
        return product
    };
    onSubmit = async () => {
            if (this.state.selectedAddOn) {
                this.props.productsStore.setSelectedAddOn(this.state.selectedAddOn);
                if(this.props.productsStore.comeFromService)
                {
                    this.props.history.push("/payments");
                }
                else {
                    this.props.history.push("/review-services");
                }
                return;
            }
        const { productsStore } = this?.props;
        const mainProduct = this?.props.productsStore?.selectedProduct;
        const optionalProductRatePlan = this?.props.productsStore?.optionalService;
        const addonProductRatePlan = this?.props.productsStore?.selectedAddOn;

        if(!mainProduct || (!optionalProductRatePlan && !productsStore?.isAtLeastAnAdditionalProductRatePlanSelected) && !addonProductRatePlan) {
            this.props.history.push("/payments");
        }
        else {
            this.props.history.push("/review-services");
        }
        this.props.productsStore.setSelectedAddOn(undefined);
        const {
            airclub_general,
            air_club_profiling,
            air_club_marketing,
            air_club_third_parties,
        } = this.state;

        await this.props.mypageStore.updateAirClubMarketingConsent(
            airclub_general,
            air_club_profiling,
            air_club_marketing,
            air_club_third_parties
        );

    };

    isSubmitDisabled = () => {
        if(!this.state.selectedAddOn){
                return true
            }
        return this.state.airclub_general !== true ||
            this.state.checkedAgreedRestrictiveClauses !== true ||
            this.state.air_club_profiling === undefined ||
            this.state.air_club_marketing === undefined ||
            this.state.air_club_third_parties === undefined
    };
    render() {
        const { productsStore, translationsStore, optional } = this.props;

        if(!productsStore.selectedProduct) {
            return <Redirect to="/" />;
        }
        return (
            <MyPageContainer className='OptionalServicesView' dataCollection={this.props.dataCollection}>

                <VehicleSelector disabled={true} />

                <Section>
                        <>
                             <div className="BannerMoVeInRenewal" dangerouslySetInnerHTML={{ __html: translationsStore.translate('servicesView.notices.data') }} />
                            <div>
                                <h2 className={'AssistanceTitle'}>{translationsStore.translate('myAppAirAddonService.titleFirstPart')} <span className={`ColoredPriceBaseAssistance`}>{translationsStore.translate('myAppAirAddonService.titleSecondPart')}</span>{translationsStore.translate('myAppAirAddonService.titleThirdPart')}</h2>
                            </div>
                            <div className={'BaseAssistanceSubTitleContainer'}>
                                <h3>{translationsStore.translate('myAppAirAddonService.subtitle')}</h3>
                            </div>
                            <div className={'SvgImageContainer'}>
                                <BreakpointsMedia>
                                    {({ breakpoints, currentBreakpoint }) =>
                                        breakpoints[currentBreakpoint] > breakpoints.extrasmall ? (
                                            <BaseAssistanceFullImage width={441} height={155}/>
                                        ) : (
                                            <BaseAssistanceImage width={441} height={155}/>
                                        )
                                    }
                                </BreakpointsMedia>
                            </div>
                            <div className={'ThreeColumnView'}>
                                <div className={'OneColumnComponent'}>
                                    <div style={{ width: 250 }} className={'AssistanceFeatureCardContainer'}>
                                        <div className={'AssistanceFeatureOneColumnComponent'}>
                                            <div className={'AxaIconContainer'}>
                                            </div>
                                        </div>
                                            <div className={'AssistanceFeatureOneColumnComponent'}>
                                                <div className={'AssistanceColumnTitle'}>{translationsStore.translate('myAppAirAddonService.boxTitle')}</div>
                                                <div className={'AssistanceColumnFeature'}>{translationsStore.translate('myAppAirAddonService.GPSLocalization')}</div>
                                                <div className={'AssistanceColumnFeature'}>{translationsStore.translate('myAppAirAddonService.theftProtection')}</div>
                                                <div className={'AssistanceColumnFeature'}>{translationsStore.translate('myAppAirAddonService.tripReport')}</div>
                                                <div className={'AssistanceColumnFeature'}>{translationsStore.translate('myAppAirAddonService.vehicleDashboard')}</div>
                                                <div className={'AssistanceColumnFeature'}>{translationsStore.translate('myAppAirAddonService.muchMore')}</div>
                                            </div>
                                        <div className={'AssistanceFeatureOneColumnComponent'}>
                                            <div className={'AssistanceIconContainer'}> <TowingTruck className={'AssistanceIconColor'}/>  </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="BaseAssistanceProductPlanSelection">
                                <h3>{translationsStore.translate('purchaseAssistanceView.chooseTheBestPlanForYou')}</h3>
                                    <div className={'BaseAssistanceCheckboxContainer'}>
                                            <div>
                                                <div  className="BaseAssistanceProductPlan">
                                                    <Checkbox
                                                        onChange={() => this.setState({selectedAddOn: this.getMyAppRecurring(this.state.myappAddonProduct[1])})}
                                                        checked={this.state.selectedAddOn === this.getMyAppRecurring(this.state.myappAddonProduct[1])}
                                                    />
                                                    <div>&nbsp;</div>
                                                    <div className="BaseAssistancePlanPrice">
                                                        <span className={`ColoredPriceBaseAssistance`}>  {this.state.myappAddonProduct[1]?.productRatePlanCharges[0].pricing[0].price}€</span>{' '}per un anno
                                                    </div>
                                                </div>
                                                <div className="BaseAssistanceProductPlan">
                                                    <Checkbox
                                                        onChange={() => this.setState({selectedAddOn: this.getMyAppOneTime(this.state.myappAddonProduct[0])})}
                                                        checked={this.state.selectedAddOn === this.getMyAppOneTime(this.state.myappAddonProduct[0])}
                                                    />
                                                    <div>&nbsp;</div>
                                                    <div className="BaseAssistancePlanPrice">
                                                        <span className={`ColoredPriceBaseAssistance`}>  {this.state.myappAddonProduct[0]?.productRatePlanCharges[0].pricing[0].price}€/mese</span>{' '}per un anno
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                            </div>
                        </>
                    {this.state.selectedAddOn   && <div>
                        <div className="AirclubPrivacyForm" style={{display: 'flex'}}>
                            <div className="OptionalServicePrivacy">
                                <div className={'generalConditionsCheckboxContainer'}>
                                    <Checkbox className="OptionalServicePrivacyCheck" onChange={() => this.setState(prevState => ({airclub_general: !prevState.airclub_general}))}
                                              checked={this.state.airclub_general === true}/>
                                    <h4 className="PrivacyHeadTitle">Confermo di aver preso visione e di accettare integralmente le <span
                                        onClick={() => this.setState({generalConditionsVisible: true})}
                                        style={{fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer'}}>Condizioni Generali per l'Adesione ai Servizi Aggiuntivi AIR</span>.
                                    </h4>
                                </div>
                                <div  className={'generalConditionsCheckboxContainer'}>
                                    <Checkbox className="OptionalServicePrivacyCheck" onChange={() => this.setState(prevState => ({checkedAgreedRestrictiveClauses: !prevState.checkedAgreedRestrictiveClauses}))}
                                              checked={this.state.checkedAgreedRestrictiveClauses === true}/>
                                    <p>Ai sensi e per gli effetti di cui agli articoli 1341 e 1342 Codice Civile, dichiaro di aver letto attentamente e di approvare specificatamente le seguenti clausole di cui alle Condizioni Generali: 1.4; 1.5; 2.6; 2.11; 2.12; 3.2, 3.3; 5.3, 5.4; 6.2; 8; 10; 15.2.</p>
                                </div>
                                <h4 style={{marginTop: 16}}>Dichiaro di aver preso visione e di aver letto attentamente l'<span
                                    onClick={() => this.setState({privacyVisible: true})}
                                    style={{fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer'}}>informativa privacy di AIR</span>
                                </h4>
                                <p><b>Consenso al trattamento dei dati personali</b></p>
                                <p>Il sottoscritto, informato in merito al trattamento dei propri dati personali da parte di AIR S.r.l. (la “Società”), come da informativa da quest’ultima predisposta ai sensi dell’art. 13 del Regolamento UE 2016/679 che conferma di aver letto e ben compreso, conferma di aver preso atto delle conseguenze del rifiuto a conferire i Dati Personali per determinati trattamenti e di essere consapevole che il consenso è revocabile in qualsiasi momento. Inoltre il sottoscritto dichiara quanto segue:</p>
                                <p>Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per l’individuazione e lo
                                    studio dei dati sulle concrete modalità di utilizzo del veicolo, quali anche acquisiti tramite
                                    Dispositivo di cui all’articolo 2 lett. e) dell’informativa (profilazione):</p>
                                <div style={{display: 'flex', fontWeight: 'bold'}}>
                                    <div style={{marginRight: 40}}><Checkbox onChange={() => this.setState({air_club_profiling: true})}
                                                                             checked={this.state.air_club_profiling === true}/> Acconsento
                                    </div>
                                    <div><Checkbox onChange={() => this.setState({air_club_profiling: false})}
                                                   checked={this.state.air_club_profiling === false}/> Non acconsento
                                    </div>
                                </div>

                                <p>Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per attività promozionali e di
                                    vendita di cui all’articolo 2 lett. f) dell’informativa (marketing): comunicazione ed iniziative
                                    promozionali e di vendita di prodotti e/o servizi della società o di soggetti terzi appartenenti alle
                                    categorie sopra menzionate via email, SMS o altri sistemi automatizzati e per posta, nonché per ricerche
                                    di mercato e rilevazioni statistiche. Il consenso per marketing deve intendersi esteso anche alle
                                    modalità tradizionali di contatto:</p>
                                <div style={{display: 'flex', fontWeight: 'bold'}}>
                                    <div style={{marginRight: 40}}><Checkbox onChange={() => this.setState({air_club_marketing: true})}
                                                                             checked={this.state.air_club_marketing === true}/> Acconsento
                                    </div>
                                    <div><Checkbox onChange={() => this.setState({air_club_marketing: false})}
                                                   checked={this.state.air_club_marketing === false}/> Non acconsento
                                    </div>
                                </div>

                                <p>Con riferimento al trasferimento dei Dati Personali, compresi quelli raccolti tramite Dispositivo, a
                                    soggetti terzi (imprese di assicurazione, broker, agenti assicurativi, banche e intermediari finanziari,
                                    operatori del settore automobilistico e della componentistica, carrozzerie e autofficine, fornitori di
                                    prodotti e servizi relativi al settore automotive, compresi fornitori di servizi di navigazione e
                                    viabilità), per lo svolgimento da parte di questi ultimi di finalità loro proprie, ove del caso anche di
                                    marketing, come indicato all’articolo 2 lett. g) dell’informativa:</p>
                                <div style={{display: 'flex', fontWeight: 'bold'}}>
                                    <div style={{marginRight: 40}}><Checkbox onChange={() => this.setState({air_club_third_parties: true})}
                                                                             checked={this.state.air_club_third_parties === true}/> Acconsento
                                    </div>
                                    <div><Checkbox onChange={() => this.setState({air_club_third_parties: false})}
                                                   checked={this.state.air_club_third_parties === false}/> Non acconsento
                                    </div>
                                </div>
                            </div>
                            <AirModal
                                className="EmbeddedDocumentModal"
                                width="90vw"
                                visible={this.state.generalConditionsVisible}
                                onCancel={() => {
                                    this.setState({generalConditionsVisible: false});
                                }}
                            >
                                <iframe src={K.POLICY_BASE_URL.concat('condizioni-generali-servizi-aggiuntivi-2022-09-23.pdf')} width="100%" height="540"/>
                            </AirModal>
                            <AirModal
                                className="EmbeddedDocumentModal"
                                width="90vw"
                                visible={this.state.privacyVisible}
                                onCancel={() => {
                                    this.setState({privacyVisible: false});
                                }}
                            >
                                <iframe src={K.POLICY_BASE_URL.concat('privacy-policy-2022-09-23.pdf')} width="100%" height="540"/>
                            </AirModal>
                        </div>
                    </div>}

                    {
                            (
                        <BreakpointsMedia>
                            {() =>
                                    <>
                                        <div className={'BaseAssistanceProceedButtonContainer'}>
                                            <MyPageButton
                                                theme="primary"
                                                className={'BaseAssistanceBuyButton'}
                                                solid
                                                disabled={this.props.mypageStore.loadingUpdatingAirClubMarketing || this.isSubmitDisabled()}
                                                title={translationsStore.translate('purchaseAssistanceView.baseAssistance.proceedToBuy')}
                                                onClick={this.onSubmit}
                                            />
                                        </div>
                                        {optional && <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 16}}>
                                            <div style={{marginRight: 16}}>{
                                                !productsStore.comeFromService &&  ( "Aderisci a Move-in senza MyAir App")
                                            }</div>
                                            {!productsStore.comeFromService &&
                                                (<MyPageButton
                                                    theme="gray"
                                                    className={'BaseAssistanceDontBuyButton'}
                                                    solid
                                                    title={translationsStore.translate('purchaseAssistanceView.baseAssistance.proceedWithout')}
                                                    onClick={() => this.setState({selectedOption: this.state.selectedOption}, this.onSubmit)}
                                                />)
                                            }
                                        </div>
                                        }
                                    </>
                            }
                        </BreakpointsMedia>
                    )}
                </Section>
                    <div className="AirClubInfoContract"><a href={K.POLICY_BASE_URL.concat('AIR - Nota informativa precontrattuale.pdf')} target="_blank" alt="Nota informativa">Nota informativa precontrattuale</a></div>
            </MyPageContainer>
        );
    }
}

export default MyAppAirAddonServicesView;
