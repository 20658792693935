import React, {Component, Fragment} from "react";
import AirModal from "../../../../common/components/AirModal";
import MyPageButton from "../../../../common/components/MyPageButton";
import {Input} from "antd";
import {inject, observer} from "mobx-react";

@inject('translationsStore', 'mypageStore')
@observer
class ManutenzioniRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manutenzioniModalVisible: false,
      manutenzioneKm: undefined
    }
  }

  onSubmit = async () => {
    this.setState({manutenzioniModalVisible: false});
    await this.props.mypageStore.createAgendaMaintenance(this.props.vehicle, this.state.manutenzioneKm);
  };

  render() {
    return (
      <Fragment>
        <MyPageButton
          theme="custom"
          className="ScadenzaButton"
          solid
          title="Aggiungi"
          onClick={() => this.setState({manutenzioniModalVisible: true})}
        />
        <AirModal
          className={"AgendaModal"}
          width="90vw"
          visible={this.state.manutenzioniModalVisible}
          onCancel={() => {
            this.setState({ manutenzioniModalVisible: false });
          }}
        >
          <h2>Indicaci a quanti Km farai la prossima manutenzione!</h2>
          <div className="Content">
            <Input addonBefore="Km" value={this.state.manutenzioneKm} onChange={(e) => this.setState({manutenzioneKm: e.target.value})} />
            <MyPageButton
              theme="primary"
              solid
              title="Salva"
              onClick={this.onSubmit}
            />
          </div>
        </AirModal>
      </Fragment>
    )
  }
}

export default ManutenzioniRow
