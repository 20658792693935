import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import moment from 'moment';
import MobileVehicleView from './MobileVehicleView';
import DesktopVehicleView from './DesktopVehicleView';
import { mypageHelper } from '../../../../helpers';

import './VehicleView.css';

@withRouter
@inject('translationsStore', 'mypageStore')
@observer
class VehicleView extends Component {
  onViewGroupChange = group => {
    const {
      dataCollection: {
        handlers: { goToKeyRoute }
      }
    } = this.props;
    goToKeyRoute(group);
  };

  render() {
    const { translationsStore } = this.props;
    const {
      mypageStore: { selectedManageableVehicle }
    } = this.props;


    let selectedVehicleData = {
      vin: selectedManageableVehicle && selectedManageableVehicle.hasOwnProperty('vin') ? selectedManageableVehicle.vin : '-',
      license_plate: selectedManageableVehicle && selectedManageableVehicle.hasOwnProperty('license_plate') ? selectedManageableVehicle.license_plate : '-',
      brand:
        selectedManageableVehicle && selectedManageableVehicle.hasOwnProperty('brand') && selectedManageableVehicle.brand.hasOwnProperty('name')
          ? selectedManageableVehicle.brand.name
          : '-',
      model:
        selectedManageableVehicle && selectedManageableVehicle.hasOwnProperty('model') && selectedManageableVehicle.model.hasOwnProperty('name')
          ? selectedManageableVehicle.model.name
          : '-',
      variant:
        selectedManageableVehicle && selectedManageableVehicle.hasOwnProperty('variant') && selectedManageableVehicle.variant.hasOwnProperty('name')
          ? selectedManageableVehicle.variant.name
          : '-',
      registration_date:
        selectedManageableVehicle && selectedManageableVehicle.hasOwnProperty('registration_date')
          ? moment(selectedManageableVehicle.registration_date).format(translationsStore.translate('common.format.YYYYMMDD'))
          : '-',
      fuel_type: selectedManageableVehicle && selectedManageableVehicle.hasOwnProperty('fuel_type') ? selectedManageableVehicle.fuel_type : '-',
      displacement: selectedManageableVehicle && selectedManageableVehicle.hasOwnProperty('displacement') ? selectedManageableVehicle.displacement : '-',
      contactEmail: '-',
      contactPhone: '-'
    };
    const viewGroupItems = [
      { value: 'vehicles', title: translationsStore.translate('groupViews.myair.vehicles') },
      { value: 'documents', title: translationsStore.translate('groupViews.myair.documents') },
      // { value: 'assets', title: translationsStore.translate('groupViews.myair.assets') },
      { value: 'services', title: translationsStore.translate('groupViews.myair.services') }
    ];
    const defaultGroupItem = 'vehicles';

    let { dataCollection } = this.props;
    dataCollection = {
      ...dataCollection,
      onVehicleChange: this.onVehicleChange,
      selectedVehicleData,
      viewGroupItems,
      defaultGroupItem,
      onViewGroupChange: this.onViewGroupChange
    };

    return (
      <BreakpointsMedia>
        {({ breakpoints, currentBreakpoint }) => {
          const cls = classNames({
            VehicleView: true,
            [currentBreakpoint]: !!currentBreakpoint
          });
          return (
            <Fragment>
              {currentBreakpoint === 'extrasmall' || currentBreakpoint === 'small' ? (
                <MobileVehicleView className={cls} dataCollection={dataCollection} />
              ) : (
                <DesktopVehicleView className={cls} dataCollection={dataCollection} />
              )}
            </Fragment>
          );
        }}
      </BreakpointsMedia>
    );
  }
}

export default VehicleView;
