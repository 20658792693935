import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {Radio, Button, message} from "antd";
import {CreditCardIcon, InfoIcon, SavingsIcon} from "../../../../../common/icons";
import ZuoraHostedPage from "./ZuoraHostedPage";
import SlimpayIframe from "./SlimpayIframe";
import {api} from "../../../../../services";

@inject('commonStore', 'translationsStore')
@observer
export default class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultPaymentMethod: undefined,
      selectedPaymentMethod: undefined,
      paymentMethods: []
    };
  }

  onPaymentMethodChange = e => {
    const selectedPaymentMethod = e.target.value;
    this.setState({ selectedPaymentMethod });
  };

  onConfirm = () => {
    this.setState(
      { loading: true },
      async () => {
        await api('setPaymentMethodDefault', this.props.customerDetails.zuora_account_id, this.state.selectedPaymentMethod.Id, this.state.selectedPaymentMethod.Type);
        this.props.onNext();
      }
    );
  }

  showErrorMessage = () => {
    const { translationsStore } = this.props;
    message.error(translationsStore.translate('common.genericError'));
  };

  zuoraHostedSubmitProcessing = callbackResponse => {
    if (callbackResponse.errorCode) {
      this.showErrorMessage();
    }
  };

  zuoraHostedSubmitSucceed = async () => {
    this.props.onNext();
  };

  zuoraHostedSubmitFailed = () => {

    this.showErrorMessage();
  };

  componentDidMount = () => {
    window.zuoraHostedSubmitFailed = this.zuoraHostedSubmitFailed;
    window.zuoraHostedSubmitSucceed = this.zuoraHostedSubmitSucceed;
    window.zuoraHostedSubmitProcessing = this.zuoraHostedSubmitProcessing;
  };

  componentWillUnmount = () => {
    window.zuoraHostedSubmitFailed = undefined;
    window.zuoraHostedSubmitSucceed = undefined;
    window.zuoraHostedSubmitProcessing = undefined;
  };

  render() {
    const { translationsStore } = this.props;
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px'
    };

    return (
      <div>
        <h3>{translationsStore.translate('paymentManagement.paymentMethodPopup.addPaymentMethod')}</h3>
        <div className="PaymentMethodContainer">
          <Radio.Group onChange={this.onPaymentMethodChange} value={this.state.selectedPaymentMethod}>
            <Radio style={radioStyle} value={'creditcard'}>
              <CreditCardIcon /> {translationsStore.translate('paymentManagement.paymentMethodPopup.creditCard')}
            </Radio>
            <Radio style={radioStyle} value={'sepa'}>
              <SavingsIcon /> {translationsStore.translate('paymentManagement.paymentMethodPopup.sepa')}
            </Radio>
          </Radio.Group>
          <div className="PaymentInfo">
            <InfoIcon width={20} height={20}/>
            <div style={{marginLeft: '5px'}}>{translationsStore.translate('paymentManagement.paymentMethodPopup.paymentInfo')}</div>
          </div>
        </div>

        {
          this.state.selectedPaymentMethod === 'creditcard'
            ? <ZuoraHostedPage
                customerDetails={this.props.customerDetails}
                onNext={this.props.onNext}
              />
            : this.state.selectedPaymentMethod === 'sepa'
              ? <SlimpayIframe
                  customerDetails={this.props.customerDetails}
                  onNext={this.props.onNext}
                />
              : <div style={{textAlign: 'center'}}>
                  <Button
                    style={{width: 'auto'}}
                    type="primary"
                    disabled={!this.state.selectedPaymentMethod}
                    onClick={() => this.onConfirm()}
                  >
                    {translationsStore.translate('paymentManagement.paymentMethodPopup.confirm')}
                  </Button>
                </div>
        }
      </div>
    );
  }
}
