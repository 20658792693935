import Rollbar from 'rollbar';

const rollbar = new Rollbar({
  accessToken: 'ee62368abc4d49eaa645ee8ea0edc6a7',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ENV
});

export default rollbar;
