import { observable, action } from 'mobx';

import { authStore, commonStore } from '.';

import { api } from '../services';

const defaultIdAdesione = '';

class MoVeInStore {
  @observable
  exceptionFound = false;

  @observable
  draftIdAdesione = defaultIdAdesione;

  @observable
  loadingCheckIdAdesione = false;

  @observable
  idAdesione = null;

  @observable
  lastError = null;

  @observable
  registering = false;

  @observable
  registered = false;

  @observable user = {};

  @observable vehicle = {};

  @observable vehicleType = {};

  @observable
  company = undefined;

  @observable
  address = {};

  @observable
  privacyPolicy = false;

  @observable
  loadingDataFromID = true;

  @action
  setExecptionFound = exceptionFound => {
    this.exceptionFound = exceptionFound;
  };

  @action
  setUser(user) {
    this.user = user;
  }

  @action
  setCompany(company) {
    this.company = company;
  }

  @action
  setVehicle(vehicle) {
    this.vehicle = vehicle;
  }

  @action
  setVehicleType(vehicleType) {
    this.vehicleType = vehicleType;
  }

  @action
  setAddress(address) {
    this.address = address;
  }

  @action
  setPrivacyPolicy(privacyPolicy) {
    this.lastError = null;
    this.privacyPolicy = privacyPolicy;
  }

  @observable
  installerList = [];

  @observable
  loadingInstallers = false;

  @observable
  loadingSubmitInstaller = false;

  @observable
  selectedInstaller = null;

  constructor({ authStore, commonStore }) {
    this.commonStore = commonStore;
    this.authStore = authStore;
    api('currentUser').then(user => {
      this.authStore.setToken(user.signInUserSession.idToken.jwtToken);
    }).catch(err => err);
  }

  @action
  setSelectedInstaller = selectedInstaller => {
    this.selectedInstaller = selectedInstaller;
  };

  @action
  setRegistered = registered => {
    this.registered = registered;
  };

  @action
  loadInstallers(province) {
    if (!province) {
      this.installerList = [];
      return;
    }

    this.loadingInstallers = true;
    return api('getInstallersByProvince', province)
      .then(response => {
        this.installerList = response;
      })
      .catch(error => {
        // this.lastError = { key: 'missingIdAdesione' };
        // this.idAdesione = null;
        this.exceptionFound = true;
        console.error(error);
      })
      .finally(() => {
        this.loadingInstallers = false;
      });
  }

  @action
  chooseInstaller(userUuid, vehicleUuid) {
    this.loadingSubmitInstaller = true;
    return api('chooseInstaller', userUuid, vehicleUuid, this.selectedInstaller.uuid)
      .catch(error => {
        // this.lastError = { key: 'missingIdAdesione' };
        // this.idAdesione = null;
        this.exceptionFound = true;
        console.error(error);
      })
      .finally(() => {
        this.loadingSubmitInstaller = false;
      });
  }
}

export default new MoVeInStore({ authStore, commonStore });
