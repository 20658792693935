import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import VehicleSelector from "../../../../common/components/VehicleSelector";

import MyPageContainer from "../../../../common/components/MyPageContainer";
import Section from "../../../../common/components/Section";
import {DoneIcon, MultipleDocuments, TowingTruck, PositionMarker, AxaLogo} from "../../../../common/icons";
import MyPageButton from "../../../../common/components/MyPageButton";
import BaseAssistanceImage from "../../../../common/components/BaseAssistanceImage/BaseAssistanceImage";
import BaseAssistanceFullImage from "../../../../common/components/BaseAssistanceFullImage";

import './PurchaseAssistanceView.css'
import {Checkbox} from "antd";
import {Media as BreakpointsMedia} from "react-breakpoints";

const FeatureItem = ({children}) => (
  <div className="ListItemContainer">
    <div className="ListIcon"><DoneIcon width={24} height={24} /></div>
    <p className="Description">{children}</p>
  </div>
)

@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class PurchaseAssistanceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRatePlan: null
    }

    this.props.productsStore.setOptionalService(undefined);
    this.props.productsStore.setOptionalServiceProduct(undefined);
  }

  getProductAnnualRatePlan = () =>
    this.props.productsStore.selectedProduct.productRatePlans.find(ratePlan => ratePlan.productRatePlanCharges[0].billingPeriod === 'Annual')

  getProductMonthRatePlan = () =>
    this.props.productsStore.selectedProduct.productRatePlans.find(ratePlan => ratePlan.productRatePlanCharges[0].billingPeriod === 'Month')

  getRateplanPrice = (productRatePlan) =>
    Number.parseFloat(productRatePlan.productRatePlanCharges.reduce(
      (acc, currentCharge) => acc+currentCharge.pricing[0].price,
      0
    )).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2});

  getRateplanPriceWithDiscount = (productRatePlan) => {
    const {productsStore} = this.props;

    const priceWithoutDiscount = Number.parseFloat(this.getRateplanPrice(productRatePlan));

    const discount = productsStore.getDiscountFromProductUuid(productsStore.selectedProduct && productsStore.selectedProduct.uuid);

    if (discount) {
     const discountedPrice =  productsStore.getDiscountedPrice(discount && discount.discountRatePlanId, priceWithoutDiscount);

     return discountedPrice && discountedPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

   return undefined;
  }

  onSubmit = () => {
    const {mypageStore, productsStore, history} = this.props

    productsStore.setSelectedProductRateplan(this.state.selectedRatePlan)
    history.push('/payments');
  }

  render() {
    const { productsStore, translationsStore, optional } = this.props;

    if(!productsStore.selectedProduct) {
      return <Redirect to="/" />;
    }

    const annualDiscountedPrice = this.getRateplanPriceWithDiscount(this.getProductAnnualRatePlan());
    const monthlyDiscountedPrice = this.getRateplanPriceWithDiscount(this.getProductMonthRatePlan());

    return (
      <MyPageContainer className='PurchaseAssistanceView' dataCollection={this.props.dataCollection}>

      <VehicleSelector disabled={true} />

        <Section>
          {(annualDiscountedPrice !== undefined || monthlyDiscountedPrice !== undefined) &&
          <div className='DiscountedBadgeContainer'>
            <div
              className={'DiscountedBadge'}>{translationsStore.translate('servicesView.serviceCard.discount.sale')}</div>
          </div>}

          {productsStore.isAssistanceAdvanced(productsStore.selectedProduct) &&
            <>
              <header>
                <h2>{translationsStore.translate('purchaseAssistanceView.advancedAssistance.title')}</h2>
                <h3>{translationsStore.translate('purchaseAssistanceView.subtitle')}</h3>
              </header>

              <div className="ProductFeatures">
                {productsStore.selectedProduct.features
                  .map((feature) => translationsStore.translate('servicesView.serviceCard.features.' + feature.node.name))
                  .flat()
                  .map((feature, index) => (
                    <FeatureItem key={index}>{feature}</FeatureItem>
                  ))}
              </div>

              <div className="ProductPlanSelection">
                <div className="ProductPlan">
                  <Checkbox
                    onChange={() => this.setState({selectedRatePlan: this.getProductAnnualRatePlan()})}
                    checked={this.state.selectedRatePlan && this.state.selectedRatePlan.id === this.getProductAnnualRatePlan().id}
                  />
                  <div className="PlanPrice">
                    {annualDiscountedPrice !== undefined && <div className='DiscountPrice'>{annualDiscountedPrice} €</div>}
                    <h2><span
                      className={monthlyDiscountedPrice ? 'StrikeThroughPrice' : ''}>{this.getRateplanPrice(this.getProductAnnualRatePlan())}€</span>
                      <span className="taxes">iva inclusa</span></h2>
                    <h3>per un anno</h3>
                  </div>
                </div>
                <div className="ProductPlan">
                  <Checkbox
                    onChange={() => this.setState({selectedRatePlan: this.getProductMonthRatePlan()})}
                    checked={this.state.selectedRatePlan && this.state.selectedRatePlan.id === this.getProductMonthRatePlan().id}
                  />
                  <div className="PlanPrice">
                    {monthlyDiscountedPrice !== undefined &&
                    <div className='DiscountPrice'>{monthlyDiscountedPrice} €</div>}
                    <h2><span
                      className={monthlyDiscountedPrice ? 'StrikeThroughPrice' : ''}>{this.getRateplanPrice(this.getProductMonthRatePlan())}€</span>
                      <span className="taxes">iva inclusa</span></h2>
                    <h3>al mese per un anno</h3>
                  </div>
                </div>
              </div>

              <div className="ReviewFooter">
                <MyPageButton
                  theme="primary"
                  solid
                  disabled={!this.state.selectedRatePlan}
                  title={"Procedi con l'acquisto"}
                  onClick={this.onSubmit}
                />
              </div>
            </>
          }
          {productsStore.isAssistanceBase(productsStore.selectedProduct) &&
            <>
              <div>
                <h2 className={'AssistanceTitle'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.title')}</h2>
              </div>
              <h3>{translationsStore.translate('purchaseAssistanceView.subtitle')}</h3>
              <div className={'SvgImageContainer'}>
                <BreakpointsMedia>
                  {({ breakpoints, currentBreakpoint }) =>
                    breakpoints[currentBreakpoint] > breakpoints.extrasmall ? (
                      <BaseAssistanceFullImage width={441} height={155}/>
                    ) : (
                      <BaseAssistanceImage width={441} height={155}/>
                    )
                  }
                </BreakpointsMedia>
              </div>
              <div className={'ThreeColumnView'}>
                <div className={'OneColumnComponent'}>
                  <div className={'AssistanceFeatureCardContainer'}>
                    <div className={'AssistanceFeatureOneColumnComponent'} />
                    <div className={'AssistanceFeatureOneColumnComponent'}>
                      <div className={'AssistanceColumnTitle'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.certificatedReport')}</div>
                      <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.localizationReport')}</div>
                    </div>
                    <div className={'AssistanceFeatureOneColumnComponent'}>
                      <div className={'AssistanceIconContainer'}> <MultipleDocuments className={'AssistanceIconColor'}/> </div>
                    </div>
                  </div>
                </div>
                <div className={'OneColumnComponent'}>
                  <div className={'AssistanceFeatureCardContainer'}>
                    <div className={'AssistanceFeatureOneColumnComponent'}>
                      <div className={'AxaIconContainer'}>
                        <AxaLogo/>
                      </div>
                    </div>
                    <div className={'AssistanceFeatureOneColumnComponent'}>
                      <div className={'AssistanceColumnTitle'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.vehicleAssistance')}</div>
                      <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.h24Support')}</div>
                      <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.courtesyCar')}</div>
                      <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.taxiService')}</div>
                      <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.h24Number')}</div>
                    </div>
                    <div className={'AssistanceFeatureOneColumnComponent'}>
                      <div className={'AssistanceIconContainer'}> <TowingTruck className={'AssistanceIconColor'}/>  </div>
                    </div>
                  </div>
                </div>
                <div className={'OneColumnComponent'}>
                  <div className={'AssistanceFeatureCardContainer'}>
                    <div className={'AssistanceFeatureOneColumnComponent'} />
                    <div className={'AssistanceFeatureOneColumnComponent'}>
                      <div className={'AssistanceColumnTitle'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.theftSupport')}</div>
                      <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.carFinder')}</div>
                      <div className={'AssistanceColumnFeature'}>{translationsStore.translate('purchaseAssistanceView.baseAssistance.dataBankProtection')}</div>
                    </div>
                    <div className={'AssistanceFeatureOneColumnComponent'}>
                      <div className={'AssistanceIconContainer'}> <PositionMarker className={'AssistanceIconColor'}/> </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="BaseAssistanceProductPlanSelection">
                <h3>{translationsStore.translate('purchaseAssistanceView.chooseTheBestPlanForYou')}</h3>
                <div className={'BaseAssistanceCheckboxContainer'}>
                  <div className="BaseAssistanceProductPlan">
                    <Checkbox
                      onChange={() => this.setState({selectedRatePlan: this.getProductAnnualRatePlan()})}
                      checked={this.state.selectedRatePlan && this.state.selectedRatePlan.id === this.getProductAnnualRatePlan().id}
                    />
                    <div>&nbsp;</div>
                    <div className="BaseAssistancePlanPrice">
                      {annualDiscountedPrice !== undefined && <div className='DiscountPrice'>{' '}{annualDiscountedPrice} €</div>}
                      <span className={`ColoredPriceBaseAssistance ${monthlyDiscountedPrice ? 'StrikeThroughPrice' : ''}`}>{this.getRateplanPrice(this.getProductAnnualRatePlan())}€</span>{' '}per un anno
                    </div>
                  </div>
                  <div className="BaseAssistanceProductPlan">
                    <Checkbox
                      onChange={() => this.setState({selectedRatePlan: this.getProductMonthRatePlan()})}
                      checked={this.state.selectedRatePlan && this.state.selectedRatePlan.id === this.getProductMonthRatePlan().id}
                    />
                    <div>&nbsp;</div>
                    <div className="BaseAssistancePlanPrice">
                      {monthlyDiscountedPrice !== undefined &&
                      <div className='DiscountPrice'>{' '}{monthlyDiscountedPrice} €</div>}
                      <span className={`ColoredPriceBaseAssistance ${monthlyDiscountedPrice ? 'StrikeThroughPrice' : ''}`}>{this.getRateplanPrice(this.getProductMonthRatePlan())}€/mese</span>{' '}per un anno
                    </div>
                  </div>
                </div>
                <MyPageButton
                  theme="primary"
                  className={'BaseAssistanceBuyButton'}
                  solid
                  disabled={!this.state.selectedRatePlan}
                  title={translationsStore.translate('purchaseAssistanceView.baseAssistance.proceedToBuy')}
                  onClick={this.onSubmit}
                />
              </div>
            </>
          }
        </Section>

      </MyPageContainer>
    );
  }
}

export default PurchaseAssistanceView;
