import React from 'react';
import { inject } from 'mobx-react';
import classNames from 'classnames';
import './ProductCardWithSelectableRatePlan.css';
import { Checkbox } from 'antd';
import { productsHelper } from '../../../helpers';

const ProductCardWithSelectableRatePlan = inject(
  'translationsStore',
  'productsStore'
)(
  ({
    translationsStore,
    productsStore,
    productNameProps,
    cardSubTitleProps,
    isHighlighted,
    topBadgeText,
    additionalTitleIcon,
    imageFilePathProps,
    featuresProps,
    productWithRatePlans,
    fakeOriginalPriceProps,
    onCheckedChange,
    checkedRatePlanId,
    ...props
  }) => {
    if (!productWithRatePlans) {
      return <></>;
    }

    const monthlyRatePlan = productsHelper.getRecurringRatePlanFromProduct(productWithRatePlans);
    const monthlyPrice = !monthlyRatePlan ? undefined : productsHelper.getProductPriceFromRatePlan(monthlyRatePlan);
    const yearlyRatePlan = productsHelper.getAnnualRatePlanFromProduct(productWithRatePlans);
    const yearlyPrice = !yearlyRatePlan ? undefined : productsHelper.getProductPriceFromRatePlan(yearlyRatePlan);
    const productMainFeature = productWithRatePlans.features && productWithRatePlans.features[0] && productWithRatePlans.features[0].node.name;
    const productName = productNameProps ?? translationsStore.translate('productCardWithSelectableRatePlan.' + productMainFeature + '.title');
    const cardSubTitle = cardSubTitleProps ?? translationsStore.translate('productCardWithSelectableRatePlan.' + productMainFeature + '.cardSubTitle');
    const features = featuresProps ?? translationsStore.translate('productCardWithSelectableRatePlan.' + productMainFeature + '.features');
    const fakeOriginalPrice = fakeOriginalPriceProps ?? productsStore.getFakeOriginalPriceByProductUuid(productWithRatePlans.uuid);
    const imageFilePath = imageFilePathProps ?? productsStore.getImageFilePathByProductUuid(productWithRatePlans.uuid);

    const informationContainerCls = classNames({
      ProductCardWithSelectableRatePlanInformationContainer: true,
      highlighted: isHighlighted,
    });

    const badgeContainerCls = classNames({
      ProductCardWithSelectableRatePlanBadgeContainer: true,
      highlighted: isHighlighted,
    });

    const priceContainerCls = classNames({
      ProductCardWithSelectableRatePlanPriceContainer: true,
      ProductCardWithSelectableRatePlanPriceContainerHighlighted: isHighlighted,
    });

    const originalPriceContainerCls = classNames({
      ProductCardWithSelectableRatePlanPriceOriginal: true,
      ProductCardWithSelectableRatePlanPriceOriginalHighlighted: isHighlighted,
    });

    return (
      <div className={'ProductCardWithSelectableRatePlanContainer'} {...props}>
        <div className={informationContainerCls}>
          {topBadgeText && <div className={badgeContainerCls}>{topBadgeText}</div>}
          {imageFilePath && (
            <div className={'ProductCardWithSelectableRatePlanImageContainer'}>
              <img src={imageFilePath} alt={productName} />
            </div>
          )}
          <div className={'ProductCardWithSelectableRatePlanTitleContainer'}>
            {React.isValidElement(additionalTitleIcon) && <div className={'ProductCardWithSelectableRatePlanAdditionalTitleIconContainer'}>{additionalTitleIcon}</div>}
            <div dangerouslySetInnerHTML={{ __html: productName }} />
          </div>
          <div className={'ProductCardWithSelectableRateSubTitleInformationContainer'}>{cardSubTitle}</div>
          <div className={'ProductCardWithSelectableRatePlanPriceOutsideContainer'}>
            <div className={priceContainerCls}>
              <div className={'ProductCardWithSelectableRatePlanPriceDiscountedContainer'}>
                <div className={'ProductCardWithSelectableRatePlanPriceDiscounted'}>{productsHelper.convertPriceToUseComa(monthlyPrice ? monthlyPrice : yearlyPrice)} €</div>
                <div>{monthlyPrice ? ` /${translationsStore.translate('common.month')}` : ` /${translationsStore.translate('common.year')}`}</div>
              </div>
              {fakeOriginalPrice && <span className={originalPriceContainerCls}>{fakeOriginalPrice}</span>}
            </div>
          </div>
          {Array.isArray(features) && (
            <div className={'ProductCardWithSelectableRatePlanFeatureContainer'}>
              {features.map((feature, index) => (
                <span className={'ProductCardWithSelectableRatePlanFeature'} key={index}>
                  {feature}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className={'ProductCardWithSelectableRatePlanChosePlanContainer'}>
          <span>{translationsStore.translate('productCardWithSelectableRatePlan.chooseTheBestPlanForYou')}</span>
          {yearlyPrice && (
            <div className="ProductCardWithSelectableRatePlan">
              <Checkbox onChange={onCheckedChange(productWithRatePlans, yearlyRatePlan)} checked={checkedRatePlanId === yearlyRatePlan.id} />
              <div>&nbsp;</div>
              <div className="ProductCardWithSelectableRatePlanPlanPrice">
                <span className={'ColoredPriceProductCardWithSelectableRatePlan'}>{productsHelper.convertPriceToUseComa(yearlyPrice)} €</span> {translationsStore.translate('common.forOne')}{' '}
                {translationsStore.translate('common.year')}
              </div>
            </div>
          )}
          {monthlyPrice && (
            <div className="ProductCardWithSelectableRatePlan">
              <Checkbox onChange={onCheckedChange(productWithRatePlans, monthlyRatePlan)} checked={checkedRatePlanId === monthlyRatePlan.id} />
              <div>&nbsp;</div>
              <div className="ProductCardWithSelectableRatePlanPlanPrice">
                <span className={'ColoredPriceProductCardWithSelectableRatePlan'}>
                  {productsHelper.convertPriceToUseComa(monthlyPrice)} €/{translationsStore.translate('common.month')}
                </span>{' '}
                {translationsStore.translate('common.forOne')} {translationsStore.translate('common.year')}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ProductCardWithSelectableRatePlan;
