import React, { Component, Fragment } from 'react';

import DrivingLicenseForm from "./DrivingLicenseForm/DrivingLicenseForm";
import IdentityCardForm from "./IdentityCardForm/IdentityCardForm";

import './PersonalDocumentsView.css';
import { inject } from 'mobx-react';
import MyPageContainer from "../../../../common/components/MyPageContainer";
import classNames from "classnames";

@inject('translationsStore', 'mypageStore')
export default class PersonalDocumentsView extends Component {
  render() {
    let { dataCollection, translationsStore, mypageStore } = this.props;
    const cls = classNames({
      [this.props.className]: !!this.props.className
    });
    return (
      <Fragment>
        <MyPageContainer className={cls} dataCollection={dataCollection}>
          <DrivingLicenseForm dataCollection={dataCollection} translationsStore={translationsStore} mypageStore={mypageStore}/>
          <IdentityCardForm dataCollection={dataCollection} translationsStore={translationsStore} mypageStore={mypageStore}/>
        </MyPageContainer>
        </Fragment>
    );
  }
}
