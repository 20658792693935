import K from '../constants';
import restHelper from '../helpers/restHelper';

import awsCognitoHelper from '../helpers/aws/awsCognitoHelper';

const validateToken = () => restHelper.withBearer.get(`${K.API_URL}/validate_token`);

/** AWS COGNITO **/
const currentUserPoolUser = () => awsCognitoHelper.currentUserPoolUser();
const login = (username, password) => awsCognitoHelper.signIn(username, password);
const completeNewPassword = (user, password) => awsCognitoHelper.completeNewPassword(user, password);
const changePassword = (user, oldpassword, newpassword) => awsCognitoHelper.changePassword(user, oldpassword, newpassword);
const forgotPassword = username => awsCognitoHelper.forgotPassword(username);
const forgotPasswordSubmit = (username, code, password) => awsCognitoHelper.forgotPasswordSubmit(username, code, password);
const currentSession = () => awsCognitoHelper.currentSession();

/** AWS COGNITO **/

export default {
    login,
    validateToken,
    completeNewPassword,
    currentUserPoolUser,
    changePassword,
    forgotPassword,
    forgotPasswordSubmit,
    currentSession
};
