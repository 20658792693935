import React, { Component, Fragment } from 'react';
import './InstallerInformationView.css';
import { inject, observer } from 'mobx-react';
import MyPageContainer from '../../../../common/components/MyPageContainer';
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';
import VehicleSelector from '../../../../common/components/VehicleSelector';
import { mypageHelper } from '../../../../helpers';
import { Col, Row } from 'antd';
import LabelInput from '../../../../common/components/LabelInput';
const { getOperationalAddress } = mypageHelper;

export const InstallerInformationForm = inject('translationsStore')(({ translationsStore, installerInformation, ...props }) => {
  const address = getOperationalAddress(installerInformation);

  return (
    <div className={'InstallerInformationForm'} {...props}>
      <Row gutter={24}>
        <Col lg={12}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelCompanyName')} value={installerInformation.company_name} disabled />
        </Col>
        <Col lg={12}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelVATIN')} value={installerInformation.uin} disabled />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={12}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelPhone')} value={installerInformation.phone} disabled />
        </Col>
        <Col lg={12}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelEmail')} value={installerInformation.email} disabled />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={6}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelCity')} value={address.city} disabled />
        </Col>
        <Col lg={6}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelAddress')} value={address.street} disabled />
        </Col>
        <Col lg={3}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelNumber')} value={address.building_number} disabled />
        </Col>
        <Col lg={2}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelProvince')} value={address.province} disabled />
        </Col>
        <Col lg={4}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelCountry')} value={address.country} disabled />
        </Col>
        <Col lg={3}>
          <LabelInput label={translationsStore.translate('InstallerInformationView.labelZipCode')} value={address.postcode} disabled />
        </Col>
      </Row>
    </div>
  );
});

@inject('translationsStore', 'mypageStore')
@observer
export default class InstallerInformationView extends Component {
  render() {
    const { dataCollection, mypageStore, translationsStore } = this.props;
    const currentVehicleInstaller = mypageStore.getInstallerFromVehicle(mypageStore.selectedManageableVehicle);

    return (
      <Fragment>
        <MyPageContainer dataCollection={dataCollection}>
          <LoadingOverlay
            active={mypageStore.loadingManageableVehicles}
            spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true} />}
            styles={{
              overlay: base => ({
                ...base,
                background: 'var(--grey-light-color-50)'
              })
            }}
          >
            <VehicleSelector />
            <div className={'InstallerInformationView'}>
              <div className={'title'}>{translationsStore.translate('InstallerInformationView.title')}</div>
              {!currentVehicleInstaller ? (
                <div>{translationsStore.translate('InstallerInformationView.noInstallerSelectedForVehicle')}</div>
              ) : (
                <InstallerInformationForm installerInformation={currentVehicleInstaller} />
              )}
            </div>
          </LoadingOverlay>
        </MyPageContainer>
      </Fragment>
    );
  }
}
