import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import { Checkbox } from 'antd';
import K from '../../../../../constants';

import './PaymentAgreements.css';
import AirModal from '../../../../../common/components/AirModal';
import MyPageCheckbox from '../../../../../common/components/MyPageCheckbox';

@inject('translationsStore', 'mypageStore')
@observer
export default class PaymentAgreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleStandardFormContract: false
    };
  }

  componentDidMount() {
    const { mypageStore, isAssistance ,isMyApp} = this.props;
    const { selectedManageableVehicle } = mypageStore || {};

    if (
      mypageStore.remoteUserIsFromMoVeInProgram &&
      (isAssistance || isMyApp) &&
      selectedManageableVehicle  && (
        selectedManageableVehicle.airclub_general !== undefined
        || selectedManageableVehicle.air_club_marketing !== undefined
        || selectedManageableVehicle.air_club_profiling !== undefined
        || selectedManageableVehicle.air_club_third_parties !== undefined
      )
    ) {
      this.onAddOnServiceAgreementsChange({
        airclub_general: selectedManageableVehicle.airclub_general,
        air_club_profiling: selectedManageableVehicle.air_club_marketing,
        air_club_marketing: selectedManageableVehicle.air_club_profiling,
        air_club_third_parties: selectedManageableVehicle.air_club_third_parties
      });
    }
  }

  getGeneralConditionsLabel = () => {
    const {
      translationsStore,
      labelGeneralConditionPrefix: labelGeneralConditionPrefixFromProps,
      labelGeneralConditionClickable: labelGeneralConditionClickableFromProps,
      labelGeneralConditionSuffix: labelGeneralConditionSuffixFromProps,
    } = this.props;

    return labelGeneralConditionPrefixFromProps || labelGeneralConditionClickableFromProps || labelGeneralConditionSuffixFromProps ?
      (<div>
          {!!labelGeneralConditionPrefixFromProps && `${labelGeneralConditionPrefixFromProps} `}
          {labelGeneralConditionClickableFromProps && <span onClick={(e) => {
            e.stopPropagation();
            this.setState({visibleStandardFormContract: true})
          }}
                 style={{fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer'}}>
            {labelGeneralConditionClickableFromProps}
          </span>}
          {!!labelGeneralConditionSuffixFromProps && ` ${labelGeneralConditionSuffixFromProps}`}
      </div>
      )
      : translationsStore.translate('installerView.sectionAgreements.lableGeneralConditions');
  }

  onAddOnServiceAgreementsChange = (updatedValues) => {
    let { addOnServiceAgreements = {} } = this.props;
    addOnServiceAgreements = { ...addOnServiceAgreements, ...updatedValues };
    const { onAddOnServiceAgreementsChange } = this.props;
    if (onAddOnServiceAgreementsChange && typeof onAddOnServiceAgreementsChange === 'function') {
      onAddOnServiceAgreementsChange(addOnServiceAgreements);
    }
  }

  render() {
    const {
      translationsStore,
      isRenewProduct,
      isMoVeInRenewal,
      generalConditionsDocumentSource,
      hideSubTitle,
      labelRestrictiveConditions: labelRestrictiveConditionsFromProps,
    } = this.props;
    const { visibleStandardFormContract } = this.state;
    const { checkedAgreedGeneralConditions = false, checkedAgreedRestrictiveClauses = false, onChangeAgreedGeneralConditions, onChangeAgreedRestrictiveClauses } = this.props;
    const {
      addOnServiceAgreements: { airclub_general, airclub_general_articles, air_club_profiling, air_club_marketing, air_club_third_parties } = {},
    } = this.props;

    const cls = classNames({
      PaymentAgreements: true,
      [this.props.className]: !!this.props.className
    });

    return (
      <div className={cls}>
        <BreakpointsMedia>
          {({ breakpoints, currentBreakpoint }) => {
            const clsHeaderAgreements = classNames({
              HeaderTwo: true,
              ['HeaderTwo-lg']: currentBreakpoint === 'desktop'
            });
            return (
              <Fragment>
                <div className={clsHeaderAgreements}>{translationsStore.translate('installerView.sectionAgreements.labelTitle')}</div>
                {!hideSubTitle && <div>
                  {translationsStore.translate('installerView.sectionAgreements.labelIntro.prefix')}{' '}
                  <span onClick={() => this.setState({ visibleStandardFormContract: true })} style={{ fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer' }}>
                    {translationsStore.translate('installerView.sectionAgreements.labelIntro.linkedDocument')}
                  </span>{' '}
                  { isMoVeInRenewal ? translationsStore.translate('installerView.sectionAgreements.labelIntro.suffixMoVeInRenewal') : isRenewProduct ?
                    translationsStore.translate('installerView.sectionAgreements.labelIntro.suffixAirRenew')
                    : translationsStore.translate('installerView.sectionAgreements.labelIntro.suffix')
                  }
                </div>}
                <AirModal
                  className="EmbeddedDocumentModal"
                  width="90vw"
                  visible={visibleStandardFormContract}
                  onCancel={() => {
                    this.setState({ visibleStandardFormContract: false });
                  }}
                >
                  {<iframe
                      src={generalConditionsDocumentSource ?
                        generalConditionsDocumentSource
                        : K.POLICY_BASE_URL.concat(isRenewProduct ? 'condizioni-generali-di-contratto-air-renew-not-flagged.pdf' : 'condizioni-generali-movein-2024-04-12-not-flagged.pdf')}
                      width="100%" height="540"/>}
                </AirModal>
                <MyPageCheckbox
                  checked={checkedAgreedGeneralConditions}
                  onChange={onChangeAgreedGeneralConditions}
                  label={this.getGeneralConditionsLabel()}
                />
                <MyPageCheckbox
                  checked={checkedAgreedRestrictiveClauses}
                  onChange={onChangeAgreedRestrictiveClauses}
                  label={
                    labelRestrictiveConditionsFromProps ?
                      labelRestrictiveConditionsFromProps
                      : isRenewProduct ?
                        translationsStore.translate('installerView.sectionAgreements.lableRestrictiveClausesAirRenew')
                        : translationsStore.translate('installerView.sectionAgreements.lableRestrictiveClauses')}
                />
                {this.props.hasOwnProperty('addOnServiceAgreements') && (
                  <div className="AirclubPrivacyForm" style={{ display: 'flex' }}>
                    <div className="OptionalServicePrivacy">
                      <h4 style={{ marginTop: 16 }}>
                        Dichiaro di aver preso visione e di aver letto attentamente l'
                        <span onClick={() => this.setState({ privacyVisible: true })} style={{ fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer' }}>
                          informativa privacy di AIR
                        </span>
                      </h4>
                      <p>
                        <b>Consenso al trattamento dei dati personali</b>
                      </p>
                      <p>
                        Il sottoscritto, informato in merito al trattamento dei propri dati personali da parte di AIR S.r.l. (la “Società”), come da informativa da quest’ultima
                        predisposta ai sensi dell’art. 13 del Regolamento UE 2016/679 che conferma di aver letto e ben compreso, conferma di aver preso atto delle conseguenze del
                        rifiuto a conferire i Dati Personali per determinati trattamenti e di essere consapevole che il consenso è revocabile in qualsiasi momento. Inoltre il
                        sottoscritto dichiara quanto segue:
                      </p>
                      <p>
                        Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per l’individuazione e lo studio dei dati sulle concrete modalità di utilizzo del
                        veicolo, quali anche acquisiti tramite Dispositivo di cui all’articolo 2 lett. e) dell’informativa (profilazione):
                      </p>
                      <div style={{ display: 'flex', fontWeight: 'bold' }}>
                        <div style={{ marginRight: 40 }}>
                          <Checkbox onChange={() => this.onAddOnServiceAgreementsChange({ air_club_profiling: true })} checked={air_club_profiling === true} /> Acconsento
                        </div>
                        <div>
                          <Checkbox onChange={() => this.onAddOnServiceAgreementsChange({ air_club_profiling: false })} checked={air_club_profiling === false} /> Non acconsento
                        </div>
                      </div>

                      <p>
                        Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per attività promozionali e di vendita di cui all’articolo 2 lett. f)
                        dell’informativa (marketing): comunicazione ed iniziative promozionali e di vendita di prodotti e/o servizi della società o di soggetti terzi appartenenti
                        alle categorie sopra menzionate via email, SMS o altri sistemi automatizzati e per posta, nonché per ricerche di mercato e rilevazioni statistiche. Il
                        consenso per marketing deve intendersi esteso anche alle modalità tradizionali di contatto:
                      </p>
                      <div style={{ display: 'flex', fontWeight: 'bold' }}>
                        <div style={{ marginRight: 40 }}>
                          <Checkbox onChange={() => this.onAddOnServiceAgreementsChange({ air_club_marketing: true })} checked={air_club_marketing === true} /> Acconsento
                        </div>
                        <div>
                          <Checkbox onChange={() => this.onAddOnServiceAgreementsChange({ air_club_marketing: false })} checked={air_club_marketing === false} /> Non acconsento
                        </div>
                      </div>

                      <p>
                        Con riferimento al trasferimento dei Dati Personali, compresi quelli raccolti tramite Dispositivo, a soggetti terzi (imprese di assicurazione, broker,
                        agenti assicurativi, banche e intermediari finanziari, operatori del settore automobilistico e della componentistica, carrozzerie e autofficine, fornitori
                        di prodotti e servizi relativi al settore automotive, compresi fornitori di servizi di navigazione e viabilità), per lo svolgimento da parte di questi
                        ultimi di finalità loro proprie, ove del caso anche di marketing, come indicato all’articolo 2 lett. g) dell’informativa:
                      </p>
                      <div style={{ display: 'flex', fontWeight: 'bold' }}>
                        <div style={{ marginRight: 40 }}>
                          <Checkbox onChange={() => this.onAddOnServiceAgreementsChange({ air_club_third_parties: true })} checked={air_club_third_parties === true} /> Acconsento
                        </div>
                        <div>
                          <Checkbox onChange={() => this.onAddOnServiceAgreementsChange({ air_club_third_parties: false })} checked={air_club_third_parties === false} /> Non acconsento
                        </div>
                      </div>
                    </div>
                    <AirModal
                      className="EmbeddedDocumentModal"
                      width="90vw"
                      visible={this.state.privacyVisible}
                      onCancel={() => {
                        this.setState({ privacyVisible: false });
                      }}
                    >
                      <iframe src={K.POLICY_BASE_URL.concat('privacy-policy-2022-09-23.pdf')} width="100%" height="540" />
                    </AirModal>
                  </div>
                )}
              </Fragment>
            );
          }}
        </BreakpointsMedia>
      </div>
    );
  }
}
