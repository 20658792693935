import MyPageButton from "../../../../common/components/MyPageButton";
import { Media as BreakpointsMedia } from 'react-breakpoints';
import React, {Component} from "react";
import {Radio, Modal, message} from "antd";
import {inject, observer} from "mobx-react";
import K from "../../../../constants";

@inject('translationsStore', 'mypageStore')
@observer
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managePolicy: false,
      policyAccepted: props.mypageStore.selectedManageableVehicle.marketing_consent_accepted,
      marketingThirdPartiesAgreement: props.mypageStore.selectedManageableVehicle.marketing_third_parties_consent_accepted
    }
  }

  showConfirm = () => {
    Modal.confirm({
      title: 'Attenzione',
      content: 'State modificando il consenso del marketing, siete sicuri di procedere?',
      okText: 'Si',
      cancelText: 'No',
      onOk: async() => {
        try {
          await this.props.mypageStore.updateMarketingConsent(this.state.policyAccepted, this.state.marketingThirdPartiesAgreement)
        } catch (e) {
          message.error(this.props.translationsStore.translate('common.genericError'));
        }
        this.props.onCancel()
      },
      onCancel() {
      },
    });
  };

  onPolicyChange = (e) => {
    this.setState({policyAccepted: e.target.value})
  };

  onMarketingThirdPartiesAgreementChange = (e) => {
    this.setState({marketingThirdPartiesAgreement: e.target.value})
  };

  render() {
    if(this.state.managePolicy) {
      return (
        <BreakpointsMedia>
          {({ breakpoints, currentBreakpoint }) => {
            const classes = breakpoints[currentBreakpoint] >= breakpoints.desktop ? "PrivacyUpdate desktop" : "PrivacyUpdate";

            return (
              <div className={classes}>
                <h1 className="PrivacyTitle">Informativa sulla privacy</h1>
                <p className="Subtitle">Per usufruire dei nostri servizi e necessario fornire il consenso al trattamento dei dati Personali secondo il nuovo Reglamenta EU 2016/679, noto come GDPR.</p>
                <p className="AgreementItem">
                  Con riferimento al trattamento dei dati personali da parte di AIR S.r.l. per le finalità di marketing di cui al punto 4 (i) del paragrafo 2 dell'informativa, vale a dire ricerche di mercato, invio di materiale informativo, promozionale o pubblicitario, comunicazione di nuove iniziative commerciali, offerta di prodotti o servizi (anche tramite il sito <a href="https://www.myair.io/" target="_blank">www.myair.io</a> ), come servizi e prodotti automotive tra cui anche prodotti assicurativi ed iniziative promozionali e di vendita di prodotti e/o servizi di AIR S.r.l. o di soggetti terzi via email, SMS o altri sistemi automatizzati, telefonicamente e per posta, o mediante offerta tramite il sito <a href="https://www.myair.io/" target="_blank">www.myair.io</a>
                </p>
                <Radio.Group className="PrivacyUpdateForm" onChange={this.onPolicyChange} value={this.state.policyAccepted} >
                  <Radio value={true}>Acconsento</Radio>
                  <Radio value={false}>Non acconsento</Radio>
                </Radio.Group>
                <p />
                <p className="AgreementItem">
                  Con riferimento alla comunicazione dei miei dati personali da parte di AIR S.r.l. alle categorie di soggetti terzi indicati al punto 4 (ii) del paragrafo 2 dell'informativa e per le finalità ivi indicate, tra cui marketing e ricerche di mercato, via email, SMS o altri sistemi automatizzati o per posta cartacea o telefonicamente
                </p>
                <Radio.Group className="PrivacyUpdateForm" onChange={this.onMarketingThirdPartiesAgreementChange} value={this.state.marketingThirdPartiesAgreement} >
                  <Radio value={true}>Acconsento</Radio>
                  <Radio value={false}>Non acconsento</Radio>
                </Radio.Group>
                <div style={{display: "flex", justifyContent: "flex-end", flexWrap: "wrap", marginTop: 8}}>
                  <MyPageButton
                    theme="primary"
                    solid
                    title={"Annula le Modifiche"}
                    onClick={() => {this.props.onCancel()}}
                  />
                  <MyPageButton
                    theme="primary"
                    solid
                    title={"Salva le Modifiche"}
                    onClick={this.showConfirm}
                  />
                </div>
              </div>
            )
          }}
        </BreakpointsMedia>
      )
    }
    return (
      <React.Fragment>
        <iframe src={K.POLICY_BASE_URL.concat('privacy-policy-2022-09-23.pdf')} width="100%" height="540" />
        <div style={{display: "flex", justifyContent: "center", marginTop: 8}}>
          <MyPageButton
            theme="primary"
            solid
            style={{width: "initial"}}
            title={"Gestisci Consensi"}
            onClick={() => {this.setState({managePolicy: true})}}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default PrivacyPolicy;
