import React, {useState} from 'react';

import './AdditionServicesConditionCheckboxes.css';
import {Checkbox} from "antd";
import AirModal from "../../../../common/components/AirModal";
import K from "../../../../constants";

export default ({...props}) => {
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [generalConditionModalVisible, setGeneralConditionModalVisible] = useState(false);

    return (
      <div>
        <div className="AirclubPrivacyForm" style={{display: 'flex'}}>
          <div className="AdditionalServicesConditionCheckboxesPrivacy">
            <div className={'generalConditionsCheckboxContainer'}>
              <Checkbox className="AdditionalServicesConditionCheckboxesPrivacyCheck" onChange={() => props.setGeneral(!props.airClubGeneral)}
                        checked={props.airClubGeneral === true}/>
              <h4 className="PrivacyHeadTitle">Confermo di aver preso visione e di accettare integralmente le <span
                onClick={() => setGeneralConditionModalVisible(true)}
                style={{fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer'}}>Condizioni Generali per l'Adesione ai Servizi Aggiuntivi AIR</span>.
              </h4>
            </div>
            <div  className={'generalConditionsCheckboxContainer'}>
              <Checkbox className="AdditionalServicesConditionCheckboxesPrivacyCheck" onChange={() => props.setCheckedAgreedRestrictiveClauses(!props.checkedAgreedRestrictiveClauses)}
                        checked={props.checkedAgreedRestrictiveClauses === true}/>
              <p>Ai sensi e per gli effetti di cui agli articoli 1341 e 1342 Codice Civile, dichiaro di aver letto attentamente e di approvare specificatamente le seguenti clausole di cui alle Condizioni Generali: 1.4; 1.5; 2.6; 2.11; 2.12; 3.2, 3.3; 5.3, 5.4; 6.2; 8; 10; 15.2.</p>
            </div>
            <h4 style={{marginTop: 16}}>Dichiaro di aver preso visione e di aver letto attentamente l'<span
              onClick={() => setPrivacyModalVisible(true)}
              style={{fontWeight: 600, color: 'var(--action-primary-color)', cursor: 'pointer'}}>informativa privacy di AIR</span>
            </h4>
            <p><b>Consenso al trattamento dei dati personali</b></p>
            <p>Il sottoscritto, informato in merito al trattamento dei propri dati personali da parte di AIR S.r.l. (la “Società”), come da informativa da quest’ultima predisposta ai sensi dell’art. 13 del Regolamento UE 2016/679 che conferma di aver letto e ben compreso, conferma di aver preso atto delle conseguenze del rifiuto a conferire i Dati Personali per determinati trattamenti e di essere consapevole che il consenso è revocabile in qualsiasi momento. Inoltre il sottoscritto dichiara quanto segue:</p>
            <p>Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per l’individuazione e lo
              studio dei dati sulle concrete modalità di utilizzo del veicolo, quali anche acquisiti tramite
              Dispositivo di cui all’articolo 2 lett. e) dell’informativa (profilazione):</p>
            <div style={{display: 'flex', fontWeight: 'bold'}}>
              <div style={{marginRight: 40}}><Checkbox onChange={() => props.setProfiling(true)}
                                                       checked={props.airClubProfiling === true}/> Acconsento
              </div>
              <div><Checkbox onChange={() => props.setProfiling(false)}
                             checked={props.airClubProfiling === false}/> Non acconsento
              </div>
            </div>

            <p>Con riferimento al trattamento dei Dati Personali da parte di AIR S.r.l. per attività promozionali e di
              vendita di cui all’articolo 2 lett. f) dell’informativa (marketing): comunicazione ed iniziative
              promozionali e di vendita di prodotti e/o servizi della società o di soggetti terzi appartenenti alle
              categorie sopra menzionate via email, SMS o altri sistemi automatizzati e per posta, nonché per ricerche
              di mercato e rilevazioni statistiche. Il consenso per marketing deve intendersi esteso anche alle
              modalità tradizionali di contatto:</p>
            <div style={{display: 'flex', fontWeight: 'bold'}}>
              <div style={{marginRight: 40}}><Checkbox onChange={() => props.setMarketing(true)}
                                                       checked={props.airClubMarketing === true}/> Acconsento
              </div>
              <div><Checkbox onChange={() => props.setMarketing(false)}
                             checked={props.airClubMarketing === false}/> Non acconsento
              </div>
            </div>

            <p>Con riferimento al trasferimento dei Dati Personali, compresi quelli raccolti tramite Dispositivo, a
              soggetti terzi (imprese di assicurazione, broker, agenti assicurativi, banche e intermediari finanziari,
              operatori del settore automobilistico e della componentistica, carrozzerie e autofficine, fornitori di
              prodotti e servizi relativi al settore automotive, compresi fornitori di servizi di navigazione e
              viabilità), per lo svolgimento da parte di questi ultimi di finalità loro proprie, ove del caso anche di
              marketing, come indicato all’articolo 2 lett. g) dell’informativa:</p>
            <div style={{display: 'flex', fontWeight: 'bold'}}>
              <div style={{marginRight: 40}}><Checkbox onChange={() => props.setThirdParties(true)}
                                                       checked={props.airClubThirdParties === true}/> Acconsento
              </div>
              <div><Checkbox onChange={() => props.setThirdParties(false)}
                             checked={props.airClubThirdParties === false}/> Non acconsento
              </div>
            </div>
          </div>
          <AirModal
            className="EmbeddedDocumentModal"
            width="90vw"
            visible={generalConditionModalVisible}
            onCancel={() => {
              setGeneralConditionModalVisible(false);
            }}
          >
            <iframe src={K.POLICY_BASE_URL.concat('condizioni-generali-servizi-aggiuntivi-2022-09-23.pdf')} width="100%" height="540"/>
          </AirModal>
          <AirModal
            className="EmbeddedDocumentModal"
            width="90vw"
            visible={privacyModalVisible}
            onCancel={() => {
              setPrivacyModalVisible(false);
            }}
          >
            <iframe src={K.POLICY_BASE_URL.concat('privacy-policy-2022-09-23.pdf')} width="100%" height="540"/>
          </AirModal>
        </div>
      </div>
    );
}

