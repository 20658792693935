import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import MyPageDesktopContainer from '../../../../../common/components/MyPageDesktopContainer';
import MyPageSelector from '../../../../../common/components/MyPageSelector';
import MyPageRadioGroup from '../../../../../common/components/MyPageRadioGroup';
import MyPageRadioButton from '../../../../../common/components/MyPageRadioButton';
import { CarIcon, AccountSquareIcon } from '../../../../../common/icons';
import LabelInput from '../../../../../common/components/LabelInput';
import SmallDivision from '../../../../../common/components/SmallDivision';

import './DesktopVehicleView.css';
import VehicleSelector from "../../../../../common/components/VehicleSelector";
import MyPageMobileContainer from "../../../../../common/components/MyPageMobileContainer";
import ContactDataForm from "../ContactDataForm/ContactDataForm";

@inject('translationsStore', 'mypageStore')
@observer
export default class DesktopVehicleView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onViewGroupChange = e => {
    const {
      dataCollection: { onViewGroupChange }
    } = this.props;
    onViewGroupChange(e.target.value);
  };

  render() {
    const { translationsStore, mypageStore, dataCollection } = this.props;
    const cls = classNames({
      [this.props.className]: !!this.props.className
    });

    const { selectedVehicleData, viewGroupItems, defaultGroupItem } = dataCollection;

    return (
      <MyPageDesktopContainer className={cls} dataCollection={dataCollection}>
        <VehicleSelector />
        <MyPageRadioGroup size="large" defaultValue={defaultGroupItem} onChange={this.onViewGroupChange} buttonStyle="solid" style={{ marginBottom: 56 }}>
          {viewGroupItems.map((item, index) => <MyPageRadioButton key={index} value={item.value}>{item.title}</MyPageRadioButton>)}
        </MyPageRadioGroup>
        <SmallDivision title={translationsStore.translate('vehiclesView.vehicleData.titleVehicleData')} icon={<CarIcon />} style={{ marginBottom: 40 }} />
        <div className="FieldsRow">
          <LabelInput label={translationsStore.translate('vehiclesView.vehicleData.labelBrand')} value={selectedVehicleData.brand} disabled={true} style={{ marginBottom: 20 }} />
          <LabelInput label={translationsStore.translate('vehiclesView.vehicleData.labelModel')} value={selectedVehicleData.model} disabled={true} style={{ marginBottom: 20 }} />
          <LabelInput
            label={translationsStore.translate('vehiclesView.vehicleData.labelVariant')}
            value={selectedVehicleData.variant}
            disabled={true}
            style={{ marginBottom: 20 }}
          />
          <LabelInput
            label={translationsStore.translate('vehiclesView.vehicleData.labelLicensePlate')}
            value={selectedVehicleData.license_plate}
            disabled={true}
            style={{ marginBottom: 20 }}
          />
        </div>
        <div className="FieldsRow">
          <LabelInput label={translationsStore.translate('vehiclesView.vehicleData.labelVin')} value={selectedVehicleData.vin} disabled={true} style={{ marginBottom: 20 }} />
          <LabelInput
            label={translationsStore.translate('vehiclesView.vehicleData.labelRegistrationDate')}
            value={selectedVehicleData.registration_date}
            disabled={true}
            style={{ marginBottom: 20 }}
          />
          <LabelInput
            label={translationsStore.translate('vehiclesView.vehicleData.labelFuelType')}
            value={selectedVehicleData.fuel_type}
            disabled={true}
            style={{ marginBottom: 20 }}
          />
          <LabelInput
            label={translationsStore.translate('vehiclesView.vehicleData.labelDisplacement')}
            value={selectedVehicleData.displacement}
            disabled={true}
            style={{ marginBottom: 20 }}
          />
        </div>
        <SmallDivision title={translationsStore.translate('vehiclesView.contactData.titleContactData')} icon={<AccountSquareIcon/>} style={{marginTop: 56, marginBottom: 40}}/>
        {!!mypageStore.remoteUserData && !!mypageStore.selectedManageableVehicle && !mypageStore.remoteUserIsFromMoVeInProgram ? (
          <ContactDataForm/>
        ) : (
          <div>
            <div className="FieldsRow">
              <LabelInput
                label={translationsStore.translate('vehiclesView.contactData.labelContactEmail')}
                value={selectedVehicleData.contactEmail}
                disabled={true}
                style={{marginBottom: 20}}
                dataTestId={'contactEmailTestId'}
              />
              <LabelInput
                label={translationsStore.translate('vehiclesView.contactData.labelContactPhone')}
                value={selectedVehicleData.contactPhone}
                disabled={true}
                style={{marginBottom: 20}}
                dataTestId={'contactPhoneTestId'}
              />
              <div style={{flexGrow: 2, marginRight: 24}}/>
            </div>
          </div>
        )}

      </MyPageDesktopContainer>
    );
  }
}
