import React, { Component } from 'react';
import classNames from 'classnames';

import './PermanentResource.css';

export default class PermanentResource extends Component {
  render() {
    const cls = classNames({
      PermanentResource: true,
      Active: !!this.props.active,
      [this.props.className]: !!this.props.className
    });
    return (
      <div className={cls}>
        <div className="ResourcePrefix" />
        <div className="ResourceIcon" onClick={this.props.onClick}>{this.props.icon}</div>
        <div className="ResourceTitle" onClick={this.props.onClick}>{this.props.title}</div>
        <div className="ResourceSuffix" />
      </div>
    );
  }
}
