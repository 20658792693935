import React, { Component } from 'react';

import './IdentityCardForm.css';
import SmallDivision from '../../../../../common/components/SmallDivision';
import { FilesIcon } from '../../../../../common/icons';
import LabelInput from '../../../../../common/components/LabelInput';
import { Form, Upload, Button, Icon, message } from 'antd';
import MyPageButton from '../../../../../common/components/MyPageButton';
import moment from 'moment';
import PersonalDocumentItem from "../PersonalDocumentItem";
import ProvinceSelector from "../../ProfileView/ProfileViewForm/ProvinceSelector/ProvinceSelector";
import CitySelector from "../../ProfileView/ProfileViewForm/CitySelector/CitySelector";

class IdentityCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.mypageStore.loadIdentityCard().then(()=>{
      if(this.props.mypageStore.hasIdentityCard){
        this.setState(this.props.mypageStore.identityCard);
      }
      if(this.props.mypageStore.hasIdentityCard){
        if(!this.props.form.getFieldValue('city')){
          this.props.form.setFieldsValue({
            city: this.props.mypageStore.identityCard.city
          });
        }
        if(!this.props.form.getFieldValue('province')){
          this.props.form.setFieldsValue({
            province: this.props.mypageStore.identityCard.province
          });
        }
      }
    });
  }

  onChangeProvince = () => e =>{
    this.props.form.setFieldsValue({'city': undefined, 'province' : e});
  };

  handleSubmit = e => {
    e.preventDefault();

    const hideLoading = message.loading('loading', 0);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        message.error(this.props.translationsStore.translate('identityCardForm.errorMessage'));
        hideLoading();
        return;
      }

      this.validateState()
        .then(() => {
          const fileFront = (this.state.fileListFront || [])[0]
            , fileBack = (this.state.fileListBack || [])[0]
            , fileFaceAndDocument = (this.state.fileListFaceAndDocument || [])[0];

          let formData = new FormData();
          Object.keys(values)
            .map(key => ({ key, value: key === 'issue_date' || key === 'expire_date' ? moment(values[key]).format('YYYY-MM-DD') : values[key] }))
            .forEach(({ key, value }) => {
              formData.append(key, value);
            });

          if(this.state.fileListFront) {
            formData.append('fileFrontExtension', fileFront.name.substring(fileFront.name.lastIndexOf('.') + 1));
            formData.append('fileFront', fileFront);
          }
          if(this.state.fileListBack) {
            formData.append('fileBackExtension', fileBack.name.substring(fileBack.name.lastIndexOf('.') + 1));
            formData.append('fileBack', fileBack);
          }
          if(this.state.fileListFaceAndDocument) {
            formData.append('fileFaceAndDocumentExtension', fileFaceAndDocument.name.substring(fileFaceAndDocument.name.lastIndexOf('.') + 1));
            formData.append('fileFaceAndDocument', fileFaceAndDocument);
          }

          let allowedFileExtensions = ['jpg', 'jpeg', 'bmp', 'gif', 'png', 'pdf'];
          ['fileFrontExtension', 'fileBackExtension', 'fileFaceAndDocumentExtension'].forEach(key => {
            if(formData.get(key)) {
              if (!allowedFileExtensions.includes(formData.get(key))) {
                throw this.props.translationsStore.translate('identityCardForm.sectionIdentityCard.errorFileExtension');
              }
            }
          });

          new Promise( resolve => {
            if(this.props.mypageStore.hasIdentityCard){
              return this.props.mypageStore
                .updateIdentityCard(formData).then(res =>{
                  resolve (res);
                });
            }else{
              return this.props.mypageStore
                .createIdentityCard(formData).then(res =>{
                  resolve(res);
              });
            }})
            .then( res => {
              hideLoading();
              if(res.error){
                throw res.error;
              }
              message.success(this.props.translationsStore.translate('identityCardForm.successMessage'));
              this.setState(res);
            })
            .catch(() => {
              hideLoading();
              message.error(this.props.translationsStore.translate('identityCardForm.errorMessage'));
            });
        })
        .catch(err => {
          hideLoading();
          message.error(err);
        });
    });
  };

  validateState = () => new Promise((resolve, reject) => {
      let isValid = true;
      if(this.props.mypageStore.hasIdentityCard){
        resolve();
      }
      ['fileListFront', 'fileListBack', 'fileListFaceAndDocument'].forEach(key => {
        isValid = isValid && !!this.state[key] && this.state[key].length > 0;
      });

      if (!isValid) {
        reject(this.props.translationsStore.translate('identityCardForm.sectionIdentityCard.errorFileEmpty'));
      } else {
        resolve();
      }
    });

  beforeUpload = (file, fileList, parameterName) => {
    fileList = fileList.slice(-1);
    this.setState({ [parameterName]: fileList });
    return false;
  };

  disabledDateAfterToday = current => {
    return current && (current > moment().endOf('day') || current < moment().subtract(10,'year').endOf('day'));
  };

  disabledDateBeforeToday = current => {
    return current && (current < moment().endOf('day') || current > moment().add(10,'year').endOf('day'));
  };

  render() {
    const { fileListFront, fileListBack, fileListFaceAndDocument } = this.state;
    let { translationsStore, mypageStore } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
        <Form className={'PersonalDocumentsView'} onSubmit={this.handleSubmit}>
          <SmallDivision title={translationsStore.translate('identityCardForm.sectionIdentityCard.title')} icon={<FilesIcon />} style={{ marginBottom: 40 }} />
          <div className="FieldsRow">

            <Form.Item>
              {getFieldDecorator('document_number', {
                initialValue: this.state.document_number,
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  },
                  {
                    len: 10,
                    message: translationsStore.translate('identityCardForm.sectionIdentityCard.errorDocumentNumberLength')
                  }
                ]
              })(<LabelInput dataTestId={'testId_document_number'} label={translationsStore.translate('identityCardForm.sectionIdentityCard.labelDocumentNumber')} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('issue_date', {
                initialValue: this.state.issue_date ? moment(this.state.issue_date) : null,
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  }
                ]
              })(<LabelInput type="date" disabledDate={this.disabledDateAfterToday} label={translationsStore.translate('identityCardForm.sectionIdentityCard.labelDocumentIssueDate')} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('expire_date', {
                initialValue: this.state.expire_date ? moment(this.state.expire_date) : null,
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  }
                ]
              })(<LabelInput type="date" disabledDate={this.disabledDateBeforeToday} label={translationsStore.translate('identityCardForm.sectionIdentityCard.labelDocumentExpiryDate')} />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('province', {
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  },
                  {
                    len: 2,
                    message: translationsStore.translate('common.provinceCodeLen')
                  }
                ]
              })(
                <ProvinceSelector
                  label={translationsStore.translate('identityCardForm.sectionIdentityCard.labelProvince')}
                  onChange={this.onChangeProvince()}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('city', {
                rules: [
                  {
                    required: true,
                    message: translationsStore.translate('common.requiredField')
                  }
                ]
              })(
                <CitySelector
                  province={this.props.form.getFieldValue('province')}
                  label={translationsStore.translate('identityCardForm.sectionIdentityCard.issueCity')}
                />
              )}
            </Form.Item>
          </div>

          <div className={"ButtonContainer"}>

            <div>
              <Form.Item required={!mypageStore.hasIdentityCard}>
                <Upload listType="text" beforeUpload={(file, fileList) => this.beforeUpload(file, fileList, 'fileListFront')} fileList={fileListFront}>
                  <Button className={'UploadButton'}>
                    <Icon type="upload" /> {translationsStore.translate('identityCardForm.sectionIdentityCard.labelFrontImage')}
                  </Button>
                </Upload>
              </Form.Item>
              {!mypageStore.loadingIdentityCard && mypageStore.identityCard && (
                <PersonalDocumentItem title={translationsStore.translate('identityCardForm.sectionIdentityCard.labelFrontImage')} link={this.state.file_front_url} />
              )}
            </div>

            <div>
              <Form.Item required={!mypageStore.hasIdentityCard}>
                <Upload listType="text" beforeUpload={(file, fileList) => this.beforeUpload(file, fileList, 'fileListBack')} fileList={fileListBack}>
                  <Button className={'UploadButton'}>
                    <Icon type="upload" /> {translationsStore.translate('identityCardForm.sectionIdentityCard.labelBackImage')}
                  </Button>
                </Upload>
              </Form.Item>
              {!mypageStore.loadingIdentityCard && mypageStore.identityCard && (
                <PersonalDocumentItem title={translationsStore.translate('identityCardForm.sectionIdentityCard.labelBackImage')} link={this.state.file_back_url} />
              )}
            </div>

            <div>
              <Form.Item required={!mypageStore.hasIdentityCard}>
                <Upload listType="text"  beforeUpload={(file, fileList) => this.beforeUpload(file, fileList, 'fileListFaceAndDocument')} fileList={fileListFaceAndDocument}>
                  <Button className={'UploadButton'}>
                    <Icon type="upload" /> {translationsStore.translate('identityCardForm.sectionIdentityCard.labelFaceAndDocumentImage')}
                  </Button>
                </Upload>
              </Form.Item>
              {!mypageStore.loadingIdentityCard && mypageStore.identityCard && (
                <PersonalDocumentItem
                  title={translationsStore.translate('identityCardForm.sectionIdentityCard.labelFaceAndDocumentImage')}
                  link={this.state.file_face_and_document_url}
                />
              )}
            </div>

           </div>

          <div className={'buttonCenter'}>
            <Form.Item>
              <MyPageButton htmlType="submit" theme="primary" title={translationsStore.translate('profileView.submitButton')} solid />
            </Form.Item>
          </div>
        </Form>
    );
  }
}

export default Form.create({ name: 'identityCard' })(IdentityCardForm);
