import React from "react";
import { inject } from 'mobx-react';
import AirClubCard from "./AirClubCard";
import MoveInCard from "./MoveInCard";
import AirProductCard from "./AirProductCard";

const isMoveIn = (product) => {
  return product.features.some(feature => feature.node.name === 'movein')
};


const ProductCard = inject('productsStore')(({ productsStore, product, ...others }) => {

  if(productsStore.isMyAppAddon(product))
  {
    return <AirProductCard product={product} {...{ ...others, logo: 'my_app_addon' }} />;
  }

  if (productsStore.isAirClub(product)) {
    return <AirClubCard product={product} {...others} />;
  }
  if (productsStore.isAssistanceBase(product)) {
    const variant = 'default',
      primaryContent = 'ASSISTENZA BASE',
      secondaryContent = 'Abbonamento 12 mesi';
    return <AirProductCard product={product} {...{ ...others, logo: 'assinstance_base_12_months', variant, primaryContent, secondaryContent }} />;
  }
  if (productsStore.isAssistanceAdvanced(product)) {
    const variant = 'default',
      primaryContent = 'ASSISTENZA AVANZATA',
      secondaryContent = 'Abbonamento 12 mesi';
    return <AirProductCard product={product} {...{ ...others, logo: 'assinstance_advanced_12_months', variant, primaryContent, secondaryContent }} />;
  }
  if (productsStore.isCarlLocator(product)) {
    const variant = 'default',
      primaryContent = 'CAR LOCATOR',
      secondaryContent = 'Abbonamento 12 mesi';
    return <AirProductCard product={product} {...{ ...others, logo: 'car_locator_12_months', variant, primaryContent, secondaryContent }} />;
  }
  if (productsStore.isFindMechanic(product)) {
    const variant = 'default',
      primaryContent = 'VALERIO IL MECCANICO AMICO',
      secondaryContent = 'Abbonamento 12 mesi';
    return <AirProductCard product={product} {...{ ...others, logo: 'valerio_meccanico_amico_12_months', variant, primaryContent, secondaryContent }} />;
  }
  if (isMoveIn(product)) {
    return <MoveInCard product={product} {...others} />;
  }
  return <AirProductCard product={product} {...others} />
});

export default ProductCard;
