import {inject, observer} from "mobx-react";
import React, {Component, Fragment} from "react";
import {FilesLabeledOutlinedIcon} from "../../../../common/icons";
import AirModal from "../../../../common/components/AirModal";
import {api} from "../../../../services";
import LabelInput from "../../../../common/components/LabelInput";
import {Select} from "antd";
import MyPageButton from "../../../../common/components/MyPageButton";
import classNames from "classnames";
import {Media as BreakpointsMedia} from "react-breakpoints";

const {Option} = Select;

@inject('translationsStore', 'mypageStore')
@observer
class CustomDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      name: this.props.document.node.name,
      category: this.props.document.node.category,
      vehicle: this.props.vehicle.uuid,
      documentContent: undefined
    }
  }

  loadDocument = () => {
    this.setState(
      {modalVisible: true},
      () =>
        api('downloadDocument', this.props.document.node.document_url).then(response => {
          const type = this.props.document.node.document_url.endsWith(".pdf") ? "application/pdf" : `image/${this.props.document.node.document_url.split('.').pop()}`;
          var blob = new Blob([response], {type});
          var link = window.URL.createObjectURL(blob);

          this.setState({
            documentContent: link
          })
        })
    )
  }

  handleUpdate = async () => {
    await this.props.mypageStore.updateDocument(
      this.props.document.node.uuid,
      {
        category: this.state.category,
        name: this.state.name,
        vehicleUuid: this.state.vehicle
      }
    );

    this.setState(
      {modalVisible: false},
      () => this.props.mypageStore.loadDocuments()
    )
  }

  handleDelete = async () => {
    await this.props.mypageStore.deleteDocument(
      this.props.document.node.uuid
    );

    this.setState(
      {modalVisible: false},
      () => this.props.mypageStore.loadDocuments()
    )
  }

  render() {
    const { translationsStore } = this.props;

    return <Fragment>
      <a onClick={() => this.loadDocument()}>{translationsStore.translate('documentsView.view')}</a>
      <AirModal
        className="EmbeddedDocumentModal"
        width="90vw"
        title={this.props.document.node.name}
        visible={this.state.modalVisible}
        onCancel={() => this.setState({modalVisible: false})}
        destroyOnClose={true}
      >
        <BreakpointsMedia>
          {({_, currentBreakpoint}) => {
            return (
              <Fragment>
                <iframe src={this.state.documentContent} width="100%" height="540"/>
                <div className={classNames({
                  CustomDocumentFields: true,
                  [currentBreakpoint]: !!currentBreakpoint
                })}>
                  <LabelInput
                    label={"Nome"}
                    value={this.state.name}
                    onChange={(e) => this.setState({name: e.target.value})}
                  />
                  <div className="LabelInput">
                    <div>Tipologia</div>
                    <Select
                      style={{width: '100%'}}
                      value={this.state.category}
                      onChange={value => this.setState({category: value})}
                    >
                      <Option value='contract'>Contratto</Option>
                      <Option value='invoice'>Fattura</Option>
                      <Option value='document'>Generico</Option>
                    </Select>
                  </div>
                  <div className="LabelInput">
                    <div>Veicolo</div>
                    <Select
                      style={{width: '100%'}}
                      defaultActiveFirstOption={true}
                      value={this.state.vehicle}
                      onChange={value => this.setState({vehicle: value})}
                    >
                      {this.props.mypageStore.manageableVehicles.map(vehicle => (
                        <Option key={Math.random} value={vehicle.uuid}>{vehicle.license_plate}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={classNames({
                  CustomDocumentButtons: true,
                  [currentBreakpoint]: !!currentBreakpoint
                })}>
                  <MyPageButton
                    theme="delete"
                    solid
                    title={"ELIMINA"}
                    onClick={this.handleDelete}
                  />
                  <MyPageButton
                    theme="primary"
                    solid
                    title={"AGGIORNA"}
                    onClick={this.handleUpdate}
                  />
                </div>
              </Fragment>
            )
          }}
        </BreakpointsMedia>
      </AirModal>
    </Fragment>
  }
}

export default CustomDocument
