import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import AppBrand from '../AppBrand';

import './AuthDesktopContainer.css';

@inject('translationsStore', 'loginStore', 'authStore')
@observer
export default class AuthDesktopContainer extends Component {
  render() {
    const { welcomeNode } = this.props;
    const cls = classNames({
      AuthDesktopContainer: true,
      [this.props.className]: !!this.props.className
    });
    const brandStyle = { width: 64, height: 28, color: 'var(--action-brand-color)' };
    const productName = 'MyPage';
    return (
      <div className={cls}>
        <div className="LoginVertical ImageLayer">
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 40, marginLeft: 40 }}>
            <AppBrand style={brandStyle} />
          </div>
        </div>
        <div className="LoginVertical FormLayer">
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: 40,
              marginLeft: 40,
              color: 'var(--action-primary-color)',
              fontSize: 24,
              fontWeight: 600,
              letterSpacing: -0.2,
              lineHeight: '36px'
            }}
          >
            {productName}
          </div>
          <div>
            <div
              style={{
                borderBottom: '2px solid var(--action-primary-color)',
                paddingBottom: 8,
                color: 'var(--action-primary-color)',
                fontSize: 24,
                fontWeight: 600,
                letterSpacing: -0.2,
                lineHeight: '36px',
                marginBottom: 80
              }}
            >
              {welcomeNode}
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
