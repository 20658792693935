import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {inject, observer} from "mobx-react";
import K from '../../../../constants'
import {api} from "../../../../services";
import {PulseLoader} from "react-spinners";
import {message} from "antd";
import AirModal from "../../../../common/components/AirModal";
import MyPageCheckbox from "../../../../common/components/MyPageCheckbox";
import MyPageButton from "../../../../common/components/MyPageButton";
import Section from "../../../../common/components/Section";
import PaymentAgreements from "./PaymentAgreements";

@withRouter
@inject('mypageStore', 'productsStore', 'translationsStore')
@observer
class SlimpayIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      success: false,
      iframeContent: null,
      hasConfirmedAgreement: false
    }
  }

  isProductMovein = () => {
    return this.props.productsStore.selectedProduct
      ? this.props.productsStore.selectedProduct.features.some(feature => feature.node.name === 'movein')
      : false;
  };

  isProductMoveinRenewal = () => {
    const { selectedProduct } = this.props.productsStore;
    return selectedProduct
        ? selectedProduct.features.some(feature => feature.node.name === 'movein') && selectedProduct.category === 'renew'
        : false;
  };

  isProductRenew = () => {
    return this.props.productsStore.selectedProduct
      ? this.props.productsStore.selectedProduct.features.some(feature => feature.node.name === 'air') && this.props.productsStore.selectedProduct.category === 'renew'
      : false;
  };

  isAssistance = () => {
    const { productsStore } = this.props;
    const { selectedProduct } = productsStore;

    return selectedProduct
      ? productsStore.isAssistance(selectedProduct)
      : false;
  };

  isOptionalAssistance = () => {
    const { productsStore } = this.props;
    const { optionalServiceProduct } = productsStore;

    return optionalServiceProduct
        ? productsStore.isAssistance(optionalServiceProduct)
        : false;
  };

  componentDidMount() {
    api('getSlimpayIframe', this.props.mypageStore.remoteUserData.uuid)
      .then(result => {
        this.setState({
          loading: false,
          iframeContent: result.iframe
        }, () => {
          this.socket = new WebSocket(`wss://${K.API_URL.split("//")[1]}`);

          this.socket.addEventListener("open", event => {
            this.socket.send(JSON.stringify({type: "salutation", message: {id: this.props.mypageStore.remoteUserData.zuora_account_id}}));
          });

          this.socket.addEventListener("message", event => {
            try {
              this.onNotification(event.data)
            } catch (e) {
              console.error("error parsing data!");
            }
          });
        })
      })
  }

  onNotification = async (data) => {
    try {
      const { productsStore } = this.props;
      await productsStore.subscribeToProduct('SEPA');
      let route = `/thankyoupage`;
      if (this.isProductMoveinRenewal()) {
        route = `${route}?movein=true&renewal=true`;
      } else if (this.isProductMovein()) {
        route = `${route}?movein=true`;
      }
      this.props.history.push(route);
    } catch (error) {
      this.showErrorMessage();
    }
  };

  componentWillUnmount() {
    this.socket && this.socket.close();
  }

  showErrorMessage = () => {
    const { translationsStore } = this.props;
    message.error(translationsStore.translate('common.genericError'));
  };

  isAssistance = () => {
    const { productsStore } = this.props;
    const { selectedProduct } = productsStore;

    return selectedProduct
        ? productsStore.isAssistance(selectedProduct)
        : false;
  };

  render() {
    const {translationsStore, mypageStore} = this.props;
    const {
      hasConfirmedAgreements,
      checkedAgreedGeneralConditions,
      checkedAgreedRestrictiveClauses,
      visibleStandardFormContract
    } = this.state;
    const isCheckedConditionsRequiredForSelectedProduct = this.isProductMovein() || this.isProductRenew() || this.isAssistance();

    if (this.state.loading) {
      return <PulseLoader sizeUnit={'px'} size={20} color={'var(--action-primary-color)'} loading={true} />
    }

    let validAgreements = checkedAgreedGeneralConditions && checkedAgreedRestrictiveClauses;
    if (this.props.hasOwnProperty('addOnServiceAgreements') && this.isAssistance()) {
      const {
        addOnServiceAgreements: { airclub_general, airclub_general_articles, air_club_profiling, air_club_marketing, air_club_third_parties },
      } = this.props;
      validAgreements = validAgreements && air_club_profiling !== undefined && air_club_marketing !== undefined && air_club_third_parties !== undefined;
    }
    const disabledPaymentSubmit = !(validAgreements || !isCheckedConditionsRequiredForSelectedProduct);

    return (
      <Fragment>
        { !hasConfirmedAgreements ?
          <div>
            {this.isProductMovein() && <PaymentAgreements
              checkedAgreedGeneralConditions={checkedAgreedGeneralConditions}
              checkedAgreedRestrictiveClauses={checkedAgreedRestrictiveClauses}
              onChangeAgreedGeneralConditions={checked => {
                this.setState({ checkedAgreedGeneralConditions: checked });
              }}
              onChangeAgreedRestrictiveClauses={checked => {
                this.setState({ checkedAgreedRestrictiveClauses: checked });
              }}
            />}
            {this.isProductRenew() && <PaymentAgreements
              checkedAgreedGeneralConditions={checkedAgreedGeneralConditions}
              checkedAgreedRestrictiveClauses={checkedAgreedRestrictiveClauses}
              onChangeAgreedGeneralConditions={checked => {
                this.setState({ checkedAgreedGeneralConditions: checked });
              }}
              onChangeAgreedRestrictiveClauses={checked => {
                this.setState({ checkedAgreedRestrictiveClauses: checked });
              }}
              isRenewProduct={this.isProductRenew()}
            />}
            {this.isAssistance() && <PaymentAgreements
              checkedAgreedGeneralConditions={checkedAgreedGeneralConditions}
              checkedAgreedRestrictiveClauses={checkedAgreedRestrictiveClauses}
              onChangeAgreedGeneralConditions={checked => {
                this.setState({ checkedAgreedGeneralConditions: checked });
              }}
              onChangeAgreedRestrictiveClauses={checked => {
                this.setState({ checkedAgreedRestrictiveClauses: checked });
              }}
              hideSubTitle={true}
              generalConditionsDocumentSource={K.POLICY_BASE_URL.concat('condizioni-generali-servizi-aggiuntivi-2022-09-23.pdf')}
              labelRestrictiveConditions={translationsStore.translate('installerView.sectionAgreements.labelRestrictiveClausesAdditionalProduct')}
              labelGeneralConditionPrefix={translationsStore.translate('installerView.sectionAgreements.labelAdditionalGeneralConditionsPrefix')}
              labelGeneralConditionClickable={translationsStore.translate('installerView.sectionAgreements.labelGeneralConditions')}
              labelGeneralConditionSuffix={translationsStore.translate('installerView.sectionAgreements.labelAdditionalGeneralConditionsSuffix')}
              {...((!!this.props.addOnServiceAgreements && !!this.props.onAddOnServiceAgreementsChange)
                ? {
                  addOnServiceAgreements: this.props.addOnServiceAgreements,
                  onAddOnServiceAgreementsChange: this.props.onAddOnServiceAgreementsChange
                }
                : {})}
            />}
            <MyPageButton
              className="PaymentPane"
              theme="primary"
              style={{ marginTop: 40, height: 40, fontSize: 16 }}
              solid
              title={translationsStore.translate('installerView.labelPaymentProceed')}
              disabled={disabledPaymentSubmit}
              onClick={() => this.setState({hasConfirmedAgreements: true})}
            />
          </div>
           : (
            <Section
              className="SlimpayIframeContainer"
            >
              <div
                className="SlimpayIframe"
                dangerouslySetInnerHTML={{ __html: this.state.iframeContent}}
                //height={600}
              />
            </Section>
          )
        }
      </Fragment>
    )
  }
}

export default SlimpayIframe;
