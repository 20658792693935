import React from 'react';

const Other76 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 20 22" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
      <path d="M15.9715 11.2432C15.9564 11.2619 15.9431 11.282 15.2093 12.3956C14.8927 12.8761 14.4419 13.5602 13.7989 14.5357C16.1003 14.8776 20 15.8934 20 18.1328C20 20.6907 14.8011 22 10 22C7.43249 22 5.00907 21.6514 3.17609 21.0186C0.551022 20.1122 0 18.949 0 18.1328C0 15.8938 3.90431 14.8769 6.20098 14.5357L4.05289 11.277C3.1424 10.0586 2.66667 8.60664 2.66667 7.13281C2.66667 3.21544 5.96258 0 10 0C14.0506 0 17.3333 3.2218 17.3333 7.13281C17.3333 8.60759 16.8674 10.0225 15.9858 11.2247C15.9783 11.235 15.9751 11.2389 15.9724 11.2422L15.9715 11.2432ZM5.14447 10.5354C5.15247 10.546 5.16011 10.5569 5.16745 10.5679L10.0008 17.9003C11.2921 15.9414 12.2403 14.5026 12.9373 13.445L12.9382 13.4436C14.849 10.5443 14.8707 10.5112 14.8962 10.4814L14.8971 10.4804C14.8993 10.4778 14.9017 10.475 14.9054 10.4702C15.6221 9.48909 16.0008 8.33534 16.0008 7.13281C16.0008 3.91054 13.3092 1.28906 10.0008 1.28906C6.68554 1.28906 4.00082 3.90938 4.00082 7.13281C4.00082 8.3334 4.38682 9.52768 5.14447 10.5354ZM10.001 20.711C4.75678 20.711 1.33429 19.2207 1.33429 18.1328C1.33429 17.4017 3.09287 16.1995 6.98976 15.7308L9.43873 19.446C9.56113 19.6318 9.77313 19.7442 10.0009 19.7442C10.2287 19.7442 10.4407 19.6317 10.5631 19.446L13.012 15.7308C16.909 16.1995 18.6676 17.4017 18.6676 18.1328C18.6676 19.2114 15.2759 20.711 10.001 20.711ZM10.0007 3.91016C8.16269 3.91016 6.66736 5.35584 6.66736 7.13281C6.66736 8.90979 8.16269 10.3555 10.0007 10.3555C11.8387 10.3555 13.334 8.90979 13.334 7.13281C13.334 5.35584 11.8387 3.91016 10.0007 3.91016ZM9.99896 9.06641C8.89616 9.06641 7.99896 8.199 7.99896 7.13281C7.99896 6.06663 8.89616 5.19922 9.99896 5.19922C11.1018 5.19922 11.999 6.06663 11.999 7.13281C11.999 8.199 11.1018 9.06641 9.99896 9.06641Z" fill="currentColor"/>
  </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <Other76 svgRef={ref} {...props} />);
export default ForwardRef;
