import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import VehicleSelector from "../../../../common/components/VehicleSelector";

import MyPageContainer from "../../../../common/components/MyPageContainer";
import Section from "../../../../common/components/Section";
import {HelpOutlinedIcon, DoneIcon} from "../../../../common/icons";
import MyPageButton from "../../../../common/components/MyPageButton";

import './AirRenewalView.css'
import {Checkbox} from "antd";

const FeatureItem = ({children}) => (
  <div className="ListItemContainer">
    <div className="ListIcon"><DoneIcon width={24} h2eight={24} /></div>
    <p className="Description">{children}</p>
  </div>
)

@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class AirRenewalView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRatePlan: null
    }

    this.props.productsStore.setOptionalService(undefined);
    this.props.productsStore.setOptionalServiceProduct(undefined);
  }

  getProductAnnualRatePlan = () =>
    this.props.productsStore.selectedProduct.productRatePlans.find(ratePlan => ratePlan.productRatePlanCharges[0].billingPeriod === 'Annual')

  getProductMonthRatePlan = () =>
    this.props.productsStore.selectedProduct.productRatePlans.find(ratePlan => ratePlan.productRatePlanCharges[0].billingPeriod === 'Month')

  getRateplanPrice = (productRatePlan) =>
    Number.parseFloat(productRatePlan.productRatePlanCharges.reduce(
      (acc, currentCharge) => acc+currentCharge.pricing[0].price,
      0
    )).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2});

  getRateplanPriceWithDiscount = (productRatePlan) => {
    const {productsStore} = this.props;

    const priceWithoutDiscount = Number.parseFloat(this.getRateplanPrice(productRatePlan));

    const discount = productsStore.getDiscountFromProductUuid(productsStore.selectedProduct && productsStore.selectedProduct.uuid);

    if (discount) {
     const discountedPrice =  productsStore.getDiscountedPrice(discount && discount.discountRatePlanId, priceWithoutDiscount);

     return discountedPrice && discountedPrice.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

   return undefined;
  }

  onSubmit = () => {
    const { mypageStore, productsStore, history } = this.props;

    productsStore.setSelectedProductRateplan(this.state.selectedRatePlan)
    let hasAssistanceSubscribed = mypageStore.hasAssistance(mypageStore.selectedManageableVehicle);
    let productAssistanceBase = productsStore.availableProducts.filter(ap => productsStore.isAssistanceBase(ap)).slice().shift();
    let availableAssistanceBase = productAssistanceBase !== undefined;
    if (!hasAssistanceSubscribed && availableAssistanceBase ) {
      // INFO no assistance featured service subscribed, assistenza base can be suggested for purchase
      history.push('/air-renew-suggest-assistance');
    } else {
      history.push('/payments');
    }
  }

  render() {
    const { mypageStore, productsStore, translationsStore, optional } = this.props;

    const viewGroupItems = [
      { value: 'vehicles', title: translationsStore.translate('groupViews.myair.vehicles') },
      { value: 'documents', title: translationsStore.translate('groupViews.myair.documents') },
      // { value: 'assets', title: translationsStore.translate('groupViews.myair.assets') },
      { value: 'services', title: translationsStore.translate('groupViews.myair.services') }
    ];
    const defaultGroupItem = 'services';

    if(!productsStore.selectedProduct) {
      return <Redirect to="/" />;
    }

    const annualDiscountedPrice = this.getRateplanPriceWithDiscount(this.getProductAnnualRatePlan());
    const monthlyDiscountedPrice = this.getRateplanPriceWithDiscount(this.getProductMonthRatePlan());

    return (
      <MyPageContainer className='AirRenewalView' dataCollection={this.props.dataCollection}>

      <VehicleSelector disabled={true} />

      <Section>
        {(annualDiscountedPrice !== undefined || monthlyDiscountedPrice !== undefined) && <div className='DiscountedBadgeContainer'><div className={'DiscountedBadge'}>{translationsStore.translate('servicesView.serviceCard.discount.sale')}</div></div>}
        <header>
          <h2>Rinnovo dei servizi AIR</h2>
          <h3>Abbonamento annuale</h3>
        </header>

        <div className="RenewalFeatures">
          {translationsStore.translate('servicesView.serviceCard.features.air')
            .map((feature, index) =>
            <FeatureItem key={index}>{feature}</FeatureItem>
          )}
        </div>

        <div className="RenewalPlanSelection">
          <div className="RenewalPlan">
            <Checkbox
              onChange={() => this.setState({selectedRatePlan: this.getProductAnnualRatePlan()})}
              checked={this.state.selectedRatePlan && this.state.selectedRatePlan.id === this.getProductAnnualRatePlan().id}
            />
            <div className="RenewalPrice">
              {annualDiscountedPrice !== undefined && <div className='DiscountPrice'>{annualDiscountedPrice} €</div>}
              <h2><span className={monthlyDiscountedPrice? 'StrikeThroughPrice': ''}>{this.getRateplanPrice(this.getProductAnnualRatePlan())}€</span> <span className="taxes">iva inclusa</span></h2>
              <h3>per un anno</h3>
            </div>
          </div>
          <div className="RenewalPlan">
            <Checkbox
              onChange={() => this.setState({selectedRatePlan: this.getProductMonthRatePlan()})}
              checked={this.state.selectedRatePlan && this.state.selectedRatePlan.id === this.getProductMonthRatePlan().id}
            />
            <div className="RenewalPrice">
              {monthlyDiscountedPrice !== undefined && <div className='DiscountPrice'>{monthlyDiscountedPrice} €</div>}
              <h2><span className={monthlyDiscountedPrice? 'StrikeThroughPrice': ''}>{this.getRateplanPrice(this.getProductMonthRatePlan())}€</span> <span className="taxes">iva inclusa</span></h2>
              <h3>al mese per un anno</h3>
            </div>
          </div>
        </div>

        <div className="ReviewFooter">
          <div className="ReviewInfo">
            <div className="ReviewFooterIcon"><HelpOutlinedIcon width={20} height={20} /></div>Se il servizio deve ancora scadere, il rinnovo partirà dalla data di scadenza del vecchio abbonamento.
          </div>
          <MyPageButton
            theme="primary"
            solid
            disabled={!this.state.selectedRatePlan}
            title={"Procedi con il rinnovo"}
            onClick={this.onSubmit}
          />
        </div>
      </Section>

      </MyPageContainer>
    );
  }
}

export default AirRenewalView;
