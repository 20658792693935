import React from 'react';

const SvgFiles30 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 16 20" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
      <path d="M4.54842 0H14.0814C15.1393 0 16 0.841133 16 1.875V15.625C16 16.6589 15.1393 17.5 14.0814 17.5H13.3702V18.6428C13.3702 19.3912 12.7821 20 12.0591 20H1.31113C0.588151 20 0 19.3912 0 18.6428V3.85723C0 3.10883 0.588151 2.5 1.31113 2.5H2.62977V1.875C2.62977 0.841133 3.49045 0 4.54842 0ZM12.0591 18.75C12.1162 18.75 12.1627 18.7019 12.1627 18.6428V3.85723C12.1627 3.79809 12.1162 3.75 12.0591 3.75H1.31113C1.254 3.75 1.20755 3.79809 1.20755 3.85723V18.6428C1.20755 18.7019 1.254 18.75 1.31113 18.75H12.0591ZM14.0814 16.25C14.4734 16.25 14.7925 15.9696 14.7925 15.625V1.875C14.7925 1.53039 14.4734 1.25 14.0814 1.25H4.54842C4.15634 1.25 3.83732 1.53039 3.83732 1.875V2.5H12.0591C12.7821 2.5 13.3702 3.10883 13.3702 3.85723V16.25H14.0814ZM3.92453 8.4375H9.66038C9.99381 8.4375 10.2642 8.15766 10.2642 7.8125C10.2642 7.46734 9.99381 7.1875 9.66038 7.1875H3.92453C3.59109 7.1875 3.32075 7.46734 3.32075 7.8125C3.32075 8.15766 3.59109 8.4375 3.92453 8.4375ZM9.66038 10.9375H3.92453C3.59109 10.9375 3.32075 10.6577 3.32075 10.3125C3.32075 9.96734 3.59109 9.6875 3.92453 9.6875H9.66038C9.99381 9.6875 10.2642 9.96734 10.2642 10.3125C10.2642 10.6577 9.99381 10.9375 9.66038 10.9375ZM3.92453 13.4375H9.66038C9.99381 13.4375 10.2642 13.1577 10.2642 12.8125C10.2642 12.4673 9.99381 12.1875 9.66038 12.1875H3.92453C3.59109 12.1875 3.32075 12.4673 3.32075 12.8125C3.32075 13.1577 3.59109 13.4375 3.92453 13.4375Z" fill="currentColor"/>
    </svg>
);

const ForwardRef = React.forwardRef((props, ref) => <SvgFiles30 svgRef={ref} {...props} />);
export default ForwardRef;
