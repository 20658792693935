import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import AuthDesktopContainer from '../../../../../common/components/AuthDesktopContainer';
import MyPageButton from '../../../../../common/components/MyPageButton';
import LabelInput from '../../../../../common/components/LabelInput';
import MyPageAlert from '../../../../../common/components/MyPageAlert';
import { ErrorIcon, InfoIcon } from '../../../../../common/icons';

import './DesktopForgottenPassword.css';

@inject('translationsStore', 'loginStore', 'authStore')
@observer
class DesktopForgottenPassword extends Component {
  handleEnterFirst = e => {
    const { loginStore } = this.props;
    if (e.key === 'Enter') {
      loginStore.forgotPasswordProceed();
    }
  };

  handleEnterSecond = e => {
    const { loginStore } = this.props;
    if (e.key === 'Enter') {
      loginStore.loginSubmit();
    }
  };

  render() {
    const { translationsStore, loginStore } = this.props;
    const errorUsername = loginStore.getForgottenTouched('username')
      ? loginStore.forgottenError('username')
        ? translationsStore.translate('login.forgotten.errors.local.missingUsername')
        : undefined
      : undefined;
    const errorVerificationCode = loginStore.getForgottenTouched('verificationCode')
      ? loginStore.forgottenError('verificationCode')
        ? translationsStore.translate('login.forgotten.errors.local.missingVerificationCode')
        : undefined
      : undefined;
    const errorNewPassword = loginStore.getForgottenTouched('newPassword')
      ? loginStore.forgottenError('newPassword')
        ? translationsStore.translate('login.forgotten.errors.local.missingNewPassword')
        : undefined
      : undefined;

    const stepOneCleared = loginStore.getForgottenData('stepOneCleared');
    const stepTwoCleared = loginStore.getForgottenData('stepTwoCleared');

    const cls = classNames({
      DesktopForgottenPassword: true
    });

    let welcomeNode = translationsStore.translate('login.recoveryStepThird.labelWelcome');
    if (!stepOneCleared) {
      welcomeNode = translationsStore.translate('login.recoveryStepFirst.labelWelcome');
    } else if (stepOneCleared && !stepTwoCleared) {
      welcomeNode = translationsStore.translate('login.recoveryStepSecond.labelWelcome');
    }

    const stepOneRemoteError = loginStore.getForgottenData('stepOneRemoteError'),
      stepOneErrorMessage = translationsStore.translate(`login.forgotten.errors.remoteTitle`),
      keyOne = `login.forgotten.errors.remote.${stepOneRemoteError?.code}`;
    let stepOneErrorDescription = translationsStore.translate(keyOne);
    if (stepOneErrorDescription === keyOne) {
      stepOneErrorDescription = translationsStore.translate('login.forgotten.errors.remoteMessageDefault');
    }

    const stepTwoRemoteError = loginStore.getForgottenData('stepTwoRemoteError'),
      stepTwoErrorMessage = translationsStore.translate(`login.forgotten.errors.remoteTitle`),
      keyTwo = `login.forgotten.errors.remote.${stepTwoRemoteError?.code}`;
    let stepTwoErrorDescription = translationsStore.translate(keyTwo);
    if (stepTwoErrorDescription === keyTwo) {
      stepTwoErrorDescription = translationsStore.translate('login.forgotten.errors.remoteMessageDefault');
    }

    return (
      <AuthDesktopContainer
        className={cls}
        welcomeNode={welcomeNode}
      >
        {!stepOneCleared && (
          <div className="RecoverySteps RecoveryStepFirst">
            <LabelInput
              label={translationsStore.translate('login.sectionFirst.labelUsername')}
              value={loginStore.getForgottenFields('username')}
              validateStatus={errorUsername ? 'error' : undefined}
              help={errorUsername}
              onChange={e => {
                loginStore.setForgottenFields({ username: e.target.value });
                loginStore.setForgottenTouched({ username: true });
                loginStore
                  .validateForgottenFields()
                  .then(() => {})
                  .catch(() => {});
              }}
              onKeyUp={this.handleEnterFirst}
              style={{ marginBottom: 20 }}
            />
            {loginStore.getForgottenData('stepOneRemoteError') !== undefined && (
              <MyPageAlert
                style={{ marginTop: 16 }}
                message={stepOneErrorMessage}
                description={stepOneErrorDescription}
                type="error"
                closable
                showIcon
                icon={<ErrorIcon width={20} height={20} />}
              />
            )}
            <div className="TwoColumns" style={{ marginTop: 54 }}>
              <MyPageButton
                className=""
                theme="primary"
                style={{ height: 40, fontSize: 16, flex: '1 0 0', marginRight: 20 }}
                outlined
                title={translationsStore.translate('login.forgotten.labelGotoLogin')}
                onClick={this.props.handlers.handleGotoLogin}
              />
              <MyPageButton
                className=""
                theme="primary"
                style={{ height: 40, fontSize: 16, flex: '1 0 0', marginLeft: 20 }}
                solid
                title={translationsStore.translate('login.forgotten.labelProceed')}
                onClick={loginStore.forgotPasswordProceed}
                disabled={loginStore.getForgottenFields('username') === ''}
              />
            </div>
          </div>
        )}
        {stepOneCleared && !stepTwoCleared && (
          <div className="RecoverySteps RecoveryStepSecond">
            <MyPageAlert
              style={{ marginBottom: 56 }}
              // message={''}
              description={translationsStore.translate('login.forgotten.textCodeSent')}
              type="info"
              showIcon
              icon={<InfoIcon width={20} height={20} />}
            />
            <LabelInput
              label={translationsStore.translate('login.forgotten.steps.labelUsername')}
              value={loginStore.getForgottenFields('username')}
              validateStatus={errorUsername ? 'error' : undefined}
              help={errorUsername}
              onChange={e => {
                loginStore.setForgottenFields({ username: e.target.value });
                loginStore.setForgottenTouched({ username: true });
                loginStore
                  .validateForgottenFields()
                  .then(() => {})
                  .catch(() => {});
              }}
              onKeyUp={this.handleEnterSecond}
              style={{ marginBottom: 20 }}
            />
            <LabelInput
              label={translationsStore.translate('login.forgotten.steps.labelVerificationCode')}
              value={loginStore.getForgottenFields('verificationCode')}
              validateStatus={errorVerificationCode ? 'error' : undefined}
              help={errorVerificationCode}
              onChange={e => {
                loginStore.setForgottenFields({ verificationCode: e.target.value });
                loginStore.setForgottenTouched({ verificationCode: true });
                loginStore
                  .validateForgottenFields()
                  .then(() => {})
                  .catch(() => {});
              }}
              onKeyUp={this.handleEnterSecond}
              style={{ marginBottom: 20 }}
            />
            <LabelInput
              type="password"
              label={translationsStore.translate('login.forgotten.steps.labelNewPassword')}
              value={loginStore.getForgottenFields('newPassword')}
              validateStatus={errorNewPassword ? 'error' : undefined}
              help={errorNewPassword}
              onChange={e => {
                loginStore.setForgottenFields({ newPassword: e.target.value });
                loginStore.setForgottenTouched({ newPassword: true });
                loginStore
                  .validateForgottenFields()
                  .then(() => {})
                  .catch(() => {});
              }}
              onKeyUp={this.handleEnterSecond}
              style={{ marginBottom: 20 }}
            />
            {loginStore.getForgottenData('stepTwoRemoteError') !== undefined && (
              <MyPageAlert
                style={{ marginTop: 16 }}
                message={stepTwoErrorMessage}
                description={stepTwoErrorDescription}
                type="error"
                closable
                showIcon
                icon={<ErrorIcon width={20} height={20} />}
              />
            )}
            <div className="TwoColumns" style={{ marginTop: 54 }}>
              <MyPageButton
                className=""
                theme="primary"
                style={{ height: 40, fontSize: 16, flex: '1 0 0', marginRight: 20 }}
                outlined
                title={translationsStore.translate('login.forgotten.labelGotoStepOne')}
                onClick={this.props.handlers.handleGotoStepOne}
              />
              <MyPageButton
                className=""
                theme="primary"
                style={{ height: 40, fontSize: 16, flex: '1 0 0', marginLeft: 20 }}
                solid
                title={translationsStore.translate('login.forgotten.labelConfirm')}
                onClick={loginStore.handleForgotPasswordSubmit}
                disabled={
                  loginStore.getForgottenFields('username') === '' ||
                  loginStore.getForgottenFields('verificationCode') === '' ||
                  loginStore.getForgottenFields('newPassword') === ''
                }
              />
            </div>
          </div>
        )}
        {stepOneCleared && stepTwoCleared && (
          <div className="RecoverySteps RecoveryStepThird">
            <MyPageAlert
              style={{ marginBottom: 56 }}
              // message={''}
              description={translationsStore.translate('login.forgotten.textDone')}
              type="info"
              showIcon
              icon={<InfoIcon width={20} height={20} />}
            />
            <MyPageButton
              className=""
              theme="primary"
              style={{ height: 40, fontSize: 16, flex: '1 0 0' }}
              solid
              title={translationsStore.translate('login.forgotten.labelDone')}
              onClick={this.props.handlers.handleDone}
            />
          </div>
        )}
      </AuthDesktopContainer>
    );
  }
}

export default DesktopForgottenPassword;
