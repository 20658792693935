import K from '../constants';
import restHelpers from '../helpers/restHelper';

const getHistoryFromIdAdesioneFn = (idAdesione) => restHelpers.withBearer.get(`${K.API_URL}/move-in/memberships/${idAdesione}/code-history`, {});

const getInstallersByProvinceFn = (province) => restHelpers.withBearer.get(`${K.API_URL}/installers-with-available-assets?province=${province.prov}&movein=true`, {});
const chooseInstallerFn = (userUuid, vehicleUuid, installerUuid) =>
  restHelpers.withBearer.post(
    `${K.API_URL}/users/${userUuid}/direct_manageable/vehicles/${vehicleUuid}/installers`,
      { data: {installer_identifier: installerUuid}}
      );


export default {
  getHistoryFromIdAdesione: getHistoryFromIdAdesioneFn,
  getInstallersByProvince: getInstallersByProvinceFn,
  chooseInstaller: chooseInstallerFn
};
