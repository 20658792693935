import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { mypageHelper } from '../../../helpers';
import { Drawer } from 'antd';
import SmallHeader from '../SmallHeader';

import './MyPageMobileContainer.css';
import AppBrand from '../AppBrand';
import PermanentResource from '../PermanentGroup/PermanentResource';
import {
  CarIcon,
  CalendarIcon,
  CrownOutlinedIcon,
  DoorIcon,
  FilesLabeledOutlinedIcon,
  FoldersOutlinedIcon,
  SupportIcon,
  UserBigIcon
} from '../../icons';
import PermanentGroup from '../PermanentGroup';
import PermanentLeft from "../PermanentLeft";

@withRouter
@inject('translationsStore', 'mypageStore')
@observer
export default class MyPageMobileContainer extends Component {
  onLeftClick = () => {
    const { mypageStore } = this.props;
    mypageStore.setVisibleLeftRegion(true);
  };

  onRightClick = () => {
    const { mypageStore } = this.props;
    isMobile && !mypageStore.remoteUserIsFromMoVeInProgram && mypageHelper.handleDeepLink('myair://app.myair.io/');
  };

  onDrawerClose = () => {
    const { mypageStore } = this.props;
    mypageStore.setVisibleLeftRegion(false);
  };

  render() {
    const { translationsStore, mypageStore } = this.props;
    let { dataCollection } = this.props;
    const cls = classNames({
      MyPageMobileContainer: true,
      [this.props.className]: !!this.props.className
    });
    const containerClassName = classNames({
      AppContent: true
    });
    return (
      <Fragment>
        <Drawer className={'MobileLeftMenu'} width={264} placement={'left'} closable={false} onClose={this.onDrawerClose} visible={mypageStore.visibleLeftRegion}>
          <PermanentLeft dataCollection={dataCollection}/>
        </Drawer>
        <div className={containerClassName}>
          <SmallHeader onLeftClick={this.onLeftClick} onRightClick={this.onRightClick} />
          <div className={cls}>{this.props.children}</div>
        </div>
      </Fragment>
    );
  }
}
