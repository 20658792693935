import MyPageButton from '../../../../common/components/MyPageButton';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import React, { Component } from 'react';
import { Radio, message } from 'antd';
import { inject, observer } from 'mobx-react';
import './PrivacyConsentModal.css';
import AirModal from '../../../../common/components/AirModal';
import K from '../../../../constants';

@inject('translationsStore', 'mypageStore')
@observer
class PrivacyConsentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDocumentSide: this.props.isDocumentSide,
      isMandatory: this.props.isMandatory ? this.props.isMandatory : false,
      isVisible: this.props.isVisible,
      agreement_profiling: this.props.mypageStore.selectedManageableVehicle.agreement_profiling,
      agreement_marketing: this.props.mypageStore.selectedManageableVehicle.agreement_marketing,
      agreement_third_parties: this.props.mypageStore.selectedManageableVehicle.agreement_third_parties
    };
  }

  componentDidUpdate = () => {
    if (this.props.hasOwnProperty('isVisible') && this.props.isVisible !== this.state.isVisible) {
      this.setState({
        isVisible: this.props.isVisible,
        isDocumentSide: this.props.isDocumentSide,
        agreement_profiling: this.props.mypageStore.selectedManageableVehicle.agreement_profiling,
        agreement_marketing: this.props.mypageStore.selectedManageableVehicle.agreement_marketing,
        agreement_third_parties: this.props.mypageStore.selectedManageableVehicle.agreement_third_parties
      });
    }
  };

  onSubmit = async () => {
    if (this.state.agreement_profiling !== undefined && this.state.agreement_marketing !== undefined && this.state.agreement_third_parties !== undefined) {
      const hideLoading = message.loading('loading', 0);
      try {
        await this.props.mypageStore.updateAirCustomerConsent(this.state.agreement_profiling, this.state.agreement_marketing, this.state.agreement_third_parties);
        message.success(this.props.translationsStore.translate('PrivacyConsentModal.success'));
      } catch (e) {
        message.error(this.props.translationsStore.translate('common.genericError'));
      }
      hideLoading();
      this.closeModal();
    } else {
      message.error(this.props.translationsStore.translate('PrivacyConsentModal.formError'));
    }
  };

  closeModal = () => {
    if (this.props.onCancelAction) {
      this.props.onCancelAction();
    }
  };

  onCancel = () =>{
    if (!this.state.isMandatory) {
      this.closeModal();
    }
  };

  onPolicyChange = policy => e => {
    this.setState({ [policy]: e.target.value });
  };

  render() {
    const { translationsStore } = this.props;
    return (
      <AirModal
        className="EmbeddedDocumentModal"
        width="90vw"
        visible={!this.props.mypageStore.remoteUserIsFromMoVeInProgram && this.state.isVisible}
        onCancel={() => {
          this.onCancel();
        }}
        destroyOnClose={true}
      >
        {this.state.isDocumentSide ? (
          <div>
            <iframe src={K.POLICY_BASE_URL.concat('privacy-policy-2022-09-23.pdf')} width="100%" height="540" />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
              <MyPageButton
                theme="primary"
                solid
                style={{ width: 'initial' }}
                title={'Gestisci Consensi'}
                onClick={() => {
                  this.setState({ isDocumentSide: false });
                }}
              />
            </div>
          </div>
        ) : (
          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {
              const classes = breakpoints[currentBreakpoint] >= breakpoints.desktop ? 'PrivacyUpdate desktop' : 'PrivacyUpdate';

              return (
                <div className={classes}>
                  <p className={'LinkToDocument'}>
                    {translationsStore.translate('PrivacyConsentModal.declaration')}
                    <a onClick={() => this.setState({ isDocumentSide: true })}>informativa privacy di air</a>
                  </p>

                  <h3 className="PrivacyTitle">{translationsStore.translate('PrivacyConsentModal.personalDataConsentSubTitle')}</h3>
                  <p className="AgreementItem" style={{ marginBottom: '0px' }}>
                    {translationsStore.translate('PrivacyConsentModal.subTitleText')}
                  </p>
                  <p className="AgreementItem">{translationsStore.translate('PrivacyConsentModal.personalDataAgreement')}</p>
                  <Radio.Group className="PrivacyUpdateForm" onChange={this.onPolicyChange('agreement_profiling')} value={this.state.agreement_profiling}>
                    <Radio value={true}>Acconsento</Radio>
                    <Radio value={false}>Non acconsento</Radio>
                  </Radio.Group>

                  <p className="AgreementItem">{translationsStore.translate('PrivacyConsentModal.promotionalAgreement')}</p>
                  <Radio.Group className="PrivacyUpdateForm" onChange={this.onPolicyChange('agreement_marketing')} value={this.state.agreement_marketing}>
                    <Radio value={true}>Acconsento</Radio>
                    <Radio value={false}>Non acconsento</Radio>
                  </Radio.Group>

                  <p className="AgreementItem">{translationsStore.translate('PrivacyConsentModal.thirdPartyAgreement')}</p>
                  <Radio.Group className="PrivacyUpdateForm" onChange={this.onPolicyChange('agreement_third_parties')} value={this.state.agreement_third_parties}>
                    <Radio value={true}>Acconsento</Radio>
                    <Radio value={false}>Non acconsento</Radio>
                  </Radio.Group>

                  <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: 8 }}>
                    {!this.state.isMandatory && <MyPageButton theme="primary" solid title={'Annulla'} onClick={this.onCancel} />}
                    <MyPageButton theme="primary" solid title={'Salva le Modifiche'} onClick={this.onSubmit} />
                  </div>
                </div>
              );
            }}
          </BreakpointsMedia>
        )}
      </AirModal>
    );
  }
}

export default PrivacyConsentModal;
