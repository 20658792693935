import React, { Component } from 'react';
import classNames from 'classnames';

import './PermanentGroup.css';

export default class PermanentGroup extends Component {
  render() {
    const cls = classNames({
      PermanentGroup: true,
      [this.props.className]: !!this.props.className
    });

    const isSelected = this.props.value === this.props.active

    return <div className={cls}>
      <div
        className={classNames({
          GroupTitle: true,
          active: isSelected
        })}
        onClick={() => {
          this.props.onMenuSelect(this.props.value);
          if(this.props.isSingleItem) {
            this.props.routeHandler();
          }
        }}
      >
        <div className="ResourceIcon">{this.props.icon}</div>
        <div>{this.props.title}</div>
      </div >
      {!this.props.isSingleItem && <div
        className={classNames({
          GroupContainer: true,
          open: isSelected
        })}
      >
        {this.props.children}
      </div>}
    </div>;
  }
}
