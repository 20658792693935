import React from 'react';
import { inject, observer } from 'mobx-react';
import { message } from 'antd';
import { fromBuffer } from 'file-type';
import { api } from '../../../../services';
import { FilesLabeledIcon } from '../../../../common/icons';

const PersonalDocumentItem = ({ title, link, translationsStore, onClick }) => {
  const onDownloadReport = () => {
    api('downloadDocument', link).then(response => {
      (async () => {

        try {
          const fileName = link.substring(link.lastIndexOf('/') + 1);
          const { mime: mimeType } = await fromBuffer(response);

          const blob = new Blob([response], { type: mimeType }),
            linkElement = document.createElement('a'),
            url = URL.createObjectURL(blob);

          linkElement.setAttribute('href', url);
          linkElement.setAttribute('download', fileName);
          linkElement.style.visibility = 'hidden';
          document.body.appendChild(linkElement);
          linkElement.click();
          document.body.removeChild(linkElement);
        } catch (error) {
          message.error(translationsStore.translate('common.genericError'));
        }
      })();
    });
  };

  const clickHandler = link !== undefined ? onDownloadReport : onClick !== undefined ? onClick : undefined;

  return (
    <div style={{ display: 'flex', paddingTop: 12, paddingBottom: 12 }}>
      <div
        style={{
          height: 40,
          width: 40,
          display: 'flex',
          justifyContent: 'center',
          borderRadius: 8
        }}
      >
        <FilesLabeledIcon />
      </div>
      <div style={{ fontSize: 16 }}>
        <div>{title}</div>
        <div>
          <a onClick={clickHandler}>{translationsStore.translate('documentsView.view')}</a>
        </div>
      </div>
    </div>
  );
};

export default inject('translationsStore')(observer(PersonalDocumentItem));
