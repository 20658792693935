import React from 'react';
import { Input, Select } from 'antd';
import classNames from 'classnames';

import cities from './comuni';
import './CitySelector.css';

const { Option } = Select;

const CitySelector = React.forwardRef(({ label, style, help, validateStatus, province, ...props }, ref) => {
  const cls = classNames({
    CitySelector: true,
    ['has-error']: validateStatus === 'error'
  });

  return (
    <div className={cls} style={style} ref={ref}>
      {label ? <div>{label}</div> : undefined}
      {
        <Select showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} {...props}>
          {cities
            .filter(city => city.prov_code === province)
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map(city => (
              <Option key={city.prov_code} value={city.name}>
                {city.name}
              </Option>
            ))}
        </Select>
      }

      {!!help && <div className={'LabelInputExplain'}>{help}</div>}
    </div>
  );
});

CitySelector.displayName = 'CitySelector';

export default CitySelector;
