import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react';
import { PulseLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import moment from "moment";
import { mypageHelper } from '../../../../helpers';

import MyPageContainer from '../../../../common/components/MyPageContainer';
import Section from '../../../../common/components/Section';

import './AgendaView.css';
import AirclubVehicleSelector from "./AirclubVehicleSelector";
import {Table, Select, Checkbox} from "antd";
import MyPageButton from "../../../../common/components/MyPageButton";
import ManutenzioniRow from "./ManutenzioniRow";
import AgendaAddDateButton from "./AgendaAddDateButton";
import AgendaRenewalButton from "./AgendaRenewalButton";
import AirModal from "../../../../common/components/AirModal";
import classNames from 'classnames';
import { Media as BreakpointsMedia } from 'react-breakpoints';
const { Option } = Select;


const columns = [
  {
    title: 'Categoria',
    dataIndex: 'category',
    key: 'category'
  },
  {
    title: 'Scadenza',
    dataIndex: 'scadenza',
    className: 'column-actions',
    key: 'scadenza'
  },
  {
    title: 'Azioni',
    dataIndex: 'actions',
    className: 'column-actions',
    key: 'actions',
  },
  {
    title: 'Veicolo',
    dataIndex: 'vehicle',
    key: 'vehicle',
  },
];

@inject('translationsStore', 'mypageStore')
@observer
class AgendaView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: undefined,
      selectedCategory: undefined,
      showAllVehicles: false,
      historyModalVisible: false,
      loadingAgenda: false
    }
  }

  componentDidMount = async () => {
    const { mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    await mypageStore.loadAgenda();
    this.setState({ loadingAgenda: false });
  };

  handleCreateAgendaManutenzione = async (vehicle, date) => {
    const { translationsStore, mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    mypageStore.createAgendaInsuranceCVT(vehicle, date)
      .then(async (r) => {
        await mypageStore.loadAgenda();
      })
      .catch(e => {
        mypageHelper.appMessageError(translationsStore.translate('common.genericError'));
      })
      .finally(() => {
        this.setState({ loadingAgenda: false });
      });
  };

  handleCreateAgendaRevisione = async (vehicle, date) => {
    const { translationsStore, mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    mypageStore.createAgendaInsuranceCVT(vehicle, date)
      .then(async (r) => {
        await mypageStore.loadAgenda();
      })
      .catch(e => {
        mypageHelper.appMessageError(translationsStore.translate('common.genericError'));
      })
      .finally(() => {
        this.setState({ loadingAgenda: false });
      });
  };

  handleCreateAgendaInsuranceCVT = async (vehicle, date) => {
    const { translationsStore, mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    mypageStore.createAgendaInsuranceCVT(vehicle, date)
      .then(async (r) => {
        await mypageStore.loadAgenda();
      })
      .catch(e => {
        mypageHelper.appMessageError(translationsStore.translate('common.genericError'));
      })
      .finally(() => {
        this.setState({ loadingAgenda: false });
      });
  };

  handleCreateAgendaInsuranceRCA = async (vehicle, date) => {
    const { translationsStore, mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    mypageStore.createAgendaInsuranceRCA(vehicle, date)
      .then(async (r) => {
        await mypageStore.loadAgenda();
      })
      .catch(e => {
        mypageHelper.appMessageError(translationsStore.translate('common.genericError'));
      })
      .finally(() => {
        this.setState({ loadingAgenda: false });
      });
  };

  handleCreateAgendaTaxes = async (vehicle, date) => {
    const { translationsStore, mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    mypageStore.createAgendaTaxes(vehicle, date)
      .then(async (r) => {
        await mypageStore.loadAgenda();
      })
      .catch(e => {
        mypageHelper.appMessageError(translationsStore.translate('common.genericError'));
      })
      .finally(() => {
        this.setState({ loadingAgenda: false });
      });
  };

  handleUpdateAgendaMaintenance = async (vehicleUuid, maintenanceUuid, date) => {
    const { translationsStore, mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    mypageStore.updateAgendaMaintenance(vehicleUuid, maintenanceUuid, date)
      .then(async (r) => {
        await mypageStore.loadAgenda();
      })
      .catch(e => {
        mypageHelper.appMessageError(translationsStore.translate('common.genericError'));
      })
      .finally(() => {
        this.setState({ loadingAgenda: false });
      });
  };

  handleUpdateAgendaInsurance = async (vehicleUuid, insuranceUuid, date) => {
    const { translationsStore, mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    mypageStore.updateAgendaInsurance(vehicleUuid, insuranceUuid, date)
      .then(async (r) => {
        await mypageStore.loadAgenda();
      })
      .catch(e => {
        mypageHelper.appMessageError(translationsStore.translate('common.genericError'));
      })
      .finally(() => {
        this.setState({ loadingAgenda: false });
      });
  };

  handleUpdateAgendaTaxes = async (vehicleUuid, taxesUuid, date) => {
    const { translationsStore, mypageStore } = this.props;
    this.setState({ loadingAgenda: true });
    mypageStore.updateAgendaTaxes(vehicleUuid, taxesUuid, date)
      .then(async (r) => {
        await mypageStore.loadAgenda();
      })
      .catch(e => {
        mypageHelper.appMessageError(translationsStore.translate('common.genericError'));
      })
      .finally(() => {
        this.setState({ loadingAgenda: false });
      });
  };

  vehicleFilterFn = (...params) => {
    // return this.props.mypageStore.hasVehicleReminder(...params);
    return true;
  }

  getPlate(vehicleUuid) {
    const vehicle = this.props.mypageStore.manageableVehicles.find(vehicle => vehicle.uuid === vehicleUuid);
    return !!vehicle ? vehicle.license_plate : undefined;
  }

  getManutenzioni(vehicleUuid) {
    const vehicleData = this.props.mypageStore.agendaMaintenances.find(vehicleData => vehicleData.uuid === vehicleUuid);
    const maintenance = vehicleData
      ? vehicleData.maintenances
        .filter(maintenance => !maintenance.node.performed_date)
        .filter(maintenance => maintenance.node.date? moment(maintenance.node.date) >= moment() : true)
        .sort((a, b)=> a.node.date && b.node.date? new Date(a.node.date) - new Date(b.node.date) : a.node.km - b.node.km)
        .find(ins => ins.node.type==="general")
      : undefined;

    return  {
      category: 'Manutenzioni',
      vehicle: this.getPlate(vehicleUuid),
      scadenza: !maintenance ? <ManutenzioniRow vehicle={vehicleUuid} />
        : maintenance.node.date? moment(maintenance.node.date).format("DD/MM/YYYY") : `${maintenance.node.km}km`
      ,
      actions: <AgendaRenewalButton
        disabled={!maintenance}
        onSubmit={(date) => this.handleUpdateAgendaMaintenance(vehicleUuid, maintenance.node.uuid, date)}
      />
    }
  }

  getRevisione(vehicleUuid) {
    const vehicleData = this.props.mypageStore.agendaMaintenances.find(vehicleData => vehicleData.uuid === vehicleUuid);
    const revision = vehicleData
      ? vehicleData.maintenances
        .filter(maintenance => !maintenance.node.performed_date)
        .find(ins => ins.node.type==="revision")
      : undefined;

    return  {
      category: 'Revisione',
      vehicle: this.getPlate(vehicleUuid),
      scadenza: !revision ? <AgendaAddDateButton
          title="Aggiungi la data di scadenza della Revisione"
          vehicle={vehicleUuid}
          onSubmit={(vehicle, date) => this.handleCreateAgendaRevisione(vehicle, date)}
        />
        : moment(revision.node.date).format("DD/MM/YYYY")
      ,
      actions: <AgendaRenewalButton
        disabled={!revision}
        onSubmit={(date) => this.handleUpdateAgendaMaintenance(vehicleUuid, revision.node.uuid, date)}
      />
    }
  }

  getAssicurazioneCVT(vehicleUuid) {
    const vehicleData = this.props.mypageStore.agendaInsurances.find(vehicleData => vehicleData.uuid === vehicleUuid);
    const insurance = vehicleData
      ? vehicleData.insurances
        .filter(insurance => !insurance.node.performed_date)
        .find(ins => ins.node.type==="CVT")
      : undefined;

    return  {
      category: 'Assicurazione CVT',
      vehicle: this.getPlate(vehicleUuid),
      scadenza: !insurance ? <AgendaAddDateButton
          title="Aggiungi la data di scadenza dell'Assicurazione CVT"
          vehicle={vehicleUuid}
          onSubmit={(vehicle, date) => this.handleCreateAgendaInsuranceCVT(vehicle, date)}
        />
        : moment(insurance.node.date).format("DD/MM/YYYY")
      ,
      actions: <AgendaRenewalButton
        disabled={!insurance}
        onSubmit={(date) => this.handleUpdateAgendaInsurance(vehicleUuid, insurance.node.uuid, date)}
      />
    }
  }

  getAssicurazioneRCA(vehicleUuid) {
    const vehicleData = this.props.mypageStore.agendaInsurances.find(vehicleData => vehicleData.uuid === vehicleUuid);
    const insurance = vehicleData
      ? vehicleData.insurances
        .filter(insurance => !insurance.node.performed_date)
        .find(ins => ins.node.type==="RCA")
      : undefined;

    return  {
      category: 'Assicurazione RCA',
      vehicle: this.getPlate(vehicleUuid),
      scadenza: !insurance ? <AgendaAddDateButton
          title="Aggiungi la data di scadenza dell'Assicurazione RCA"
          vehicle={vehicleUuid}
          onSubmit={(vehicle, date) => this.handleCreateAgendaInsuranceRCA(vehicle, date)}
        />
        : moment(insurance.node.date).format("DD/MM/YYYY")
      ,
      actions: <AgendaRenewalButton
        disabled={!insurance}
        onSubmit={(date) => this.handleUpdateAgendaInsurance(vehicleUuid, insurance.node.uuid, date)}
      />
    }
  }

  getBollo(vehicleUuid) {
    const vehicleData = this.props.mypageStore.agendaTaxes.find(vehicle => vehicle.uuid === vehicleUuid);
    const taxes = vehicleData
      ? vehicleData.taxes.filter(tax => !tax.node.performed_date)[0]
      : undefined;

    return  {
      category: 'Bollo',
      vehicle: this.getPlate(vehicleUuid),
      scadenza: !taxes
        ? <AgendaAddDateButton
          title='Aggiungi la data di scadenza del BOLLO'
          vehicle={vehicleUuid}
          onSubmit={(vehicle, date) => this.handleCreateAgendaTaxes(vehicle, date)}
        />
        : moment(taxes.node.date).format("DD/MM/YYYY")
      ,
      actions: <AgendaRenewalButton
        disabled={!taxes}
        onSubmit={(date) => this.handleUpdateAgendaTaxes(vehicleUuid, taxes.node.uuid, date)}
      />
    }
  }

  getAirClub(vehicleUuid) {
    const vehicleData = this.props.mypageStore.manageableVehicles.find(vehicleData => vehicleData.uuid === vehicleUuid);
    const subscription = vehicleData.subscriptions.find(sub => sub.product.name === "Air Club");

    return subscription ? {
      category: 'Servizio AIR CLUB',
      vehicle: this.getPlate(vehicleUuid),
      scadenza: moment(subscription.node.end_date).format("DD/MM/YYYY"),
      actions: <MyPageButton
        theme="grey"
        solid
        disabled={true}
        title="Rinnova"
        onClick={() => console.log("ADD")}
      />
    } : undefined;
  }

  getMovein(vehicleUuid) {
    const vehicleData = this.props.mypageStore.manageableVehicles.find(vehicleData => vehicleData.uuid === vehicleUuid);
    const subscription = vehicleData.subscriptions.find(sub => sub.product.features.some(feat=> feat.node.name === "black-box"));

    return (vehicleData.hasOwnProperty('id_adesione') && subscription) ? {
      category: 'Servizio Move-in',
      vehicle: this.getPlate(vehicleUuid),
      scadenza: moment(subscription.node.end_date).format("DD/MM/YYYY"),
      actions: <MyPageButton
        theme="grey"
        solid
        disabled={true}
        title="Rinnova"
        onClick={() => console.log("ADD")}
      />
    } : undefined;
  }

  getDataForVehicle(vehicleUuid) {
    const all = [
      this.getManutenzioni(vehicleUuid),
      this.getAssicurazioneRCA(vehicleUuid),
      this.getAssicurazioneCVT(vehicleUuid),
      this.getBollo(vehicleUuid),
      this.getRevisione(vehicleUuid),
      this.getAirClub(vehicleUuid),
      this.getMovein(vehicleUuid),
    ].filter(Boolean);
    return this.state.selectedCategory ? all.filter((item) => item.category === this.state.selectedCategory) : all;
  }

  getDataset() {
    const {mypageStore} = this.props;

    if(!this.state.selectedVehicle) return [];

    if(this.state.showAllVehicles) {
      return mypageStore.manageableVehicles.filter(this.vehicleFilterFn)
        .map(vehicle => this.getDataForVehicle(vehicle.uuid)).flat()
    }

    return this.getDataForVehicle(this.state.selectedVehicle.key)
  }

  getHistoryMaintanances() {
    return this.props.mypageStore.agendaMaintenances.map(
      vehicle => vehicle.maintenances
        .filter(maintenance => !!maintenance.node.performed_date)
        .map(maintenance => ({
          category: maintenance.node.type === 'general'? 'Manutenzione' :'Revisione',
          vehicle: vehicle.license_plate,
          scadenza: moment(maintenance.node.date).format("DD/MM/YYYY"),
          actions: moment(maintenance.node.performed_date).format("DD/MM/YYYY"),
        }))
    ).flat();
  }

  getHistoryTaxes() {
    return this.props.mypageStore.agendaTaxes.map(
      vehicle => vehicle.taxes
        .filter(maintenance => !!maintenance.node.performed_date)
        .map(maintenance => ({
          category: 'Bollo',
          vehicle: vehicle.license_plate,
          scadenza: moment(maintenance.node.date).format("DD/MM/YYYY"),
          actions: moment(maintenance.node.performed_date).format("DD/MM/YYYY"),
        }))
    ).flat();
  }

  getHistoryInsurances() {
    return this.props.mypageStore.agendaInsurances.map(
      vehicle => vehicle.insurances
        .filter(maintenance => !!maintenance.node.performed_date)
        .map(maintenance => ({
          category: maintenance.node.type === 'RCA'? 'Assicurazione RCA' : 'Assicurazione CVT',
          vehicle: vehicle.license_plate,
          scadenza: moment(maintenance.node.date).format("DD/MM/YYYY"),
          actions: moment(maintenance.node.performed_date).format("DD/MM/YYYY"),
        }))
    ).flat();
  }

  getHistoryDataset() {
    if(!this.state.selectedVehicle) return [];
    return [
      this.getHistoryMaintanances(),
      this.getHistoryTaxes(),
      this.getHistoryInsurances()
    ].flat()
  }

  render() {
    const { dataCollection, translationsStore, mypageStore } = this.props,
      hasMultipleVehicles = mypageStore.manageableVehicles.length > 1;
    const { loadingAgenda } = this.state;

    return (
      <MyPageContainer className="AgendaView" dataCollection={dataCollection}>
        <BreakpointsMedia>
          {({ currentBreakpoint, breakpoints }) => {
            const cls = classNames({
              AgendaView: true,
              [currentBreakpoint]: !!currentBreakpoint
            });
            return (
              <Fragment>
                <LoadingOverlay
                  active={loadingAgenda}
                  spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true} />}
                  styles={{
                    overlay: base => ({
                      ...base,
                      background: 'var(--grey-light-color-50)'
                    })
                  }}
                >
                  <AirclubVehicleSelector selectedVehicle={this.state.selectedVehicle} onVehicleChange={(vehicle) => this.setState({selectedVehicle: vehicle})} />
                  <Section title={"Scadenze"}>
                    <div>
                      <div className={breakpoints[currentBreakpoint] >= breakpoints.desktop ? 'AgendaControls' : 'AgendaControls mobile'}>
                        <Select placeholder="Scegli categoria" defaultValue={undefined} style={{ width: 240 }} onChange={value => this.setState({selectedCategory: value})} allowClear>
                          <Option value="Manutenzioni">Manutenzioni</Option>
                          <Option value="Assicurazione RCA">Assicurazione RCA</Option>
                          <Option value="Assicurazione CVT">Assicurazione CVT</Option>
                          <Option value="Bollo">Bollo</Option>
                          <Option value="Revisione">Revisione</Option>
                          <Option value="Servizio AIR CLUB">Servizio AIR CLUB</Option>
                          <Option value="Servizio Move-in">Servizio Move-in</Option>
                        </Select>
                        {hasMultipleVehicles && (
                        <div>
                          Visualizza per tutte le auto <Checkbox onChange={e => this.setState({showAllVehicles: e.target.checked})} className={breakpoints[currentBreakpoint] >= breakpoints.desktop ? '' : 'align-right'} />
                        </div>
                        )}
                        <div className="History">
                          <a onClick={() => this.setState({historyModalVisible: true})}>STORICO SCADENZE</a>
                          <AirModal
                            width="90vw"
                            className={"AgendaModal"}
                            visible={this.state.historyModalVisible}
                            onCancel={() => {
                              this.setState({ historyModalVisible: false });
                            }}
                          >
                            <h2>Storico scadenze</h2>
                            <Table className="AgendaTable" pagination={false} columns={columns} dataSource={this.getHistoryDataset()} />
                          </AirModal>
                        </div>
                      </div>
                      <Table className="AgendaTable" pagination={false} columns={columns} dataSource={this.getDataset().map((item, index) => ({ ...item, key: index }))} />
                    </div>
                  </Section>
                </LoadingOverlay>
              </Fragment>
            );
          }}
        </BreakpointsMedia>
      </MyPageContainer>
    );
  }
}

export default AgendaView;
