import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import SmallDivision from '../../../../common/components/SmallDivision';
import { HeadphonesIcon, IphoneIcon } from '../../../../common/icons';
import MyPageContainer from '../../../../common/components/MyPageContainer';

import './SupportHelpDeskView.css';
import AppBrand from '../../../../common/components/AppBrand';

@inject('translationsStore')
@observer
class SupportHelpDeskView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOperatorCardFlipped: false,
      isAxaAssistanceFlipped: false
    };
  }

  onFaqClick = () => {
    const url = 'https://www.myair.io/it/faq-domande-frequenti/';
    window.open(url, '_blank');
    return null;
  };

  onRequestClick = () => {
    const url = 'https://share.hsforms.com/1aqr2m159Swe5oOSAiQKePw3csdt';
    window.open(url, '_blank');
    return null;
  };

  onChatClick = () => {
    window.location = window.location + '#hs-chat-open';
    return null;
  };

  render() {
    const { translationsStore, dataCollection } = this.props;

    const brandStyle = { width: 52, height: 23, color: 'var(--white-color)', marginTop: '36px', marginLeft: 23 };

    return (
      <MyPageContainer dataCollection={dataCollection}>
        <BreakpointsMedia>
          {({ currentBreakpoint, breakpoints }) => {
            const cls = classNames({
              SupportHelpDeskView: true,
              [currentBreakpoint]: !!currentBreakpoint
            });
            return (
              <Fragment className={cls}>
                <SmallDivision title={translationsStore.translate('supportView.helpDesk.airAssistance')} icon={<HeadphonesIcon />} style={{ marginBottom: 40 }} />

                <div className={breakpoints[currentBreakpoint] >= breakpoints.desktop ? 'SupportContainer' : 'SupportContainerMobile'}>

                  <div className={cls}>
                    <div className="bubble">
                      <AppBrand style={brandStyle} />
                    </div>

                    <div className="title">{translationsStore.translate('supportView.helpDesk.request.title')}</div>
                    <div className="subtitle">
                      <div className="days">{translationsStore.translate('supportView.helpDesk.request.description')}</div>
                    </div>
                    <div className="numbers" onClick={this.onRequestClick}>
                      <div className="phoneIcon">
                        <IphoneIcon />
                      </div>

                      <div className="mobile">{translationsStore.translate('supportView.helpDesk.request.button')}</div>
                    </div>
                  </div>

                  <div className={cls}>
                    <div className="bubble">
                      <AppBrand style={brandStyle} />
                    </div>

                    <div className="title">{translationsStore.translate('supportView.helpDesk.chat.title')}</div>
                    <div className="subtitle">
                      <div className="days">{translationsStore.translate('supportView.helpDesk.chat.description')}</div>
                    </div>
                    <div className="numbers" onClick={this.onChatClick}>
                      <div className="phoneIcon">
                        <IphoneIcon />
                      </div>

                      <div className="mobile">{translationsStore.translate('supportView.helpDesk.chat.button')}</div>
                    </div>
                  </div>

                  {!this.state.isOperatorCardFlipped ? (
                    <div className={cls}>
                      <div className="bubble">
                        <AppBrand style={brandStyle} />
                      </div>
                      <div className="title">{translationsStore.translate('supportView.helpDesk.operator.title')}</div>
                      <div className="subtitle">
                        <div className="days">{translationsStore.translate('supportView.helpDesk.operator.description')}</div>
                      </div>
                      <div
                        className="numbers"
                        onClick={() => {
                          this.setState({ isOperatorCardFlipped: true });
                        }}
                      >
                        <div className="phoneIcon">
                          <IphoneIcon />
                        </div>

                        <div className="mobile">{translationsStore.translate('supportView.helpDesk.operator.button')}</div>
                      </div>
                    </div>
                  ) : (
                    <div className={cls}>
                      <div className="title">{translationsStore.translate('supportView.helpDesk.operator.back.title')}</div>
                      <div className="ContactContainer">
                        {translationsStore.translate('supportView.helpDesk.operator.back.phone')}
                        <a href="tel:0381092120">0381092120</a>
                        {translationsStore.translate('supportView.helpDesk.operator.back.whatsapp')}
                        <a href="tel:3491233800">3491233800</a>
                        {translationsStore.translate('supportView.helpDesk.operator.back.email')}
                        <a href="mailto:info@myair.io">info@myair.io</a>
                      </div>

                      <div
                        className="numbers"
                        onClick={() => {
                          this.setState({ isOperatorCardFlipped: false });
                        }}
                      >
                        <div className="phoneIcon">
                          <IphoneIcon />
                        </div>
                        <div className="mobile">{translationsStore.translate('supportView.helpDesk.operator.back.cancel')}</div>
                      </div>
                    </div>
                  )}

                  <div className={cls}>
                    <div className="bubble">
                      <AppBrand style={brandStyle} />
                    </div>
                    <div className="title">{translationsStore.translate('supportView.helpDesk.faq.title')}</div>
                    <div className="subtitle">
                      <div className="days">{translationsStore.translate('supportView.helpDesk.faq.description')}</div>
                    </div>
                    <div className="numbers" onClick={this.onFaqClick}>
                      <div className="phoneIcon">
                        <IphoneIcon />
                      </div>

                      <div className="mobile">{translationsStore.translate('supportView.helpDesk.faq.button')}</div>
                    </div>
                  </div>
                </div>

                <SmallDivision title={translationsStore.translate('supportView.helpDesk.axa.title')} icon={<HeadphonesIcon />} style={{ marginBottom: 40, marginTop: 40 }} />

                <div className={breakpoints[currentBreakpoint] >= breakpoints.desktop ? 'SupportContainer' : 'SupportContainerMobile'}>

                {!this.state.isAxaAssistanceFlipped ? (
                  <div className={cls}>
                    <div className="bubble">
                      <AppBrand style={brandStyle} />
                    </div>

                    <div className="title">{translationsStore.translate('supportView.helpDesk.axa.axaAssistanceTitle')}</div>
                    <div className="subtitle">&nbsp;</div>
                    <div
                      className="numbers"
                      onClick={() => {
                        this.setState({ isAxaAssistanceFlipped: true });
                      }}
                    >
                      <div className="phoneIcon">
                        <IphoneIcon />
                      </div>

                      <div className="mobile" style={{ fontSize: 'small' }}>
                        {translationsStore.translate('supportView.helpDesk.axa.button')}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={cls}>
                    <div className="bubble">
                      <AppBrand style={brandStyle} />
                    </div>
                    <div className="title">{translationsStore.translate('supportView.helpDesk.axa.back.title')}</div>
                    <div className="AxaContactContainer">
                      {translationsStore.translate('supportView.helpDesk.axa.back.phone')}
                      <a href="tel:+390547313404"> +390547313404</a>
                    </div>
                    <div
                      className="numbers"
                      onClick={() => {
                        this.setState({ isAxaAssistanceFlipped: false });
                      }}
                    >
                      <div className="phoneIcon">
                        <IphoneIcon />
                      </div>

                      <div className="mobile">{translationsStore.translate('supportView.helpDesk.axa.back.cancel')}</div>
                    </div>
                  </div>
                )}

                </div>
              </Fragment>
            );
          }}
        </BreakpointsMedia>
      </MyPageContainer>
    );
  }
}

export default SupportHelpDeskView;
