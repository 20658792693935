import { observable, action } from 'mobx';

import { Auth } from 'aws-amplify';

import { api } from '../services';

import AWS from 'aws-sdk';
import K from "./../constants";

AWS.config.update({
  region: K.AWS_AUTH_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: K.AWS_AUTH_USER_POOL_ID
  })
});
import { CognitoUser, CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUserPool } from 'amazon-cognito-identity-js';

class AuthStore {
  @observable
  inProgress = false;
  @observable
  errors = undefined;
  @observable
  justLoggedIn = false;
  @observable
  newPasswordRequired = false;
  @observable
  resetPasswordRequired = false;
  @observable
  isLoggedIn;
  @observable
  token;
  @observable
  user;
  @observable
  sessionFromCustomSSO = false;

  @action
  login({ username, password }) {
    this.inProgress = true;
    this.errors = undefined;
    return api('login', username, password)
      .then(user => {
        this.user = user;
        this.newPasswordRequired = 'NEW_PASSWORD_REQUIRED' === user.challengeName;
      })
      .catch(
        action(err => {
          this.resetPasswordRequired = err.code === 'PasswordResetRequiredException';
          this.errors = [err];
          throw err;
        })
      )
      .then(
        action(() => {
          this.inProgress = false;
          this.justLoggedIn = true;
        })
      );
  }

  @action
  completeNewPassword({ newPassword }) {
    this.inProgress = true;
    this.errors = undefined;
    return api('completeNewPassword', this.user, newPassword)
      .then(() => {
        this.newPasswordRequired = false;
      })
      .catch(
        action(err => {
          this.errors = [err];
          throw err;
        })
      )
      .then(
        action(() => {
          this.inProgress = false;
          this.justLoggedIn = true;
        })
      );
  }

  @action
  setJustLoggedIn(trueOrFalse) {
    this.justLoggedIn = trueOrFalse;
  }

  @action
  logout() {
    this.setIsLoggedIn(false);
    return Auth.signOut();
  }

  @action
  setIsLoggedIn(isLoggedIn) {
    this.isLoggedIn = isLoggedIn;
  }

  @action
  setToken(token) {
    this.token = `Bearer ${token}`;
  }

  @action
  setUser(user) {
    this.user = user;
  }

  checkSession() {
    const urlParams = new URLSearchParams(window.location.search),
      url = new URL(window.location.href);

    if (urlParams.has('refresh_token') && ['/custom-sso'].includes(url.pathname)) {
      // checking session from backend originated flow
      const CognitoProvider = new AWS.CognitoIdentityServiceProvider(),
        RefreshToken = urlParams.get('refresh_token'),
        initiateAuthPromise = CognitoProvider.initiateAuth({
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          ClientId: K.AWS_AUTH_USER_POOL_WEBCLIENT_ID,
          AuthParameters: {
            REFRESH_TOKEN: RefreshToken
          }
        }).promise();

      return initiateAuthPromise
        .then(async data => {
          const {
              AuthenticationResult: { IdToken, AccessToken }
            } = data,
            getUser = await CognitoProvider.getUser({ AccessToken })
              .promise()
              .then(data => data)
              .catch(err => err);

          if (!(getUser instanceof Error)) {
            const poolData = {
                UserPoolId: K.AWS_AUTH_USER_POOL_ID,
                ClientId: K.AWS_AUTH_USER_POOL_WEBCLIENT_ID
              },
              userSession = new CognitoUserSession({
                IdToken: new CognitoIdToken({ IdToken }),
                AccessToken: new CognitoAccessToken({ AccessToken }),
                RefreshToken: new CognitoRefreshToken({ RefreshToken })
              }),
              userData = {
                Username: getUser.Username,
                Pool: new CognitoUserPool(poolData)
              },
              cognitoUser = new CognitoUser(userData);

            cognitoUser.setSignInUserSession(userSession);
            cognitoUser.getSession((err, session) => {
              if (session.isValid()) {
                this.user = {
                  attributes: getUser.UserAttributes.reduce((acc, sub) => ({ ...acc, [sub.Name]: sub.Value }), {}),
                  username: getUser.Username,
                  signInUserSession: {
                    idToken: {
                      jwtToken: IdToken
                    }
                  }
                };
                this.setSessionFromCustomSSO(true);
                this.setIsLoggedIn(true);
                let sessionUrl = `${url.origin}/${urlParams.has('route') ? urlParams.get('route') : 'profile'}`;
                const paramsToPass = Array.from(urlParams.entries()).filter(entry => entry[0] !== 'refresh_token' && entry[0] !== 'route');
                if (paramsToPass.length >= 1) {
                  const sessionUrlSearchParams = new URLSearchParams(paramsToPass);
                  sessionUrl = `${sessionUrl}?${sessionUrlSearchParams.toString()}`;
                }
                window.history.replaceState('', '', sessionUrl);
              }
            });
          } else {
            // CognitoISP getUser failed
            this.setIsLoggedIn(false);
          }
        })
        .catch(err => {
          // CognitoISP initiateAuth failed
          this.setIsLoggedIn(false);
        });
    } else {
      // checking session from frontend originated flow
      return Auth.currentSession()
        .then(() => {
          this.setSessionFromCustomSSO(false);
          this.setIsLoggedIn(true);
        })
        .catch(() => this.setIsLoggedIn(false));
    }
  }

  @action
  setSessionFromCustomSSO(sessionFromCustomSSO) {
    this.sessionFromCustomSSO = sessionFromCustomSSO;
  }
}

export default new AuthStore();
