import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Select } from 'antd';
const { Option } = Select;

import './ProvinceSelector.css';

const provinceList = [
  {name:'Alessandria', prov: 'AL'},
  {name:'Asti', prov: 'AT'},
  {name:'Biella', prov: 'BI'},
  {name:'Belluno', prov: 'BL'},
  {name:'Bergamo', prov: 'BG'},
  {name:'Bologna', prov: 'BO'},
  {name:'Brescia', prov: 'BS'},
  {name:'Como', prov: 'CO'},
  {name:'Cremona', prov: 'CR'},
  {name:'Cuneo', prov: 'CN'},
  {name:'Ferrara', prov: 'FE'},
  {name:'Forlì-Cesena', prov: 'FC'},
  {name:'Lecco', prov: 'LC'},
  {name:'Lodi', prov: 'LO'},
  {name:'Mantova', prov: 'MN'},
  {name:'Modena', prov: 'MO'},
  {name:'Monza e della Brianza', prov: 'MB'},
  {name:'Milano', prov: 'MI'},
  {name:'Novara', prov: 'NO'},
  {name:'Padova', prov: 'PD'},
  {name:'Pavia', prov: 'PV'},
  {name:'Parma', prov: 'PR'},
  {name:'Piacenza', prov: 'PC'},
  {name:'Ravenna', prov: 'RA'},
  {name:'Reggio Emilia', prov: 'RE'},
  {name:'Rimini', prov: 'RN'},
  {name:'Rovigo', prov: 'RO'},
  {name:'Sondrio', prov: 'SO'},
  {name:'Torino', prov: 'TO'},
  {name:'Treviso', prov: 'TV'},
  {name:'Varese', prov: 'VA'},
  {name:'Venezia', prov: 'VE'},
  {name:'Verbano-Cusio-Ossola', prov: 'VB'},
  {name:'Vercelli', prov: 'VC'},
  {name:'Verona', prov: 'VR'},
  {name:'Vicenza', prov: 'VI'}
];

const style1 = {
  color: 'var(--black-color)',
  fontSize: '22px',
  fontWeight: 600,
  letterSpacing: '-0.7px',
  lineHeight: '32px'
};

const selectedProvinceStyle = {
  width: 250,
  backgroundColor: '#78D0CA',
  margin: '0 auto',
  padding: 8,
  border: '1px solid #78D0CA',
  boxSizing: 'border-box',
  borderRadius: 4,
  color: 'white'
}

@inject('translationsStore')
@observer
export default class ProvinceSelector extends Component {

  renderSelected = () => (
    <div>
      <div style={{ ...style1, marginBottom: 16 }}>{this.props.translationsStore.translate('movein.stepInstaller.labelProvinceSelected')}</div>
      <div style={selectedProvinceStyle}>{this.props.province.name}</div>
      <a style={{textDecoration: 'underline'}} onClick={() =>this.props.onChange(null)}>{this.props.translationsStore.translate('movein.stepInstaller.actionSwitchProvincia')}</a>
    </div>
  )

  handleChange = (province) => {
    this.props.onChange(provinceList.find(prov => prov.prov == province));
  }

  renderToSelect = () => (
    <div>
      <div style={{ ...style1, marginBottom: 16 }}>{this.props.translationsStore.translate('movein.stepInstaller.labelSelectProvince')}</div>
      <div>
        <Select
          showSearch
          filterOption={(input, option) => {
            const {
              props: { value, children },
            } = option;
            const valueMatches = value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            const labelMatches = children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            return valueMatches || labelMatches;
          }}
          style={{ width: 250 }}
          onChange={this.handleChange}
          data-testid={'selectTestId'}
        >
          {provinceList.map((prov) =>
            <Option key={prov.name} value={prov.prov}>{prov.name}</Option>
          )}
        </Select>
      </div>
    </div>
  )

  render() {
    const cls = classNames({
      MyPageCheckbox: true,
      [this.props.className]: !!this.props.className
    });

    const { province } = this.props;

    return !province ? this.renderToSelect() : this.renderSelected()
  }
}
