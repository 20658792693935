import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import App from './App';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import ForgottenPassword from './Auth/ForgottenPassword';

@inject('authStore')
@observer
class AppRouter extends Component {
  render() {
    const { isLoggedIn } = this.props.authStore;
    return (
      <Router>
        <Switch>
          <Route path="/logout" component={Logout} />
          <Route path="/login" component={Login} />
          <Route path="/forgotten-password" component={ForgottenPassword} />
          <Route path="/" render={() => (isLoggedIn ? <App /> : <Redirect to="/login" />)} />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
