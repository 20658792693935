import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import api from "../../../../../services/api";
import Spin from "antd/es/spin";
import {message} from "antd";

@inject('commonStore', 'translationsStore')
@observer
export default class ZuoraHostedPage extends Component {
  componentDidMount() {
    this.loadPayment()
  }

  showErrorMessage = () => {
    const { translationsStore } = this.props;
    message.error(translationsStore.translate('common.genericError'));
  };

  loadCreditCard = async () => {
    const { translationsStore } = this.props;
    const pageId = process.env.REACT_APP_ZUORA_HOSTED_PAGE_ID;
    const result = await api('getZuoraToken', pageId);
    const locale = translationsStore.translate('common.localeISO') !== 'common.localeISO' ? translationsStore.translate('common.localeISO') : 'it_IT';

    return {
      id: pageId,
      signature: result.signature,
      token: result.token,
      tenantId: result.tenantId,
      key: result.key,
      field_accountId: this.props.customerDetails.zuora_account_id,
      style: 'inline',
      submitEnabled: 'true',
      locale: locale,
      param_supportedTypes: 'Visa,MasterCard',
      url: process.env.REACT_APP_ZUORA_HOSTED_PAGE_URL,
      paymentGateway: process.env.REACT_APP_ZUORA_PAYMENT_GATEWAY
    };
  };

  loadPayment = () => {
    const loadMe = async () => {
      const params = await this.loadCreditCard();
      const callback = async response => {
        if (!response.success) {
          this.showErrorMessage();
          return;
        }

        await api('setPaymentMethodDefault', this.props.customerDetails.zuora_account_id, response.refId);
        this.props.onNext();
      };

      // render the Zuora Hosted Page
      window.Z.render(params, { creditCardCountry: 'ITA' }, callback);
    };
    loadMe();
  };

  render() {
    return (
      <div id='zuora_payment' className="PaymentMethodContainer"><Spin /></div>
    );
  }
}
