import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Form, Row, Spin, Modal } from 'antd';

import { api } from '../../../../../services';

import './ChangePasswordForm.css';
import LabelInput from '../../../../../common/components/LabelInput';
import SmallDivision from '../../../../../common/components/SmallDivision';
import { AccountSquareIcon } from '../../../../../common/icons';
import MyPageButton from '../../../../../common/components/MyPageButton';

@withRouter
@inject('translationsStore', 'authStore', 'mypageStore')
@observer
class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  getUserField = field => {
    const user = this.props.mypageStore.remoteUserData;
    if (!user) {
      return;
    }
    return user[field];
  };

  handleSubmit = e => {
    e.preventDefault();
    const { authStore, translationsStore } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        api('changePassword', authStore.user, values.oldPassword, values.newPassword)
          .then(() => {
            Modal.success({
              title: translationsStore.translate('profileView.changePasswordForm.success')
            });
          })
          .catch(errorRes => {
            if( errorRes.code === 'NotAuthorizedException'){
              Modal.error({
                title: translationsStore.translate('profileView.changePasswordForm.errorOldPasswordMismatch')
              });
            }else if (errorRes.code === 'LimitExceededException'){
              Modal.error({
                title: translationsStore.translate('profileView.changePasswordForm.errorLimitExceeded')
              });
            }else {
              Modal.error({
                title: translationsStore.translate('profileView.changePasswordForm.error')
              });
            }
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { translationsStore } = this.props;
    return this.props.mypageStore.remoteUserIsFromMoVeInProgram ? null : (
      <Spin spinning={this.state.loading}>
        <SmallDivision title={translationsStore.translate('profileView.changePasswordForm.title')} icon={<AccountSquareIcon />} />
        <Form className="ChangePasswordForm">
          <Form.Item>
            {getFieldDecorator('email', {
              initialValue: this.getUserField('email'),
              rules: [
                {
                  required: true,
                  message: translationsStore.translate('common.requiredField')
                }
              ]
            })(<LabelInput label={translationsStore.translate('profileView.changePasswordForm.email')} disabled />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('mobile', {
              initialValue: this.getUserField('mobile'),
              rules: [
                {
                  required: true,
                  message: translationsStore.translate('common.requiredField')
                }
              ]
            })(<LabelInput label={translationsStore.translate('profileView.changePasswordForm.mobile')} disabled />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('oldPassword', {
              rules: [
                {
                  required: true,
                  message: translationsStore.translate('profileView.changePasswordForm.oldPasswordRequired')
                },
                {
                  min: 6,
                  message: translationsStore.translate('profileView.changePasswordForm.oldPasswordMinimumLength')
                }
              ]
            })(<LabelInput type="password" label={translationsStore.translate('profileView.changePasswordForm.oldPassword')} />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('newPassword', {
              rules: [
                {
                  required: true,
                  message: translationsStore.translate('profileView.changePasswordForm.newPasswordHint'),
                  pattern: /^(?=.{8,}$)(?=.*[A-Z])(?=.*[0-9]).*$/
                }
              ]
            })(<LabelInput type="password" label={translationsStore.translate('profileView.changePasswordForm.newPassword')} />)}
          </Form.Item>

          <Form.Item>
            <div className={'SubmitContainer'}>
              <MyPageButton onClick={this.handleSubmit} theme="primary" title={translationsStore.translate('profileView.changePasswordForm.submit')} solid />
            </div>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}
export default Form.create()(ChangePasswordForm);
