import React, { Component, Fragment } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { QsHelper } from '../../../../helpers';

@withRouter
export default class ProcessZuora extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    try {

      if (window.parent !== window) {
        const helper = new QsHelper(this.props.location.search);
        const requestParameters = {
          responseFrom: helper.get('responseFrom'),
          success: helper.get('success'),
          errorCode: helper.get('errorCode'),
          errorMessage: decodeURIComponent(helper.get('errorMessage')),
          token: helper.get('token'),
          signature: helper.get('signature'),
          tenantId: helper.get('tenantId'),
          refId: helper.get('refId')
        };
        if (requestParameters.responseFrom === 'Response_From_Submit_Page') {
          window.parent.zuoraHostedSubmitProcessing(requestParameters);
          // TODO verify signature
          if (requestParameters.success === 'true') {
            window.parent.zuoraHostedSubmitSucceed(requestParameters);
          } else {
            window.parent.zuoraHostedSubmitFailed(requestParameters);
          }
          return <Fragment />;
        } else {
          return <Redirect to="/" />;
        }
      } else {
        return <Redirect to="/" />;
      }
    } catch (error) {

      window.parent.zuoraHostedSubmitFailed({errorMessage: error.message ? error.message : error});
      return <Fragment />;
    }
  }
}
