import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {inject, observer} from "mobx-react";
import {Alert, Col, Form, Row, message} from "antd";
import { GoogleApiWrapper } from 'google-maps-react';
import K from '../../../../../constants';
import debounce from 'lodash.debounce';


import moment from "moment";

import SmallDivision from "../../../../../common/components/SmallDivision";
import LabelInput from "../../../../../common/components/LabelInput";
import {AccountSquareIcon } from "../../../../../common/icons";
import MyPageButton from "../../../../../common/components/MyPageButton";

import './ProfileViewForm.css';
import StateSelector from "./StateSelector/StateSelector";
import ProvinceSelector from "./ProvinceSelector/ProvinceSelector";
import CitySelector from "./CitySelector/CitySelector";
import MyPageAlert from "../../../../../common/components/MyPageAlert";

@withRouter
@inject('translationsStore', 'mypageStore')
@observer
class ProfileViewForm extends Component {
  constructor(props) {
    super(props);
    // Delay action 2 seconds
    this.onAddressChange = debounce(this.onAddressChange, 1500)
  }

  getUserAddressField = (field) => {
    const user = this.props.mypageStore.remoteUserData;
    if(!user || user.addresses.length === 0) return;
    return user.addresses[0].node[field];
  };

  getUserField = (field) => {
    const user = this.props.mypageStore.remoteUserData;
    if(!user) return;
    return user[field];
  };

  getCountry = () => {
    return this.getUserAddressField('country') || 'IT'
  };

  isLegalPerson = () => {
    const user = this.props.mypageStore.remoteUserData;
    return user && user.person_type==='LegalPerson'
  };

  isItaly = () => {
    return !!this.props.form.getFieldValue('country')
      ? this.props.form.getFieldValue('country') === 'IT'
      : this.getCountry() === "IT"
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if(err) {
        message.error(this.props.translationsStore.translate('profileView.errorMessage'));
        return;
      }

      this.props.mypageStore.updateUserProfile(values).then(() => {
        message.success(this.props.translationsStore.translate('profileView.successMessage'));
        if(this.props.mypageStore.hasToCompleteProfile) {
          this.props.mypageStore.setHasToCompleteProfile(false);
          this.props.history.push('/services');
        }
      }).catch(error => {
        message.error(this.props.translationsStore.translate(`profileView.errors.${error}`));
      });
    });
  };

  onChangeProvince = () => e =>{
    this.props.form.setFieldsValue({'city': undefined, 'province' : e});
  };

  onAddressChange = () => {
    const postCodeForInputAddress = this.getPostCode();

    if (!postCodeForInputAddress) {
      message.error(this.props.translationsStore.translate('common.genericError'));
    } else {
      this.props.form.setFieldsValue({'postcode': postCodeForInputAddress});
    }
  };

  getPostCode = () => {
    const street = this.props.form.getFieldValue('street');
    const no = this.props.form.getFieldValue('building_number');
    const city = this.props.form.getFieldValue('city');

    if (!this.isItaly || !street || !city) {
      return undefined;
    }

    const { google } = this.props;
    const geocoder = new google.maps.Geocoder();

    const address = `${street} ${no} ${city}`;

    geocoder.geocode({'address': address}, (results, status) => {
      if (status !== 'OK') {
        return undefined;
      }

      const postal_code = Array.isArray(results) && results.length && Array.isArray(results[0].address_components) && results[0].address_components.find(a => a.types[0] === "postal_code");

      return postal_code && postal_code.short_name;
    });
  };

  render() {
    const { translationsStore, dataCollection, mypageStore } = this.props;
    const {getFieldDecorator} = this.props.form;

    const user = mypageStore.remoteUserData;
    const remoteUserIsIsDealerCustomer = !mypageStore.remoteUserIsFromMoVeInProgram;
    return (
          <Form className={'ProfileViewForm'} onSubmit={this.handleSubmit}>
            <SmallDivision title={translationsStore.translate('profileView.sectionRecordData.title')} icon={<AccountSquareIcon />} />
            {mypageStore.hasToCompleteProfile && <MyPageAlert className={'ProfileAlert'} message="Prima di acquistare il servizio inserisci tutti i tuoi dati anagrafici e completa la registrazione" type="warning" />}
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('first_name', {
                    initialValue: this.getUserField('first_name'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    <LabelInput
                      label={translationsStore.translate('profileView.sectionRecordData.labelName')}
                      disabled={!this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('last_name', {
                    initialValue: this.getUserField('last_name'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    <LabelInput
                      label={translationsStore.translate('profileView.sectionRecordData.labelSurname')}
                      disabled={!this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            {!this.isLegalPerson() &&
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('birth_date', {
                    initialValue: this.getUserField('birth_date') ? moment(this.getUserField('birth_date')) : null,
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    <LabelInput
                      type='date'
                      label={translationsStore.translate('profileView.sectionRecordData.labelBirthdate')}
                      disabled={remoteUserIsIsDealerCustomer}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('birth_province', {
                    initialValue: this.getUserField('birth_province'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      },
                      {
                        len: 2,
                        message: translationsStore.translate('common.provinceCodeLen')
                      }
                    ]
                  })(
                    <ProvinceSelector
                      label={translationsStore.translate('profileView.sectionRecordData.labelBirthplaceSub1')}
                      disabled={remoteUserIsIsDealerCustomer}
                      showCodeAsLabel={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('birth_place', {
                    initialValue: this.getUserField('birth_place'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    <LabelInput
                      label={translationsStore.translate('profileView.sectionRecordData.labelBirthplaceSub2')}
                      required
                      disabled={remoteUserIsIsDealerCustomer}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            }

            <Row gutter={24}>
              <Col span={12}>
                {!this.isLegalPerson() ?
                  <Form.Item>
                    {getFieldDecorator('uin', {
                      initialValue: this.getUserField('uin'),
                      rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                    })(
                      <LabelInput
                        label={translationsStore.translate('profileView.sectionRecordData.labelNationalNumber')}
                        disabled
                      />
                    )}
                  </Form.Item>
                  :
                  <Form.Item>
                    {getFieldDecorator('uin', {
                      initialValue: this.getUserField('uin'),
                      rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                    })(
                      <LabelInput
                        label={translationsStore.translate('profileView.sectionRecordData.labelVATIN')}
                        disabled
                      />
                    )}
                  </Form.Item>
                }
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('province', {
                    initialValue: this.getUserAddressField('province'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      },
                      {
                        len: 2,
                        message: translationsStore.translate('common.provinceCodeLen')
                      }
                    ]
                  })(
                    !this.isItaly() ? (
                      <LabelInput
                        label={translationsStore.translate('profileView.sectionRecordData.labelResidenceplaceSub1')}
                        disabled={this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                      />
                    ) : (
                      <ProvinceSelector
                        label={translationsStore.translate('profileView.sectionRecordData.labelResidenceplaceSub1')}
                        disabled={this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                        onChange={this.onChangeProvince()}
                      />
                    )
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('city', {
                    initialValue: this.getUserAddressField('city'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    !this.isItaly() ? (
                      <LabelInput
                        label={translationsStore.translate('profileView.sectionRecordData.labelResidenceplaceSub2')}
                        disabled={this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                      />
                    ) : (
                      <CitySelector
                        province={this.props.form.getFieldValue('province')}
                        useInputText={this.props.form.getFieldValue('country') !== 'IT'}
                        label={translationsStore.translate('profileView.sectionRecordData.labelResidenceplaceSub2')}
                        disabled={this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                        onChange={this.onAddressChange}
                      />
                    )
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('street', {
                    initialValue: this.getUserAddressField('street'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    <LabelInput
                      label={translationsStore.translate('profileView.sectionRecordData.labelResidenceplaceAddress')}
                      disabled={this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                      onChange={(e) => this.onAddressChange(e.target.value)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('building_number', {
                    initialValue: this.getUserAddressField('building_number'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    <LabelInput
                      label={translationsStore.translate('profileView.sectionRecordData.labelResidenceplaceHouseNumber')}
                      disabled={this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                      onChange={this.onAddressChange}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('postcode', {
                    initialValue: this.getUserAddressField('postcode'),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    <LabelInput
                      label={translationsStore.translate('profileView.sectionRecordData.labelResidenceplacePostcode')}
                      disabled={this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('country', {
                    initialValue: this.getCountry(),
                    rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      }
                    ]
                  })(
                    <StateSelector
                      label={translationsStore.translate('profileView.sectionRecordData.labelResidenceplaceCountry')}
                      disabled={this.isLegalPerson() || remoteUserIsIsDealerCustomer}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            {this.isLegalPerson() &&
            <Row gutter={24}>
              <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator('invoice_code', {
                      initialValue: this.getUserField('invoice_code'),
                      rules: [
                      {
                        required: true,
                        message: translationsStore.translate('common.requiredField')
                      },
                      {
                        type: "string",
                        len: 7,
                        message: translationsStore.translate('common.invoiceCodeLen')
                      }
                    ]
                    })(
                      <LabelInput
                        label={translationsStore.translate('profileView.sectionRecordData.labelInvoiceCode')}
                      />
                    )}
                  </Form.Item>
              </Col>
            </Row>
            }

            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Form.Item>
                <MyPageButton
                  htmlType='submit'
                  disabled={mypageStore.loadingRemoteUserData || remoteUserIsIsDealerCustomer}
                  theme="primary"
                  title={translationsStore.translate('profileView.submitButton')}
                  solid
                />
              </Form.Item>
            </div>
          </Form>
    );
  }
}

export default Form.create({name: 'profile'})(GoogleApiWrapper({
  apiKey: K.GOOGLE_API_KEY
})(ProfileViewForm))
