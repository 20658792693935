import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import './MoveinSuggestAdditionalServicesView.css';
import VehicleSelector from '../../../../common/components/VehicleSelector';

import MyPageContainer from '../../../../common/components/MyPageContainer';
import Section from '../../../../common/components/Section';
import MyPageButton from '../../../../common/components/MyPageButton';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import AdditionServicesConditionCheckboxes from '../AdditionServicesConditionCheckboxes';
import ProductCardWithSelectableRatePlan from '../../../../common/components/ProductCardWithSelectableRatePlan';
import { AxaLogo } from '../../../../common/icons';
import K from "../../../../constants";

@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class MoveinSuggestAdditionalServicesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: undefined,
    };
  }

  componentDidMount() {
    const { mypageStore, productsStore } = this.props;
    const { selectedManageableVehicle } = mypageStore || {};

    if (!(productsStore.availableProducts && productsStore.availableProducts.length)) {
      productsStore.loadAvailableProducts();
    }

    if (
      selectedManageableVehicle &&
      (selectedManageableVehicle.airclub_general !== undefined ||
        selectedManageableVehicle.air_club_marketing !== undefined ||
        selectedManageableVehicle.air_club_profiling !== undefined ||
        selectedManageableVehicle.air_club_third_parties !== undefined)
    ) {
      this.setState({
        airclub_general: selectedManageableVehicle.airclub_general,
        checkedAgreedRestrictiveClauses: selectedManageableVehicle.airclub_general,
        air_club_profiling: selectedManageableVehicle.air_club_marketing,
        air_club_marketing: selectedManageableVehicle.air_club_profiling,
        air_club_third_parties: selectedManageableVehicle.air_club_third_parties,
      });
    }
  }

  onSubmit = async () => {
    if (this.state.selectedOption === 'none') {
      this.props.history.push('/payments');
      this.props.productsStore.resetSelectedOptionalServiceWithRatePlans();
      return;
    }

    const { airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties } = this.state;

    await this.props.mypageStore.updateAirClubMarketingConsent(airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties);

    if (this.props.optional) {
      this.props.history.push('/review-services');
    } else {
      this.props.history.push('/payments');
    }
  };

  isSubmitDisabled = () => {
    if (this.state.selectedOption === 'none') return false;

    return (
      this.state.airclub_general !== true ||
      this.state.checkedAgreedRestrictiveClauses !== true ||
      this.state.air_club_profiling === undefined ||
      this.state.air_club_marketing === undefined ||
      this.state.air_club_third_parties === undefined ||
      !this.props.productsStore.isAtLeastAnAdditionalProductRatePlanSelected
    );
  };

  onCheckedChange = (productWithRatePlans, ratePlan) => (e) => {
    const { productsStore } = this.props;

    if (e.target.checked) {
      productsStore.addSelectedOptionalServiceWithRatePlans({
        product: productWithRatePlans,
        ratePlan,
      });
      this.setState({ [productWithRatePlans.uuid]: ratePlan.id });
    } else {
      productsStore.removeSelectedOptionalServiceWithRatePlansByProductUuidAndRatePlanId(productWithRatePlans.uuid, ratePlan.id);
      if (this.state[productWithRatePlans.uuid] === ratePlan.id) {
        this.setState({ [productWithRatePlans.uuid]: undefined });
      }
    }
  };

  render() {
    const { productsStore, translationsStore, optional } = this.props;
    const { isAtLeastAnAdditionalProductRatePlanSelected } = productsStore;

    if (!productsStore.selectedProduct) {
      return <Redirect to="/" />;
    }

    const suggestedAdditionalProductsProps = [
      {
        features: translationsStore.translate('additionalServicesView.carLocator.features'),
        productWithRatePlans: productsStore.availableProducts.find((product) => productsStore.isCarlLocator(product)),
      },
      {
        isHighlighted: true,
        topBadgeText: translationsStore.translate('additionalServicesView.mostSoldAfter'),
        additionalTitleIcon: (
          <div className={'AxaIconContainer'}>
            <AxaLogo />
          </div>
        ),
        features: translationsStore.translate('additionalServicesView.baseAssistance.features'),
        productWithRatePlans: productsStore.availableProducts.find((product) => productsStore.isAssistanceBase(product)),
      },
      {
        features: translationsStore.translate('additionalServicesView.findMechanic.features'),
        productWithRatePlans: productsStore.availableProducts.find((product) => productsStore.isFindMechanic(product)),
      },
    ];

    const buyableSuggestedAdditionalProductsProps = suggestedAdditionalProductsProps.filter(
      (suggestedProduct) => suggestedProduct.productWithRatePlans && productsStore.isBuyableProduct(suggestedProduct.productWithRatePlans)
    );

    if (!buyableSuggestedAdditionalProductsProps.length) {
      return <Redirect to="/payments" />;
    }

    return (
      <MyPageContainer className="SuggestedAdditionalServicesView" dataCollection={this.props.dataCollection}>
        <VehicleSelector disabled={true} />

        <Section>
          <>
            <div>
              <h2 className={'SuggestedAdditionalServicesSelectionTitle'} dangerouslySetInnerHTML={{ __html: translationsStore.translate('additionalServicesView.title') }} />
            </div>

            <div className={'ThreeColumnView'} style={{marginTop: '60px'}}>
              {buyableSuggestedAdditionalProductsProps.map((suggestedAdditionalProductProps, index) => (
                <div key={index} className={'OneColumnComponent'}>
                  <ProductCardWithSelectableRatePlan
                    {...suggestedAdditionalProductProps}
                    onCheckedChange={this.onCheckedChange}
                    checkedRatePlanId={this.state[suggestedAdditionalProductProps.productWithRatePlans.uuid]}
                  />
                </div>
              ))}
            </div>
          </>

          {isAtLeastAnAdditionalProductRatePlanSelected && (
            <AdditionServicesConditionCheckboxes
              airClubGeneral={this.state.airclub_general}
              setGeneral={(newValue) => this.setState({ airclub_general: newValue })}
              checkedAgreedRestrictiveClauses={this.state.checkedAgreedRestrictiveClauses}
              setCheckedAgreedRestrictiveClauses={(newValue) => this.setState({ checkedAgreedRestrictiveClauses: newValue })}
              airClubProfiling={this.state.air_club_profiling}
              setProfiling={(newValue) => this.setState({ air_club_profiling: newValue })}
              airClubMarketing={this.state.air_club_marketing}
              setMarketing={(newValue) => this.setState({ air_club_marketing: newValue })}
              airClubThirdParties={this.state.air_club_third_parties}
              setThirdParties={(newValue) => this.setState({ air_club_third_parties: newValue })}
            />
          )}

          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) =>
              breakpoints[currentBreakpoint] > breakpoints.extrasmall ? (
                <>
                  <div className={'SuggestedAdditionalServicesProceedButtonContainer'}>
                    <MyPageButton
                      theme="primary"
                      className={'SuggestedAdditionalServicesBuyButton'}
                      solid
                      disabled={this.props.mypageStore.loadingUpdatingAirClubMarketing || this.isSubmitDisabled()}
                      title={translationsStore.translate('additionalServicesView.proceedToBuy')}
                      onClick={this.onSubmit}
                    />
                  </div>
                  {optional && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 16 }}>
                      <div style={{ marginRight: 16 }}>Aderisci a Move-In senza Servizi Aggiuntivi</div>
                      <MyPageButton
                        theme="gray"
                        className={'SuggestedAdditionalServicesDontBuyButton'}
                        solid
                        title={translationsStore.translate('additionalServicesView.proceedWithout')}
                        onClick={() => this.setState({ selectedOption: 'none' }, this.onSubmit)}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={'SuggestedAdditionalServicesProceedButtonsMobileContainer'}>
                    {optional && (
                      <div>
                        <MyPageButton
                          theme="gray"
                          className={'SuggestedAdditionalServicesDontBuyMobileButton'}
                          solid
                          title={translationsStore.translate('additionalServicesView.proceedWithoutNotInterested')}
                          onClick={() => this.setState({ selectedOption: 'none' }, this.onSubmit)}
                        />
                      </div>
                    )}
                    <MyPageButton
                      theme="primary"
                      className={'SuggestedAdditionalServicesBuyButton'}
                      innerStyle={{ maxWidth: 100 }}
                      solid
                      disabled={this.props.mypageStore.loadingUpdatingAirClubMarketing || this.isSubmitDisabled()}
                      title={translationsStore.translate('additionalServicesView.proceedToBuy')}
                      onClick={this.onSubmit}
                    />
                  </div>
                </>
              )
            }
          </BreakpointsMedia>
        </Section>
        <div className="InformativeNotesContract">
          <a href={K.POLICY_BASE_URL.concat('AIR - Nota informativa precontrattuale.pdf')} target="_blank" alt="Nota informativa">
            Nota informativa precontrattuale
          </a>
        </div>
      </MyPageContainer>
    );
  }
}

export default MoveinSuggestAdditionalServicesView;
