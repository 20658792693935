import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Checkbox, Col, Input, Row } from 'antd';
import { mypageHelper } from '../../../../../helpers';

import './InstallerRow.css';

const { getOperationalAddress } = mypageHelper;

@inject('translationsStore', 'moveinStore')
@observer
export default class InstallerRow extends Component {
  render() {
    const { translationsStore, moveinStore, installer } = this.props;
    const address = getOperationalAddress(installer) || {};
    const availableOBDs = Math.max(0, installer.assets.length - installer.vehicles.length);

    return (
      <div className="InstallerRow">
        <Checkbox
          className="InstallerCheck"
          onChange={() => moveinStore.setSelectedInstaller(installer)}
          checked={!!moveinStore.selectedInstaller && moveinStore.selectedInstaller === installer}
        />
        <Row gutter={8} className="InstallerFields" type="flex" justify="space-around" align="middle">
          <Col xs={24} md={2}>
            <div>{translationsStore.translate('movein.stepInstaller.dataAvailability')}</div>
            <Input className={`AvailabeOBDs${availableOBDs === 0 ? ' noOBDs' : ''}`} value={availableOBDs} />
          </Col>
          <Col xs={12} md={8}>
            <div>{translationsStore.translate('movein.stepInstaller.dataInstallerName')}</div>
            <Input value={installer.name} />
          </Col>
          <Col xs={12} md={6}>
            <div>Comune</div>
            <Input value={address.city || translationsStore.translate('common.notAvailable')} />
          </Col>
          <Col xs={12} md={6}>
            <div>Indirizzo</div>
            <Input value={`${address.street || translationsStore.translate('common.notAvailable')}, ${address.building_number || translationsStore.translate('common.notAvailable')}`} />
          </Col>
          <Col xs={12} md={2}>
            <div>Provincia</div>
            <Input value={address.province || translationsStore.translate('common.notAvailable')} />
          </Col>
        </Row>
      </div>
    );
  }
}
