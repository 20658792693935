import React, { Component, Fragment } from 'react';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import {reaction} from 'mobx';
import { PulseLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import {Table, Input, Select, Checkbox} from "antd";
import K from '../../../../constants';

import MyPageContainer from '../../../../common/components/MyPageContainer';
import {FilesLabeledOutlinedIcon} from '../../../../common/icons';
import MyPageRadioButton from '../../../../common/components/MyPageRadioButton';
import MyPageRadioGroup from '../../../../common/components/MyPageRadioGroup';
import VehicleSelector from '../../../../common/components/VehicleSelector/';

import {
  StaticDocument,
  MoveInPrivacyPolicy,
  AirPrivacyPolicy,
  AirClubPrivacyPolicy,
  InvoiceDocument,
  ContractDocument
} from "./Documents";

import './DocumentsView.css';
import AddDocumentButton from "./AddDocumentButton";
import CustomDocument from "./CustomDocument";

const { Search } = Input;

const { Option } = Select;

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Azione',
    dataIndex: 'actions',
    className: 'column-actions document-buttons',
    key: 'actions',
  },
  {
    title: 'Tipologia',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Veicolo',
    dataIndex: 'vehicle',
    key: 'vehicle'
  },
  {
    title: 'Data caricamento',
    dataIndex: 'date',
    key: 'date',
    render: date => moment(date).format("DD/MM/YYYY"),
  },
];

@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class DocumentsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: undefined,
      search: undefined,
      showAllVehicles: false
    }
  }

  componentDidMount() {
    disposeOnUnmount(
      this,
      reaction(
        () => this.props.mypageStore.selectedManageableVehicle,
        vehicle => {
          if (vehicle) {
            this.props.mypageStore.loadDocuments();
            this.props.mypageStore.loadContractsGeneralConditionsName();
          }
        },
        { fireImmediately: true }
      )
    );
  }

  onViewGroupChange = e => {
    const {
      dataCollection: {
        handlers: { goToKeyRoute }
      }
    } = this.props;
    goToKeyRoute(e.target.value);
  };

  getAirPrivacyPolicy = (vehicle) => {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.generalDocuments.labelPersonalDataProcessingPolicyAir'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: vehicle.createdAt,
      actions: <AirPrivacyPolicy
      translationsStore={translationsStore} />
    }
  }

  getAirRenewContract = (vehicle) => {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.generalDocuments.labelGeneralConditionRenewAir'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: vehicle.createdAt,
      actions: <StaticDocument src={K.POLICY_BASE_URL.concat('condizioni-generali-di-contratto-air-renew-flagged.pdf')}
      translationsStore={translationsStore} />
    }
  }

  getMoveInContract = (vehicle) => {
    const {translationsStore, mypageStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.generalDocuments.labelStandardFormContract'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: vehicle.createdAt,
      actions: <StaticDocument src={K.POLICY_BASE_URL.concat(mypageStore.contractGeneralConditionsName)}
      translationsStore={translationsStore} />
    }
  }
  getMoveInPrivacyPolicy(vehicle) {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.generalDocuments.labelPersonalDataProcessingPolicy'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: vehicle.createdAt,
      actions: <MoveInPrivacyPolicy
      translationsStore={translationsStore} />
    }
  }

  getAirClubSubscriptionDate = (vehicle) => {
    const subscription = vehicle.subscriptions
      .find(sub => sub.product.features.some(feature => feature.node.name === 'reminder'));
    return subscription.since;
  }

  getAssistanceSubscriptionDate = (vehicle) => {
    const subscription = vehicle.subscriptions
      .find(sub => sub.product.features.some(feature => feature.node.name === 'axa-assistance') && !sub.product.features.some(feature => feature.node.name === 'reminder'));
    return subscription.since;
  }

  getAdditionalServiceSubscriptionData = (vehicle) => {
    const subscription = vehicle.subscriptions
      .find(sub => sub.product.category === 'add-on' && !sub.product.features.some(feature => feature.node.name === 'reminder'));
    return subscription && subscription.since;
  }

  getAirClubContract = (vehicle) => {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.generalDocuments.labelAirClubFormContract'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: this.getAirClubSubscriptionDate(vehicle),
      actions: <StaticDocument src={K.POLICY_BASE_URL.concat('Air-club-con-flag.pdf')}
      translationsStore={translationsStore} />
    }
  }
  getAirClubPrivacyPolicy = (vehicle) => {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.generalDocuments.labelAirClubPolicy'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: this.getAirClubSubscriptionDate(vehicle),
      actions: <AirClubPrivacyPolicy
      translationsStore={translationsStore} />
    }
  }
  getAirClubNotaInformativa = (vehicle) => {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.generalDocuments.labelAirClubNotaInformativa'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: this.getAirClubSubscriptionDate(vehicle),
      actions: <StaticDocument src={K.POLICY_BASE_URL.concat('AIR - Nota informativa precontrattuale.pdf')}
      translationsStore={translationsStore} />
    }
  }
  getAirClubTessera = (vehicle) => {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.tesseraTitle'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: this.getAirClubSubscriptionDate(vehicle),
      actions: <StaticDocument src={K.POLICY_BASE_URL.concat('tessera_air_club.pdf')}
      translationsStore={translationsStore} />
    }
  }

  getAdditionalServicesPrivacyPolicy = (vehicle) => {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.generalDocuments.labelSubscriptionNamePersonalDataProcessingPolicy')
        .replace('__SUBSCRIPTION_NAME__', translationsStore.translate('documentsView.generalDocuments.additionalServices')),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: this.getAdditionalServiceSubscriptionData(vehicle),
      actions: <AirClubPrivacyPolicy translationsStore={translationsStore} src={K.POLICY_BASE_URL.concat('personal-data-processing-policy-addons.it.pdf')} />,
    };
  }

  getBaseAssistanceTessera = (vehicle) => {
    const {translationsStore} = this.props;

    return {
      name: translationsStore.translate('documentsView.tesseraAssistanceTitle'),
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: this.getAssistanceSubscriptionDate(vehicle),
      actions: <StaticDocument src={K.POLICY_BASE_URL.concat('tessera_assistance.pdf')} translationsStore={translationsStore} />
    }
  }

  getStaticDocuments = (vehicle) => {
    const { mypageStore, productsStore } = this.props;

    const documents = [];

    if(mypageStore.remoteUserIsFromMoVeInProgram) {
      documents.push(this.getMoveInContract(vehicle));
      documents.push(this.getMoveInPrivacyPolicy(vehicle));
    } else {
      documents.push(this.getAirPrivacyPolicy(vehicle));
      if (productsStore.isRenewSubscribed) {
        documents.push(this.getAirRenewContract(vehicle));
      }
    }

    if(mypageStore.hasVehicleReminder(vehicle)) {
      documents.push(this.getAirClubContract(vehicle));
      documents.push(this.getAirClubNotaInformativa(vehicle));
      documents.push(this.getAirClubTessera(vehicle));
    }

    if(mypageStore.hasAssistanceBase(vehicle)) {
      documents.push(this.getBaseAssistanceTessera(vehicle));
    }

    if(mypageStore.hasAdditionalServiceSubscription(vehicle)) {
      documents.push(this.getAdditionalServicesPrivacyPolicy(vehicle));
    }

    return documents;
  }

  getInvoice = (vehicle, invoice) => {
    const {translationsStore} = this.props;

    return {
      name: `${translationsStore.translate('documentsView.invoices.label')} ${invoice.node.name}`,
      type: 'Fattura',
      vehicle: vehicle.license_plate,
      date: invoice.node.date,
      actions: <InvoiceDocument
        slugCode={`${translationsStore.translate('documentsView.invoices.label')}.${invoice.node.date}`}
        link={invoice.node.url}
        translationsStore={translationsStore}
      />
    }
  }

  getContract = (vehicle, invoice) => {
    const {translationsStore} = this.props;

    return {
      name: 'Contratto',
      type: 'Contratto',
      vehicle: vehicle.license_plate,
      date: invoice.node.date,
      actions: <ContractDocument
        link={invoice.node.url}
        title={invoice.node.name}
        translationsStore={translationsStore}
      />
    }
  }

  getCustomDocument = (vehicle, document) => {
    return {
      name: document.node.name,
      type: document.node.category === 'invoice'? 'Fattura' : document.node.category === 'contract' ? 'Contratto' : 'Generico',
      vehicle: vehicle.license_plate,
      date: document.node.date,
      actions: <CustomDocument
        document={document}
        vehicle={vehicle}
      />
    }
  }

  getRemoteDocuments = (vehicle) => {
    const {mypageStore} = this.props;

    if (!mypageStore.documents) {
      return [];
    }

    const vehicleDocuments = mypageStore.documents.vehicles && mypageStore.documents.vehicles.find(v => vehicle.uuid === v.uuid);

    const documents = vehicleDocuments && vehicleDocuments.documents;

    if (!Array.isArray(documents)) {
      return [];
    }

    return documents
      .map(document => {
        if(document.node.source === 'custom') return this.getCustomDocument(vehicle, document);
        if(document.node.type === 'invoice') return this.getInvoice(vehicle, document);
        if(document.node.type === 'contract') return this.getContract(vehicle, document);

        return {
          name: document.node.name,
          type: 'Generico',
          vehicle: vehicle.license_plate,
          date: document.node.date,
          actions: <FilesLabeledOutlinedIcon width={16} height={16} />
        }
      });
  }

  loadVehicle = (vehicle) => {
    if(!vehicle) {
      return [];
    }

    return [
      ...this.getStaticDocuments(vehicle),
      ...this.getRemoteDocuments(vehicle)
    ];
  }

  filterDocuments = (document) => {
    return (
      !this.state.selectedCategory || this.state.selectedCategory === document.type)
      && (!this.state.search ||
        document.name.toLowerCase().includes(this.state.search.toLowerCase()) ||
        document.type.toLowerCase().includes(this.state.search.toLowerCase()) ||
        document.vehicle.toLowerCase().includes(this.state.search.toLowerCase()) ||
        document.date.toLowerCase().includes(this.state.search.toLowerCase()))
  }

  getDataset = () => {
    if(!this.state.showAllVehicles) {
      const vehicle = this.props.mypageStore.selectedManageableVehicle;
      return this.loadVehicle(vehicle)
    }

    return this.props.mypageStore.manageableVehicles.map(this.loadVehicle).flat()
  }

  render() {
    const { dataCollection, translationsStore, mypageStore } = this.props,
      hasMultipleVehicles = mypageStore.manageableVehicles.length > 1;

    const viewGroupItems = [
      { value: 'vehicles', title: translationsStore.translate('groupViews.myair.vehicles') },
      { value: 'documents', title: translationsStore.translate('groupViews.myair.documents') },
      // { value: 'assets', title: translationsStore.translate('groupViews.myair.assets') },
      { value: 'services', title: translationsStore.translate('groupViews.myair.services') }
    ];
    const defaultGroupItem = 'documents';

    const loadingView = mypageStore.loadingDocuments;

    const dataSource = this.getDataset() || [];

    return (
      <MyPageContainer className="DocumentsView" dataCollection={dataCollection}>
        <LoadingOverlay
          active={loadingView}
          spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true} />}
          styles={{
            overlay: base => ({
              ...base,
              background: 'var(--grey-light-color-50)'
            })
          }}
        >
          <VehicleSelector />
          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {
              const inputProps = breakpoints[currentBreakpoint] >= breakpoints.desktop ? { size: 'large' } : {};
              return (
                <MyPageRadioGroup {...inputProps} className="SubPageNavbar" defaultValue={defaultGroupItem} onChange={this.onViewGroupChange} buttonStyle="solid">
                  {viewGroupItems.map((item, index) => (
                    <MyPageRadioButton key={index} value={item.value}>
                      {item.title}
                    </MyPageRadioButton>
                  ))}
                </MyPageRadioGroup>
              );
            }}
          </BreakpointsMedia>
          <Search
            className="FilterRow"
            placeholder="Cerca documento..."
            onChange={e => this.setState({search: e.target.value})}
            style={{ maxWidth: 400 }}
          />
          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {

              return (
                <Fragment>
                  <div className={breakpoints[currentBreakpoint] >= breakpoints.desktop ? 'FilterRow' : 'FilterRow mobile'}>
                    <Select placeholder="Scegli tipologia" defaultValue={undefined} style={{ width: 240, marginRight: 20 }} onChange={value => this.setState({selectedCategory: value})} allowClear>
                      <Option value="Contratto">Contratto</Option>
                      <Option value="Fattura">Fattura</Option>
                      <Option value="Generico">Generico</Option>
                    </Select>

                    {hasMultipleVehicles && (
                      <div>
                        Visualizza per tutte le auto <Checkbox onChange={e => this.setState({showAllVehicles: e.target.checked})} className={breakpoints[currentBreakpoint] >= breakpoints.desktop ? '' : 'align-right'}  />
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            }}
          </BreakpointsMedia>


          <Table className="AgendaTable" pagination={false} columns={columns} dataSource={dataSource.filter(this.filterDocuments).map((item, index) => ({ key: index, ...item }))} />
          <AddDocumentButton />
        </LoadingOverlay>
      </MyPageContainer>
    );
  }
}

export default DocumentsView;
