import { observable, reaction, action, computed } from 'mobx';
import { get, set } from 'mobx';
import { createTransformer } from 'mobx-utils';
import schema from 'async-validator';

import { authStore, commonStore } from '.';
import { api } from '../services';

class LoginStore {
  constructor({ authStore, commonStore }) {
    this.authStore = authStore;
    this.commonStore = commonStore;

    this.defaultFields = {
      username: '',
      password: '',
      newPassword: ''
    };

    this.defaultTouched = { username: false, password: false, newPassword: false };

    this.defaultForgottenFields = {
      username: '',
      verificationCode: '',
      newPassword: ''
    };
    this.defaultForgottenTouched = {
      username: false,
      verificationCode: false,
      newPassword: false
    };
    this.defaultForgottenData = {
      stepOneRemoteError: undefined,
      stepTwoRemoteError: undefined,
      stepOneCleared: false,
      stepTwoCleared: false
    };
    //
    const storageValue = this.commonStore.getStoragePreferencesKey('keepLogged');
    const keepLogged = storageValue !== undefined ? storageValue : true;
    const loginKeepLogged = storageValue !== undefined ? storageValue : true;
    this.defaultFields = { ...this.defaultFields, loginKeepLogged };
    //
  }

  @observable
  _loginUsername = this.defaultFields.username;

  @observable
  _loginPassword = this.defaultFields.password;

  @observable
  _loginNewPassword = this.defaultFields.newPassword;

  @computed get fields() {
    return { username: this._loginUsername, password: this._loginPassword, newPassword: this._loginNewPassword };
  }

  @observable
  _touched = this.defaultTouched;

  @computed get fieldTouched() {
    return createTransformer(field => {
      return this._touched[field];
    });
  }

  @observable
  loginKeepLogged = this.defaultFields.loginKeepLogged;

  @action
  clearFields = () => {
    this._loginUsername = '';
    this._loginPassword = '';
    this._loginNewPassword = '';
    this._touched = this.defaultTouched;
  };

  @action
  setUsername = newValue => {
    this._loginUsername = newValue;
    this._touched = { ...this._touched, username: true };
  };

  @action
  setPassword = newValue => {
    this._loginPassword = newValue;
    this._touched = { ...this._touched, password: true };
  };

  @action
  setNewPassword = newValue => {
    this._loginNewPassword = newValue;
    this._touched = { ...this._touched, newPassword: true };
  };

  @action
  setKeepLogged = newValue => {
    //
    this.commonStore.setStoragePreferencesKey('keepLogged', newValue);
    //
    this.loginKeepLogged = newValue;
  };

  @observable
  loginLoading = false;

  @observable
  _loginErrors = []; // will contain domain attribute ('local'|'remote')

  @computed get fieldError() {
    return createTransformer(field => {
      return (this._loginErrors || []).filter(error => error.field === field).shift();
    });
  }

  @computed get remoteError() {
    return this._loginErrors.find(error => error.domain === 'remote');
  }

  @observable
  _forgottenErrors = [];

  @computed get forgottenError() {
    return createTransformer(field => {
      return (this._forgottenErrors || []).filter(error => error.field === field).shift();
    });
  }

  validateFields = ({ fields: focusFields, schemaDescriptor, onValidate }) => {
    return new Promise((resolve, reject) => {
      const validator = new schema(schemaDescriptor);
      validator.validate(focusFields, (errors, fields) => {
        let localErrors = (errors || []).map(error => ({ ...error, domain: 'local' }));
        onValidate(errors, fields);
        if (localErrors.length > 0) {
          reject(false);
        } else {
          resolve(true);
        }
      });
    });
  };

  @action
  validateLoginFields = () => {
    const schemaDescriptor = {
      username: {
        type: 'string',
        required: true
      },
      password: {
        type: 'string',
        required: true
      },
      newPassword: {
        type: 'string',
        required: this.authStore.newPasswordRequired,
        pattern: /^(?=.{8,}$)(?=.*[A-Z])(?=.*[0-9]).*$/
      }
    };
    const onValidate = (errors, fields) => {
      let localErrors = (errors || []).map(error => ({ ...error, domain: 'local' }));
      this._loginErrors = localErrors;
    };
    return this.validateFields({ fields: this.fields, schemaDescriptor, onValidate });
  };

  @action
  validateForgottenFields = () => {
    const schemaDescriptor = {
      username: {
        type: 'string',
        required: true
      },
      verificationCode: {
        type: 'string',
        required: this.getForgottenData('stepOneCleared')
      },
      newPassword: {
        type: 'string',
        equired: this.getForgottenData('stepOneCleared'),
        pattern: /^(?=.{8,}$)(?=.*[A-Z])(?=.*[0-9]).*$/
      }
    };
    const onValidate = (errors, fields) => {
      let localErrors = (errors || []).map(error => ({ ...error, domain: 'local' }));
      this._forgottenErrors = localErrors;
    };
    return this.validateFields({ fields: this.getForgottenFields([]), schemaDescriptor, onValidate });
  };

  @action
  loginSubmit = () => {
    return this.validateLoginFields()
      .then(() => {
        // fields validation successful
        this.loginLoading = true;
        const loginAction = this.authStore.newPasswordRequired ? this.authStore.completeNewPassword : this.authStore.login;
        loginAction
          .bind(this.authStore)(this.fields)
          .then(() => ({}))
          .catch(error => ({ error }))
          .then(({ error }) => {
            this._loginErrors = [];
            this.loginLoading = false;
            if (error) {
              let remoteErrors = [{ ...error, domain: 'remote' }];
              this._loginErrors = remoteErrors;
            } else {
              if (!this.authStore.newPasswordRequired) {
                this.clearFields();
                this.authStore.setIsLoggedIn(true);
              }
            }
          });
      })
      .catch(() => {
        // fields validation not passed
      });
  };

  @observable
  forgottenFields = this.defaultForgottenFields;
  @observable
  forgottenTouched = this.defaultForgottenTouched;
  @observable
  forgottenData = this.defaultForgottenData;

  @computed get getForgottenFields() {
    return createTransformer(field => {
      // const isEmpty = Object.keys(field).length === 0 && field.constructor === Object;
      const isEmpty = Array.isArray(field) && field.length === 0;
      // TODO improvement
      if (isEmpty) {
        return this.forgottenFields;
      }
      return get(this.forgottenFields, field);
    });
  }
  @computed get getForgottenTouched() {
    return createTransformer(field => {
      const isEmpty = Array.isArray(field) && field.length === 0;
      // TODO improvement
      if (isEmpty) {
        return this.forgottenTouched;
      }
      return get(this.forgottenTouched, field);
    });
  }
  @computed get getForgottenData() {
    return createTransformer(field => {
      const isEmpty = Array.isArray(field) && field.length === 0;
      // TODO improvement
      if (isEmpty) {
        return this.forgottenData;
      }
      return get(this.forgottenData, field);
    });
  }

  @action
  setForgottenFields = newObjectValues => {
    Object.keys(newObjectValues).forEach(key => {
      set(this.forgottenFields, key, newObjectValues[key]);
    });
  };
  @action
  setForgottenTouched = newObjectValues => {
    Object.keys(newObjectValues).forEach(key => {
      set(this.forgottenTouched, key, newObjectValues[key]);
    });
  };
  @action
  setForgottenData = newObjectValues => {
    Object.keys(newObjectValues).forEach(key => {
      set(this.forgottenData, key, newObjectValues[key]);
    });
    // this.forgottenData = { ...this.forgottenData, ...newObjectValue };
  };

  @action
  resetForgottenFields = () => {
    Object.keys(this.defaultForgottenFields).forEach(key => {
      set(this.forgottenData, key, this.defaultForgottenFields[key]);
    });
  };
  @action
  resetForgottenTouched = () => {
    Object.keys(this.defaultForgottenTouched).forEach(key => {
      set(this.forgottenData, key, this.defaultForgottenTouched[key]);
    });
  };
  @action
  resetForgottenData = () => {
    Object.keys(this.defaultForgottenData).forEach(key => {
      set(this.forgottenData, key, this.defaultForgottenData[key]);
    });
    // this.forgottenData = this.defaultForgottenData;
  };

  @action
  forgotPasswordProceed = () => {
    const username = this.getForgottenFields('username');
    return api('forgotPassword', username)
      .then(() => {
        this.setForgottenData({ stepOneCleared: true, stepTwoCleared: false });
      })
      .catch(err => {
        this.setForgottenData({ stepOneRemoteError: err });
      });
  };

  @action
  handleForgotPasswordSubmit = () => {
    const username = this.getForgottenFields('username');
    const verificationCode = this.getForgottenFields('verificationCode');
    const newPassword = this.getForgottenFields('newPassword');
    return api('forgotPasswordSubmit', username, verificationCode, newPassword)
      .then(() => {
        this.setForgottenData({ stepOneCleared: true, stepTwoCleared: true });
      })
      .catch(err => {
        this.setForgottenData({ stepTwoRemoteError: err });
      });
  };
}

export default new LoginStore({ authStore, commonStore });
