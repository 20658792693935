import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { api } from '../../../../services';
import { mypageHelper } from '../../../../helpers';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import moment from 'moment';
import classNames from 'classnames';
import { Table } from 'antd';
import AirModal from '../../../../common/components/AirModal';
import MyPageButton from '../../../../common/components/MyPageButton';
import ProfileViewForm from './ProfileViewForm';
import ChangePasswordForm from "./ChangePasswordForm";
import ReportNotificationWithActions from './ReportNotificationWithActions';

import './ProfileView.css';

import MyPageContainer from "../../../../common/components/MyPageContainer";

@withRouter
@inject('authStore', 'mypageStore', 'translationsStore')
@observer
class ProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivedNotification: undefined,
      archivedNotificationVisible: false,
      archivedHistory: []
    };
    this.paramNameNotificationId = 'notification_id';

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(this.paramNameNotificationId)) {
      const {
          attributes: { 'custom:external_uuid': userUuid },
        } = this.props.authStore.user,
        notificationId = urlParams.get(this.paramNameNotificationId);

      (async () => {
        try {
          const notification = await api('getNotification', userUuid, notificationId);
          if (notification.state === 'archived') {
            let archivedHistory = await api('getArchivedNotifications', userUuid, notification.type);
            archivedHistory = archivedHistory.sort((a, b) => (a.timestamp > b.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0));
            this.setState({ archivedNotificationVisible: true, archivedNotification: notification, archivedHistory });
          }
          if (notification.state === 'pending') {
            const { mypageStore } = props;
            this.setState({ reportNotification: notification });
            mypageStore.setReportNotification(notification);
          }
        } catch (error) {
          this.urlClearSearch();
        }
      })();
    }
  }

  componentDidMount() {
    disposeOnUnmount(
      this,
      reaction(
        () => this.props.mypageStore.reportNotification,
        (reportNotification) => {
          const reportNotificationVisible = !!reportNotification;
          this.setState({ reportNotificationVisible });
        },
        { fireImmediately: true }
      )
    );
  }

  urlClearSearch = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete(this.paramNameNotificationId);
    const search = url.searchParams.toString();
    this.props.history.replace(`${url.pathname}${search ? `?${search}` : ''}`);
  };

  handleArchivedNotificationDismiss = () => {
    this.setState({ archivedNotificationVisible: false, archivedNotification: undefined, archivedHistory: [] }, () => {
      this.urlClearSearch();
      mypageHelper.handleDeepLink('myair://app.myair.io/');
    });
  };

  render() {
    const { dataCollection } = this.props;
    const { archivedNotificationVisible, archivedNotification, archivedHistory } = this.state;
    const { reportNotificationVisible } = this.state;
    let { mypageStore: { reportNotification = {} } } = this.props;

    const archiveColumns = [
      {
        dataIndex: 'date',
        width: 120,
        render: (text, record) => moment(record.timestamp).format(this.props.translationsStore.translate('profileNotifications.archived.__NOTIFICATION_DATE__'))
      },
      {
        dataIndex: 'time',
        render: (text, record) => `${this.props.translationsStore.translate('common.atTime')} ${moment(record.timestamp).format(this.props.translationsStore.translate('profileNotifications.archived.__NOTIFICATION_TIME__'))}`
      },
    ];

    return (
      <MyPageContainer dataCollection={dataCollection}>
        {archivedNotification && <BreakpointsMedia>
          {({ _, currentBreakpoint }) => {
            const { translationsStore } = this.props;
            const cls = classNames({
              ProfileNotification: true,
              [currentBreakpoint]: !!currentBreakpoint,
            });
            return (
              <AirModal
                className={cls}
                visible={archivedNotificationVisible}
                onCancel={this.handleArchivedNotificationDismiss}
                cancelButton={<MyPageButton theme="primary" solid title="OK" />}
              >
                <div style={{ marginBottom: 20 }}>
                  {translationsStore
                    .translate(`profileNotifications.archived.type.${archivedNotification.type}`)
                    .replace(/(__NOTIFICATION_DATE__|__NOTIFICATION_TIME__)/g, (match) =>
                      match === '__NOTIFICATION_DATE__'
                        ? moment(archivedNotification.timestamp).format(translationsStore.translate('profileNotifications.archived.__NOTIFICATION_DATE__'))
                        : moment(archivedNotification.timestamp).format(translationsStore.translate('profileNotifications.archived.__NOTIFICATION_TIME__'))
                    )}
                </div>
                <div style={{ marginBottom: 20, fontWeight: 'bold' }}>{translationsStore.translate('profileNotifications.archived.historyTitle')}</div>
                <Table
                  className="ProfileNotificationArchive"
                  columns={archiveColumns}
                  dataSource={archivedHistory.map((item, index) => ({ ...item, key: index }))}
                  size="small"
                  showHeader={false}
                  pagination={false}
                />
              </AirModal>
            );
          }}
        </BreakpointsMedia>}
        <ReportNotificationWithActions reportNotification={reportNotification} visible={reportNotificationVisible} />
        <ChangePasswordForm dataCollection={dataCollection} />
        <ProfileViewForm dataCollection={dataCollection} />
      </MyPageContainer>
    );
  }
}

export default ProfileView;
