import React, { Component } from 'react';
import { Radio } from 'antd';
import classNames from 'classnames';

import './MyPageRadioGroup.css';

export default class MyPageRadioGroup extends Component {
  render() {
    const isSizeLarge = this.props.size === 'large';
    const cls = classNames({
      MyPageRadioGroup: true,
      /* eslint-disable no-useless-computed-key */
      ['MyPageRadioGroup-lg']: isSizeLarge,
      [this.props.className]: !!this.props.className
    });
    return (
      <Radio.Group {...this.props} className={cls}>
        {this.props.children}
      </Radio.Group>
    );
  }
}
