import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import SmallDivision from '../../../../common/components/SmallDivision';
import { HeadphonesIcon, IphoneIcon } from '../../../../common/icons';
import MyPageContainer from '../../../../common/components/MyPageContainer';

import './SupportRegioneLombardiaView.css';
import AppBrand from '../../../../common/components/AppBrand';

@inject('translationsStore')
@observer
class SupportRegioneLombardiaView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSupportClick = () => {
    const url = 'tel:+393459782557';
    // window.location.href = url;
    window.open(url, '_blank');
    return null;
  };

  render() {
    const { translationsStore, dataCollection } = this.props;

    const brandStyle = { width: 52, height: 23, color: 'var(--white-color)', marginTop: '36px', marginLeft: 23 };
    const phoneNumber = '+393459782557';

    return (
      <MyPageContainer dataCollection={dataCollection}>
        <BreakpointsMedia>
          {({ _, currentBreakpoint }) => {
            const cls = classNames({
              SupportRegioneLombardiaView: true,
              [currentBreakpoint]: !!currentBreakpoint
            });
            return (
              <Fragment>
                <SmallDivision title={translationsStore.translate('supportView.title')} icon={<HeadphonesIcon />} style={{ marginBottom: 40 }} />
                <div className={cls}>
                  <div className="bubble">
                    <AppBrand style={brandStyle} />
                  </div>

                  <div className="title">{translationsStore.translate('supportView.phone.support')}</div>
                  <div className="subtitle">
                    <div className="days">{translationsStore.translate('supportView.subtitle.days')}</div>
                    <div className="hours">{translationsStore.translate('supportView.subtitle.hours')}</div>
                  </div>
                  <div className="numbers">
                    <div className="phoneIcon">
                      <IphoneIcon />
                    </div>

                    <div className="mobile" onClick={this.onSupportClick}>
                      {phoneNumber}
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          }}
        </BreakpointsMedia>
      </MyPageContainer>
    );
  }
}

export default SupportRegioneLombardiaView;
