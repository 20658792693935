import React, { Component } from 'react';
import {api} from "../../../../../services";
import Spin from "antd/es/spin";
import K from "../../../../../constants";

class SlimpayIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      success: false,
      iframeContent: null
    }
  }

  componentDidMount() {
    api('getSlimpayIframe', this.props.customerDetails.uuid)
      .then(result => {
        this.setState({
          loading: false,
          iframeContent: result.iframe
        }, () => {
          this.socket = new WebSocket(`wss://${K.API_URL.split("//")[1]}`);

          this.socket.addEventListener("open", () => {
            this.socket.send(JSON.stringify({type: "salutation", message: {id: this.props.customerDetails.zuora_account_id}}));
          });

          this.socket.addEventListener("message", event => {
            try {
              this.onNotification(event.data)
            } catch (e) {
              console.error("error parsing data!");
            }
          });
        })
      })
  }

  onNotification = () => {
    this.props.onNext();
  };

  componentWillUnmount() {
    this.socket && this.socket.close();
  }

  render() {
    if (this.state.loading) {
      return <Spin />
    }

    return (
      <div
        className="PaymentMethodContainer"
      >
        <div
          className="SlimpayIframe"
          dangerouslySetInnerHTML={{ __html: this.state.iframeContent}}
        />
      </div>
    )

  }
}

export default SlimpayIframe;
