import React, { Component, Fragment } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { api } from '../../../../services';
import MyPageContainer from '../../../../common/components/MyPageContainer';
import { PulseLoader } from 'react-spinners';
import { Radio, message } from 'antd';
import VehicleSelector from '../../../../common/components/VehicleSelector';
import { Media as BreakpointsMedia } from 'react-breakpoints';
import MyPageRadioGroup from '../../../../common/components/MyPageRadioGroup';
import MyPageRadioButton from '../../../../common/components/MyPageRadioButton';
import MyPageButton from '../../../../common/components/MyPageButton';
import { ErrorOutlinedIcon, InfoIcon } from '../../../../common/icons';
import { debounce, isFunction } from 'lodash';
import PaymentAgreements from './PaymentAgreements';
import K from '../../../../constants';

import './PaymentView.css';
import Section from '../../../../common/components/Section';
import { mypageHelper } from '../../../../helpers';
import SlimpayIframe from './SlimpayIframe';
import LoadingOverlay from 'react-loading-overlay';
import rollbar from "../../../../services/rollbar";

const CreditCardFriendlyReminder = inject('translationsStore')(
  observer(({ ...props }) => {
    const { translationsStore } = props;
    return <div className="FriendlyReminder"><InfoIcon width={14} height={14} style={{color: 'var(--action-primary-color)', marginRight: 10}} />{translationsStore.translate('installerView.sectionPaymentMethod.creditcard.friendlyReminder')}</div>;
  })
);

@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore', 'authStore')
@observer
export default class PaymentView extends Component {
  zuoraDOMID = 'zuora_payment';

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      selectedPaymentMethod: 'creditcard',
      loadingToken: false,
      renderingPaymentFrame: false,
      hideSubmitPaymentButton: false,
      lastPaymentErrorMessage: undefined,
      addOnServiceAgreements: {
        // airclub_general: undefined,
        // airclub_general_articles: undefined,
        air_club_profiling: undefined,
        air_club_marketing: undefined,
        air_club_third_parties: undefined
      }
    };

    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        // zuora hosted page payment is inside a frame
        if (mutation.target.tagName.toUpperCase() === 'IFRAME' && mutation.type === 'attributes' && mutation.attributeName === 'height') {
          this.setState({ renderingPaymentFrame: false });
        }
      });
    });
  }

  componentDidMount = async () => {
    const { productsStore, authStore, mypageStore } = this.props;
    const { selectedPaymentMethod } = this.state;

    if (!(productsStore.availableProducts && productsStore.availableProducts.length)) {
      await productsStore.loadAvailableProducts();
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('selectedProductId')) {

      productsStore.setSelectedProductById(urlParams.get('selectedProductId'));
    }

    if (urlParams.has('selectedRatePlanId')) {

      productsStore.setSelectedProductRateplanById(urlParams.get('selectedRatePlanId'));
    }

    if (urlParams.has('discountRatePlanId') && urlParams.has('discountId')) {

      productsStore.setDiscountFromMyApp(urlParams.get('discountRatePlanId'), urlParams.get('discountId'));
    } else {

      productsStore.setDiscountFromMyApp();
    }

    if (!mypageStore.selectedManageableVehicle && urlParams.has('plate')) {
      await mypageStore.loadUserManageableVehicles();

      const vehicle = mypageStore.manageableVehicles.find(vehicle => vehicle.license_plate === urlParams.get('plate'));
      vehicle && vehicle.uuid && mypageStore.setSelectedManageableVehicleByUuid(vehicle.uuid);
    }

    if (!authStore.user
      || !mypageStore.selectedManageableVehicle
      || !(productsStore.selectedProduct || productsStore.optionalServiceProduct || productsStore.selectedAddOnProduct)
        || !(productsStore.selectedProductRateplan || productsStore.optionalService || productsStore.selectedAddOn)
    ) {
      this.props.history.push('/');
    }

    window.zuoraHostedSubmitFailed = this.zuoraHostedSubmitFailed;
    window.zuoraHostedSubmitSucceed = this.zuoraHostedSubmitSucceed;
    window.zuoraHostedSubmitProcessing = this.zuoraHostedSubmitProcessing;
    window.Z.setEventHandler("onCaptchaStateChange", this.removeModalLayer);

    this.setState(
      {
        checkedAgreedGeneralConditions: false,
        checkedAgreedRestrictiveClauses: false
      },
      () => {
        switch (selectedPaymentMethod) {
          case 'creditcard':
            this.loadPayment();
        }
      }
    );
  };

  componentDidUpdate = () => {
    const zuoraDOM = document.getElementById(this.zuoraDOMID);
    if (zuoraDOM !== null) {
      this.mutationObserver.observe(zuoraDOM, {
        attributes: true,
        subtree: true
      });
    }
  };

  componentWillUnmount = () => {
    window.zuoraHostedSubmitFailed = undefined;
    window.zuoraHostedSubmitSucceed = undefined;
    window.zuoraHostedSubmitProcessing = undefined;

    this.mutationObserver.disconnect();
  };

  showErrorMessage = () => {
    const { translationsStore } = this.props;
    message.error(translationsStore.translate('common.genericError'));
  };

  loadCreditCard = async () => {
    const { translationsStore } = this.props;
    this.setState({ loadingToken: true, renderingPaymentFrame: true });
    const pageId = process.env.REACT_APP_ZUORA_HOSTED_PAGE_ID;
    const result = await api('getZuoraHostedPageToken', pageId).finally(() => this.setState({ loadingToken: false }));
    const locale = translationsStore.translate('common.localeISO') !== 'common.localeISO' ? translationsStore.translate('common.localeISO') : 'it_IT';

    return {
      id: pageId,
      signature: result.signature,
      token: result.token,
      tenantId: result.tenantId,
      key: result.key,
      field_accountId: this.props.mypageStore.remoteUserData.zuora_account_id,
      style: 'inline',
      submitEnabled: 'false',
      locale: locale,
      param_supportedTypes: 'Visa,MasterCard',
      url: process.env.REACT_APP_ZUORA_HOSTED_PAGE_URL,
      paymentGateway: process.env.REACT_APP_ZUORA_PAYMENT_GATEWAY
    };
  };

  loadPayment = () => {
    const { mypageStore, history } = this.props;

    const loadMe = async () => {
      const params = await this.loadCreditCard();
      const callback = async response => {
        if (!response.success) {
          this.showErrorMessage();
          return;
        }
      };

      // render the Zuora Hosted Page
      window.Z.renderWithErrorHandler(params, { creditCardCountry: 'ITA' }, callback, this.zuoraClientErrorMessageCallback, null, null, this.removeModalLayer);
    };
    loadMe();
  };

  onPaymentSubmit = async () => {
    const { selectedPaymentMethod } = this.state;

    if (this.isAssistance()) {
      const { checkedAgreedGeneralConditions, checkedAgreedRestrictiveClauses } = this.state;
      const {
        addOnServiceAgreements: { airclub_general, airclub_general_articles, air_club_profiling, air_club_marketing, air_club_third_parties },
      } = this.state;

      await this.props.mypageStore.updateAirClubMarketingConsent(
          checkedAgreedGeneralConditions,
          air_club_profiling,
          air_club_marketing,
          air_club_third_parties,
      );
    }

    this.setState(
      {
        isSubmitting: true,
        renderingPaymentFrame: true,
        lastPaymentErrorMessage: undefined
      },
      () => {
        switch (selectedPaymentMethod) {
          case 'creditcard':
            window.Z.submit();
        }
      }
    );
  };

  debouncedClearSubmitting = debounce(cb => {
    this.setState({ isSubmitting: false }, () => {
      isFunction(cb) && cb();
    });
  }, 1000);

  zuoraClientErrorMessageCallback = (key, code, message) => {
    this.debouncedClearSubmitting();
    window.Z.sendErrorMessageToHpm(key, message);
  };

  removeModalLayer = () => {
    this.setState({ isSubmitting: false, renderingPaymentFrame: false, hideSubmitPaymentButton: true });
  };

  zuoraServerErrorMessageCallback = callbackResponse => {
    this.setState({ isSubmitting: false, lastPaymentErrorMessage: callbackResponse.errorMessage });
  };

  zuoraHostedSubmitFailed = callbackResponse => {

    const { mypageStore } = this.props;

    if (!(callbackResponse && callbackResponse.errorCode && callbackResponse.errorCode.includes('ThreeDs2_Authentication_Exception'))) {
      rollbar.error(`Error zuora hosted page submit for user with uuid ${mypageStore.remoteUserData.uuid}, callbackResponse ${JSON.stringify(callbackResponse)}`);
    }

    const loadMeWhenSubmitFailed = async () => {
      const params = await this.loadCreditCard();

      const callback = async response => {
        if (!response.success) {
          this.showErrorMessage();
          return;
        }
      };

      window.Z.renderWithErrorHandler(params, { creditCardCountry: 'ITA' }, callback, this.zuoraClientErrorMessageCallback, null, null, this.removeModalLayer);
      window.Z.runAfterRender(() => {
        this.zuoraServerErrorMessageCallback(callbackResponse);
      });
    };
    loadMeWhenSubmitFailed();
  };

  zuoraHostedSubmitSucceed = async callbackResponse => {
    this.setState({ isSubmitting: true });

    const { productsStore } = this.props;

    try {

      let route = `/thankyoupage`;

      if (this.isProductMoveinRenewal()) {

        route = `${route}?movein=true&renewal=true`;
      } else if (this.isProductMovein()) {
        route = `${route}?movein=true`;
      }
      await productsStore.subscribeToProduct('Carta di credito', callbackResponse.refId);

      this.setState({ isSubmitting: false }, () => {
        this.props.history.push(route);
      });
    } catch (error) {
      this.showErrorMessage();
    }
  };

  zuoraHostedSubmitProcessing = callbackResponse => {

    if (callbackResponse.errorCode) {
      this.showErrorMessage();
      this.setState({ hideSubmitPaymentButton: false })
    }
  };

  onViewGroupChange = e => {
    const {
      dataCollection: {
        handlers: { goToKeyRoute }
      }
    } = this.props;
    goToKeyRoute(e.target.value);
  };

  onPaymentMethodChange = e => {
    const selectedPaymentMethod = e.target.value;
    this.setState({ selectedPaymentMethod }, () => {
      switch (selectedPaymentMethod) {
        case 'creditcard':
          this.loadPayment();
      }
    });
  };

  isProductMovein = () => {
    return this.props.productsStore.selectedProduct
      ? this.props.productsStore.selectedProduct.features.some(feature => feature.node.name === 'movein')
      : false;
  };

  isMyAppAddon = () => {
    return this.props.productsStore.selectedProduct
        ? this.props.productsStore.selectedProduct.features.some(feature => feature.node.name === 'myapp')
        : false;
  };

  isProductRenew = () => {
    return this.props.productsStore.selectedProduct
      ? this.props.productsStore.selectedProduct.features.some(feature => feature.node.name === 'air') && this.props.productsStore.selectedProduct.category === 'renew'
      : false;
  };

  isProductMoveinRenewal = () => {
    const { selectedProduct } = this.props.productsStore;
    return selectedProduct
      ? selectedProduct.features.some(feature => feature.node.name === 'movein') && selectedProduct.category === 'renew'
      : false;
  };

  isAssistance = () => {
    const { productsStore } = this.props;
    const { selectedProduct } = productsStore;

    return selectedProduct
      ? productsStore.isAssistance(selectedProduct)
      : false;
  };

  isOptionalAssistance = () => {
    const { productsStore } = this.props;
    const { optionalServiceProduct } = productsStore;

    return optionalServiceProduct
      ? productsStore.isAssistance(optionalServiceProduct)
      : false;
  };

  render() {
    const { mypageStore, authStore, translationsStore } = this.props;
    const {
      checkedAgreedGeneralConditions,
      checkedAgreedRestrictiveClauses,
      visibleStandardFormContract,
      lastPaymentErrorMessage,
      selectedPaymentMethod
    } = this.state;
    const { dataCollection } = this.props;


    if (!!mypageStore.remoteUserData && mypageStore.remoteUserData.addresses.length === 0) {
      mypageStore.setHasToCompleteProfile(true);
      return <Redirect to="/profile" />;
    }

    const viewGroupItems = [
      { value: 'vehicles', title: translationsStore.translate('groupViews.myair.vehicles') },
      { value: 'documents', title: translationsStore.translate('groupViews.myair.documents') },
      // { value: 'assets', title: translationsStore.translate('groupViews.myair.assets') },
      { value: 'services', title: translationsStore.translate('groupViews.myair.services') }
    ];
    const defaultGroupItem = 'services';
    const {
      addOnServiceAgreements: { airclub_general, airclub_general_articles, air_club_profiling, air_club_marketing, air_club_third_parties },
    } = this.state;
    const isCheckedConditionsRequiredForSelectedProduct = this.isProductMovein() || this.isProductRenew() || this.isAssistance() || this.isMyAppAddon();
    let validAgreements = checkedAgreedGeneralConditions && checkedAgreedRestrictiveClauses;
    if (mypageStore.remoteUserIsFromMoVeInProgram && this.isAssistance()) {
      validAgreements = validAgreements && air_club_profiling !== undefined && air_club_marketing !== undefined && air_club_third_parties !== undefined;
    }

    const disabledPaymentSubmit = this.state.isSubmitting || !(validAgreements || !isCheckedConditionsRequiredForSelectedProduct);

    return (
      <MyPageContainer dataCollection={dataCollection}>
        <div className="InstallerPaymentView">
          <VehicleSelector disabled={true} />
          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {
              const inputProps = breakpoints[currentBreakpoint] >= breakpoints.desktop ? { size: 'large' } : {};
              const clsHeaderPaymentMethod = classNames({
                HeaderTwo: true,
                ['HeaderTwo-lg']: currentBreakpoint === 'desktop',
                PaymentPane: true
              });
              const clsHeaderAgreements = classNames({
                HeaderTwo: true,
                ['HeaderTwo-lg']: currentBreakpoint === 'desktop'
              });

              const radioStyle = {
                display: 'block',
                height: '30px',
                lineHeight: '30px'
              };

              return (
                <Fragment>
                  <MyPageRadioGroup {...inputProps} className="SubPageNavbar" defaultValue={defaultGroupItem} onChange={this.onViewGroupChange} buttonStyle="solid">
                    {viewGroupItems.map((item, index) => (
                      <MyPageRadioButton key={index} value={item.value}>
                        {item.title}
                      </MyPageRadioButton>
                    ))}
                  </MyPageRadioGroup>

                  <LoadingOverlay
                    active={this.state.renderingPaymentFrame || this.state.isSubmitting}
                    spinner={<PulseLoader sizeUnit={'px'} size={20} color={'var(--action-primary-color)'} loading={true} />}
                    styles={{ overlay: base => ({ ...base, background: 'var(--grey-light-color-50)' }) }}
                  >
                    <div className={clsHeaderPaymentMethod}>{translationsStore.translate('installerView.sectionPaymentMethod.labelTitle')}</div>
                    <Section className="PaymentPane">
                      <Radio.Group onChange={this.onPaymentMethodChange} value={this.state.selectedPaymentMethod}>
                        <Radio style={radioStyle} value={'creditcard'}>
                          {mypageHelper.paymentIcon('creditcard')} {translationsStore.translate(`common.paymentMethods.creditcard.label`)}
                        </Radio>
                        <Radio style={radioStyle} value={'sepa'}>
                          {mypageHelper.paymentIcon('sepa')} {translationsStore.translate(`common.paymentMethods.sepa.label`)}
                        </Radio>
                      </Radio.Group>
                    </Section>

                    {lastPaymentErrorMessage && (
                      <div className="PaymentErrorMessage PaymentPane">
                        <ErrorOutlinedIcon />
                        {`${translationsStore.translate('installerView.labelPaymentCallbackError')}: `}
                        <i>{lastPaymentErrorMessage}</i>
                      </div>
                    )}

                    <div>
                      {(() => {
                        switch (selectedPaymentMethod) {
                          case 'creditcard':
                            return <div id={this.zuoraDOMID} className="ZuoraPayment PaymentPane" />;
                          case 'sepa':
                            return (mypageStore.remoteUserIsFromMoVeInProgram && this.isAssistance()) ? (
                              <SlimpayIframe
                                clsHeaderAgreements={clsHeaderAgreements}
                                addOnServiceAgreements={this.state.addOnServiceAgreements}
                                onAddOnServiceAgreementsChange={(addOnServiceAgreements) => this.setState({ addOnServiceAgreements })}
                              />
                            ) : (
                              <SlimpayIframe clsHeaderAgreements={clsHeaderAgreements} />
                            );
                        }
                      })()}
                      {selectedPaymentMethod === 'creditcard' && <CreditCardFriendlyReminder />}
                      {selectedPaymentMethod !== 'sepa' && (
                        // TODO current sepa slimpay integration inserted this inside its component
                        <Fragment>
                          {this.isProductMovein() && <PaymentAgreements
                            checkedAgreedGeneralConditions={checkedAgreedGeneralConditions}
                            checkedAgreedRestrictiveClauses={checkedAgreedRestrictiveClauses}
                            onChangeAgreedGeneralConditions={checked => {
                              this.setState({ checkedAgreedGeneralConditions: checked });
                            }}
                            onChangeAgreedRestrictiveClauses={checked => {
                              this.setState({ checkedAgreedRestrictiveClauses: checked });
                            }}
                            isMoVeInRenewal={this.isProductMoveinRenewal()}
                            isAssistance={this.isAssistance()}
                          />}
                          {this.isProductRenew() && <PaymentAgreements
                            isAssistance={this.isAssistance()}
                            checkedAgreedGeneralConditions={checkedAgreedGeneralConditions}
                            checkedAgreedRestrictiveClauses={checkedAgreedRestrictiveClauses}
                            onChangeAgreedGeneralConditions={checked => {
                              this.setState({ checkedAgreedGeneralConditions: checked });
                            }}
                            onChangeAgreedRestrictiveClauses={checked => {
                              this.setState({ checkedAgreedRestrictiveClauses: checked });
                            }}
                            isRenewProduct={this.isProductRenew()}
                          />}
                          {(this.isAssistance() || this.isMyAppAddon()) && <PaymentAgreements
                            isAssistance={this.isAssistance()}
                            isMyApp={this.isMyAppAddon()}
                            checkedAgreedGeneralConditions={checkedAgreedGeneralConditions}
                            checkedAgreedRestrictiveClauses={checkedAgreedRestrictiveClauses}
                            onChangeAgreedGeneralConditions={checked => {
                              this.setState({ checkedAgreedGeneralConditions: checked });
                            }}
                            onChangeAgreedRestrictiveClauses={checked => {
                              this.setState({ checkedAgreedRestrictiveClauses: checked });
                            }}
                            hideSubTitle={true}
                            generalConditionsDocumentSource={K.POLICY_BASE_URL.concat('condizioni-generali-servizi-aggiuntivi-2022-09-23.pdf')}
                            labelRestrictiveConditions={translationsStore.translate('installerView.sectionAgreements.labelRestrictiveClausesAdditionalProduct')}
                            labelGeneralConditionPrefix={translationsStore.translate('installerView.sectionAgreements.labelAdditionalGeneralConditionsPrefix')}
                            labelGeneralConditionClickable={translationsStore.translate('installerView.sectionAgreements.labelGeneralConditions')}
                            labelGeneralConditionSuffix={translationsStore.translate('installerView.sectionAgreements.labelAdditionalGeneralConditionsSuffix')}
                            {...(mypageStore.remoteUserIsFromMoVeInProgram
                              ? {
                                addOnServiceAgreements: this.state.addOnServiceAgreements,
                                onAddOnServiceAgreementsChange: (addOnServiceAgreements) => this.setState({ addOnServiceAgreements }),
                              }
                              : {})}
                          />}
                          {!this.state.hideSubmitPaymentButton && <MyPageButton
                            className="PaymentPane"
                            theme="primary"
                            style={{ marginTop: 40, height: 40, fontSize: 16 }}
                            solid
                            title={translationsStore.translate('installerView.labelPaymentProceed')}
                            disabled={disabledPaymentSubmit}
                            onClick={this.onPaymentSubmit}
                          />}
                        </Fragment>
                      )}
                    </div>
                  </LoadingOverlay>

                </Fragment>
              );
            }}
          </BreakpointsMedia>
        </div>
      </MyPageContainer>
    );
  }
}
