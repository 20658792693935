import React, { Component } from 'react';
import classNames from 'classnames';
import { Input, DatePicker } from 'antd';

import './LabelInput.css';

export default class LabelInput extends Component {
  getWidget = () => {
    const {value, type, placeholder} = this.props;

    if (type === 'password') {
      return (
        <Input.Password
          placeholder={placeholder}
          value={value}
          onChange={this.props.onChange}
          onKeyDown={this.props.onKeyDown}
          onKeyUp={this.props.onKeyUp}
          maxLength={this.props.maxlength}
          disabled={this.props.disabled}
          data-testid={this.props.dataTestId}
        />
      )
    }

    if (type === 'date') {
      return (
        <DatePicker
          placeholder={placeholder}
          value={value}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          style={{width: '100%'}}
          disabledDate={this.props.disabledDate}
          data-testid={this.props.dataTestId}
        />
      );
    }

    return (<Input
        placeholder={placeholder}
        value={value}
        onChange={this.props.onChange}
        onKeyDown={this.props.onKeyDown}
        onKeyUp={this.props.onKeyUp}
        maxLength={this.props.maxlength}
        disabled={this.props.disabled}
        data-testid={this.props.dataTestId}
      />
    );
  };

  render() {
    const cls = classNames({
      LabelInput: true,
      /* eslint-disable no-useless-computed-key */
      ['has-error']: this.props.validateStatus === 'error'
    });
    const { label} = this.props;
    const widget = this.getWidget();

    return (
      <div className={cls} style={this.props.style}>
        {label ? <div>{label}</div> : undefined}
        {widget}
        {!!this.props.help && <div className={'LabelInputExplain'}>{this.props.help}</div>}
      </div>
    );
  }
}
