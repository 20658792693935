import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LabelInput from '../../../../../common/components/LabelInput';

import './ContactDataForm.css';
import MyPageButton from '../../../../../common/components/MyPageButton';
import { Form, message } from 'antd';

@inject('translationsStore', 'mypageStore')
@observer
class ContactDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = e => {
    e.preventDefault();

    const hideLoading = message.loading('loading', 0);
    try{
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (err) {
          throw this.props.translationsStore.translate('vehiclesView.contactData.errorMessage');
        }

        this.props.mypageStore.updateVehicleContactData(this.props.form.getFieldValue('contact_email'),this.props.form.getFieldValue('contact_mobile'))
          .then(()=>{
            message.success(this.props.translationsStore.translate('vehiclesView.contactData.successMessage'));
          })
          .catch(()=>{
            throw this.props.translationsStore.translate('vehiclesView.contactData.errorMessage');
          });

      });
    }catch (errorMessage) {
      message.error(errorMessage);
    } finally{
      hideLoading();
    }

  };

  render() {
    const { translationsStore, mypageStore } = this.props,
      { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <div className={"FieldsRow"}>
          <Form.Item>
            {getFieldDecorator('contact_email', {
              initialValue: mypageStore.selectedManageableVehicle.contact_email ? mypageStore.selectedManageableVehicle.contact_email : mypageStore.remoteUserData.email,
              rules: [
                {
                  required: true,
                  message: translationsStore.translate('common.requiredField')
                },
                {
                  type: "email",
                  message: translationsStore.translate('vehiclesView.contactData.invalidEmailMessage')
                }
              ]
            })(<LabelInput label={translationsStore.translate('vehiclesView.contactData.labelContactEmail')} type={'text'} style={{ marginBottom: 20 }} dataTestId={'contactEmailEditableTestId'}/>)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('contact_mobile', {
              initialValue: mypageStore.selectedManageableVehicle.contact_mobile ? mypageStore.selectedManageableVehicle.contact_mobile : mypageStore.remoteUserData.mobile,
              rules: [
                {
                  required: true,
                  message: translationsStore.translate('common.requiredField')
                },
                {
                  min: 8,
                  message: translationsStore.translate('vehiclesView.contactData.invalidPhoneMessageLength')
                },
                {
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  message: translationsStore.translate('vehiclesView.contactData.invalidPhoneMessage')
                }
              ]
            })(<LabelInput label={translationsStore.translate('vehiclesView.contactData.labelContactPhone')} type={'text'} style={{ marginBottom: 20 }} dataTestId={'contactPhoneEditableTestId'}/>)}
          </Form.Item>
          <div style={{ flexGrow: 2, marginRight: 24 }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MyPageButton onClick={this.handleSubmit} theme="primary" title={translationsStore.translate('profileView.submitButton')} solid style={{ maxWidth: '50px' }} />
        </div>
      </Form>
    );
  }
}

export default Form.create({ name: 'contactDataForm' })(ContactDataForm);
